import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { courseData } from './core/models';


const CourseBuffer = () => {
    const navigate = useNavigate();
    const {id} = useParams();
    const courseId = courseData.find(o => o.id == id).urlTitle;

    useEffect(() => {
        setTimeout(() => {
            navigate(`/user-dashboard/course/course-preview/${courseId}`)
        }, 5000);
        return () => clearTimeout()
    }, []);

    return (
        <div style={{ backgroundImage: `url("/assets/img/CourseBuffer.png)` }}>
            <img src='/assets/img/CourseBuffer.png' className='position-relative' alt='' />
            <div className='' style={{ top: "50%", right: "50%" }}>
                <div className="loader"></div>
            </div>
        </div>
    )
}

export default CourseBuffer