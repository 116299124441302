import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const ClientGeneralQuestionsTable = ({ questions }) => {
  //console.log("QUESTIONS", questions)
  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table" className="GeneralQuestionsTable">
      <TableBody>
        {
          questions?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}. {item.question}</TableCell>

              <TableCell>{`${item.response}`}</TableCell>
            </TableRow>
          ))
        }



      </TableBody>
    </Table>
  );
};

export default ClientGeneralQuestionsTable;
