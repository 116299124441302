import React, { useEffect, useState } from 'react'
import { Button, CloseButton, Modal } from 'react-bootstrap'
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import NearMeIcon from '@mui/icons-material/NearMe';
import { Checkbox, FormControl, FormControlLabel, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import "./LocationModal.css"
import { GeoLocationExpertStates, GetGeoLocationByBrowser } from './core/_request';
import { useAuth } from '../auth';

const LocationModal = (props) => {
    const [city, setCity] = useState();
    const [country, setCountry] = useState("");
    const [step, setStep] = useState(1);
    const [active, setActive] = useState(false);
    const [longitude, setLongitude] = useState();
    const [latitude, setLatitude] = useState();
    const [addressStatus, setAddressStatus] = useState(false);
    const [manuallyData, setManuallyData] = useState(false);
    const [locationData, setLocationData] = useState();
    const [state, setState] = useState();
    const [stateList, setStateList] = useState();
    const { currentUser, logout, updateMobileNo, updateLocation } = useAuth();

    var stateBody = { country: country }
    useEffect(() => {
        GeoLocationExpertStates(stateBody).then(resp => {
            if (resp?.code === 200) {
                setStateList(resp?.data);
            } else {
                console.log(resp?.responseMessage);
            }
        });
    }, [country]);

    const handleStateChange = (event) => {
        debugger
        setState(event?.target?.value);
        updateLocation(event?.target?.value);
    };

    const handleCountryChange = (event, values) => {
        setCountry(event?.target?.value);
    }

    const onGPSButton = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
        } else {
            console.log("Geolocation not supported");
        }

        function success(position) {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
            console.log("position", position)
        }

        function error() {
            console.log("Unable to retrieve your location");
        }

        var locationBody = { lat: latitude, lon: longitude }
        GetGeoLocationByBrowser(locationBody).then(resp => {
            if (resp?.code === 200) {
                setLocationData(resp?.data);
                setCountry(resp?.data?.address?.country)
                setState(resp?.data?.address?.state);
                setCity(resp?.data?.address?.city_district);
                console.log("location Data", resp?.data);
                sessionStorage?.setItem("UserState", resp?.data?.address?.state);
                updateLocation(resp?.data?.address?.state)
                setAddressStatus(true);
            }
        })
    };

    const onClickLocationSubmit = () => {
        window.location.href = "/?Profession=Therapist";
    }

    const onClickManualAddress = () => {
        setManuallyData(true);
        setAddressStatus(true);
    }

    return (
        <>
            <div className='LocationModal'>
                <Modal
                    {...props}
                    // size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='Centered-location-modal'
                >
                    {
                        <Modal.Body>
                            {/* <div>
                                <iframe
                                    title='Google Map'
                                    width="600"
                                    height="450"
                                    frameBorder="0"
                                    style={{ border: 0 }}
                                    src={googleMapURL}
                                    allowFullScreen
                                ></iframe>
                            </div> */}
                            <div className='d-flex align-items-center justify-content-between my-2'>
                                <h6 className='font-roboto text-black font-w400' style={{ fontSize: "30px", fontStyle: "400" }}>Set your location</h6>
                                <CloseButton onClick={props.onHide} />
                            </div>
                            <div className='rounded-3 px-3 py-2 d-flex flex-column gap-3'
                                style={{ border: "1px solid #541CF533", background: "#fff", boxShadow: "0px 0px 13px 0px #0000001A" }}
                                onClick={onGPSButton}>
                                <div className='d-flex align-items-center gap-3'>
                                    <GpsFixedIcon sx={{ color: locationData && "#541CF5" || "#000" }} />
                                    <div>
                                        <p className='font-inter font-w500' style={{ fontSize: 16, fontStyle: "normal", color: locationData && "#541CF5" || "#282828" }}>Detect current location</p>
                                        <p className='font-inter' style={{ fontSize: 14, fontStyle: "normal", color: "#282828" }}>Using GPS</p>
                                    </div>
                                    <br />
                                </div>
                                {locationData &&
                                    <TextField
                                        disabled
                                        className='my-2'
                                        value={`${locationData?.address?.state_district ? locationData?.address?.state_district : ''} ${locationData?.address?.state ? locationData?.address?.state : ''} ${locationData?.address?.country ? locationData?.address?.country : ''} - ${locationData?.address?.postcode ? locationData?.address?.postcode : ''}`} />
                                }
                            </div>
                            <div className='d-flex align-items-center my-3'>
                                <div className="line"></div>
                                <div className="or">OR</div>
                                <div className="line"></div>
                            </div>
                            <div className='rounded-3 px-3 py-1' style={{ border: "1px solid #541CF533", background: "#fff", boxShadow: "0px 0px 13px 0px #0000001A" }}>
                                <div className='d-flex align-items-center justify-content-between py-2'>
                                    <p style={{ color: active && "#541CF5" || "" }}><NearMeIcon /> Add location</p>
                                    {active === false &&
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setActive(true)}>
                                            <circle cx="12" cy="12" r="12" fill="#EBE3FF" />
                                            <path d="M16.5 13.1484H12.75V17.0156C12.75 17.2208 12.671 17.4175 12.5303 17.5625C12.3897 17.7076 12.1989 17.7891 12 17.7891C11.8011 17.7891 11.6103 17.7076 11.4697 17.5625C11.329 17.4175 11.25 17.2208 11.25 17.0156V13.1484H7.5C7.30109 13.1484 7.11032 13.067 6.96967 12.9219C6.82902 12.7769 6.75 12.5801 6.75 12.375C6.75 12.1699 6.82902 11.9731 6.96967 11.8281C7.11032 11.683 7.30109 11.6016 7.5 11.6016H11.25V7.73438C11.25 7.52925 11.329 7.33252 11.4697 7.18747C11.6103 7.04242 11.8011 6.96094 12 6.96094C12.1989 6.96094 12.3897 7.04242 12.5303 7.18747C12.671 7.33252 12.75 7.52925 12.75 7.73438V11.6016H16.5C16.6989 11.6016 16.8897 11.683 17.0303 11.8281C17.171 11.9731 17.25 12.1699 17.25 12.375C17.25 12.5801 17.171 12.7769 17.0303 12.9219C16.8897 13.067 16.6989 13.1484 16.5 13.1484Z" fill="black" />
                                        </svg>
                                    }
                                </div>
                                {active && !manuallyData &&
                                    <>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            {/* <div> */}
                                            <FormControl className='col-3' sx={{ m: 1 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={country}
                                                    label="Country"
                                                    onChange={handleCountryChange}
                                                // defaultChecked={ }
                                                >
                                                    <MenuItem value={"united states"}>USA</MenuItem>
                                                </Select>
                                            </FormControl>
                                            {/* </div>
                                            <div > */}
                                            <FormControl className='col-3' sx={{ m: 1 }}>
                                                <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={state}
                                                    onChange={(e) => handleStateChange(e)}
                                                    label="State"
                                                >
                                                    {stateList?.states?.map((item, id) =>
                                                        <MenuItem value={item?.name}>{item?.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                            {/* </div> */}
                                            <div className='col-4'>
                                                <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="City"
                                                    defaultValue={city}
                                                    onChange={(e) => setCity(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <Button className='bg-white d-block ms-auto my-2'
                                            style={{ border: "1px solid #FD6F1F", color: "#FD6F1F" }}
                                            onClick={onClickManualAddress}>Ok</Button>
                                    </>
                                }
                                {manuallyData &&
                                    // <TextField
                                    //     disabled
                                    //     className='my-2'
                                    //     value={`${city ? city : ''} ${state ? state : ''} ${country ? country : ''}`}
                                    // />
                                    <OutlinedInput
                                        // disabled={disable}
                                        id="outlined-adornment-weight"
                                        endAdornment={<InputAdornment position="end">
                                            <Link onClick={() => setManuallyData(false)} className='text-decoration-none font-w600' style={{ fontSize: "16px", color: "#541CF5", cursor: 'pointer' }}>Change</Link>
                                        </InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        className='my-3 w-100'
                                        value={`${city ? city : ''} ${state ? state : ''} ${country ? country : ''}`}
                                    />

                                }
                            </div>
                            <Button
                                disabled={!addressStatus}
                                onClick={onClickLocationSubmit}
                                className='d-block mx-auto my-3 rounded-5 px-3'
                                style={{ borderRadius: "53.344px", border: "1.067px solid #FD6F1F", background: "#FD6F1F", boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05);" }}>
                                {/* border:"1.067px solid #FD6F1F", background:"#FD6F1F", boxShadow:"0px 1px 2px 0px rgba(16, 24, 40, 0.05)" */}
                                Submit
                            </Button>
                        </Modal.Body>
                    }
                </Modal>
            </div>
        </>
    )
}

export default LocationModal