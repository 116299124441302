import React, { useEffect, useState } from 'react'
import { Button, CloseButton, Modal } from 'react-bootstrap'
import { GeoLocationExpertStates, GetGeoLocationByBrowser } from './core/_request';
import { Checkbox, FormControlLabel, InputAdornment, InputLabel, MenuItem, FormControl, OutlinedInput, Select, TextField } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import NearMeIcon from '@mui/icons-material/NearMe';

const LocationConfirmation = (props) => {
    const [step, setStep] = useState(1)
    const [longitude, setLongitude] = useState();
    const [latitude, setLatitude] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState("");
    const [state, setState] = useState();
    const [locationData, setLocationData] = useState();
    const [addressStatus, setAddressStatus] = useState(false);
    const [stateList, setStateList] = useState();
    const [disable, setDisable] = useState(true)
    var locationStateArray = props?.data?.licensed_practice_in_state?.split(',');
    const { currentUser, updateLocation } = useAuth();
    const navigate = useNavigate();
    const [active, setActive] = useState(false);
    const [buttonDisable, setButtonDisable] = useState(true);
    const [manuallyData, setManuallyData] = useState(false);

    var stateBody = { country: "united states" }
    useEffect(() => {
        GeoLocationExpertStates(stateBody).then(resp => {
            if (resp?.code === 200) {
                setStateList(resp?.data);
            } else {
                alert(resp?.responseMessage);
            }
        });
    }, []);

    const onGPSButton = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
        } else {
            console.log("Geolocation not supported");
        }

        function success(position) {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
            console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
            console.log("position", position)
        }

        function error() {
            console.log("Unable to retrieve your location");
        }
        var locationBody = { lat: latitude, lon: longitude }
        GetGeoLocationByBrowser(locationBody).then(resp => {
            if (resp?.code === 200) {
                setLocationData(resp?.data);
                setCountry(resp?.data?.address?.country)
                setState(resp?.data?.address?.state);
                setCity(resp?.data?.address?.city_district);
                console.log("location Data", resp?.data);
                updateLocation(resp?.data?.address?.state);
                sessionStorage?.setItem("UserState", resp?.data?.address?.state);
                setAddressStatus(true);
            }
        })
    };

    const handleDisable = () => {
        setStep(2);
        setDisable(false)
    }

    const onClickConfirm = () => {
        navigate(`/book-an-appointment/${props?.data?.Id}`);
        props.onHide();
    }

    const handleCountryChange = (event, values) => {
        setCountry(event?.target?.value);
    }

    const handleStateChange = (event, values) => {
        debugger
        setState(event?.target?.value);
        updateLocation(event?.target?.value);
    };

    const onClickManualAddress = () => {
        setAddressStatus(true);
        setManuallyData(true);
        navigate('/?Profession=Mental+Health+and+Wellness+Coach');
    }

    const handleCheckboxChange = (e) => {
        if (e?.target?.checked) {
            setButtonDisable(false);
        } else {
            setButtonDisable(true);
        }
    }

    return (
        <div className='LocationModal'>
            <Modal
                {...props}
                // size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='Centered-location-modal'
            >
                <Modal.Body>
                    <div className='w-100'>
                        <CloseButton className='d-block ms-auto' onClick={props.onHide} />
                    </div>
                    <div className='d-flex align-items-center justify-content-between my-2'>
                        <h6 className='font-roboto text-black font-w400' style={{ fontSize: "30px", fontStyle: "400" }}>Confirmation</h6>
                    </div>
                    <div>
                        <p className='font-inter font-w600' style={{ fontSize: 16, fontStyle: "normal", color: "#000" }}>The expert is licensed to practice in following states :</p>
                        <div className='d-flex align-items-center gap-2 py-3' style={{ overflowX: "scroll", scrollbarWidth: "thin" }}>
                            {locationStateArray && locationStateArray?.map((item) =>
                                <div className='rounded-3 px-3 py-2' style={{ border: "1px solid #D9D9D9", whiteSpace: "nowrap" }}>
                                    <p>{item}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    {step === 1 &&
                        <div>
                            <div className='my-3'>
                                <p className='font-inter font-w500' style={{ fontSize: "16px", color: "#541CF5" }}>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.625 7.875C16.625 7.07065 16.4666 6.27418 16.1588 5.53106C15.851 4.78794 15.3998 4.11273 14.831 3.54397C14.2623 2.97521 13.5871 2.52405 12.8439 2.21624C12.1008 1.90843 11.3043 1.75 10.5 1.75C9.69565 1.75 8.89918 1.90843 8.15606 2.21624C7.41294 2.52405 6.73773 2.97521 6.16897 3.54397C5.60021 4.11273 5.14905 4.78794 4.84124 5.53106C4.53343 6.27418 4.375 7.07065 4.375 7.875C4.375 9.08862 4.73288 10.2174 5.34188 11.1694H5.33488L10.5 19.25L15.6651 11.1694H15.659C16.2898 10.1864 16.625 9.04296 16.625 7.875ZM10.5 10.5C9.80381 10.5 9.13613 10.2234 8.64384 9.73116C8.15156 9.23887 7.875 8.57119 7.875 7.875C7.875 7.17881 8.15156 6.51113 8.64384 6.01884C9.13613 5.52656 9.80381 5.25 10.5 5.25C11.1962 5.25 11.8639 5.52656 12.3562 6.01884C12.8484 6.51113 13.125 7.17881 13.125 7.875C13.125 8.57119 12.8484 9.23887 12.3562 9.73116C11.8639 10.2234 11.1962 10.5 10.5 10.5Z" fill="#541CF5" />
                                    </svg>
                                    Your location
                                </p>
                                <OutlinedInput
                                    disabled={disable}
                                    id="outlined-adornment-weight"
                                    endAdornment={<InputAdornment position="end">
                                        <Link onClick={handleDisable} className='text-decoration-none font-w600' style={{ fontSize: "16px", color: "#541CF5", cursor: 'pointer' }}>Change</Link>
                                    </InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    className='my-3 w-100'
                                    defaultValue={currentUser?.Location ? currentUser?.Location : (state ? state : '')}
                                />
                            </div>
                        </div>
                    }
                    {step === 2 &&
                        <div>
                            <div className='my-3'>
                                <p className='font-inter font-w500' style={{ fontSize: "16px", color: "#541CF5" }}>
                                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.625 7.875C16.625 7.07065 16.4666 6.27418 16.1588 5.53106C15.851 4.78794 15.3998 4.11273 14.831 3.54397C14.2623 2.97521 13.5871 2.52405 12.8439 2.21624C12.1008 1.90843 11.3043 1.75 10.5 1.75C9.69565 1.75 8.89918 1.90843 8.15606 2.21624C7.41294 2.52405 6.73773 2.97521 6.16897 3.54397C5.60021 4.11273 5.14905 4.78794 4.84124 5.53106C4.53343 6.27418 4.375 7.07065 4.375 7.875C4.375 9.08862 4.73288 10.2174 5.34188 11.1694H5.33488L10.5 19.25L15.6651 11.1694H15.659C16.2898 10.1864 16.625 9.04296 16.625 7.875ZM10.5 10.5C9.80381 10.5 9.13613 10.2234 8.64384 9.73116C8.15156 9.23887 7.875 8.57119 7.875 7.875C7.875 7.17881 8.15156 6.51113 8.64384 6.01884C9.13613 5.52656 9.80381 5.25 10.5 5.25C11.1962 5.25 11.8639 5.52656 12.3562 6.01884C12.8484 6.51113 13.125 7.17881 13.125 7.875C13.125 8.57119 12.8484 9.23887 12.3562 9.73116C11.8639 10.2234 11.1962 10.5 10.5 10.5Z" fill="#541CF5" />
                                    </svg>
                                    Your location
                                </p>
                                {/* <OutlinedInput
                                    disabled={disable}
                                    id="outlined-adornment-weight"
                                    endAdornment={<InputAdornment position="end">
                                        <Link onClick={handleDisable} className='text-decoration-none font-w600' style={{ fontSize: "16px", color: "#541CF5", cursor: 'pointer' }}>Change</Link>
                                    </InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                    className='my-3 w-100'
                                    defaultValue={currentUser?.Location ? currentUser?.Location : (state ? state : '')}
                                /> */}
                            </div>
                            <div className='rounded-3 px-3 py-2 d-flex flex-column gap-3'
                                style={{ border: "1px solid #541CF533", background: "#fff", boxShadow: "0px 0px 13px 0px #0000001A" }}
                                onClick={onGPSButton}>
                                <div className='d-flex align-items-center gap-3'>
                                    <GpsFixedIcon />
                                    <div>
                                        <p className='font-inter font-w500' style={{ fontSize: 16, fontStyle: "normal", color: "#282828" }}>Detect current location</p>
                                        <p className='font-inter' style={{ fontSize: 14, fontStyle: "normal", color: "#282828" }}>Using GPS</p>
                                    </div>
                                    <br />
                                </div>
                                {locationData &&
                                    <TextField
                                        disabled
                                        className='my-2'
                                        value={`${locationData?.address?.state_district ? locationData?.address?.state_district : ''} ${locationData?.address?.state ? locationData?.address?.state : ''} ${locationData?.address?.country ? locationData?.address?.country : ''} - ${locationData?.address?.postcode ? locationData?.address?.postcode : ''}`}
                                    />
                                }
                            </div>
                            <div className='d-flex align-items-center my-3'>
                                <div className="line"></div>
                                <div className="or">OR</div>
                                <div className="line"></div>
                            </div>
                            <div className='rounded-3 px-3 py-1' style={{ border: "1px solid #541CF533", background: "#fff", boxShadow: "0px 0px 13px 0px #0000001A" }}>
                                <div className='d-flex align-items-center justify-content-between py-2'>
                                    <p><NearMeIcon /> Add location</p>
                                    {active === false &&
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setActive(true)}>
                                            <circle cx="12" cy="12" r="12" fill="#EBE3FF" />
                                            <path d="M16.5 13.1484H12.75V17.0156C12.75 17.2208 12.671 17.4175 12.5303 17.5625C12.3897 17.7076 12.1989 17.7891 12 17.7891C11.8011 17.7891 11.6103 17.7076 11.4697 17.5625C11.329 17.4175 11.25 17.2208 11.25 17.0156V13.1484H7.5C7.30109 13.1484 7.11032 13.067 6.96967 12.9219C6.82902 12.7769 6.75 12.5801 6.75 12.375C6.75 12.1699 6.82902 11.9731 6.96967 11.8281C7.11032 11.683 7.30109 11.6016 7.5 11.6016H11.25V7.73438C11.25 7.52925 11.329 7.33252 11.4697 7.18747C11.6103 7.04242 11.8011 6.96094 12 6.96094C12.1989 6.96094 12.3897 7.04242 12.5303 7.18747C12.671 7.33252 12.75 7.52925 12.75 7.73438V11.6016H16.5C16.6989 11.6016 16.8897 11.683 17.0303 11.8281C17.171 11.9731 17.25 12.1699 17.25 12.375C17.25 12.5801 17.171 12.7769 17.0303 12.9219C16.8897 13.067 16.6989 13.1484 16.5 13.1484Z" fill="black" />
                                        </svg>
                                    }
                                </div>
                                {active && !manuallyData &&
                                    <>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <FormControl className='col-3' sx={{ m: 1 }}>
                                                <InputLabel id="demo-simple-select-helper-label">Country</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    defaultValue={country}
                                                    label="Country"
                                                    onChange={handleCountryChange}
                                                // defaultChecked={ }
                                                >
                                                    <MenuItem value={"united states"}>USA</MenuItem>
                                                </Select>
                                            </FormControl>
                                            <FormControl className='col-3' sx={{ m: 1 }}>
                                                <InputLabel id="demo-simple-select-helper-label">State</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-helper-label"
                                                    id="demo-simple-select-helper"
                                                    value={state?.children}
                                                    onChange={handleStateChange}
                                                    label="State"
                                                >
                                                    {stateList?.states?.map((state, id) =>
                                                        <MenuItem value={state?.name}>{state?.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <TextField
                                                required
                                                className='col-4'
                                                id="outlined-required"
                                                label="City"
                                                defaultValue=""
                                                onChange={(e) => setCity(e.target.value)}
                                            />
                                        </div>
                                        <Button className='bg-white d-block ms-auto my-2'
                                            style={{ border: "1px solid #FD6F1F", color: "#FD6F1F" }}
                                            onClick={onClickManualAddress}>Ok</Button>
                                    </>
                                }
                                {/* {manuallyData &&
                                    <OutlinedInput
                                        // disabled={disable}
                                        id="outlined-adornment-weight"
                                        endAdornment={<InputAdornment position="end">
                                            <Link onClick={() => setManuallyData(false)} className='text-decoration-none font-w600' style={{ fontSize: "16px", color: "#541CF5", cursor: 'pointer' }}>Change</Link>
                                        </InputAdornment>}
                                        aria-describedby="outlined-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        className='my-3 w-100'
                                        value={`${city ? city : ''} ${state ? state : ''} ${country ? country : ''}`}
                                    />
                                } */}
                            </div>
                        </div>
                    }
                    <FormControlLabel
                        control={<Checkbox
                            onChange={(e) => handleCheckboxChange(e)} sx={{
                                color: "#CECECE",
                                '&.Mui-checked': {
                                    color: "#541CF5",
                                },
                            }} />}
                        className='mt-4 w-100 justify-content-center'
                        label={<p className='font-roboto mx-auto' style={{ color: "#696969", fontSize: "16px" }}>
                            I confirm my location to proceed for booking appointment</p>}
                    />

                    <Button
                        disabled={buttonDisable}
                        className='d-block mx-auto my-3 rounded-5 px-3 text-white font-w700 font-helvetica d-block mx-auto'
                        style={{ border: "1.067px solid #FD6F1F", background: "#FD6F1F", boxShadow: "0px 1.06688px 2.13376px 0px rgba(16, 24, 40, 0.05)", fontSize: "20px" }}
                        onClick={onClickConfirm}>
                        {/* border:"1.067px solid #FD6F1F", background:"#FD6F1F", boxShadow:"0px 1px 2px 0px rgba(16, 24, 40, 0.05)" */}
                        Confirm and Proceed
                    </Button>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LocationConfirmation