import React from 'react'
import '.././Plans.css'

const TopNavArea = () => {
    return (
        <div className="top-nav mobile-display-none desktop-display">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-9 col-xs-6">
                        <div className="top-nav-text">
                            <p>"Elevate your experience with our premium subscription - unlocking a world of exclusive features and content tailored just for you."</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-xs-6">
                        <div className="top-nav-btn">
                            <button type="button" className="btn"><a href="#Subscribe_Now">Subscribe</a></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopNavArea