import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppointmentTimeService, OODOGetUserProfileV4Service } from './core/_request';
import { useAuth } from '../auth';
import { getCurrencyCode } from './core/commonFun';
import { Button, ButtonGroup, Container, Form, Modal, Nav, NavDropdown, Navbar, Offcanvas, ToggleButton } from 'react-bootstrap';
import { AppContext } from '../context';
import { useContext } from 'react';
import moment from 'moment';
import { GetMySubscription } from './subscriptions/core/request';
import LocationConfirmation from './LocationConfirmation';

const DetailPageCard = (props) => {
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const { data } = props;
    const [sdata, setSdata] = useState();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const CurrencyCode = getCurrencyCode();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [calenderData, setCalenderData] = useState();
    const [loading, setLoading] = useState(false);
    const [slots, setSlots] = useState();
    const [locationConfirmation, setLocationConfirmation] = useState(false);

    useEffect(() => {
        binddata();
    }, []);

    const binddata = () => {
        var dates = [];
        for (var i = 0; i <= 15; i++) {
            var date = moment(new Date()).add("d", i);
            dates.push({ date, avaliableSlots: "Loading", checked: false });
        }
        setCalenderData(dates);

        dates?.forEach((element, index) => {
            AppointmentTimeService(data.Id, moment(element.date).format("YYYY/MM/DD")).then(resp => {
                if (resp.ResponseCode === 200) {
                    var length = resp?.data?.filter(o => o.IsAvailable)?.length;
                    // element.avaliableSlots = length == 0 ? "Not Avaliable" : length + " Available"; 
                    // setCalenderData([...dates]);                  
                    // if (index == 0) {
                    //     setSlots(resp?.data?.filter(o => o.IsAvailable))
                    // }
                    if (length > 0) {
                        element.avaliableSlots = length + " Available";
                    }
                    else {
                        element.avaliableSlots = "Not Avaliable"
                    }
                    element.data = resp?.data?.filter(o => o.IsAvailable);

                    if (dates.filter(o => o.avaliableSlots == "Loading").length == 0) {
                        var ndata = dates.filter(o => o.data.length > 0).sort((o, p) => moment(o.data) < moment(p.data));
                        if (ndata.length > 0) {
                            var sdata = ndata[0];
                            setSlots(sdata?.data?.filter(o => o.IsAvailable));
                            sdata.checked = true;
                        }
                    }
                    setCalenderData([...dates]);
                }

            });
        });
    }

    const getSlotByDate = (element) => {
        setCalenderData(calenderData?.map(o => { if (o.date == element?.date) o.checked = true; else o.checked = false; return o }));

        AppointmentTimeService(data?.Id, moment(element.date).format("YYYY/MM/DD")).then(resp => {
            if (resp?.ResponseCode == 200) {
                setSlots(resp?.data?.filter(o => o.IsAvailable))
            }
        });
    }

    console.log("propssssssss", props)

    const onBookNow = (data) => {
        debugger
        var selectSlot = slots?.filter(o => o.checked);
        if (selectSlot?.length == 0) {
            alert("Please select a time slot!");
            return false;
        }
        setLoading(true);
        localStorage.setItem("bookAnAppointmentData", JSON.stringify({ selectedDate: calenderData?.find(o => o.checked)?.date, selectSlot: slots?.find(o => o.checked) }));
        if (currentUser) {
            if (data?.RegisterYourselfAs === "Therapist") {
                setLocationConfirmation(true);
                console.log("data", data)
            } else {
                navigate(`/book-an-appointment/${data?.Id}`);
            }
            // var body = { "user_id": data?.Id }
            // GetMySubscription(body).then(resp => {
            //     if (resp.ResponseCode === 200) {
            //         // if(resp.data){

            //         // }
            //     } else {
            //         // alert(resp.ResponseMessage)
            //     }
            // })
        }
        else {
            // navigate(`/login?retrunurl=/book-an-appointment/${data?.Id}`)
            localStorage.setItem('retrunurl', `/book-an-appointment/${data?.Id}`);
            setShowLloginPopup(true)
        }

    }

    return (
        data &&
        <div className='bg-white py-3 px-3 position-relative h-100' style={{ border: "1px solid #E2E2E1" }}>
            <div className='row align-items-center'>
                <div className='col-5'>
                    <img src={data?.ProfileImage} className='col-12 rounded-3 p-1' alt='' />
                </div>
                <div className='col-7'>
                    <p className='font-w600 fs-6 font-inter lh-base text-black'>{data?.Name}</p>
                    <p className='font-inter fs-6 lh-base'>{data?.RegisterYourselfAs}</p>
                    <p className='font-inter fs-6 lh-base'>Years of Experience : {data?.Experience}</p>
                    {/* <p className='font-inter fs-6 lh-base'>{data?.Qualifications}</p> */}
                    <p className='font-inter fs-6 lh-base'><img src='/assets/img/usa-20.png' alt='' /> United States</p>
                </div>
            </div>
            <hr />
            {/* Desktop */}
            <div className='d-md-flex flex-row justify-content-around d-none'>
                {/* <p className='font-inter fs-6 lh-base m-0'>
                    {
                        (data?.RegisterYourselfAs === "Psychiatrist")
                        &&
                        <> <span className='font-inter fs-6 lh-base text-black'>Starts @ <span className="font-inter fs-6 lh-base text-black font-w600">{CurrencyCode} {data?.countryAmount}</span></span></>
                        ||
                        <span className='font-inter fs-6 lh-base text-black'>Starts @ <span className="font-inter fs-6 lh-base text-black font-w600">{CurrencyCode} {Math.round(data?.countryAmount / 2)}</span></span>
                    }
                </p> */}
                <button id='professional_detail_book_now' onClick={() => onBookNow(data)} className="book-now-a-professional px-3 px-md-3 border-0 font-w600 font-inter fs-6 lh-base"
                    style={{ background: "#9FE770", borderRadius: "40px", height: '48px' }}>
                    Book Now
                </button>
            </div>
            <hr className='d-none d-md-block' />
            {/* Desktop */}
            {/* Mobile */}
            <div className='d-block d-md-none position-fixed bottom-0 bg-white py-2 col-12' style={{ left: 0, zIndex: '1000' }}>
                <div className='d-flex flex-row justify-content-around py-2'>
                    {/* <p className='font-inter fs-6 lh-1 m-0'>
                        {
                            (data?.RegisterYourselfAs === "Psychiatrist")
                            &&
                            <> <span className='font-inter fs-6 lh-base text-black'>Starts @ <span className="font-inter fs-6 lh-base text-black font-w600">{CurrencyCode} {data?.countryAmount}</span></span></>
                            ||
                            <span className='font-inter fs-6 lh-base text-black'>Starts @ <span className="font-inter fs-6 lh-base text-black font-w600">{CurrencyCode} {Math.round(data?.countryAmount / 2)}</span></span>
                        }
                    </p> */}
                    <button id='professional_detail_book_now' onClick={() => onBookNow(data)} className="book-now-a-professional px-3 px-md-5 border-0 font-w600 font-inter fs-6 lh-base"
                        style={{ background: "#9FE770", borderRadius: "40px", height: '48px' }}>
                        Book Now
                    </button>
                </div>
            </div>
            {/* Mobile */}
            <div>
                <h5 className='font-inter'>Availability</h5>
                <div className=' '>

                    <ButtonGroup className='d-flex flex-row gap-3 col-12 my-2 overflowX-scroll'>
                        {
                            calenderData?.filter(o => o.data?.length > 0)?.map((item, index) =>
                                item.avaliableSlots != 'Loading' &&
                                <ToggleButton
                                    onClick={() => getSlotByDate(item)}
                                    type="radio"
                                    name="radio"
                                    className="date-availability bg-white rounded-2 col-2 px-1 py-1 ms-1 h-auto"
                                    style={{
                                        background: item.checked ? "linear-gradient(180deg, #E7F6FF 100%, #e7f6ff 0%)" : "#fff",
                                        color: item?.checked ? "#000000" : "#50B3EF",
                                        border: "1px solid #50B3EF",
                                        minWidth: "110px",
                                        maxWidth: "150px"
                                    }}>
                                    <p className='font-inter fs-6 text-center'>{moment(item.date).format("DD MMM") == moment().format("DD MMM") ? "Today" : moment(item.date).format("DD MMM")}</p>
                                    <p className='font-inter fs-6 text-center'>{item.avaliableSlots}</p>
                                </ToggleButton>)
                        }

                    </ButtonGroup>
                </div>
            </div>
            {
                slots && slots?.length == 0 &&
                <div className='my-3'>
                    <hr />
                    <p className='font-inter fs-5 text-black font-w600'>No slot Available</p>
                </div>
                ||
                <div className='my-3'>
                    <h5>Available time slots ({data?.TimeZone})</h5>
                    <div className=''>
                        <ButtonGroup className='row gap-3 col-12 mx-1 my-2'>
                            {
                                slots?.map((item, index) => {
                                    return (<ToggleButton
                                        onClick={() => setSlots(slots?.map(o => { if (o.ID === item.ID) o.checked = true; else o.checked = false; return o }))}
                                        type="radio"
                                        name="radio"
                                        className="time-availability bg-white rounded-2 py-2 px-0 col-3"
                                        style={{
                                            background: item.checked ? "linear-gradient(180deg, #E7F6FF 100%, #e7f6ff 0%)" : "#F2F4F7",
                                            color: "#6A6C6A",
                                            border: "1px solid #D9D9D9",
                                            minWidth: "110px",
                                            maxWidth: "150px"
                                        }}>
                                        <p className='font-inter fs-6 text-center'>{item.Time_Duration}</p>
                                    </ToggleButton>)
                                })
                            }

                        </ButtonGroup>
                    </div>
                </div>
            }



            {/* Location confirmation modal */}
            <LocationConfirmation
                show={locationConfirmation}
                onHide={() => setLocationConfirmation(false)}
                data={data}
            />


        </div>
        ||
        <div>Loading...</div>
    )
    // return (
    //     data &&
    //     <div className='bg-white d-flex flex-column align-items-center justify-content-around px-2 py-3'
    //         style={{ borderRadius: "12px", boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)", }}>
    //         <div className='row my-3 align-items-center justify-content-around'>
    //             <div className='my-3 d-flex align-items-center justify-content-around col-xs-6 col-md-12'>
    //                 <img src={data?.ProfileImage} style={{ height: 200, width: 200, borderRadius: "50%" }} alt='' />
    //             </div>
    //             <div className='d-flex flex-column my-1 col-xs-6 col-md-12'>
    //                 <h6 className='text-center'>{data?.Name}</h6>
    //                 <p className='text-center fs-6'>{data?.RegisterYourselfAs}</p>
    //                 <p className='text-center fs-6'>Experience : {data?.Experience} years</p>
    //                 {
    //                     (data?.RegisterYourselfAs === "Psychiatrist")
    //                     &&
    //                     <> <span className='text-center fs-6'>Starts @ <span className="font-w500 text-black">{CurrencyCode} {data?.countryAmount}</span></span></>
    //                     ||
    //                     <span className='text-center fs-6'>Starts @ <span className="font-w500 text-black">{CurrencyCode} {Math.round(data?.countryAmount / 2)}</span></span>
    //                 }
    //             </div>
    //         </div>
    //         <hr className="w-100" />
    //         {sdata && <div className='d-flex flex-row justify-content-between w-100'>
    //             <p className='fs-6'>Followers {sdata?.followersCount}</p>
    //             <p className='fs-6'>Following {sdata?.followingCount}</p>
    //         </div>}
    //         <button onClick={() => onBookNow(data)} className="px-5 border-0"
    //             style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", borderRadius: "40px", height: '48px', color: '#fff', }}>
    //             Book Now
    //         </button>
    //     </div>
    //     ||
    //     <div>Loading...</div>
    // ) 

}

export default DetailPageCard