import React, { useState, useEffect } from "react";
import {
  Navbar,
  Nav,
  Button,
  NavDropdown,
  Container,
  Offcanvas,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Cookies from "universal-cookie";
import { useAuth } from "../auth";
import { SignInDrawer } from "./SignInDrawer";
import { AppContext } from "../context";
import { useContext } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "bootstrap/dist/css/bootstrap.min.css";
import locationSVG from "../images/location.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LocationModal from "./LocationModal";
import "./LocationModal.css";
import { validate_token } from "./core/_request";
// import { GetGeoLocationByBrowser } from "./core/_request";

const searchPlaceholder = ["an Expert", "a course", "a blog"];

function Header2() {
  const { currentUser, userType, logout } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const [changingPlaceholder, setChangingPlaceholder] = useState(0);
  const [searchText, setSearchText] = useState();
  const [locationModalShow, setLocationModalShow] = React.useState(false);

  const stellaAPIKey = process.env.REACT_APP_STELLAAPIKEY;
  const baseURL = "https://odoo.unitedwecare.ca";

  const headerSearch = (e) => {
    debugger;
    setSearchText(e.target.value);
  };

  const onHeaderSearchIcon = () => {
    window.location.href = `https://www.unitedwecare.com/search/?query=${searchText}`;
  };

  const handleEnterKeyDown = (e) => {
    debugger;
    if (e.key === "Enter") {
      e.preventDefault();
      onHeaderSearchIcon();
    }
  };

  //const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const onLogout = () => {
    //console.log("logout")
    let isAngelBroking = currentUser?.Emailid.includes("@angelbroking.com");
    logout();
    if (isAngelBroking) {
      window.location.href =
        "https://authentication.angelbroking.com/adfs/ls/?login_hint=&wctx=o6i1UeLKkhSGgMRIy8esRHvCiLdKltGI&wtrealm=urn%3Aauth0%3Auwc&wa=wsignin1.0";
    } else {
      navigate("/");
    }
  };

  const togglePopup = (state) => {
    setMenuOpen(false);
    setShowLloginPopup(state);
  };
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const timer = () => {
      setChangingPlaceholder((prevIndex) => {
        if (prevIndex === searchPlaceholder.length - 1) {
          return 0;
        }
        return prevIndex + 1;
      });
    };
    setInterval(timer, 5000);

    //cleanup function in order clear the interval timer
    //when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, []);

  const handleClose = () => setMenuOpen(false);

  const location = useLocation();
  const search = useLocation().search;
  const token_url = new URLSearchParams(search).get("token");
  const searchParams = new URLSearchParams(location.search);
  const [previousLocation, setPreviousLocation] = useState(null);

  // useEffect(() => {
  //   if (
  //     previousLocation &&
  //     previousLocation.pathname == "/onboarding-stella" &&
  //     location.pathname !== "/onboarding-stella"
  //   ) {
  //     // Alert or perform any other action when the user navigates from the previous location
  //     //console.log("Kimochi!!")

  //     const headers = {
  //       "Content-Type": "application/json",
  //       "X-API-Key": stellaAPIKey,
  //     };

  //     const sessionID = sessionStorage.getItem("stella_s_id");

  //     const body = {
  //       senderId: sessionID,
  //     };

  //     const getStellaClose = async () => {
  //       try {
  //         //setIsLoading(true);

  //         const response = await fetch(
  //           `${baseURL}/new-uwc/stella/close/session`,
  //           {
  //             method: "POST",
  //             headers,
  //             body: JSON.stringify(body),
  //           }
  //         );

  //         if (!response.ok) {
  //           // Handle error response
  //           throw new Error("Error fetching languages");
  //         }

  //         const jsonData = await response.json();
  //         console.log("RESPONSE", response);

  //         if (response.status == 200) {
  //           console.log("CLEAR");
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data:", error);
  //       }
  //     };

  //     getStellaClose();
  //   }

  //   // Save the current location as the previous location
  //   setPreviousLocation(location);
  // }, [location, previousLocation]);

  const saveCookie = (firstName, oneYearFromNow) => {
    const cookies = new Cookies();
    //console.log("saveCookie called!", "firstName", firstName, "oneYearFromNow", oneYearFromNow);

    cookies.set("displayName", `${firstName}`, {
      maxAge: `${oneYearFromNow}`,
      domain: ".unitedwecare.com",
      path: "/",
    });
  };

  useEffect(() => {
    // console.log("validate_token calling !!!!!!!!", token_url);
    if (token_url) {
      validate_token(token_url).then((resp) => {
        //console.log("resp", resp);
        if (resp?.ResponseCode === 200) {
          localStorage.setItem("Authorization", resp?.data?.usertoken);

          localStorage.setItem(
            "profile",
            JSON.stringify(resp?.data?.userdetails)
          );
          if (
            resp?.data?.userdetails.header_image &&
            window.location.pathname === "/"
          ) {
            navigate("/user-dashboard");
          } else {
            searchParams.delete("token");
            // Convert the searchParams object back to a string
            const searchString = searchParams.toString();
            // Split the parameters
            const paramsArray = searchString.split("&");

            // Filter out the token parameter
            const filteredParamsArray = paramsArray.filter(
              (param) => !param.startsWith("token=")
            );

            // Reconstruct the query string
            const queryString = filteredParamsArray
              .join("&")
              .replace(/\+/g, "%20");

            // Create the updated URL

            const updatedURL = `${location.pathname}?${queryString}${location.hash}`;

            window.location.href = updatedURL;
            // window.location.href = window.location.href = window.location.origin + window.location.pathname;
          }

          let now = new Date();
          let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
          let firstName = resp?.data?.userdetails?.Name.split(" ")
            .slice(0, 1)
            .join("");

          // if (resp?.data?.userdetails.header_image) {
          //   navigate("/user-dashboard");
          // }
          //saveCookie(firstName, oneYearFromNow);

          //console.log(firstName, oneYearFromNow.toUTCString())

          document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toUTCString()};domain=.unitedwecare.com;path=/;`;
        }
      });
    }
  }, []);

  const urlSplit = window.location.href
    .split("?")[0]
    .split("/")
    .includes("user-dashboard");

  return (
    <div
      style={{
        background: "#FFFFFF",
        boxShadow: "0px 4px 17px rgba(0, 0, 0, 0.09)",
      }}
      className="header-container"
    >
      <Navbar
        key={"sm"}
        expand={"sm"}
        className={`${urlSplit ? "d-none" : "col-12"} Header--2`}
      >
        <div
          className="d-flex align-items-center justify-content-between col-12"
          style={{ padding: "5px 5%" }}
        >
          {/* logo Desktop */}
          <Navbar.Brand className="d-none d-md-block">
            <a
              href="https://www.unitedwecare.com/us"
              className="text-decoration-none"
            >
              <img src="/assets/img/Group(3).png" alt="" />
            </a>
          </Navbar.Brand>
          {/* logo Mobile */}
          <Navbar.Brand className="d-block d-md-none">
            <a
              href="https://www.unitedwecare.com/us"
              className="text-decoration-none"
            >
              <img src="/assets/img/Logo(2).png" alt="" />
            </a>
          </Navbar.Brand>

          {/* <Form className="d-flex d-md-none align-items-center ms-2 rounded-5 me-3" style={{ border: "0.5px solid #541CF5", height: "45px" }}>
            <InputGroup>
              <FormControl onChange={(e) => headerSearch(e)} onKeyDown={(e) => handleEnterKeyDown(e)} type="search" className="ms-2 border-0 headerSearch" style={{ borderRight: "none" }} placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`} />
              
              <SearchIcon fontSize="small" sx={{ color: "#541CF5", display: "block", marginY: "auto", marginRight: "10px" }} onClick={onHeaderSearchIcon} />
              
            </InputGroup>
          </Form> */}

          {/*  If User Login*/}
          {(currentUser && (
            <>
              <Navbar.Toggle
                aria-controls={"offcanvasNavbar-expand-sm"}
                onClick={toggleMenu}
              />
              <Navbar.Offcanvas
                id={"offcanvasNavbar-expand-sm"}
                aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                placement="end"
                style={{ backgroundColor: "#fff" }}
                restoreFocus={false}
                show={menuOpen}
                onHide={handleClose}
              >
                <Offcanvas.Header closeButton></Offcanvas.Header>
                <Offcanvas.Body className="justify-content-md-end">
                  <Nav className="align-items-baseline align-items-md-center">
                    {/* Mobile View Start */}
                    <Nav.Link
                      className="mx-2 d-block d-md-none text-decoration-none header-title lh-lg"
                      onClick={toggleMenu}
                      href="https://www.unitedwecare.com/about-us/"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      About Us
                    </Nav.Link>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Care"
                      className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-2 dropdown-header-blue"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="/?Profession=Mental Health and Wellness Coach">
                        Professionals
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/course">
                        Programs
                      </NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/?Profession=Mental Health and Wellness Coach"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Professionals
                      </Link>
                    </Nav.Link>
                    <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/course"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Programs
                      </Link>
                    </Nav.Link> */}

                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Resource Centre"
                      className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-2 dropdown-header-blue"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/blog/">
                        Blogs
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/explore">
                        Wellness Library
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/plans"
                        className="text-decoration-none header-title lh-lg"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Plans and Pricing
                      </Link>
                    </Nav.Link>

                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="For Enterprise"
                      className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-2"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">
                        USA
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">
                        India
                      </NavDropdown.Item>
                    </NavDropdown>
                    {/* Mobile View End */}

                    {/* Desktop View Start */}
                    <Nav.Link
                      className="mx-2 d-none d-md-block text-decoration-none header-title lh-lg"
                      href="https://www.unitedwecare.com/about-us/"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      About Us
                    </Nav.Link>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Care"
                      className="font-inter text-decoration-none header-title lh-lg mx-2 d-none d-md-block text-black header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="/?Profession=Mental Health and Wellness Coach">
                        Professionals
                      </NavDropdown.Item>
                      <NavDropdown.Item href="/course">
                        Programs
                      </NavDropdown.Item>
                    </NavDropdown>
                    {/* <Nav.Link className="mx-2 d-none d-md-block">
                      <Link
                        to="/?Profession=Mental Health and Wellness Coach"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Professionals
                      </Link>
                    </Nav.Link>
                    <Nav.Link className="mx-2 d-none d-md-block">
                      <Link
                        to="/course"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Programs
                      </Link>
                    </Nav.Link> */}
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="Resource Center"
                      className="font-inter text-decoration-none header-title lh-lg mx-2 d-none d-md-block text-black header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/blog/">
                        Blogs
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/explore">
                        Wellness Library
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link className="mx-2 d-none d-md-block">
                      <Link
                        to="/plans"
                        className="text-decoration-none header-title lh-lg"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Plans and Pricing
                      </Link>
                    </Nav.Link>
                    <NavDropdown
                      id="nav-dropdown-dark-example"
                      title="For Enterprises"
                      className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-2 header-dropdown"
                      menuVariant="dark"
                    >
                      <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">
                        USA
                      </NavDropdown.Item>
                      <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">
                        India
                      </NavDropdown.Item>
                    </NavDropdown>
                    {/* Desktop View End */}
                    {/* {currentUser && (<Nav.Link className="mx-2 d-none d-md-block"><Link to="/onboarding-stella" className='text-decoration-none header-title lh-lg'>Stella</Link></Nav.Link>)} */}
                    {(currentUser.UserType == userType.ENDUSER && (
                      // <NavDropdown
                      //   title={
                      //     <>
                      //       Hi{" "}
                      //       {currentUser?.Name.split(" ").slice(0, 1).join("")}
                      //     </>
                      //   }
                      //   id={`offcanvasNavbarDropdown-expand-sm`}
                      //   className="rounded-5 ms-md-3 px-4 px-md-3 header-title-user py-1 toggleButton text-white"
                      //   style={{
                      //     backgroundColor: "#541CF5",
                      //     color: "#fff !important",
                      //   }}
                      // >
                      //   {/* Mobile View Start */}
                      //   <NavDropdown.Item className="d-block d-md-none">
                      //     <Nav.Link onClick={toggleMenu}>
                      //       <Link
                      //         to="/user-dashboard/my-appointments"
                      //         className="text-decoration-none"
                      //       >
                      //         My Appointments
                      //       </Link>
                      //     </Nav.Link>
                      //   </NavDropdown.Item>
                      //   <NavDropdown.Item className="d-block d-md-none">
                      //     <Nav.Link onClick={toggleMenu}>
                      //       <Link
                      //         to="/user-dashboard/course/"
                      //         className="text-decoration-none"
                      //       >
                      //         My Courses
                      //       </Link>
                      //     </Nav.Link>
                      //   </NavDropdown.Item>
                      //   <NavDropdown.Item className="d-block d-md-none">
                      //     <Nav.Link onClick={toggleMenu}>
                      //       <Link
                      //         to="/my-subscription"
                      //         className="text-decoration-none"
                      //       >
                      //         My Subscription
                      //       </Link>
                      //     </Nav.Link>
                      //   </NavDropdown.Item>
                      //   <Nav.Link
                      //     className="d-block d-md-none"
                      //     onClick={toggleMenu}
                      //   >
                      //     <Button
                      //       className="bg-white border-0 rounded-4 w-100 text-start"
                      //       style={{ color: "#6384E3" }}
                      //       onClick={onLogout}
                      //     >
                      //       Logout
                      //     </Button>
                      //   </Nav.Link>
                      //   {/* Mobile View End */}
                      //   {/* Desktop View Start */}
                      //   <NavDropdown.Item className="d-none d-md-block">
                      //     <Nav.Link>
                      //       <Link
                      //         to="/user-dashboard/my-appointments"
                      //         className="text-decoration-none"
                      //       >
                      //         My Appointments
                      //       </Link>
                      //     </Nav.Link>
                      //   </NavDropdown.Item>
                      //   <NavDropdown.Item
                      //     href="#action/3.2"
                      //     className="d-none d-md-block"
                      //   >
                      //     <Nav.Link>
                      //       <Link
                      //         to="/user-dashboard/course/"
                      //         className="text-decoration-none"
                      //       >
                      //         My Courses
                      //       </Link>
                      //     </Nav.Link>
                      //   </NavDropdown.Item>
                      //   <NavDropdown.Item className="d-none d-md-block">
                      //     <Nav.Link>
                      //       <Link
                      //         to="/my-subscription"
                      //         className="text-decoration-none"
                      //       >
                      //         My Subscription
                      //       </Link>
                      //     </Nav.Link>
                      //   </NavDropdown.Item>
                      //   <Nav.Link href="#!" className="d-none d-md-block">
                      //     <Button
                      //       className="bg-white border-0 rounded-4 w-100 text-start"
                      //       style={{ color: "#6384E3" }}
                      //       onClick={onLogout}
                      //     >
                      //       Logout
                      //     </Button>
                      //   </Nav.Link>
                      //   {/* Desktop View End */}
                      // </NavDropdown>
                      <button
                        className="userLoggedIn--Btn"
                        style={{
                          border: "none",
                          borderRadius: "40px",
                          backgroundColor: "#541cf5",
                          color: "#FFF",
                          fontSize: "14px",
                          fontWeight: "500",
                          padding: "10px 25px",
                        }}
                        onClick={() => {
                          navigate("/user-dashboard");
                        }}
                      >
                        Hi {currentUser?.Name.split(" ").slice(0, 1).join("")}
                      </button>
                    )) ||
                      (currentUser.UserType == userType.EXPERT && (
                        <NavDropdown
                          title={
                            <>
                              Hi{" "}
                              {currentUser?.Name.split(" ")
                                .slice(0, 1)
                                .join("")}
                            </>
                          }
                          id="basic-nav-dropdown"
                          className="rounded-3 ms-3 px-2 header-title-user"
                          style={{
                            backgroundColor: "#541CF5",
                            color: "#fff !important",
                          }}
                        >
                          {/* Mobile View Start */}
                          {/* <NavDropdown.Item className="d-block d-md-none">
                            <Nav.Link onClick={toggleMenu}>
                              <Link
                                to="/user-dashboard/my-appointments"
                                className="text-decoration-none"
                              >
                                My Appointments
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item
                            // href="#action/3.1"
                            className="d-block d-md-none"
                          >
                            <Nav.Link onClick={toggleMenu}>
                              <Link
                                to="/my-subscription"
                                className="text-decoration-none"
                              >
                                My Subscription
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item> */}
                          <NavDropdown.Item className="d-block d-md-none">
                            <Nav.Link onClick={toggleMenu}>
                              <Link
                                to="/expert/dashboard"
                                className="text-decoration-none"
                              >
                                My Dashboard
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <Nav.Link
                            href="#!"
                            className="text-white d-block d-md-none"
                            onClick={toggleMenu}
                          >
                            <Button
                              className="bg-white border-0 rounded-4 w-100 mx-1 text-start"
                              style={{ color: "#6384E3" }}
                              onClick={onLogout}
                            >
                              Logout
                            </Button>
                          </Nav.Link>
                          {/* Mobile View End */}
                          {/* Desktop View Start */}
                          {/* <NavDropdown.Item
                            href="#action/3.1"
                            className="d-none d-md-block"
                          >
                            <Nav.Link>
                              <Link
                                to="/user-dashboard/my-appointments"
                                className="text-decoration-none"
                              >
                                My Appointments
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item className="d-none d-md-block">
                            <Nav.Link>
                              <Link
                                to="/my-subscription"
                                className="text-decoration-none"
                              >
                                My Subscription
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item> */}

                          <NavDropdown.Item
                            href="#action/3.1"
                            className="d-none d-md-block"
                          >
                            <Nav.Link>
                              <Link
                                to="/expert/dashboard"
                                className="text-decoration-none"
                              >
                                My Dashboard
                              </Link>
                            </Nav.Link>
                          </NavDropdown.Item>

                          <Nav.Link className="text-white d-none d-md-block">
                            <Button
                              className="bg-white border-0 rounded-4 w-100 mx-1 text-start"
                              style={{ color: "#6384E3" }}
                              onClick={onLogout}
                            >
                              Logout
                            </Button>
                          </Nav.Link>
                          {/* Desktop View End */}
                        </NavDropdown>
                      ))}
                  </Nav>
                  {/* <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      className="me-2"
                      aria-label="Search"
                    />
                    <Button variant="outline-success">Search</Button>
                  </Form> */}
                  <Form
                    className="d-none align-items-center ms-2 rounded-5"
                    style={{ border: "0.5px solid #541CF5", height: "45px" }}
                  >
                    <InputGroup>
                      <FormControl
                        onChange={(e) => headerSearch(e)}
                        onKeyDown={(e) => handleEnterKeyDown(e)}
                        type="search"
                        className="ms-2 border-0 headerSearch"
                        style={{ borderRight: "none" }}
                        placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`}
                      />
                      {/* <InputGroup.Text className="border-0" style={{ background: "none" }} > */}
                      <SearchIcon
                        sx={{
                          color: "#541CF5",
                          display: "block",
                          marginY: "auto",
                          marginRight: "10px",
                        }}
                        onClick={onHeaderSearchIcon}
                      />
                      {/* </InputGroup.Text> */}
                    </InputGroup>
                  </Form>
                  <div
                    className="d-none d-md-flex ms-3 align-items-center"
                    onClick={() => setLocationModalShow(true)}
                  >
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.5699 24.8C15.7761 25.1187 16.1386 25.3312 16.5511 25.3312C16.9636 25.3312 17.3261 25.1187 17.5386 24.8L24.4261 14.1625C25.3511 12.7313 25.8886 11.1562 25.8886 9.33125C25.8824 4.05625 21.7386 0 16.5511 0C11.3636 0 7.21988 4.05625 7.21988 9.33125C7.21988 11.1562 7.75739 12.7313 8.68239 14.1625L15.5699 24.8ZM16.5511 4C19.4949 4 21.8824 6.3875 21.8824 9.33125C21.8824 12.275 19.4949 14.6625 16.5511 14.6625C13.6074 14.6625 11.2199 12.275 11.2199 9.33125C11.2199 6.3875 13.6074 4 16.5511 4ZM23.3886 20.2188L16.5511 29.3312L9.71364 20.2188C7.37614 21.4438 5.88239 23.275 5.88239 25.3312C5.88239 29.0125 10.6574 32 16.5511 32C22.4449 32 27.2199 29.0125 27.2199 25.3312C27.2199 23.275 25.7261 21.4438 23.3886 20.2188Z"
                        fill="url(#paint0_linear_2299_2675)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2299_2675"
                          x1="16.5511"
                          y1="0"
                          x2="16.5511"
                          y2="32"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#541CF5" stop-opacity="0.68" />
                          <stop offset="1" stop-color="#541CF5" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <KeyboardArrowDownIcon sx={{ color: "#541CF5" }} />
                  </div>

                  <LocationModal
                    show={locationModalShow}
                    onHide={() => setLocationModalShow(false)}
                  />
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </>
          )) || (
            <>
              <Navbar.Toggle
                aria-controls={"offcanvasNavbar-expand-sm"}
                onClick={toggleMenu}
              />
              <Navbar.Offcanvas
                id={"offcanvasNavbar-expand-sm"}
                aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                placement="end"
                style={{ backgroundColor: "#C4E5F9" }}
                className="px-2"
                restoreFocus={false}
                show={menuOpen}
                onHide={handleClose}
              >
                <Offcanvas.Header closeButton></Offcanvas.Header>

                <Nav className="align-items-baseline align-items-md-center justify-content-end">
                  {/* Mobile View Start */}
                  <Nav.Link
                    className="mx-2 d-block d-md-none text-decoration-none header-title lh-lg"
                    onClick={toggleMenu}
                    href="https://www.unitedwecare.com/about-us/"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    About Us
                  </Nav.Link>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Care"
                    className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-2 dropdown-header"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item href="/?Profession=Mental Health and Wellness Coach">
                      Professionals
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/course">Programs</NavDropdown.Item>
                  </NavDropdown>
                  {/* <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/?Profession=Mental Health and Wellness Coach"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Professionals
                      </Link>
                    </Nav.Link>
                    <Nav.Link
                      className="mx-2 d-block d-md-none"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/course"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Programs
                      </Link>
                    </Nav.Link> */}
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Resource Centre"
                    className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-2 dropdown-header"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item href="https://www.unitedwecare.com/blog/">
                      Blogs
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://www.unitedwecare.com/explore">
                      Wellness Library
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link
                    className="mx-2 d-block d-md-none"
                    onClick={toggleMenu}
                  >
                    <Link
                      to="/plans"
                      className="text-decoration-none header-title lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Plans and Pricing
                    </Link>
                  </Nav.Link>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="For Enterprise"
                    className="font-inter text-decoration-none header-title lh-lg d-block d-md-none mx-2"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">
                      USA
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">
                      India
                    </NavDropdown.Item>
                  </NavDropdown>
                  {/* Mobile View End */}

                  {/* Desktop View Start */}
                  <Nav.Link
                    className="mx-2 d-none d-md-block text-decoration-none header-title lh-lg"
                    href="https://www.unitedwecare.com/about-us/"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    About Us
                  </Nav.Link>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Care"
                    className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-2 header-dropdown"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item href="/?Profession=Mental Health and Wellness Coach">
                      Professionals
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/course">Programs</NavDropdown.Item>
                  </NavDropdown>
                  {/* <Nav.Link className="mx-2 d-none d-md-block">
                      <Link
                        to="/?Profession=Mental Health and Wellness Coach"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Professionals
                      </Link>
                    </Nav.Link>
                    <Nav.Link className="mx-2 d-none d-md-block">
                      <Link
                        to="/course"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Programs
                      </Link>
                    </Nav.Link> */}
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="Resource Center"
                    className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-2 header-dropdown"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item href="https://www.unitedwecare.com/blog/">
                      Blogs
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://www.unitedwecare.com/explore">
                      Wellness Library
                    </NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link className="mx-2 d-none d-md-block">
                    <Link
                      to="/plans"
                      className="text-decoration-none header-title lh-lg"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Plans and Pricing
                    </Link>
                  </Nav.Link>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title="For Enterprises"
                    className="font-inter text-decoration-none header-title lh-lg d-none d-md-block text-black mx-2 header-dropdown"
                    menuVariant="dark"
                  >
                    <NavDropdown.Item href="https://www.unitedwecare.com/us/b2b/">
                      USA
                    </NavDropdown.Item>
                    <NavDropdown.Item href="https://www.unitedwecare.com/in/b2b/">
                      India
                    </NavDropdown.Item>
                  </NavDropdown>
                  {/* <Nav.Link
                      className="mx-2 d-none d-md-block"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/?Profession=Mental Health and Wellness Coach"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Stella
                      </Link>
                    </Nav.Link>
                    <Nav.Link
                      className="mx-2 d-none d-md-block"
                      onClick={toggleMenu}
                    >
                      <Link
                        to="/?Profession=Mental Health and Wellness Coach"
                        className="text-decoration-none header-title lh-lg"
                      >
                        Enterprise
                      </Link>
                    </Nav.Link> */}

                  {/* {currentUser && (<Nav.Link className="mx-2 d-none d-md-block"><Link to="/onboarding-stella" className='text-decoration-none header-title lh-lg'>Stella</Link></Nav.Link>)} */}

                  {/* <Nav.Link onClick={() => {togglePopup(true);toggleMenu();}} className="text-white"><Link className="text-decoration-none header-title lh-lg"><Button className='text-black border-0 rounded-4' style={{ backgroundColor: "#EDEFEB" }}>Login</Button></Link></Nav.Link> */}
                  <Nav.Link
                    onClick={() => {
                      togglePopup(true);
                    }}
                    className="text-white"
                  >
                    <Link className="text-decoration-none header-title lh-lg">
                      <Button
                        id="header_login"
                        className="text-white border-0 rounded-4 px-5 mx-2"
                        style={{ backgroundColor: "#541CF5" }}
                      >
                        Login
                      </Button>
                    </Link>
                  </Nav.Link>
                  {/* <div className="rounded-5  px-2" style={{ background: 'rgba(84, 28, 245, 0.06)', border: '0.25px #1B8BCF solid' }}>
                      <div className="col-12" style={{ justifyContent: 'space-between', alignItems: 'center', display: 'inline-flex' }}>
                        <div><span style={{ color: '#1B8BCF', fontSize: 18, fontFamily: 'Helvetica', fontWeight: '700', wordWrap: 'break-word' }}>Search</span><span style={{ color: '#1B8BCF', fontSize: 14, fontFamily: 'Helvetica', fontWeight: '700', wordWrap: 'break-word' }}>..</span></div>
                        <div>
                          <img src="\assets\img\SVG - Search.png" alt="" />
                        </div>
                      </div>
                    </div> */}
                  <Form
                    className="d-none align-items-center ms-2 rounded-5"
                    style={{ border: "0.5px solid #541CF5", height: "45px" }}
                  >
                    <InputGroup>
                      <FormControl
                        onChange={(e) => headerSearch(e)}
                        onKeyDown={(e) => handleEnterKeyDown(e)}
                        type="search"
                        className="ms-2 border-0 headerSearch"
                        style={{ borderRight: "none" }}
                        placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`}
                      />
                      {/* <InputGroup.Text className="border-0" style={{ background: "none" }} > */}
                      <SearchIcon
                        sx={{
                          color: "#541CF5",
                          display: "block",
                          marginY: "auto",
                          marginRight: "10px",
                        }}
                        onClick={onHeaderSearchIcon}
                      />
                      {/* </InputGroup.Text> */}
                    </InputGroup>
                  </Form>
                  {/* <div className="d-flex mx-3 align-items-center">
                      <img src="assets/img/subway_location-3.png" alt="" />
                      <KeyboardArrowDownIcon sx={{ color: "#541CF5" }} />
                    </div> */}
                </Nav>

                <Modal
                  show={ShowLloginPopup}
                  onHide={() => togglePopup(false)}
                  dialogClassName="modal-90w"
                  aria-labelledby="example-custom-modal-styling-title"
                  className="m-0 col-4 signInModal"
                  style={{ height: "100vh" }}
                >
                  <SignInDrawer />
                </Modal>
              </Navbar.Offcanvas>
            </>
          )}
        </div>
        <div className="d-flex align-items-center justify-content-center px-4 col-12 gap-2 col-12">
          <Form
            className="d-none align-items-center justify-content-center rounded-2 my-2"
            style={{
              border: "0.5px solid #541CF5",
              height: "45px",
              width: currentUser ? "83.33%" : "91.66%",
            }}
          >
            <InputGroup>
              <FormControl
                onChange={(e) => headerSearch(e)}
                onKeyDown={(e) => handleEnterKeyDown(e)}
                type="search"
                className="ms-2 border-0 headerSearch"
                style={{ borderRight: "none" }}
                placeholder={`Search for ${searchPlaceholder[changingPlaceholder]}`}
              />
              {/* <InputGroup.Text className="border-0" style={{ background: "none" }} > */}
              <SearchIcon
                sx={{
                  color: "#541CF5",
                  display: "block",
                  marginY: "auto",
                  marginRight: "10px",
                }}
                onClick={onHeaderSearchIcon}
              />
              {/* </InputGroup.Text> */}
            </InputGroup>
          </Form>
          {currentUser && (
            <div
              className="d-flex align-items-center d-md-none ml-auto my-2 "
              style={{ marginLeft: "auto" }}
              onClick={() => setLocationModalShow(true)}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 13 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.89992 14.7149C6.02214 14.9037 6.23696 15.0297 6.4814 15.0297C6.72585 15.0297 6.94066 14.9037 7.06659 14.7149L11.1481 8.41115C11.6962 7.563 12.0147 6.62967 12.0147 5.54818C12.011 2.42226 9.55548 0.0185547 6.4814 0.0185547C3.40733 0.0185547 0.951772 2.42226 0.951772 5.54818C0.951772 6.62967 1.27029 7.563 1.81844 8.41115L5.89992 14.7149ZM6.4814 2.38893C8.22585 2.38893 9.64066 3.80374 9.64066 5.54818C9.64066 7.29263 8.22585 8.70744 6.4814 8.70744C4.73696 8.70744 3.32214 7.29263 3.32214 5.54818C3.32214 3.80374 4.73696 2.38893 6.4814 2.38893ZM10.5333 12L6.4814 17.4L2.42955 12C1.04437 12.726 0.15918 13.8111 0.15918 15.0297C0.15918 17.2111 2.98881 18.9815 6.4814 18.9815C9.974 18.9815 12.8036 17.2111 12.8036 15.0297C12.8036 13.8111 11.9184 12.726 10.5333 12Z"
                  fill="url(#paint0_linear_503_15074)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_503_15074"
                    x1="6.4814"
                    y1="0.0185547"
                    x2="6.4814"
                    y2="18.9815"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#541CF5" stop-opacity="0.68" />
                    <stop offset="1" stop-color="#541CF5" />
                  </linearGradient>
                </defs>
              </svg>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.51851 11.8703L6.16602 8.5186L7.28404 7.40137L9.51851 9.63584L11.753 7.40137L12.871 8.5186L9.51851 11.8711V11.8703Z"
                  fill="#541CF5"
                />
              </svg>
            </div>
          )}
        </div>
        {/* <div className="d-flex mx-3 align-items-center">
          <img src="assets/img/subway_location-3.png" alt="" />
          <KeyboardArrowDownIcon sx={{ color: "#541CF5" }} />
        </div> */}
      </Navbar>
    </div>
  );
}

export default Header2;
