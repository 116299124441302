import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useState } from "react";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AllSessions from "./cards/AllSessions";
import MarkedSessions from "./cards/MarkedSessions";
import axios from "axios";
import { Config } from "../../core/_request";
import { json } from "react-router-dom";
import { useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Pagination from "./Pagination/pagination";

const SessionManagement = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModal3, setOpenModal3] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [getSessionManagement, setSessionManagement] = useState({});
  const [session, setSession] = useState("all");
  const [loading, setLoading] = useState(true);
  const [currentPage, setcurrPage] = useState(0);

  const handleOpenModal1 = () => {
    setOpenModal1(true);
  };

  const handleOpenModal2 = () => {
    setOpenModal2(true);
  };

  const handleCloseModal1 = () => {
    setOpenModal1(false);
  };

  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModal3 = () => {
    setOpenModal3(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleCloseModal3 = () => {
    setOpenModal3(false);
  };

  useEffect(() => {
    handleSessionManagement("all");
    showModelPop();
  }, []);

  const showModelPop = () => {
    let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
    let body = {
      user_id: userId,
      user_type: "expert",
      current_time: getCurrentTime(),
    };
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/session-feedback-popup`,
        body,
        Config()
      )
      .then((resp) => resp.data)
      .then((resp) => {
        if (resp.ResponseCode === 200) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    handleSessionManagement(session);
  }, [currentPage]);

  const handleSessionManagement = (sessions) => {
    let body = null;
    let limit = 16;
    setLoading(true);
    let userId = JSON.parse(localStorage.getItem("profile"))?.UserId;
    if (sessions === "all") {
      body = {
        expert_id: userId,
        all_session: true,
        current_time: getCurrentTime(),
        page: currentPage,
        limit: limit,
      };
    } else if (sessions === "completed") {
      body = {
        expert_id: userId,
        completed: true,
        current_time: getCurrentTime(),
        page: currentPage,
        limit: limit,
      };
    } else if (sessions === "in-complete") {
      body = {
        expert_id: userId,
        incompleted: true,
        current_time: getCurrentTime(),
        page: currentPage,
        limit: limit,
      };
    }
    setSession(sessions);
    axios
      .post(
        `${process.env.REACT_APP_OODO_BASE_URL}new-uwc/appointment/session-list`,
        body,
        Config()
      )
      .then((resp) => resp.data)
      .then((resp) => {
        if (resp.ResponseCode === 200) {
          setLoading(false);
          setSessionManagement(resp.data[0]);
        }
      });
  };

  const getCurrentTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div>
      <div
        style={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 650,
          fontSize: "22px",
          marginLeft: "15px",
          color: "#000000",
        }}
      >
        Session Management
      </div>
      <div>
        <div className="d-flex flex-wrap mt-2">
          <button
            onClick={() => {
              handleSessionManagement("all");
              setcurrPage(0);
            }}
            className={
              session === "all"
                ? "btn btn-outline-primary activeprimary"
                : "btn btn-outline-primary"
            }
            style={{
              fontSize: "15px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              borderRadius: "10px",
              border: "3px solid",
            }}
          >
            <span style={{ flex: 1, whiteSpace: "nowrap" }}>
              Unmarked Sessions
            </span>
          </button>
          <button
            onClick={() => {
              handleSessionManagement("completed");
              setcurrPage(0);
            }}
            className={
              session === "completed"
                ? "btn btn-outline-success mx-3 activeCompleted"
                : "btn btn-outline-success mx-3"
            }
            style={{
              border: "3px solid",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              borderRadius: "10px",
            }}
          >
            Completed
          </button>
          <button
            onClick={() => {
              handleSessionManagement("in-complete");
              setcurrPage(0);
            }}
            className={
              session === "in-complete"
                ? "btn btn-outline-danger activeDanger"
                : "btn btn-outline-danger"
            }
            style={{
              borderColor: "#B54708",
              borderWidth: "3px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 600,
              color: "black",
              borderRadius: "10px",
              marginTop: "2px",
            }}
            onMouseEnter={(e) => {
              e.target.style.color = "white";
            }}
            onMouseLeave={(e) => {
              e.target.style.color = "black";
            }}
          >
            Not Completed
          </button>
        </div>
        {loading && (
          <div className="text-center">
            <CircularProgress />
          </div>
        )}
      </div>
      <div
        style={{
          backgroundColor: "white",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          borderRadius: "20px",
          marginTop: "25px",
        }}
      >
        {session === "all" && (
          <AllSessions
            session={session}
            unMarkedSession={getSessionManagement?.unmarked_session}
            openModal={openModal}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            openModal1={openModal1}
            handleOpenModal1={handleOpenModal1}
            handleCloseModal1={handleCloseModal1}
          />
        )}
        {session === "completed" && (
          <MarkedSessions
            openModal={openModal3}
            handleOpenModal={handleOpenModal3}
            handleCloseModal={handleCloseModal3}
            openModal1={openModal2}
            handleOpenModal1={handleOpenModal2}
            handleCloseModal1={handleCloseModal2}
            session={session}
            markedSessions={
              session === "completed"
                ? getSessionManagement?.completed_session
                : getSessionManagement?.marked_session
            }
          />
        )}
        {session === "in-complete" && (
          <MarkedSessions
            openModal={openModal}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            openModal1={openModal2}
            handleOpenModal1={handleOpenModal2}
            handleCloseModal1={handleCloseModal2}
            session={session}
            markedSessions={
              session === "in-complete"
                ? getSessionManagement?.incompleted_session
                : getSessionManagement?.marked_session
            }
          />
        )}
        <div className="text-center col-md-12">
          <Pagination
            currentPage={currentPage}
            setcurrPage={setcurrPage}
            count={getSessionManagement?.session_count}
          ></Pagination>
        </div>
      </div>
    </div>
  );
};

export default SessionManagement;
