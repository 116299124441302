import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "../../../../context";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import Logo from "../../../dashboard/images/Logo.webp";
import logo from "../../images/Logo2.png";
import { useAuth } from "../../../../auth";

import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { validate_token } from "../../../core/_request";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

function Navbar() {
  const { currentUser, logout } = useAuth();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const onHeaderSearchIcon = () => {
    // navigate(`/search/?query=${searchText}`);
    window.location.href = `https://www.unitedwecare.com/search/?query=${searchText}`;
  };

  const handleEnterKeyDown = (event) => {
    //debugger;
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      onHeaderSearchIcon(); // Trigger the search function
    }
  };

  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    navigate("/");
    logout();
  };

  const search = useLocation().search;

  const token_url = new URLSearchParams(search).get("token");

  useEffect(() => {
    validate_token(token_url).then((resp) => {
      if (resp?.ResponseCode === 200) {
        localStorage.setItem("Authorization", resp?.data?.usertoken);

        localStorage.setItem(
          "profile",
          JSON.stringify(resp?.data?.userdetails)
        );

        let now = new Date();
        let oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
        let firstName = resp?.data?.userdetails?.Name.split(" ")
          .slice(0, 1)
          .join("");
        document.cookie = `displayName=${firstName};expires=${oneYearFromNow.toISOString()};domain=.unitedwecare.com;path=/;`;

        navigate("/user-dashboard");
        // window.location.reload();
      }
    });
  }, []);

  useEffect(() => {
    if (!currentUser) {
      setShowLloginPopup(true);
    }
  }, [currentUser]);

  return (
    <>
      <Helmet>
        <title>User Dashboard</title>
      </Helmet>

      <nav className="navbar navbar-expand-lg userdashboard--Navbar">
        <div className="container-fluid">
          <div className="d-flex align-items-center ">
            <a
              className="navbar-brand"
              href="https://www.unitedwecare.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt=""
                src={Logo}
                width={"41.1"}
                className="d-block d-sm-none img-fluid"
                style={{ objectFit: "contain" }}
              />
              <img
                alt=""
                src={logo}
                width="250"
                height="50"
                fill="currentColor"
                className="bi bi-search me-3 d-none d-md-block"
                viewBox="0 0 16 16"
                style={{ objectFit: "contain" }}
              />
            </a>

            {currentUser?.header_image && (
              <img
                src={currentUser?.header_image}
                className="img-fluid"
                style={{
                  width: "110px",
                  height: "65px",
                  objectFit: "contain",
                }}
                alt="logo"
                // onClick={() => navigate("/user-dashboard")}
              />
            )}
          </div>
          {/* <div className="d-flex align-items-center border-bottom mx-5 userdashboard--Search w-100">
            <IconButton onClick={onHeaderSearchIcon}>
              <SearchIcon />
            </IconButton>
            <input
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyDown={handleEnterKeyDown}
              className="me-2 w-100 search_bar"
              type="search"
              placeholder="Search Experts, Programs, Worksheet etc..."
              aria-label="Search"
              style={{ border: "none", backgroundColor: "transparent" }}
            />
          </div> */}

          {/* <button
            className="navbar-toggler d-none"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
            style={{ boxShadow: "none", border: "none" }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav m-auto  align-items-center flex-grow-1 pe-3">
                <li className="nav-item w-100 d-none d-md-block">
                  <div className="d-flex align-items-center border-bottom mx-5">
                    <IconButton onClick={onHeaderSearchIcon}>
                      <SearchIcon />
                    </IconButton>
                    <input
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      onKeyDown={handleEnterKeyDown}
                      className="me-2 w-100 search_bar"
                      type="search"
                      placeholder="Search Experts, Programs, Worksheet etc..."
                      aria-label="Search"
                      style={{ border: "none", backgroundColor: "transparent" }}
                    />
                  </div>
                </li>

                
              </ul>
            </div>
          </div> */}
          <div className="userdashboard--Navbar__user d-flex align-items-center justify-content-between">
            <div
              className="d-flex align-items-center mr-4 p-1"
              style={{
                //border: "1px solid lightblue",
                borderRadius: "30px",
                minWidth: "max-content",
              }}
            >
              <img
                src={currentUser?.ProfileImage}
                width={42}
                height={42}
                alt=""
                style={{ borderRadius: "50%", cursor: "pointer" }}
                onClick={() => {
                  navigate("/user-dashboard");
                }}
              />
              <span style={{ fontWeight: "500", marginLeft: "5px" }}>
                Hi, {currentUser?.Name.split(" ")[0]}
              </span>
              <IconButton onClick={handleMenuClick}>
                <div className="d-flex align-items-center"></div>
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}

                <MenuItem onClick={onLogout}>Logout</MenuItem>
              </Menu>
            </div>
            {/* <Link
              to="https://www.unitedwecare.com/search"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton className="d-none userdashboard--Search__BTN">
                <SearchIcon />
              </IconButton>
            </Link> */}
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
