import axios from "axios";
import jwtDecode from "jwt-decode";

const headers = {
  "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
  "Content-Type": "application/json",
};

export const postAddSessionData = async (body, step) => {
  switch (step) {
    case 1:
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/session-notes/add`,
          {
            method: "POST",
            headers,
            body,
          }
        );

        const postResponse = await response.json();

        return postResponse.data;
      } catch (error) {
        console.error("Error:", error);
      }

      break;
    default:
      console.log("Something went wrong");
  }
};

export const fetchForms = async () => {
  try {
    const response = await fetch(
      "https://us.uwc.world/new-uwc/session-notes/get-forms",
      {
        method: "GET",
        headers,
      }
    );

    const postResponse = await response.json();
    //console.log("postResponse forms", postResponse)
    return postResponse.data;
    // Handle the response data
  } catch (error) {
    console.error(error);
    // Handle the error
  }
};

export const postClientScreenersData = async (userID, expertID) => {
  try {
    const screenerBody = {
      expert_id: userID,
      user_id: expertID,
      // user_id: currentUser?.UserId,
    };

    const response = await fetch(
      `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/assignments`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(screenerBody),
      }
    );
    const postResponse = await response.json();

    //const { assignments } = await postResponse.data;
    //const { worksheets } = await postResponse.data;

    return postResponse.data;

    // setClientScreeners(assignments);
    //setClientScreenersQNA(worksheets);
    //setUserData(postResponse?.data);

    //console.log("data", postResponse);
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};

export const getSessionNotes = async (professionalID, clientID) => {
  const body = {
    professional_id: professionalID,
    client_id: clientID,
  };

  // console.log("body", body)
  try {
    const response = await fetch(
      `https://us.uwc.world/new-uwc/session-notes/get-notes`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      }
    );

    const postResponse = await response.json();
    //console.log("postResponse", postResponse)

    return postResponse.data;
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};

export const getSessionNotesById = async (professionalID, id) => {
  const body = {
    professional_id: `${professionalID}`, //1545,
    session_note_id: +id, //46
  };

  try {
    const response = await fetch(
      `https://us.uwc.world/new-uwc/session-notes/get-notes`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      }
    );
    const postResponse = await response.json();

    return postResponse.data;
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};
