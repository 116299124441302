import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth';
import { AppointmentService } from './core/_request';

const AppointmentCard = (props) => {
    const { item } = props;
    console.log("item", item)
    const { currentUser } = useAuth();
    const onClickCallNow = (url) => {
        var request = { AppointmentId: item.ID, type: currentUser.UserType == 3 ? "Expert" : "User" };
        AppointmentService(request);
        window.open(url, "_blank");
    }

    const navigate = useNavigate();

    const onChatClick = () => {
        navigate(`/user-dashboard/chat/${item.ID}`)
    }

    return (
        <div>
            <div className='container bg-white d-flex align-items-center justify-content-between py-2 px-4 w-100' style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12" }}>
                <div className='d-flex flex-column flex-md-row align-items-center gap-3 gap-md-5 w-100'>
                    <div className='d-flex flex-row flex-md-column align-items-baseline justify-content-between gap-3 gap-md-0 content' style={{ width: "max-content" }}>
                        <Badge pill bg={item?.Status == "Open" ? "success" : item?.Status == "Completed" ? "secondary" : item?.Status == "Canceled" ? "danger" : "info"}>{item?.Status}</Badge>
                        <p className='fs-6 font-w600 text-black'>{item?.AppDate}</p>
                        <p className='fs-6 font-w600 text-black'>{item?.AppTime} ({item?.TimeZone})</p>
                    </div>
                    <div className="vr d-none d-md-block"></div>
                    <div className='d-flex gap-3 align-items-center py-md-2 w-100'>
                        <img src={item?.ProfileImage} style={{ width: 100, height: 100, borderRadius: "50%" }} alt='' />
                        <div className='d-flex flex-column justify-content-center'>
                            <h6>{item?.Name || "Professional"}</h6>
                            <p>{item.ExpertType}</p>
                        </div>
                    </div>
                    <div className='d-md-none flex-row justify-content-between align-items-center w-100 d-flex'>
                        <Link to={`${item.ID}`} className="text-decoration-none"><Button className='rounded-5 py-2' variant="secondary" style={{ whiteSpace: "nowrap" }}>View More</Button></Link>
                        {
                            item?.Status == "Open" &&
                            <Button onClick={() => onClickCallNow(item?.Meeting_link)} className='rounded-5 border-0 px-3 py-2' style={{ background: "linear-gradient(102.41deg, rgba(96, 180, 231, 0.8) -0.17%, rgba(100, 97, 224, 0.8) 70.6%)" }}><img src='/assets/img/video-1.png' className='px-2' alt='' />Video Call</Button>
                        }
                        <Button className='rounded-5 border-0' onClick={onChatClick} style={{ background: "linear-gradient(102.41deg, rgba(96, 180, 231, 0.8) -0.17%, rgba(100, 97, 224, 0.8) 70.6%)" }}>Chat</Button>
                    </div>
                </div>
                <div className='d-md-flex flex-row gap-3 align-items-center d-none justify-content-end'>
                    <Link to={`${item.ID}`} className="text-decoration-none"><Button className='rounded-5' variant="secondary" style={{ whiteSpace: "nowrap" }}>View More</Button></Link>
                    {
                        item?.Status == "Open" &&
                        <Button onClick={() => onClickCallNow(item?.Meeting_link)} className='rounded-5 border-0' style={{ background: "linear-gradient(102.41deg, rgba(96, 180, 231, 0.8) -0.17%, rgba(100, 97, 224, 0.8) 70.6%)" }}><img src='/assets/img/video-1.png' className='px-2' alt='' />Video Call</Button>
                    }
                    <Button className='rounded-5 border-0' onClick={onChatClick} style={{ background: "linear-gradient(102.41deg, rgba(96, 180, 231, 0.8) -0.17%, rgba(100, 97, 224, 0.8) 70.6%)" }}>Chat</Button>
                </div>
            </div>
        </div>
    )
}

export default AppointmentCard