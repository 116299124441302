import React, { useEffect, useState } from 'react'
import { Button, Toast, ToastContainer } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CourseCard = (props) => {
    const navigate = useNavigate();
    const [responseMessage, setResponseMessage] = useState(false);
    const [show, setShow] = useState(false);

    const onClickCourse = () => {
        navigate(`/course/${props?.courseData?.name}/${props?.courseData?.id}`);
    }

    const onCLickCopy = async () => {
        try {
            await navigator?.clipboard?.writeText(props?.courseData?.website_url);
            // setResponseMessage(true);
            alert("Link Copied!");
            // setShow(true);
        } catch (err) {
            // console.log(err)
        }
    }

    // useEffect(() => {
    //     setTimeout(() => {
    //         setResponseMessage(false);
    //     }, 10000);
    //     return () => clearTimeout()
    // }, [responseMessage]);

    const onClickWhatsAppOnMobileWeb = () => {
        window.location.href = `whatsapp://send?text=${props?.courseData?.website_url}`
    }

    const onClickWhatsAppOnWeb = () => {
        window.location.href = `https://web.whatsapp.com/send?text=${props?.courseData?.website_url}`
    }

    return (
        // <Link className='text-decoration-none' onClick={onClickCourse}>
        <div className='rounded-3 px-3 py-3 d-flex flex-column justify-content-between gap-3' style={{ border: "1px solid #ECECEC", height: "430px" }}>
            <Link onClick={onClickCourse} className='text-decoration-none d-flex flex-column gap-1' >
                <img src={props?.courseData?.website_thumbnail_url} alt='' className='rounded-3' />
                <h2 className='font-w600 font-inter fs-4 my-2 my-md-0' style={{ color: "#101828", lineHeight: "32px" }}>{props?.courseData?.name}</h2>
            </Link>
            <div className='d-flex flex-column gap-3'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='rounded-5 d-flex align-items-center gap-1 justify-content-center px-2 py-1' style={{ background: "rgba(159, 214, 139, 0.10)" }}>
                        <img src='assets/img/tabler_clock-filled.png' alt='' />
                        <p className='font-inter fs-6 font-w500' style={{ color: "#4C9234" }}>{props?.courseData?.duration}</p>
                    </div>
                    <div className='d-flex gap-2 align-items-center'>
                        <Link onClick={onCLickCopy} className='rounded-5 d-flex align-items-center gap-1 justify-content-center px-2 py-1 text-decoration-none' style={{ border: "1px solid #D0D5DD", boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}>
                            <img src='assets/img/copy.png' alt='' />
                            <p className='font-inter font-w600 fs-6' style={{ color: "#344054" }}>Copy link</p>
                        </Link>
                        {/* <ToastContainer
                            className="p-3"
                            position='top-end'
                            style={{ zIndex: 1 }}
                        >
                            <Toast.Header closeButton>

                            </Toast.Header>
                            <Toast bg='success' show={show} delay={3000} autohide>
                                <Toast.Body className='font-inter text-white'>Link Copied!</Toast.Body>
                            </Toast>
                        </ToastContainer> */}
                        <div className='rounded-circle d-flex align-items-center justify-content-center' style={{ border: "1px solid #D0D5DD", height: "35px", width: "35px", cursor: "pointer" }}>
                            <a className='d-none d-md-block' style={{ cursor: "pointer" }} onClick={onClickWhatsAppOnWeb}>
                                <img src='assets/img/whatsapp.png' alt='' style={{ height: "25px", width: "25px" }} />
                            </a>
                            <a className='d-block d-md-none' style={{ cursor: "pointer" }} onClick={onClickWhatsAppOnMobileWeb}>
                                <img src='assets/img/whatsapp.png' alt='' style={{ height: "25px", width: "25px" }} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center justify-content-center col-12'>
                    {props?.courseData?.price === 0 &&
                        <Button
                            onClick={onClickCourse}
                            className='d-flex align-items-center gap-3 justify-content-center border-0 rounded-5 px-3 col-9 my-2'
                            style={{ background: "#FD6F1F" }}>
                            <img src='assets/img/unlock.png' alt='' />
                            Start for FREE
                        </Button>
                        ||
                        <Button
                            onClick={onClickCourse}
                            className='d-flex align-items-center gap-3 justify-content-center border-0 rounded-5 px-3 col-9 my-2'
                            style={{ background: "#FD6F1F" }}>
                            <img src='assets/img/lock.png' alt='' />
                            Subscribe to Enroll
                        </Button>
                    }
                </div>
            </div>
        </div >
        // </Link>
    )
}

export default CourseCard