import React from 'react'
import '.././Plans.css'

const ClientArea = () => {
    return (
        <div>
            {/* <!------ client-area  (desktop-display)------> */}
            <div className="client-area mobile-display-none desktop-display">
                <div className="container">
                    <div className="client-inner">
                        <h2 className="heading1">Our Enterprise Clients</h2>
                        <div className="client-up">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-3">
                                    <img src="assets/img/Client1.jpg" alt='' />
                                </div>
                                <div className="col-lg-3 col-md-3 col-3">
                                    <img src="assets/img/Client2.jpg" alt='' />
                                </div>
                                <div className="col-lg-3 col-md-3 col-3">
                                    <img src="assets/img/Client7.jpg" alt='' />
                                </div>
                                <div className="col-lg-3 col-md-3 col-3">
                                    <img src="assets/img/Client4.jpg" alt='' />
                                </div>
                            </div>

                        </div>
                        {/* <div className="client-down">
                            <div className="row">
                                <div className="col-lg-3 col-md-3 col-3">
                                    <img src="assets/img/Client5.jpg" alt='' />
                                </div>
                                <div className="col-lg-3 col-md-3 col-3">
                                    <img src="assets/img/Client6.jpg" alt='' />
                                </div>
                                <div className="col-lg-3 col-md-3 col-3">
                                    <img src="assets/img/Client7.jpg" alt='' />
                                </div>
                                <div className="col-lg-3 col-md-3 col-3">
                                    <img src="assets/img/Client8.jpg" alt='' />
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            {/* <!------ client-area  (mobile-display)------> */}
            <div className="client-area mobile-display desktop-display-none">
                <div className="container">
                    <div className="client-inner">
                        <h2 className="heading1">Our Enterprise Clients</h2>
                        <div className="client-up">
                            <div className="m-client">
                                <img src="assets/img/Client1.jpg" alt='' />
                            </div>
                            <div className="m-client">
                                <img src="assets/img/Client2.jpg" alt='' />
                            </div>
                            <div className="m-client">
                                <img src="assets/img/Client7.jpg" alt='' />
                            </div>
                            <div className="m-client">
                                <img src="assets/img/Client4.jpg" alt='' />
                            </div>
                        </div>
                        {/* <div className="client-down">
                            <div className="m-client">
                                <img src="assets/img/Client5.jpg" alt='' />
                            </div>
                            <div className="m-client">
                                <img src="assets/img/Client6.jpg" alt='' />
                            </div>
                            <div className="m-client">
                                <img src="assets/img/Client7.jpg" alt='' />
                            </div>
                            <div className="m-client">
                                <img src="assets/img/Client8.jpg" alt='' />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ClientArea