import { useState, useEffect, forwardRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from "@mui/material/Button";

import { format } from "date-fns";
import moment from "moment";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

//Icons
import EditIcon from '@mui/icons-material/Edit';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

dayjs.extend(isBetween)

const MyScheduleTable = ({ onHolidayDateClick }) => {
  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedSlots, setSelectedSlots] = useState([]);
  //const [newSelectedSlots, setNewSelectedSlots] = useState([]);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);
  const [scheduleUpdating, setScheduleUpdating] = useState(false)
  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const [holidayList, setHolidayList] = useState([]);

  const userID = JSON.parse(localStorage.getItem("profile")).UserId;

  const getSlotsData = async () => {
    const payload = {
      user_id: userID,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/slots`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const { slots } = await postResponse.data;
      const { holidays } = await postResponse.data;

      setSelectedSlots(slots);
      setHolidayList(holidays)
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const fetchTimes = async () => {
    try {
      const response = await fetch(
        "https://us.uwc.world/new-uwc/expert/dashboard/all-timeslots",
        {
          method: "GET",
          headers,
        }
      );

      const postResponse = await response.json();

      setTimeSlots(postResponse.data?.slots);
      // Handle the response data
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  //Get Slots
  useEffect(() => {
    getSlotsData();
  }, []);

  //Time slots
  useEffect(() => {
    fetchTimes();

  }, []);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  // Helper function to check if a slot is selected
  const isSlotSelected = (date, timeSlot) => {
    return selectedSlots.some(
      (slot) =>
        slot.date === moment(date).format("YYYY-MM-DD") &&
        slot.slot === timeSlot
    );
  };

  const handleSlotToggle = (slotdate, timeSlot, id) => {
    setSelectedSlots((prevSelectedSlots) => {
      const newSelectedSlots = [...prevSelectedSlots];
      const isSelected = isSlotSelected(slotdate, timeSlot);

      if (isSelected) {
        // If the slot is already selected, remove it from the array
        return newSelectedSlots.filter(
          (slot) =>
            slot.date !== moment(slotdate).format("YYYY-MM-DD") ||
            slot.slot !== timeSlot
        );
      } else {
        // If it is a single select question, unselect other slots in the same date
        if (!timeSlots.some((slot) => slot.slot === timeSlot)) {
          return newSelectedSlots.filter(
            (slot) => slot.date !== moment(slotdate).format("YYYY-MM-DD")
          );
        }

        // Add the slot to the array
        return [
          ...newSelectedSlots,
          {
            date: moment(slotdate).format("YYYY-MM-DD"),
            slot: timeSlot,
            slot_id: id,
          },
        ];
      }
    });

    //setNewSelectedSlots(selectedSlots);
  };

  const handlePreviousDates = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() - 9);
      return newDate;
    });
  };

  const handleNextDates = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setDate(newDate.getDate() + 9);
      return newDate;
    });
  };

  const today = new Date();
  const endDate = new Date(today);
  endDate.setDate(endDate.getDate() + 13); // 13 days excluding today total 14 days

  const dates = [];
  let startDate = new Date(currentDate);
  while (dates.length < 9 && startDate <= endDate) {
    dates.push(new Date(startDate));
    startDate.setDate(startDate.getDate() + 1);
  }

  // Add empty cells to ensure 9 table cells are rendered
  const remainingEmptyCells = 9 - dates.length;
  for (let i = 0; i < remainingEmptyCells; i++) {
    dates.push(null);
  }

  const currentMonth = format(currentDate, "MMMM");
  const currentYear = format(currentDate, "yyyy");

  //Send Slot update data to API

  const updateSchedule = async () => {
    setScheduleUpdating(true)
    const transformedData = selectedSlots.map((item) => ({
      date: item.date,
      slot_id: item.slot_id,
    }));

    const payload = {
      user_id: userID,
      slots: transformedData,
    };
    // console.log("payload", payload);

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/slots`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const reponseCode = await postResponse?.code;

      if (reponseCode == 200 || reponseCode == 201) {
        setSnackbarMessage(postResponse?.data?.message);
        setOpenSnackbar(true);
        setScheduleUpdating(false);
        fetchTimes();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };



  /*const isDateBetween = (date, startDate, endDate) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const current = dayjs(date);

    //return (dayjs(date).isBetween(dayjs(startDate), dayjs(endDate), 'day', '[)'))

    return start <= current && current <= end;
  };*/

  const isDateBetween = (date, holidayRanges) => {
    //const formattedDateItem = ;

    for (const range of holidayRanges) {
      const start = dayjs(range.start_date).format("YYYY-MM-DD");
      const end = dayjs(range.end_date).format("YYYY-MM-DD");
      const current = dayjs(date).format("YYYY-MM-DD");

      if (start <= current && current <= end) {
        return true; // Date is within a holiday range
      }
    }

    return false; // Date is not within any holiday range
  };


  function findDateInRange(dateToCheck, dateRanges) {
    dateToCheck = dayjs(dateToCheck);
    return dateRanges.find(range => {
      const startDate = dayjs(range.start_date);
      const endDate = dayjs(range.end_date);
      return dateToCheck.isBetween(startDate, endDate, null, "[]");
    });
  }


  const handleHolidayClick = (date) => {
    //console.log("holidayList", holidayList)
    const result = findDateInRange(dayjs(date).format("YYYY-MM-DD"), holidayList)
    onHolidayDateClick(date, result)
  }

  //console.log("timeSlots", timeSlots);
  return (
    <div>
      <Table className="ManualDateSelector--Table">
        <TableHead>
          <TableRow className="DateSelector--Container">
            <TableCell>
              {currentMonth} {currentYear}
            </TableCell>
            <TableCell>
              {currentDate > today && (
                <TableCell>
                  <Button onClick={handlePreviousDates}>
                    <ChevronLeftOutlinedIcon />
                  </Button>
                </TableCell>
              )}
            </TableCell>
            {dates.map((date, index) => (
              <TableCell key={index} align="center">
                {date && (
                  <div
                    className={`DateSelector--Item ${date.getDate() === today.getDate() ? "Today" : ""
                      }`}
                  >
                    <p>{format(date, "dd MMM")}</p>
                    <span>{format(date, "EEE")}</span>
                  </div>
                )}
              </TableCell>
            ))}
            <TableCell>
              {startDate <= endDate && (
                <TableCell>
                  <Button onClick={handleNextDates}>
                    <ChevronRightOutlinedIcon />
                  </Button>
                </TableCell>
              )}
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {timeSlots.map((timeSlot) => (
            <TableRow key={timeSlot.id}>
              <TableCell>
                <div>{timeSlot.slot}</div>
              </TableCell>
              <TableCell></TableCell>
              {dates.map((date, index) => {
                const isSelected = isSlotSelected(date, timeSlot.slot);

                //'dayjs' expects the date string to be in a format like 'YYYY-MM-DD' for accurate comparison.
                const formattedDateItem = dayjs(date).format("YYYY-MM-DD");
                const isBetweenDates = isDateBetween(formattedDateItem, holidayList)

                return (
                  <TableCell key={index} align="center" style={{ background: `${(date && isBetweenDates) ? '#d2f1fc' : 'white'}`, margin: '5px' }}>
                    {
                      date ? (
                        (
                          isBetweenDates ? (
                            <Button onClick={() => handleHolidayClick(date)} variant="contained" sx={{ minWidth: '0', padding: '5px' }}>
                              <EditIcon sx={{ fontSize: '0.75rem' }} />
                            </Button>
                          ) : (
                            <Checkbox
                              checked={isSelected}
                              onChange={() =>
                                handleSlotToggle(date, timeSlot.slot, timeSlot.id)
                              }
                            />
                          )
                        )
                      ) : (null)
                    }


                  </TableCell>
                );
              })}
              <TableCell></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div className="UpdateButton--Container bg-white d-flex" style={{ position: 'sticky', bottom: '0px', zIndex: '10' }}>
        <Button
          sx={{ display: "block", marginLeft: "auto" }}
          className="my-3 GreenCTA"
          onClick={updateSchedule}
          disabled={scheduleUpdating}
        >
          {
            scheduleUpdating ? "Updating..." : "Update Schedule"
          }

        </Button>
      </div>


      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MyScheduleTable;
