import React, { useEffect, useState } from "react";
import DetailPageCard from "../DetailPageCard";
import {
  Badge,
  Button,
  ButtonGroup,
  Modal,
  Row,
  ToggleButton,
} from "react-bootstrap";
import PrescriptionsCard from "../PrescriptionsCard";
import { useParams, useNavigate } from "react-router-dom";
import {
  MySubscriptionService,
  GetAppointment_Documents_Files_Sent_Expert,
  GetAppointment_Documents_Files_Sent_User,
  saveAppointment_Documents_Files,
  CancelAppointmentService,
  CheckFeedbackStatus,
} from "./core/_request";
import {
  AppointmentService,
  ExpertDetailsService,
  FeedbackFormCall,
  RateYourExpertAndFeedback,
  UpdateCallJoinStatus,
  getAppointment_Session,
} from "../core/_request";
import { useAuth } from "../../auth";
import { storage } from "../../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, signInAnonymously } from "firebase/auth";
import { MySubscription_Status } from "../core/models";
import moment from "moment";
import { getCountryCode } from "../core/commonFun";
import DashboardDetailCard from "./DashboardDetailCard";
import { useContext } from "react";
import { AppContext } from "../../context";
import { Rating, TextField } from "@mui/material";

const AppointmentDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [expertData, setExpertData] = useState();
  const [expertDocumentList, setExpertDocumentList] = useState();
  const [userDocumentList, setUserDocumentList] = useState();
  const [sessionData, setSessionData] = useState();
  const [percent, setPercent] = useState();
  const [title, setTitle] = useState();
  const [docsFile, setDocsFile] = useState();
  const [loading, setLoading] = useState();
  const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
  const { currentUser, logout } = useAuth();
  const countryCode = getCountryCode();
  const [show, setShow] = useState(false);
  const [starValue, setStarValue] = useState(0);
  const [callQuality, setCallQuality] = useState("");
  const [expertInteraction, setExpertInteraction] = useState("");
  const [reviewText, setReviewText] = useState("");
  const [displayNameBool, setDisplayNameBool] = useState();
  const [bookAnotherSession, setBookAnotherSession] = useState();
  const [feedbackStatus, setFeedbackStatus] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getCurrentTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
    MySubscriptionService().then((resp) =>
      setData(resp?.data?.find((o) => o.ID == id))
    );
  }, []);

  useEffect(() => {
    bindFiles();
  }, [expertData]);

  useEffect(() => {
    //console.log("DATA", data);
    if (data?.ExpertId)
      ExpertDetailsService(data?.ExpertId).then((resp) => {
        //console.log("ExpertDetailsService", resp)
        var data = resp.data;
        // console.log("resp.data", resp.data)
        if (countryCode == "IN")
          data["countryAmount"] = data.Hourly_Consultaion_Fee_amount;
        else data["countryAmount"] = data.USD_Amount ?? 0;
        setExpertData(resp.data);
      });
  }, [data]);

  const bindFiles = () => {
    if (expertData && expertData.Id) {
      GetAppointment_Documents_Files_Sent_Expert(id, expertData.Id).then(
        (resp) => setExpertDocumentList(resp.data)
      );
      GetAppointment_Documents_Files_Sent_User(id, expertData.Id).then((resp) =>
        setUserDocumentList(resp.data)
      );
      getAppointment_Session(expertData.Id).then((resp) =>
        setSessionData(resp.data)
      );
    }
  };

  const uploadNewFile = async (event) => {
    setLoading(true);
    var file = event.target.files[0];
    var dirName = moment().format("MMMM_YYYY");
    const storageRef = ref(storage, dirName + "/" + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // update progress
        setPercent(percent);
      },
      (err) => alert(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setDocsFile(url);
          setLoading(false);
        });
      }
    );
  };

  const onSubmitNewDoc = () => {
    setLoading(true);
    // //console.log(this.props.route.params);
    // //console.log(this.state);
    if (!docsFile || !title) return;
    var req = {
      AppointmentId: id,
      Title: title,
      Note: "Note",
      DocsFile: docsFile,
    };

    //console.log("Add Documents req", req)
    saveAppointment_Documents_Files(req).then((resp) => {
      bindFiles();
      setLoading(false);
      setTitle("");
      if (resp.ResponseCode == 200 || resp.ResponseCode == 201) {
        //console.log("Add Documents resp", resp);
      } else {
        alert(resp.ResponseMessage);
      }
    });
  };

  const onClickRatingSubmit = () => {
    var ratingBody = {
      ExpertId: data?.ExpertId,
      AppointmentId: id,
      RateYourExpert: starValue,
      CallQuality: callQuality,
      InteractionWithExpert: expertInteraction,
      ReviewForExpert: reviewText,
      DisplayMyName: displayNameBool,
      BookAnotherSessionWithExpert: bookAnotherSession,
    };
    RateYourExpertAndFeedback(ratingBody).then((resp) => {
      if (resp.ResponseCode === 200) {
        alert(resp.ResponseMessage);
        handleClose();
      }
    });
  };

  const cancellation = () => {
    setLoading(true);
    var req = { AppointmentId: data?.ID };
    console.log("CancelAppointmentService req", req);
    CancelAppointmentService(req).then((resp) => {
      if (resp != null && resp.ResponseCode == 200) {
        alert("Appointment Cancelled Successfully");
        window.location.reload();
        setLoading(false);
      } else if (resp != null && resp.ResponseCode == 401) {
        logout();
        // navigate("/login");
        setShowLloginPopup(true);
      }
    });
  };

  var user_id = JSON.parse(localStorage.getItem("profile"))?.UserId;
  var feedbackBody = {
    user_id: user_id,
    user_type: "user",
    current_time: getCurrentTime(),
  };
  FeedbackFormCall(feedbackBody).then((resp) => {
    if (resp.ResponseCode === 200) {
      console.log("resp?.data?.show_popup", resp?.data?.show_popup);
      if (resp?.data?.show_popup === true) {
        handleShow();
      }
    }
  });

  const onClickCallNow = (url) => {
    var body = {
      user_id: user_id,
      appointment_id: id,
      join_status: "Joined",
      user_type: "Expert",
    };

    UpdateCallJoinStatus(body).then((resp) => {
      if (resp?.ResponseCode) {
      }
    });

    var request = {
      AppointmentId: id,
      type: currentUser.UserType == 3 ? "Expert" : "User",
    };
    AppointmentService(request);
    window.open(url, "_blank");
  };

  const onClickSkip = () => {
    handleClose();
  };

  useEffect(() => {
    CheckFeedbackStatus(id).then((resp) => {
      debugger;
      if (resp?.data === true) {
        setFeedbackStatus(true);
      } else {
        setFeedbackStatus(false);
      }
    });
  }, [feedbackStatus]);

  return (
    <>
      <div
        className="pt-5"
        style={{
          background:
            "linear-gradient(180deg, #3DACF0 55.1%, rgba(99, 132, 227, 0) 100%)",
          padding: 0,
          margin: 0,
        }}
      >
        <div className="container">
          <div>
            <p className="fs-3 text-white mb-5" style={{ color: "#fff" }}>
              My Appointments / {data?.Name || "Professional"}
            </p>
          </div>
          <div className="row">
            <div className="col-md-4">
              <DashboardDetailCard data={expertData ?? data} />
            </div>
            <div className="col-md-8 d-flex flex-column gap-5">
              <div className="d-flex justify-content-between align-items-center px-2 py-2 bg-white rounded-2 mt-3 mt-md-0">
                <div className="d-flex py-2 gap-2">
                  <Badge bg="success" className="py-4 px-4">
                    {data?.Status}
                  </Badge>
                  <p className="fs-6 font-w600 text-black">
                    {data?.AppDate}
                    <br /> {data?.AppTime} ({data?.TimeZone})
                  </p>
                  <p className="fs-6 font-w600 text-black"></p>
                </div>
                <div className="">
                  {data?.Status == "Open" && (
                    <Button
                      onClick={() => onClickCallNow(data?.Meeting_link)}
                      className="rounded-5 border-0"
                      style={{
                        background:
                          "linear-gradient(102.41deg, rgba(96, 180, 231, 0.8) -0.17%, rgba(100, 97, 224, 0.8) 70.6%)",
                      }}
                    >
                      <img
                        src="/assets/img/phone.png"
                        className="px-2"
                        alt=""
                      />
                      Call Now
                    </Button>
                  )}
                  {data?.Status == MySubscription_Status.BOOKED &&
                    data?.IsValid24HourReschdule && (
                      <div className="d-flex justify-content-between align-items-center px-4 py-4 bg-white rounded-2 mt-3 mt-md-0 gap-3">
                        <Button
                          onClick={() =>
                            navigate(
                              `/book-an-appointment/${data?.ExpertId}?AppointmentId=${data?.ID}`
                            )
                          }
                          className="rounded-3 border-0 py-2"
                          style={{
                            background:
                              "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)",
                            color: "#fff",
                          }}
                        >
                          {/* <img src='/assets/img/clock.png' className='px-2' alt='' /> */}
                          Reschedule
                        </Button>
                        {(loading && (
                          <Button
                            className="rounded-3 border-0 py-2"
                            style={{
                              background:
                                "linear-gradient(102.41deg, rgba(96, 180, 231, 0.8) -0.17%, rgba(100, 97, 224, 0.8) 70.6%)",
                            }}
                          >
                            <img
                              src="/assets/img/loading.gif"
                              alt="Unitedwecare"
                              style={{ height: 26, width: 26 }}
                            />
                          </Button>
                        )) || (
                          <Button
                            onClick={() => cancellation()}
                            className="rounded-3 border-0 py-2"
                            style={{
                              background:
                                "linear-gradient(102.41deg, rgba(96, 180, 231, 0.8) -0.17%, rgba(100, 97, 224, 0.8) 70.6%)",
                            }}
                          >
                            Cancel
                          </Button>
                        )}
                      </div>
                    )}
                  {data?.Status === "Completed" && (
                    <Button
                      onClick={handleShow}
                      className="border-0"
                      style={{}}
                    >
                      Feedback
                    </Button>
                  )}
                  <Modal
                    className="feedbackModal px-0"
                    show={show}
                    onHide={handleClose}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header className="d-block d-md-none">
                      <h5 className="font-helvetica font-w700 fs-2 text-center">
                        Feedback Form
                      </h5>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                      <div className="d-none d-md-flex">
                        <div
                          className="col-md-4 px-4 d-flex flex-column align-items-baseline justify-content-center"
                          style={{ background: "#FDFFD6" }}
                        >
                          <img
                            src={expertData?.ProfileImage}
                            alt=""
                            className="rounded-circle d-block mx-auto my-4"
                            style={{
                              background: "#EEEFEE",
                              height: "235px",
                              width: "235px",
                            }}
                          />
                          <div className="d-flex align-items-center gap-2 my-2">
                            <h5 className="font-helvetica font-w700 fs-2">
                              {expertData?.Title}{" "}
                              {expertData?.Name || "Professional"}
                            </h5>
                            <img
                              src="/assets/img/IN.png"
                              alt=""
                              className="rounded-circle"
                              style={{}}
                            />
                          </div>
                          {/* <div className='d-flex align-items-center gap-2 my-2'>
                                                        <img src='/assets/img/Vector(32).png' alt='' />
                                                        <p className='font-helvetica text-black fs-5'>
                                                            Your live call with our expert for <b>2:03 mins</b>
                                                        </p>
                                                    </div> */}
                          <div
                            className="rounded-3 w-auto my-2"
                            style={{ background: "#E0F6D8" }}
                          >
                            <p
                              className="font-inter text-center fs-6 font-w500 px-2"
                              style={{ color: "#134700" }}
                            >
                              {expertData?.RegisterYourselfAs}
                            </p>
                          </div>
                          <div className="my-2">
                            <h6 className="font-inter fs-6 font-w600 text-black">
                              {expertData?.Experience} years of Experience
                            </h6>
                            <h6 className="font-inter fs-6 font-w600 text-black">
                              Speaks:
                              <span className="font-inter fs-6 font-w500 text-black">
                                {expertData?.Languages}
                              </span>
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-8 py-3 px-5">
                          <div className="d-flex flex-column align-items-center justify-content-center my-5">
                            <h4 className="font-helvetica fs-2 font-w700">
                              How was your call with our expert?
                            </h4>
                            <Rating
                              name="simple-controlled"
                              value={starValue}
                              onChange={(event, newValue) => {
                                setStarValue(newValue);
                              }}
                              precision={0.5}
                              sx={{ fontSize: "50px" }}
                            />
                          </div>
                          <div className="d-flex align-items-center gap-5 my-5">
                            <div>
                              <h6 className="font-helvetica font-w500 fs-3 text-black">
                                How was your cloud call quality?
                              </h6>
                              <ButtonGroup className="d-flex align-items-center gap-2">
                                <ToggleButton
                                  className="w-auto p-2"
                                  style={{
                                    borderRadius: "10px",
                                    border:
                                      callQuality === "0"
                                        ? "2px solid #FD6F1F"
                                        : "2px solid #E1E5EE",
                                    background: "rgba(225, 229, 238, 0.31)",
                                  }}
                                  name="radio"
                                  type="radio"
                                  onClick={() => setCallQuality("0")}
                                >
                                  <img
                                    src="/assets/img/slightly_frowning_face.png"
                                    alt=""
                                  />
                                </ToggleButton>
                                <ToggleButton
                                  className="w-auto p-2"
                                  style={{
                                    borderRadius: "10px",
                                    border:
                                      callQuality === "1"
                                        ? "2px solid #FD6F1F"
                                        : "2px solid #E1E5EE",
                                    background: "rgba(225, 229, 238, 0.31)",
                                  }}
                                  name="radio"
                                  type="radio"
                                  onClick={() => setCallQuality("1")}
                                >
                                  <img src="/assets/img/blush.png" alt="" />
                                </ToggleButton>
                                <ToggleButton
                                  className="w-auto p-2"
                                  style={{
                                    borderRadius: "10px",
                                    border:
                                      callQuality === "2"
                                        ? "2px solid #FD6F1F"
                                        : "2px solid #E1E5EE",
                                    background: "rgba(225, 229, 238, 0.31)",
                                  }}
                                  name="radio"
                                  type="radio"
                                  onClick={() => setCallQuality("2")}
                                >
                                  <img
                                    src="/assets/img/heart_eyes.png"
                                    alt=""
                                  />
                                </ToggleButton>
                              </ButtonGroup>
                            </div>
                            <div>
                              <h6 className="font-helvetica font-w500 fs-3 text-black">
                                How was your interaction with expert?
                              </h6>
                              <ButtonGroup className="d-flex align-items-center gap-2">
                                <ToggleButton
                                  className="w-auto p-2"
                                  style={{
                                    borderRadius: "10px",
                                    border:
                                      expertInteraction === "0"
                                        ? "2px solid #FD6F1F"
                                        : "2px solid #E1E5EE",
                                    background: "rgba(225, 229, 238, 0.31)",
                                  }}
                                  name="radio"
                                  type="radio"
                                  onClick={() => setExpertInteraction("0")}
                                >
                                  <img
                                    src="/assets/img/slightly_frowning_face.png"
                                    alt=""
                                  />
                                </ToggleButton>
                                <ToggleButton
                                  className="w-auto p-2"
                                  style={{
                                    borderRadius: "10px",
                                    border:
                                      expertInteraction === "1"
                                        ? "2px solid #FD6F1F"
                                        : "2px solid #E1E5EE",
                                    background: "rgba(225, 229, 238, 0.31)",
                                  }}
                                  name="radio"
                                  type="radio"
                                  onClick={() => setExpertInteraction("1")}
                                >
                                  <img src="/assets/img/blush.png" alt="" />
                                </ToggleButton>
                                <ToggleButton
                                  className="w-auto p-2"
                                  style={{
                                    borderRadius: "10px",
                                    border:
                                      expertInteraction === "2"
                                        ? "2px solid #FD6F1F"
                                        : "2px solid #E1E5EE",
                                    background: "rgba(225, 229, 238, 0.31)",
                                  }}
                                  name="radio"
                                  type="radio"
                                  onClick={() => setExpertInteraction("2")}
                                >
                                  <img
                                    src="/assets/img/heart_eyes.png"
                                    alt=""
                                  />
                                </ToggleButton>
                              </ButtonGroup>
                            </div>
                          </div>
                          <h6 className="font-helvetica text-black fs-4 font-w500 my-2">
                            Please write a review for expert
                          </h6>
                          <TextField
                            fullWidth
                            multiline
                            rows={3}
                            style={{ height: "100px" }}
                            className="feedbackInput mb-2"
                            onChange={(e) => setReviewText(e.target.value)}
                          />
                          <div className="my-3">
                            <h6 className="font-helvetica font-w500 fs-3 text-black">
                              Would you like to have your name displayed with
                              your review?
                            </h6>
                            <ButtonGroup className="d-flex align-items-center gap-3">
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: displayNameBool
                                    ? "2px solid #134700"
                                    : "none",
                                  background: "#EEFFE8",
                                  color: "#134700",
                                  width: "55px",
                                }}
                                onClick={() => setDisplayNameBool(true)}
                              >
                                <p className="font-helvetica fs-6">Yes</p>
                              </ToggleButton>
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: !displayNameBool
                                    ? "2px solid #B54100"
                                    : "none",
                                  background: "#FFF8E5",
                                  color: "#B54100",
                                  width: "55px",
                                }}
                                onClick={() => setDisplayNameBool(false)}
                              >
                                <p className="font-helvetica fs-6">No</p>
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <div className="my-3">
                            <h6 className="font-helvetica font-w500 fs-3 text-black">
                              Will you book another session with this expert?
                            </h6>
                            <ButtonGroup className="d-flex align-items-center gap-3">
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: bookAnotherSession
                                    ? "2px solid #134700"
                                    : "none",
                                  background: "#EEFFE8",
                                  color: "#134700",
                                  width: "55px",
                                }}
                                onClick={() => setBookAnotherSession(true)}
                              >
                                <p className="font-helvetica fs-6">Yes</p>
                              </ToggleButton>
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: !bookAnotherSession
                                    ? "2px solid #B54100"
                                    : "none",
                                  background: "#FFF8E5",
                                  color: "#B54100",
                                  width: "55px",
                                }}
                                onClick={() => setBookAnotherSession(false)}
                              >
                                <p className="font-helvetica fs-6">No</p>
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <Row className="my-4 gap-3">
                            <Button
                              disabled={feedbackStatus === true}
                              onClick={onClickRatingSubmit}
                              className="border-0 rounded-5 col-3 px-3 py-2"
                              style={{
                                background: "#FD6F1F",
                                boxShadow:
                                  "0px 1.06px 2.13px 0px rgba(16, 24, 40, 0.05)",
                              }}
                            >
                              Submit
                            </Button>
                            <Button
                              onClick={onClickSkip}
                              className="rounded-5 bg-white col-3 px-3 py-2"
                              style={{
                                border: "1px solid #FD6F1F",
                                boxShadow:
                                  "0px 1.06px 2.13px 0px rgba(16, 24, 40, 0.05)",
                                color: "#FD6F1F",
                              }}
                            >
                              Skip
                            </Button>
                          </Row>
                        </div>
                      </div>
                      <div className="d-block d-md-none">
                        <div
                          className="d-flex flex-column gap-2"
                          style={{ background: "#FDFFD6", padding: "30px 0" }}
                        >
                          <div className="d-flex align-items-center justify-content-center gap-2 px-2">
                            <img
                              src={expertData?.ProfileImage}
                              alt=""
                              className="rounded-circle"
                              style={{
                                background: "#EEEFEE",
                                height: "100px",
                                width: "100px",
                              }}
                            />
                            <div className="">
                              <div className="d-flex align-items-center gap-2 my-2">
                                <h5 className="font-helvetica font-w700 fs-2">
                                  {expertData?.Title}{" "}
                                  {expertData?.Name || "Professional"}
                                </h5>
                                <img
                                  src="/assets/img/IN.png"
                                  alt=""
                                  className="rounded-circle"
                                  style={{}}
                                />
                              </div>
                              <div
                                className="rounded-3 w-auto my-2"
                                style={{ background: "#E0F6D8" }}
                              >
                                <p
                                  className="font-inter text-center fs-6 font-w500 px-2"
                                  style={{ color: "#134700" }}
                                >
                                  {expertData?.RegisterYourselfAs}
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* <div className='d-flex align-items-center justify-content-center'>
                                                        <div className='d-flex align-items-center gap-2 my-2 mx-1 rounded-3 p-2' style={{ border: "1px solid #00860D", background: "rgba(255, 255, 255, 0.47)", width: "fit-content" }}>
                                                            <img src='/assets/img/Vector(32).png' alt='' />
                                                            <p className='font-helvetica text-black fs-6'>
                                                                Your live call with our expert for <b>2:03 mins</b>
                                                            </p>
                                                        </div>
                                                    </div> */}
                        </div>
                        <div className="px-2">
                          <div className="d-flex flex-column my-3">
                            <h4 className="font-helvetica fs-5 font-w700 mb-0">
                              How was your call with our expert?
                            </h4>
                            <Rating
                              name="simple-controlled"
                              value={starValue}
                              onChange={(event, newValue) => {
                                setStarValue(newValue);
                              }}
                              precision={0.5}
                              sx={{ fontSize: "35px" }}
                            />
                          </div>
                          <div className="my-3">
                            <h6 className="font-helvetica fs-5 font-w700">
                              How was your cloud call quality?
                            </h6>
                            <ButtonGroup className="d-flex align-items-center gap-2">
                              <ToggleButton
                                className="w-auto p-2"
                                style={{
                                  borderRadius: "10px",
                                  border: "2px solid #E1E5EE",
                                  background:
                                    callQuality === "0"
                                      ? "#3DACF0"
                                      : "rgba(225, 229, 238, 0.31)",
                                }}
                                name="radio"
                                type="radio"
                                onClick={() => setCallQuality("0")}
                              >
                                <img
                                  src="/assets/img/slightly_frowning_face(1).png"
                                  alt=""
                                  style={{}}
                                />
                              </ToggleButton>
                              <ToggleButton
                                className="w-auto p-2"
                                style={{
                                  borderRadius: "10px",
                                  border: "2px solid #E1E5EE",
                                  background:
                                    callQuality === "1"
                                      ? "#3DACF0"
                                      : "rgba(225, 229, 238, 0.31)",
                                }}
                                name="radio"
                                type="radio"
                                onClick={() => setCallQuality("1")}
                              >
                                <img src="/assets/img/blush(1).png" alt="" />
                              </ToggleButton>
                              <ToggleButton
                                className="w-auto p-2"
                                style={{
                                  borderRadius: "10px",
                                  border: "2px solid #E1E5EE",
                                  background:
                                    callQuality === "2"
                                      ? "#3DACF0"
                                      : "rgba(225, 229, 238, 0.31)",
                                }}
                                name="radio"
                                type="radio"
                                onClick={() => setCallQuality("2")}
                              >
                                <img
                                  src="/assets/img/heart_eyes(1).png"
                                  alt=""
                                />
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <div className="my-3">
                            <h6 className="font-helvetica fs-5 font-w700">
                              How was your interaction with expert?
                            </h6>
                            <ButtonGroup className="d-flex align-items-center gap-2">
                              <ToggleButton
                                className="w-auto p-2"
                                style={{
                                  borderRadius: "10px",
                                  border: "2px solid #E1E5EE",
                                  background:
                                    expertInteraction === "0"
                                      ? "#3DACF0"
                                      : "rgba(225, 229, 238, 0.31)",
                                }}
                                name="radio"
                                type="radio"
                                onClick={() => setExpertInteraction("0")}
                              >
                                <img
                                  src="/assets/img/slightly_frowning_face(1).png"
                                  alt=""
                                />
                              </ToggleButton>
                              <ToggleButton
                                className="w-auto p-2"
                                style={{
                                  borderRadius: "10px",
                                  border: "2px solid #E1E5EE",
                                  background:
                                    expertInteraction === "1"
                                      ? "#3DACF0"
                                      : "rgba(225, 229, 238, 0.31)",
                                }}
                                name="radio"
                                type="radio"
                                onClick={() => setExpertInteraction("1")}
                              >
                                <img src="/assets/img/blush(1).png" alt="" />
                              </ToggleButton>
                              <ToggleButton
                                className="w-auto p-2"
                                style={{
                                  borderRadius: "10px",
                                  border: "2px solid #E1E5EE",
                                  background:
                                    expertInteraction === "2"
                                      ? "#3DACF0"
                                      : "rgba(225, 229, 238, 0.31)",
                                }}
                                name="radio"
                                type="radio"
                                onClick={() => setExpertInteraction("2")}
                              >
                                <img
                                  src="/assets/img/heart_eyes(1).png"
                                  alt=""
                                />
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <div className="my-3">
                            <h6 className="font-helvetica fs-5 font-w700">
                              Please write a review for expert
                            </h6>
                            <TextField
                              fullWidth
                              multiline
                              rows={3}
                              style={{ height: "100px" }}
                              className="feedbackInput mb-2"
                              onChange={(e) => setReviewText(e.target.value)}
                            />
                          </div>
                          <div className="my-3">
                            <h6 className="font-helvetica fs-5 font-w700">
                              Would you like to have your name displayed with
                              your review?
                            </h6>
                            <ButtonGroup className="d-flex align-items-center gap-3">
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: displayNameBool
                                    ? "2px solid #134700"
                                    : "none",
                                  background: "#EEFFE8",
                                  color: "#134700",
                                }}
                                onClick={() => setDisplayNameBool(true)}
                              >
                                <p className="font-helvetica fs-6">Yes</p>
                              </ToggleButton>
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: !displayNameBool
                                    ? "2px solid #B54100"
                                    : "none",
                                  background: "#FFF8E5",
                                  color: "#B54100",
                                }}
                                onClick={() => setDisplayNameBool(false)}
                              >
                                <p className="font-helvetica fs-6">No</p>
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <div className="my-3">
                            <h6 className="font-helvetica fs-5 font-w700">
                              Will you book another session with this expert?
                            </h6>
                            <ButtonGroup className="d-flex align-items-center gap-3">
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: bookAnotherSession
                                    ? "2px solid #134700"
                                    : "none",
                                  background: "#EEFFE8",
                                  color: "#134700",
                                }}
                                onClick={() => setBookAnotherSession(true)}
                              >
                                <p className="font-helvetica fs-6">Yes</p>
                              </ToggleButton>
                              <ToggleButton
                                // onClick={}
                                type="radio"
                                name="radio"
                                className="rounded-5"
                                style={{
                                  border: !bookAnotherSession
                                    ? "2px solid #B54100"
                                    : "none",
                                  background: "#FFF8E5",
                                  color: "#B54100",
                                }}
                                onClick={() => setBookAnotherSession(false)}
                              >
                                <p className="font-helvetica fs-6">No</p>
                              </ToggleButton>
                            </ButtonGroup>
                          </div>
                          <Row className="my-4 justify-content-around">
                            <Button
                              disabled={feedbackStatus === true}
                              onClick={onClickRatingSubmit}
                              className="border-0 rounded-5 col-5 px-3 py-2"
                              style={{
                                background: "#FD6F1F",
                                boxShadow:
                                  "0px 1.06px 2.13px 0px rgba(16, 24, 40, 0.05)",
                              }}
                            >
                              Submit
                            </Button>
                            <Button
                              onClick={onClickSkip}
                              className="rounded-5 bg-white col-5 px-3 py-2"
                              style={{
                                border: "1px solid #FD6F1F",
                                boxShadow:
                                  "0px 1.06px 2.13px 0px rgba(16, 24, 40, 0.05)",
                                color: "#FD6F1F",
                              }}
                            >
                              Skip
                            </Button>
                          </Row>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
              {/* {
                                data?.Status == MySubscription_Status.BOOKED && data?.IsValid24HourReschdule &&
                                <div className='d-flex justify-content-between align-items-center px-4 py-4 bg-white rounded-2 mt-3 mt-md-0 '>
                                    <Button
                                        onClick={() => navigate(`/book-an-appointment/${data?.ExpertId}?AppointmentId=${data?.ID}`)}
                                        className='rounded-3 border-0 py-2'
                                        style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", color: '#fff', }}>
                                        Reschedule
                                    </Button>
                                    <Button
                                        onClick={() => cancellation()}
                                        className='rounded-3 border-0 py-2'
                                        style={{ background: "linear-gradient(102.41deg, rgba(96, 180, 231, 0.8) -0.17%, rgba(100, 97, 224, 0.8) 70.6%)" }}>
                                        Cancel
                                    </Button>
                                </div>
                            } */}
              <div
                className="px-2 bg-white rounded-1 mb-5"
                style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)" }}
              >
                <p className="font-600 fs-4 text-black pt-3 pb-4">
                  Prescriptions
                </p>
                <div>
                  {expertDocumentList?.map((item, index) => (
                    <>
                      <PrescriptionsCard data={item} />
                      <hr />
                    </>
                  ))}
                </div>
                <p className="font-600 fs-4 text-black pt-3 pb-4">Document</p>
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Title"
                  defaultValue={title}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <input type="file" accept="image/*" onChange={uploadNewFile} />
                <Button
                  className="w-100 my-2 mb-4 border-0 py-2"
                  style={{ backgroundColor: "#6384E3" }}
                  onClick={onSubmitNewDoc}
                >
                  {loading ? "loading..." : "Upload New Document"}
                </Button>
                <div>
                  {userDocumentList?.map((item, index) => (
                    <>
                      <PrescriptionsCard data={item} />
                      <hr />
                    </>
                  ))}
                </div>
                <p className="font-600 fs-4 text-black pt-3 pb-4">
                  Session History
                </p>
                {sessionData?.map((item, index) => (
                  <>
                    <Badge
                      bg={
                        item.Status == "Canceled"
                          ? "danger"
                          : item.Status == "Open"
                          ? "success"
                          : "warning"
                      }
                    >
                      {item.Status}
                    </Badge>
                    <p>
                      Session Date:{" "}
                      <span>
                        {item.AppDate} {item.AppTime}
                      </span>
                    </p>
                    <p>
                      Mode: <span>{item.CallingMode}</span>
                    </p>
                    <hr />
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppointmentDetail;
