import React from 'react'
import { Button } from 'react-bootstrap'

const PrescriptionsCard = (props) => {
    const{data}=props;
    return (
        <a href={data?.DocsFile} target="_blank" className="text-decoration-none">
        <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex gap-3'>
                <img src='/assets/img/prescriptions-img.png' alt='' />
                <div>
                    <p className='font-w500 fs-5 text-black'>{data?.Title}</p>
                    <p className='font-w500 fs-5'>{data?.CreatedDateTime}</p>
                </div>
            </div>
            <div className='d-flex gap-2'>
                {/* <a href={data?.DocsFile} target="_blank"><Button variant="secondary" className='d-none d-md-block'>View</Button></a> */}
              <Button variant="secondary" className='d-none d-md-block'>Download</Button>
            </div>
        </div>
        </a>
    )
}

export default PrescriptionsCard