import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import Grid from "@mui/material/Grid";
import WorksheetCard from "./cards/WorksheetCard";

import TextField from "@mui/material/TextField";
import SaveAltOutlinedIcon from "@mui/icons-material/SaveAltOutlined";
import PreviewOutlinedIcon from "@mui/icons-material/PreviewOutlined";
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";

import OndemandVideoOutlinedIcon from "@mui/icons-material/OndemandVideoOutlined";
import MicNoneOutlinedIcon from "@mui/icons-material/MicNoneOutlined";

import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import ComingSoon from "./component/ComingSoon";
import NewWorksheet from "./component/worksheets/NewWorksheet";
import ViewWorksheet from "./component/worksheets/ViewWorksheet";
import ViewClientWorksheet from "./component/worksheets/ViewClientWorksheet";

import jwtDecode from "jwt-decode";
import { useAuth } from "../../auth";

//Helpers
import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";

function ExpertWorksheetsDB() {
  const [worksheetView, setWorksheetView] = useState({
    type: "Default",
    name: "",
    id: null,
    image: "",
    assignedOn: "",
    completedOn: "",
    status: "",
    country: "",
  });

  const [selectedTab, setSelectedTab] = useState("All");
  const [allWorksheetList, setAllWorksheetList] = useState([]);
  const [savedWorksheetList, setSavedWorksheetList] = useState([]);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const { currentUser, userType, logout } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();

  // Check if user is logged in
  useEffect(() => {
    const isLoggedIn = isUserLoggedIn();

    if (!isLoggedIn) {
      // Redirect to "/login"
      //navigate('/login');
      window.location.href = "https://www.unitedwecare.com/";
    }
  }, [location, navigate]);

  useEffect(() => {
    const payload = {
      jsonrpc: "2.0",
      params: {
        group: true,
      },
    };
    const getWorksheetData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/uwc_worksheet_assign/list/${currentUser?.UserId}`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        setSavedWorksheetList(
          jwtDecode(postResponse?.data)?.assigned_worksheets
        );
        setAllWorksheetList(jwtDecode(postResponse?.data)?.all_worksheets);
        //setAllWorksheetList(jwtDecode(postResponse?.data)?.assigned_worksheets);
        console.log("postResponse", jwtDecode(postResponse?.data));
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getWorksheetData();
  }, []);

  const handleAddWorksheet = () => {
    setWorksheetView((prevState) => ({
      ...prevState,
      type: "New",
    }));
  };

  const handleViewActivity = (name, id) => {
    //console.log("name", name, "id", id)
    setWorksheetView((prevState) => ({ ...prevState, type: "View", name, id }));
  };

  const handleViewClient = (
    id,
    name,
    status,
    img,
    country,
    assignedOn,
    completedOn
  ) => {
    setWorksheetView((prevState) => ({
      ...prevState,
      type: "ViewClient",
      id,
      name,
      status,
      img,
      country,
      assignedOn,
      completedOn,
    }));
  };

  const handleTabSelect = (text) => {
    setSelectedTab(text);
  };

  // console.log("savedWorksheetList", savedWorksheetList)

  return (
    <div className="mt-3 ExpertWorksheet--Dashboard">
      <div className="ExpertWorksheet--Header">
        <h2>Worksheets</h2>
      </div>

      {worksheetView.type == "Default" && (
        <div className="d-flex ExpertAppointmentDashboard--Options align-items-center mt-3">
          <div className="d-flex gap-3 Options">
            <div
              className={`Option--Item ${
                selectedTab == "All" ? "SelectedItem" : ""
              }`}
              onClick={() => handleTabSelect("All")}
            >
              All Worksheets
            </div>
            <div
              className={`Option--Item ${
                selectedTab == "Saved" ? "SelectedItem" : ""
              }`}
              onClick={() => handleTabSelect("Saved")}
            >
              Assigned Worksheets
            </div>
          </div>

          <Button
            variant="contained"
            className="PrimaryCTA"
            disableElevation
            onClick={handleAddWorksheet}
          >
            <AddCircleIcon />
            Worksheet Templates
          </Button>
        </div>
      )}

      <div className="ExpertWorksheetDashboard--Content mt-3 padding-3">
        {worksheetView.type == "Default" && (
          <Grid container spacing={2}>
            {selectedTab == "All" &&
              (allWorksheetList.length === 0 ? (
                <p style={{ margin: "25px auto" }}>No Worksheets Found</p>
              ) : (
                allWorksheetList?.map((item) => (
                  <Grid item xs={6} md={4} lg={3}>
                    <WorksheetCard
                      key={item.worksheet_template_id}
                      assigned={item.assigned}
                      id={item.worksheet_template_id}
                      name={item.worksheet_name}
                      updatedOn={item.updated_on}
                      avatars={item.images}
                      viewActivity={handleViewActivity}
                    />
                  </Grid>
                ))
              ))}

            {selectedTab == "Saved" &&
              (savedWorksheetList.length === 0 ? (
                <p style={{ margin: "25px auto" }}>No Worksheets Found</p>
              ) : (
                savedWorksheetList?.map((item) => (
                  <Grid item xs={6} md={4} lg={3}>
                    <WorksheetCard
                      key={item.worksheet_template_id}
                      assigned={item.assigned}
                      id={item.worksheet_template_id}
                      name={item.worksheet_name}
                      updatedOn={item.updated_on}
                      avatars={item.images}
                      viewActivity={handleViewActivity}
                    />
                  </Grid>
                ))
              ))}
          </Grid>
        )}

        {worksheetView.type == "New" && <NewWorksheet />}

        {worksheetView.type == "View" && (
          <ViewWorksheet
            worksheetID={worksheetView.id}
            viewClient={handleViewClient}
          />
        )}

        {worksheetView.type == "ViewClient" && (
          <ViewClientWorksheet
            id={worksheetView.id}
            name={worksheetView.name}
            img={worksheetView.img}
            assignedOn={worksheetView.assignedOn}
            completedOn={worksheetView.completedOn}
            status={worksheetView.status}
            country={worksheetView.country}
          />
        )}
      </div>
      {/* <ComingSoon /> */}
    </div>
  );
}

export default ExpertWorksheetsDB;
