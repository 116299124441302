import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import { useState } from "react";
import AccessTimeIcon from "../../../assets/icons/clock-icon.svg";
import SessionComplete from "../modals/SessionCompleted";
import NotCompleted from "../modals/NotCompleted";
import Snacknbar from "./Snackbar";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import "./cards.css";

const AllSessions = ({
  session,
  unMarkedSession,
  openModal,
  handleOpenModal,
  handleCloseModal,
  openModal1,
  handleOpenModal1,
  handleCloseModal1,
}) => {
  const [appointment_id, setAppointmentId] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("success");
  const handleCloseSnackbar = () => {
    setOpen(false);
  };

  const handleOpenSnackbar = () => {
    setOpen(true);
  };

  return (
    <div>
      <Snacknbar
        message={message}
        open={open}
        status={status}
        handleClose={handleCloseSnackbar}
      ></Snacknbar>
      <p
        style={{
          color: "black",
          fontSize: "16px",
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: 600,
          marginLeft: "50px",
          paddingTop: "30px",
        }}
      >
        {" "}
        {session === "in-complete" ? "Marked Sessions" : "Unmarked Sessions"}
      </p>

      <div className="d-flex flex-wrap   mt-3 mx-5 custom-font custom-card1 ">
        {unMarkedSession &&
          unMarkedSession.length > 0 &&
          unMarkedSession.map((data, index) => (
            <div key={index} className="col-md-6 col-lg-3   ">
              <div className="card mb-3 custom-font custom-card  ">
                <div className="card-body ">
                  <div className="d-flex justify-content justify-spaceBetween align-items-center mb-3">
                    <img
                      src={data.client_pofile_picture}
                      alt="User"
                      className="img-fluid rounded-circle"
                      style={{ width: "40px", height: "40px" }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h5
                        className="card-title"
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 600,
                          fontSize: "14px",
                          marginLeft: "10px",
                        }}
                      >
                        {data.client_name}
                      </h5>

                      <p
                        style={{
                          fontFamily: "Inter",
                          fontStyle: "normal",
                          fontWeight: 300,
                          fontSize: "10px",
                          color: "grey",
                          marginLeft: "10px",
                          marginTop: "-8px",
                        }}
                      >
                        Follow-up
                      </p>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center mt-2 px-1 ">
                    <div
                      style={{
                        FontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <CalendarMonthIcon
                        style={{
                          color: "rgb(27, 139, 207)",
                          height: "22px",
                          whiteSpace: "nowrap",
                        }}
                      />
                      {data.start_date}
                    </div>
                    <div
                      style={{
                        marginRight: "5px",
                        FontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      }}
                    >
                    
                      <img
                        src={AccessTimeIcon}
                        style={{
                          height: "20px",
                          whiteSpace: "nowrap",
                          paddingRight: "5px",
                        }}
                      />
                      {data.start_time}
                    </div>
                  </div>
                  <div className="d-flex flex-column px-2 mt-4">
                    <button
                      style={{
                        border: "2px solid",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        borderRadius: "10px",
                        height: "34px",
                      }}
                      onClick={() => {
                        handleOpenModal();
                        setAppointmentId(data.appointment_id);
                      }}
                      className={`btn btn-outline-success btn-sm mb-2 ${
                        data.session_status ? "" : "d-none"
                      }`}
                    >
                      Completed
                    </button>

                    <button
                      style={{
                        borderColor: "red",
                        border: "2px solid",
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: 600,
                        borderRadius: "10px",
                        height: "34px",
                      }}
                      onClick={() => {
                        handleOpenModal1();
                        setAppointmentId(data.appointment_id);
                      }}
                      className="btn btn-outline-danger btn-sm"
                    >
                      Not Completed
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        {unMarkedSession && unMarkedSession.length === 0 && (
          <h4>No Data Found</h4>
        )}
        <NotCompleted
          status={"write"}
          appointment_id={appointment_id}
          open={openModal1}
          handleClose={handleCloseModal1}
          setMessage={setMessage}
          handleOpenSnackbar={handleOpenSnackbar}
          setStatus={setStatus}
        />
        <SessionComplete
          status={"write"}
          appointment_id={appointment_id}
          open={openModal}
          handleClose={handleCloseModal}
          setMessage={setMessage}
          handleOpenSnackbar={handleOpenSnackbar}
          setStatus={setStatus}
        />
      </div>
    </div>
  );
};

export default AllSessions;
