import React from 'react'
import '.././Plans.css'

const MakeUsArea = () => {
    return (
        <div>
            {/* <!------ Make-Us-area ------> */}
            <div className="make-us-area mobile-display desktop-display-none">
                <div className="container">
                    <div className="make-us-inner">
                        <h2 className="heading">What makes us your <span className="diff-clr">best choice</span></h2>
                        <img src="assets/img/make-us.png" alt='' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MakeUsArea