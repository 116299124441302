import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

//Icons
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AvTimerOutlinedIcon from "@mui/icons-material/AvTimerOutlined";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

//Menu
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

import { Link, useNavigate } from "react-router-dom";

import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import isBetween from "dayjs/plugin/isBetween";
import { useAuth } from "../../../auth";

dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

//Component Start
const AppointmentCard = ({
  appointmentDate,
  appointmentTime,
  appointmentID,
  name,
  img,
  clientType,
  joinURL,
  clientID,
  status,
  onReschedule,
  onAddNote,
  onPrescription,
  onCancel,
}) => {
  const [isLocked, setIsLocked] = useState(false);
  const [isMatchingConditions, setIsMatchingConditions] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showJoin, setShowJoin] = useState(false);

  const { currentUser } = useAuth();

  useEffect(() => {
    const currentDate = dayjs();
    const appointmentDateTime = dayjs(
      `${appointmentDate} ${appointmentTime}`,
      "DD MMM YYYY hh:mm A"
    );

    //console.log("currentDate", currentDate.format("DD MMM YYYY hh:mm A"));
    const isToday = appointmentDateTime.isSame(currentDate, "day");

    const isWithinRange = currentDate.isBetween(
      appointmentDateTime.subtract(10, "minutes"),
      appointmentDateTime.add(20, "minutes"),
      null,
      "[)"
    );

    // const isWithinRange = appointmentDateTime.isBetween(
    //   currentDate.subtract(10, "minutes"),
    //   currentDate.add(20, "minutes"),
    //   null,
    //   "[)"
    // );

    // console.log(
    //   "10 mins before",
    //   appointmentDateTime.subtract(10, "minutes").format("DD MMM YYYY hh:mm A")
    // );
    // console.log(
    //   "30 mins after",
    //   appointmentDateTime.add(30, "minutes").format("DD MMM YYYY hh:mm A")
    // );
    // console.log("IsBetween", isWithinRange);

    const diffInHours = appointmentDateTime.diff(currentDate, "hours");
    const isWithin48Hours = diffInHours <= 48;

    setIsMatchingConditions(isToday && isWithinRange);
    setIsLocked(isWithin48Hours);
  }, []);

  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    navigate(`/expert/clients?client=${name}&id=${clientID}`);
  };

  const onCourseUndertaken = () => {
    navigate(`/expert/clients?client=${name}&id=${clientID}`);
  };

  const onChat = (clientId) => {
    navigate(`/expert/chat?clientID=${clientId}`);
  };

  const formattedDate = appointmentDate;

  const nextCard = {
    borderRadius: "10px",
    borderColor: "#50B3EF",
    outlineStyle: "solid !important",
    outlineWidth: "5px !important",
    outlineColor: "#1B8BCF1A !important",
  };

  const defaultCard = {
    position: "relative",
    borderRadius: "10px",
  };

  const handleJoinCall = async () => {
    const headers = {
      "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      "Content-Type": "application/json",
    };

    const payload = {
      user_id: currentUser?.UserId,
      appointment_id: appointmentID,
      join_status: "Joined",
      user_type: "Expert",
      current_time: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    //console.log("payload", payload);
    try {
      const response = await fetch(
        `https://odoo.unitedwecare.ca/new-uwc/appointment/update-join-status`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();
      console.log("postResponse", postResponse);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Card
      variant="outlined"
      className={`AppointmentCard ${
        status == "Cancelled" && "Appointment--Cancelled"
      }`}
      sx={isMatchingConditions ? nextCard : defaultCard}
    >
      <CardContent sx={{ paddingBottom: "10px" }}>
        <div className="d-flex gap-2 Card--Top">
          <img src={img} style={{ borderRadius: "50%" }} />

          <div>
            <h6>{name}</h6>
            <p>
              {clientType}{" "}
              {status == "Cancelled" && (
                <>
                  {" "}
                  |{" "}
                  <span style={{ fontWeight: "500", color: "red" }}>
                    Cancelled
                  </span>
                </>
              )}
            </p>
          </div>
          {status !== "Cancelled" &&
            (isLocked || status == "Completed" ? (
              <LockIcon />
            ) : (
              <LockOpenIcon />
            ))}
        </div>

        <div className="Card--Timings d-flex">
          <div>
            <CalendarMonthOutlinedIcon />
            <p>{formattedDate}</p>
          </div>

          <div>
            <AvTimerOutlinedIcon />
            <p>{appointmentTime}</p>
          </div>
        </div>
      </CardContent>

      <CardActions className="Card--Actions">
        {(status == "Open" || status == "Booked") && isMatchingConditions ? (
          <Button
            disableElevation
            variant="contained"
            size="large"
            sx={{
              borderRadius: "30px",
              background: "#1B8BCF !important",
              color: "#FFF ! important",
              fontSize: "14px",
              textTransform: "capitalize",
              width: "100%",
              aspectRatio: "auto !important",
              flex: "1 !important",
            }}
            target="_blank"
            href={joinURL}
            onClick={() => handleJoinCall()}
          >
            Join Now
          </Button>
        ) : (
          <>
            {status !== "Cancelled" && (
              <>
                <Button onClick={handleView}>
                  <VisibilityIcon />
                  <p>View</p>
                </Button>

                <Button onClick={() => onChat(clientID)}>
                  <ChatBubbleOutlineOutlinedIcon />
                  <div
                    className="text-decoration-none"
                    style={{ color: "#414141", textTransform: "capitalize" }}
                  >
                    Chat
                  </div>
                </Button>
                <Button
                  id="demo-positioned-button"
                  aria-controls={open ? "demo-positioned-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <ArrowDropDownOutlinedIcon />
                </Button>
              </>
            )}

            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="DarkDropdown"
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem sx={{ fontSize: "14px" }} onClick={onAddNote}>
                Add Note
              </MenuItem>
              <Divider />

              {/* <MenuItem sx={{ fontSize: "14px" }}>Book Appointment</MenuItem>
                <Divider /> */}

              <MenuItem sx={{ fontSize: "14px" }} onClick={onReschedule}>
                Reschedule
              </MenuItem>
              <Divider />
              <MenuItem sx={{ fontSize: "14px" }} onClick={onPrescription}>
                Add Presciptions
              </MenuItem>
              <Divider />
              <MenuItem sx={{ fontSize: "14px" }} onClick={onCourseUndertaken}>
                Courses Undertaken
              </MenuItem>
              <Divider />
              {!isLocked && status == "Open" && (
                <MenuItem
                  sx={{ fontSize: "14px", color: "#f57e8c !important" }}
                  onClick={onCancel}
                >
                  Cancel Appointment
                </MenuItem>
              )}
            </Menu>
          </>
        )}
      </CardActions>
    </Card>
  );
};

export default AppointmentCard;
