import { Routes, Route, BrowserRouter } from "react-router-dom";
import Layout from "../components/Layout";
import Home from "../pages/Home";
import LandingPage from "../pages/LandingPage";
import ExpertDetails from "../pages/ExpertDetails";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import BookAnAppointment from "../pages/BookAnAppointment";
import ExpertDashboard from "../pages/expertDashboard";
import About from "../pages/About";
import PaymentStatus from "../pages/PaymentStatus";
import TermAndCondition from "../components/TermAndCondition";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Disclaimer from "../components/Disclaimer";
import Listing from "../components/course/Listing";
import CourseDetailID1 from "../components/course/courseDetailsComponents/CourseDetailID1";
import CourseListing from "../components/course/CourseListing";
import UserDashbaord from "../pages/dashboard";
import CoursePaymentStatus from "../pages/CoursePaymentStatus";
import ExpertBookingPreview from "../pages/ExpertBookingPreview";
import CourseOrderPreviewPage from "../pages/CourseOrderPreviewPage";
import ScrollToTop from "../ScrollToTop";
import RegisterAsProfessional from "../components/professional/RegisterAsProfessional";
import UsOnBoardingDashboard from "../pages/USonBoardingDashboard/UsOnBoardingDashboard";
import DashboardRouter from "../pages/USonBoardingDashboard";
import SubscriptionPlans from "../components/subscriptions/SubscriptionPlans";
import SubscriptionUpgrade from "../components/subscriptions/SubscriptionUpgrade";
import CancelSubscriptionNote from "../components/subscriptions/CancelSubscriptionNote";
import CancelSubscriptions from "../components/subscriptions/CancelSubscriptions";
import SubscriptionOrderSummary from "../components/subscriptions/SubscriptionOrderSummary";
import MySubscription from "../components/subscriptions/MySubscription";
import Stella from "../pages/Stella";
import OnboardingStella from "../pages/OnboardingStella";
import OverlayStella from "../components/FloatingStella";
import PlansAndPricing from "../pages/Plans&Pricing";
import ThankYou from "../components/subscriptions/ThankYou";
import TransactionFailed from "../components/subscriptions/TransactionFailed";
import PlanDetailsPage from "../pages/PlanDetailsPage";
import NewPlansPage1 from "../components/subscriptions/NewPlansPage-1";
import NewPlans2 from "../components/subscriptions/NewPlans2";
import Main from "../components/dashboard/Components/Dashboard/Main";

const AppRoutes = () => {
  const isExpertDashboard = () => {
    if (window.location.href.includes("/expert/")) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      {/* {!isExpertDashboard() && <OverlayStella />} */}
      <Routes>
        <Route element={<Layout />}>
          {/* <Route path="/" element={<Navigate to="/register-as-professional" replace />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/landing-page" element={<LandingPage />} />
          <Route path="/profession/:profession/:expertName/:id" element={<ExpertDetails />} />
          <Route
            path="/book-an-appointment/:id"
            element={<BookAnAppointment />}
          />
          <Route
            path="/payment-order-preview/:id"
            element={<ExpertBookingPreview />}
          />

          <Route path="/payment-status/:id" element={<PaymentStatus />} />

          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          {/* <Route path="/course/details/:id" element={<CourseDetail />} /> */}
          <Route path="/course/:name/:id" element={<CourseDetailID1 />} />

          <Route
            path="/course/payment-status/:id"
            element={<CoursePaymentStatus />}
          />

          <Route
            path="/course/course-order-preview/:id"
            element={<CourseOrderPreviewPage />}
          />
          <Route path="/course" element={<Listing />} />
          <Route path="/term-and-condition" element={<TermAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/register-as-professional"
            element={<RegisterAsProfessional />}
          />
          <Route path="/dashboard/*" element={<UserDashbaord />} />
          <Route path="/user-dashboard" element={<Main/>} />
          {/* UserDashbaord */}
          <Route path="/expertdashboard/*" element={<ExpertDashboard />} />
          <Route path="/login" element={<SignIn />} />
          <Route path="/create-account" element={<SignUp />} />
          <Route path="/subscription-plans" element={<SubscriptionPlans />} />
          <Route path="/subscription-upgrade" element={<SubscriptionUpgrade />} />
          <Route path="/cancel-subscription" element={<CancelSubscriptions />} />
          <Route path="/cancel-subscription-note" element={<CancelSubscriptionNote />} />
          <Route path="/subscription-order-summary" element={<SubscriptionOrderSummary />} />
          <Route path="/my-subscription" element={<MySubscription />} />
          <Route path="/stella" element={<Stella />} />
          <Route path="/onboarding-stella" element={<OnboardingStella />} />
          <Route path="/plans" element={<NewPlansPage1 />} />
          {/* <Route path='/listing' element={<Listing />} /> */}
          <Route path='/payment-success' element={<ThankYou />} />
          <Route path='/payment-failed' element={<TransactionFailed />} />
          {/* <Route path='/plans-and-pricing' element={<NewPlansPage1 />} /> */}
          <Route path="/plans-and-pricing-2" element={<NewPlans2 />} />
          <Route path='/platinum-care-plan' element={<PlanDetailsPage type="Platinum" />} />
          <Route path='/premium-care-plan' element={<PlanDetailsPage type="Premium" />} />
          <Route path='/super-care-plan' element={<PlanDetailsPage type="Super" />} />
        </Route>
        <Route path="/expert/*" element={<DashboardRouter />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
