export const Config = () => ({ 
       headers: {
         'Content-Type':'application/json',
         'Authorization':localStorage.getItem("Authorization"),
         'X-Email-Id':(localStorage.getItem("profile")? JSON.parse(localStorage.getItem("profile")).Emailid:"vikas@cyberexpert.com"),
         'X-API-Key':'76d802e426a2cb28f3760c8c8f669983f67ed775',
         'Content-Type': 'application/json', 
         'DeviceType': 'web',
         'DeviceId':'123',
         'Lat':'1',
         'Long':'1'
       },
       data:{}
})