import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Form, Table, ToggleButton } from 'react-bootstrap'
import { usOnBoarding_scheduling_timeslots, usOnBoarding_Save_scheduling_timeslots } from '../core/_request';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RegisterAsProfessionalSchedulingTab = (props) => {
    const [timeslots, setTimeSlots] = useState([]);
    const [sunday_list, setSunday_List] = useState([]);
    const [monday_list, setMonday_List] = useState([]);
    const [tuesday_list, setTuesday_List] = useState([]);
    const [wednesday_list, setWednesday_List] = useState([]);
    const [thursday_list, setThursday_List] = useState([]);
    const [friday_list, setFriday_List] = useState([]);
    const [saturday_list, setSaturday_List] = useState([]);
    const [totalHours, setTotalHours] = useState(0);

    var RegisterAs = localStorage.getItem("RegisterAs")
    var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
    useEffect(() => {
        var body = { "user_id": user_id };
        usOnBoarding_scheduling_timeslots(body).then(resp => {
            setTimeSlots(resp?.data?.slots);
        })
    }, [user_id, RegisterAs]);

    useEffect(() => {
        var sData = JSON.parse(localStorage.getItem("User_Registered_Info"));
        setSunday_List(sData?.scheduling?.sunday);
        setMonday_List(sData?.scheduling?.monday);
        setTuesday_List(sData?.scheduling?.tuesday);
        setWednesday_List(sData?.scheduling?.wednesday);
        setThursday_List(sData?.scheduling?.thursday);
        setFriday_List(sData?.scheduling?.friday);
        setSaturday_List(sData?.scheduling?.saturday);
    }, [user_id])

    const Sunday_clickHandler = (e, item) => {
        if (e.target.checked) {
            setSunday_List(prevState => [
                ...prevState || [],
                item?.id
            ])
        }
        else {
            const newInitialList = sunday_list?.filter((list, index) => list !== item?.id);
            setSunday_List(newInitialList);
        }
        //setTotalHours({...totalHours, timeslots.filter(x=> x.id === item?.id).minutes});
    }

    const Monday_clickHandler = (e, item) => {
        if (e.target.checked) {
            setMonday_List(prevState => [
                ...prevState || [],
                item?.id
            ])
        }
        else {
            const newInitialList = monday_list?.filter((list, index) => list !== item?.id);
            setMonday_List(newInitialList)
        }
    }

    const Tuesday_clickHandler = (e, item) => {
        if (e.target.checked) {
            setTuesday_List(prevState => [
                ...prevState || [],
                item?.id
            ])
        }
        else {
            const newInitialList = tuesday_list?.filter((list, index) => list !== item?.id);
            setTuesday_List(newInitialList)
        }
    }

    const Wednesday_clickHandler = (e, item) => {
        if (e.target.checked) {
            setWednesday_List(prevState => [
                ...prevState || [],
                item?.id
            ])
        }
        else {
            const newInitialList = wednesday_list?.filter((list, index) => list !== item?.id);
            setWednesday_List(newInitialList)
        }
    }

    const Thursday_clickHandler = (e, item) => {
        if (e.target.checked) {
            setThursday_List(prevState => [
                ...prevState || [],
                item?.id
            ])
        }
        else {
            const newInitialList = thursday_list?.filter((list, index) => list !== item?.id);
            setThursday_List(newInitialList)
        }
    }

    const Friday_clickHandler = (e, item) => {
        if (e.target.checked) {
            setFriday_List(prevState => [
                ...prevState || [],
                item?.id
            ])
        }
        else {
            const newInitialList = friday_list?.filter((list, index) => list !== item?.id);
            setFriday_List(newInitialList)
        }
    }

    const Saturday_clickHandler = (e, item) => {
        if (e.target.checked) {
            setSaturday_List(prevState => [
                ...prevState || [],
                item?.id
            ])
        }
        else {
            const newInitialList = saturday_list?.filter((list, index) => list !== item?.id);
            setSaturday_List(newInitialList)
        }
    }

    // useEffect(() => {
    //     var total_slots = sunday_list?.length + monday_list?.length + tuesday_list?.length + wednesday_list?.length + thursday_list?.length + friday_list?.length + saturday_list?.length;
    //     var sTimecont = ((total_slots * (timeslots && timeslots[0]?.minutes)) / 60);
    //     setTotalHours(sTimecont);

    // }, [sunday_list, monday_list, tuesday_list, wednesday_list, thursday_list, friday_list, saturday_list])

    const handelNextOnclick = () => {
        var user_id = (JSON.parse(localStorage.getItem("UsOnBoarding"))?.id ?? JSON.parse(localStorage.getItem("User_Registered_Info"))?.id);
        var total_slots = sunday_list?.length + monday_list?.length + tuesday_list?.length + wednesday_list?.length + thursday_list?.length + friday_list?.length + saturday_list?.length;
 

        if (total_slots < 10) {
            toast("choose a minimum of 10 slots weekly");
            return false;
        }

        var body = {
            "user_id": user_id,
            "sunday": sunday_list,
            "monday": monday_list,
            "tuesday": tuesday_list,
            "wednesday": wednesday_list,
            "thursday": thursday_list,
            "friday": friday_list,
            "saturday": saturday_list,
        }
        usOnBoarding_Save_scheduling_timeslots(body).then(resp => {
            if (resp?.code === 200) {
                toast('Schedule Details Saved Successfully',{
                    toastId: "Schedule Details Saved Successfully"
                });
                localStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
                props.onNext();
            }
            else {
                toast(resp?.data?.error ?? resp?.message)
            }
        })
    }

    const handelSkip = () => {
        props.onNext();
    }
    return (
        <div>
            <div className='d-flex align-items-center gap-3 py-3 rounded-top px-3' style={{ background: "#C4E5F9" }}>
                <img src='/assets/img/Vector(26).png' alt='' />
                <p className='font-inter font-w500 text-black' style={{ color: "#21231E", fontSize: "24px" }}>Scheduling</p>
            </div>
            <div className='m-3'>
                <h6 className='font-inter font-w500 fs-4' style={{ color: "#21231E" }}>Tentative Schedule</h6>
                <p className='font-inter ' style={{ color: "#21231E", fontSize: "14px" }}>You can change it any time (choose a minimum of 10 slots weekly)</p>
            </div>
            <div className='col-12 m-3 p-3'>
                <p className='font-inter font-w600 lh-lg' style={{ color: "#21231E" }}>Select days and time to add them</p>
                <div style={{ height: "500px", overflowY: "scroll" }}>
                    <Table responsive="sm">
                        <thead className='sticky-top bg-white' style={{ zIndex: 100 }}>
                            <tr className=''>
                                <th className='w-25'></th>
                                <th>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>S</div>
                                    </div>
                                </th>
                                <th className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>M</div>
                                    </div>
                                </th>
                                <th className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>T</div>
                                    </div>
                                </th>
                                <th className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>W</div>
                                    </div>
                                </th>
                                <th className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>T</div>
                                    </div>
                                </th>
                                <th className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>F</div>
                                    </div>
                                </th>
                                <th className=''>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "60px", borderRadius: "50%", border: "1px solid #21231E" }}>S</div>
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody className='col-12' style={{ height: '300px', overflowY: 'scroll', display: '' }}>
                            {
                                timeslots?.map((item, index) =>
                                    <tr>
                                        <td className='px-2'>
                                            <div className='d-flex align-items-center justify-content-center rounded-3' style={{ height: "60px", width: "auto", border: "1px solid #21231E" }}>
                                                {item?.name}
                                            </div>
                                        </td>
                                        <td className='px-2'>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Form.Check type="checkbox" value={item?.id} checked={sunday_list?.filter(o => o === item.id).length > 0} onClick={(e) => Sunday_clickHandler(e, item)} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Form.Check type="checkbox" value={item?.id} checked={monday_list?.filter(o => o === item.id).length > 0} onClick={(e) => Monday_clickHandler(e, item)} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Form.Check type="checkbox" value={item?.id} checked={tuesday_list?.filter(o => o === item.id).length > 0} onClick={(e) => Tuesday_clickHandler(e, item)} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Form.Check type="checkbox" value={item?.id} checked={wednesday_list?.filter(o => o === item.id).length > 0} onClick={(e) => Wednesday_clickHandler(e, item)} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Form.Check type="checkbox" value={item?.id} checked={thursday_list?.filter(o => o === item.id).length > 0} onClick={(e) => Thursday_clickHandler(e, item)} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Form.Check type="checkbox" value={item?.id} checked={friday_list?.filter(o => o === item.id).length > 0} onClick={(e) => Friday_clickHandler(e, item)} />
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center' style={{ height: "60px", width: "auto", background: "#1C1B1F1F" }}>
                                                <Form.Check type="checkbox" value={item?.id} checked={saturday_list?.filter(o => o === item.id).length > 0} onClick={(e) => Saturday_clickHandler(e, item)} />
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end my-5'>
                <ButtonGroup className='d-flex flex-row align-items-center justify-content-end gap-4 me-3 my-3'>
                    <Button onClick={handelSkip} className='rounded-3 text-black bg-white' style={{ border: "1px solid #21231E" }}>
                        Skip
                    </Button>
                    <Button onClick={handelNextOnclick} className='rounded-3 border-0 text-black' style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}>
                        Next
                    </Button>
                </ButtonGroup>
            </div>
            <ToastContainer />
        </div>
    )
}

export default RegisterAsProfessionalSchedulingTab