
import React from 'react'  
import Search from '../components/Search'

function home() {
  return (
     
        <Search />
      
  )
}

export default home 