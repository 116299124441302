import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InboxRowCard from "../cards/InboxRowCard";

import isUserLoggedIn from "../helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../../../auth";
import jwtDecode from "jwt-decode";

const Inbox = () => {
  const [inboxList, setInboxList] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [bearerToken, setBearerToken] = useState(
    sessionStorage.getItem("token")
  );

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, userType, logout } = useAuth();

  const getToken = async () => {
    const payload = {
      OTP: "7777",
      UserName: currentUser?.Emailid,
    };

    const otpHeaders = {
      "Content-Type": "application/json",
      Authorization: "Bearer 1231243123hjvjVJHVvjv",
      DeviceId: "us_web",
      DeviceType: "web",
      Lat: "us_lat",
      Long: "us_long",
    };

    try {
      const response = await fetch(
        `https://app.uwc.world/api/Auth/LoginByOTP`,
        {
          method: "POST",
          headers: otpHeaders,
          body: JSON.stringify(payload),
        }
      );

      const postResponse = await response.json();
      const token = postResponse?.data;
      const value = jwtDecode(token);

      setBearerToken(value.access_token);

      sessionStorage.setItem("token", value.access_token);
      window.location.reload();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Fetch Clients
  useEffect(() => {
    const chatClientsHeaders = {
      "Content-Type": "application/json",
      DeviceId:
        "cv7oBheDEUTNgCARlRYrDp:APA91bEJXHTBQwqsQpl-TXiDLblgv1kKrR8LZlEhR1OjD59ti2zJFGlrHSQeD9S2wpj049iD2Gggb5ukha1DXfyrSUO21PH7t4Jgh9FPdaQ5JfbFdU_XdLli9zNSX4TysuZR30kzN_Ak",
      DeviceType: "ios",
      Lat: "1",
      Long: "1",
      Authorization: `Bearer ${bearerToken}`,
    };

    const postClientsData = async () => {
      try {
        const response = await fetch(
          `https://app.uwc.world/api/transaction/getMyAspirantsForExpert`,
          {
            method: "GET",
            headers: chatClientsHeaders,
          }
        );
        const postResponse = await response.json();
        if (postResponse?.ResponseCode == 401) {
          getToken();
          postClientsData();
        }

        if (postResponse?.ResponseCode == 200) {
          setClientList(jwtDecode(postResponse?.data));
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postClientsData();
  }, []);

  return (
    <div
      className="InboxWidget Widget rounded-4"
      style={{ background: "#FFFFFF" }}
    >
      <div className="d-flex justify-content-between p-3 align-items-center Widget--Header">
        <h6 className="font-inter fs-5">Inbox</h6>
        {clientList?.length > 0 && (
          <Link
            to="/expert/chat"
            className="text-decoration-none d-flex align-items-center gap-2"
          >
            <p className="font-inter" style={{ color: "#1B8BCF" }}>
              View All{" "}
            </p>
            <img src="/assets/img/Mask group(15).png" alt="" />
          </Link>
        )}
      </div>
      <div className="InboxWidget--Content">
        {clientList?.length === 0 ? (
          <p style={{ margin: "0 auto 20px 0", textAlign: "center" }}>No Inbox Data</p>
        ) : (
          clientList
            ?.slice(0, 4)
            .map((item) => (
              <InboxRowCard
                key={item.Id}
                img={item.ProfileImage}
                name={item.Name}
                messageType={item.message_type}
                message={item.message}
                unreadMessages={item.count}
              />
            ))
        )}
      </div>
    </div>
  );
};

export default Inbox;
