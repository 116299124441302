import React, { useEffect, useState } from "react";
import { Button, Form, ProgressBar } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  SendRequestOTPService,
  LoginByOTPService,
  OODOGetCountryListV4Service,
  OODOGetTimezoneV4Service,
  RegistrationByOTPV4Service,
  OODOCreateUserV4Service,
  CheckReferralCode,
} from "./core/_request";
import { useAuth } from "../auth";
import { TextField } from "@mui/material";

const SignupComponent = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { login, currentUser, setCurrentUser, userType } = useAuth();
  const [referralCodeMessage, setReferralCodeMessage] = useState("");
  const [step, setStep] = useState(props?.step ?? 1);
  const [validationMessage, setValidationMessage] = useState([]);
  const [validReferralCode, setValidReferralCode] = useState();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(props?.data);
  const navigate = useNavigate();
  const [countryList, setCountryList] = useState([]);
  const [timezoneList, setTimezoneList] = useState([]);
  const [disclaimer, setDisclaimer] = useState();
  const [iAgree, setIAgree] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const [counter, setCounter] = useState(0);
  const count_time = process.env.REACT_APP_OTP_TIME;
  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const resendOTP = () => {
    setOtpSent(true);
    setCounter(count_time);
    // debugger
    var req = { Email: data.Email };
    SendRequestOTPService(req).then((resp) => {
      setLoading(false);
      //console.log("SendRequestOTPService resp", resp);
      if (resp != null && resp.ResponseCode == 200) {
        setOtpSent(true);
        setCounter(count_time);
        setStep(2);
      } else if (resp != null && resp.ResponseCode == 401) {
        //console.log("ResponseCode 401");
      }
    });
  };

  useEffect(() => {
    // debugger
    let plan_id = searchParams.get("plan_id");
    if (plan_id !== undefined && plan_id !== null) {
      localStorage.setItem("retrunurl", "/subscription-order-summary");
      sessionStorage.setItem("subscriptionID", plan_id);
    } else {
      console.log("No redirection");
    }
  }, []);

  useEffect(() => {
    if (data?.referralCode || data?.skyp) {
      createRegistration();
    }
  }, [data]);

  const onSubmit = () => {
    //console.log("retrunurl", retrunurl)
    if (
      sessionStorage.getItem("Login-Invoked") !== undefined &&
      sessionStorage.getItem("Login-Invoked") !== null &&
      sessionStorage.getItem("Login-Invoked").includes("/stella") &&
      currentUser.UserType == userType.ENDUSER
    ) {
      navigate(sessionStorage.getItem("Login-Invoked"));
      sessionStorage.removeItem("Login-Invoked");
    } else {
      var retrunurl_local = localStorage.getItem("retrunurl");
      const retrunurl = params.get("retrunurl") || retrunurl_local;
      if (retrunurl) {
        //window.location.href=retrunurl;
        localStorage.removeItem("retrunurl");
        navigate(retrunurl);
      } else {
        //navigate("/user-dashboard/my-appointments")
        navigate("/onboarding-stella");
      }
    }
  };

  //const{retrunurl}=useParams();
  const retrunurl = null;
  const params = new URLSearchParams(window ? window.location.search : {});
  useEffect(() => {
    OODOGetCountryListV4Service().then((resp) =>
      setCountryList(resp.data.countries)
    );
  }, []);

  const onCountryChange = (value) => {
    var item = countryList.find((o) => o.id == value);
    if (item) {
      setData({ ...data, countryId: value, country: item.name });
      setLoading(true);
      var body = {
        code: item.code,
      };
      OODOGetTimezoneV4Service(body).then((resp) => {
        //console.log("OODOGetTimezoneV4Service resp ", JSON.stringify(resp));
        setTimezoneList(resp.data.timezones);
        setLoading(false);
      });
      setLoading(false);
    } else {
      setTimezoneList([]);
      setData({ ...data, timezoneId: null, timezoneName: null });
    }
  };

  const onTimezoneChange = (value) => {
    const item = timezoneList.find((o) => o.id == value);
    if (item) {
      setData({ ...data, timezoneId: value, timezoneName: item.name });
    } else {
      setData({ ...data, timezoneId: null, timezoneName: null });
    }
  };

  const onLogin = () => {
    setValidationMessage([]);
    setLoading(true);
    switch (step) {
      case 1: {
        var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
        if (!pattern.test(data.Email)) {
          let newValidationMessage = Object.assign(
            [],
            [
              ...validationMessage.filter((o) => o.key != "Email"),
              { key: "Email", message: "not a valid e-mail address" },
            ]
          );
          setValidationMessage(newValidationMessage);
          setLoading(false);
          break;
        }

        var req = { Email: data.Email };
        SendRequestOTPService(req).then((resp) => {
          setLoading(false);
          //console.log("SendRequestOTPService resp", resp);
          if (resp != null && resp.ResponseCode == 200) {
            setOtpSent(true);
            setCounter(count_time);
            setStep(2);
          } else if (resp != null && resp.ResponseCode == 401) {
            //console.log("ResponseCode 401");
          }
        });

        break;
      }
      case 2: {
        var req = {
          Email: data.Email,
          OTP: data?.OTP1 + data?.OTP2 + data?.OTP3 + data?.OTP4,
        };
        LoginByOTPService(req).then((resp) => {
          //console.log("LoginByOTPService resp", resp);
          if (resp != null && resp.ResponseCode == 200) {
            if (resp.data.IsLogin) {
              let UserType = resp.data.UserType;
              let profile = resp.data.userdetails;
              profile.UserType = UserType;
              login(resp.data);
              setCurrentUser(profile);
              //localStorage.setItem("Authorization", resp.data.token_type + " " + resp.data.access_token);
              //localStorage.setItem("profile", JSON.stringify(profile));
              var retrunurl_local = localStorage.getItem("retrunurl");
              const retrunurl = params.get("retrunurl") || retrunurl_local;
              //console.log("retrunurl", retrunurl)
              if (retrunurl) {
                //window.location.href=retrunurl;
                localStorage.removeItem("retrunurl");
                navigate(retrunurl);
              } else {
                //navigate("/user-dashboard/my-appointments")
                navigate("/onboarding-stella");
              }
            } else {
              setLoading(false);
              setStep(3);
            }
          } else {
            let newValidationMessage = Object.assign(
              [],
              [
                ...validationMessage.filter((o) => o.key != "OTP"),
                { key: "OTP", message: resp.ResponseMessage },
              ]
            );
            setValidationMessage(newValidationMessage);
            setLoading(false);
          }
        });

        break;
      }
      case 3: {
        setLoading(false);
        setStep(4);
        break;
      }
      case 4: {
        createRegistration();
        break;
      }
      // case 5: {
      //     createRegistration();
      //     break;
      // }
      default:
        console.error("Something unexpected occured!");
    }
  };

  const HandelReferalCodeChange = (e) => {
    const textWithoutSpaces = e.target.value.replace(/\s/g, ""); // Remove spaces
    setValidReferralCode(textWithoutSpaces);
  };

  const onRedeemCode = () => {
    debugger;
    setLoading(true);
    CheckReferralCode(validReferralCode).then((resp) => {
      console.log("respppppppp", resp);
      debugger;
      if (resp.ResponseCode === 200) {
        setReferralCodeMessage("Referral code Applied");
        setLoading(false);
        setData({ ...data, referralCode: validReferralCode });
      } else {
        setReferralCodeMessage("Referral code doesn't exist");
        //setValidReferralCode('')
        setData({ ...data, Referral_Code: null });
        setLoading(false);
      }
    });
    setLoading(false);
  };

  const handleSkip = () => {
    setValidReferralCode("");
    setData({ ...data, referralCode: null, skyp: true });
  };

  const createRegistration = () => {
    setLoading(true);
    //const{SocialID, SocialType}=this.props.route.params??{};
    const SocialID = null;
    const SocialType = null;

    var MobileNo = "";
    MobileNo = "+1 0000000000";
    var name = "";
    if (data.firstName != null && data.firstName != "") {
      // name = data.firstName + " " + data?.lastName;
      name = data.firstName;
    } else {
      name = "user";
      setData({ data: { ...data, firstName: "user", lastName: " " } });
    }

    //SocialID:appleAuthRequestResponse.user, SocialType:"apple"
    var req = {
      Emailid: data.Email,
      Title: "`",
      Name: name,
      MobileNo: MobileNo,
      ReferralCode: null,
      Referral_Code: data.referralCode,
      Country: data.country,
      UserType: 4,
      TimeZone: data.timezoneName,
      SocialID,
      SocialType,
    };

    //var req={"Email":"demo555@tmail.com","OTP":"7777","firstName":"test","lastName":"testest","countryId":"37","country":"Belize","timezoneId":"America/Belize","timezoneName":"America/Belize","email":"demo555@tmail.com","userId":30493,"device_type":"web"};

    //console.log("RegistrationByOTPV4Service req", req);
    RegistrationByOTPV4Service(req).then((resp) => {
      if (resp != null && resp.ResponseCode == 200) {
        //console.log("RegistrationByOTPV4Service resp", resp);

        let UserType = resp.data.UserType;
        let profile = resp.data.userdetails;
        profile.UserType = UserType;

        if (
          resp.data.userdetails.Country == null &&
          resp.data.userdetails.MobileNo.includes("+91")
        ) {
          profile.Country = "India";
        } else if (
          resp.data.userdetails.Country == null &&
          resp.data.userdetails.MobileNo.includes("+1")
        ) {
          profile.Country = "Canada";
        }
        //this.setState({profile, data:{...data, email:profile.Emailid, userId:profile.UserId}});

        login(resp.data);
        setCurrentUser(profile);

        var oodoCreateUserData = {
          ...data,
          email: profile.Emailid,
          userId: profile.UserId,
          device_type: "web",
          timezone: data.timezoneName,
        };
        //console.log("OODOCreateUserV4Service req", oodoCreateUserData);

        OODOCreateUserV4Service(oodoCreateUserData).then((resp2) => {
          //console.log("OODOCreateUserV4Service resp", JSON.stringify(resp2));
          //this.setState(prevState=>({createUserResp:resp2.data, profile:{...prevState.profile, showB2BWelcomeScreen:resp2.data.showB2BWelcomeScreen}}), ()=> {callback()});
          //Auth.setAddAProfList(resp2.data.AddAProfList);
          // localStorage.setItem("disclaimer", resp2?.data?.disclaimer);
          console.log("resp2?.data?.disclaimer", resp2);
          setDisclaimer(resp2?.data?.disclaimer);

          setLoading(false);
          const retrunurl = params.get("retrunurl");

          // navigate(`/disclaimer${retrunurl ? `?retrunurl=${retrunurl}` : ""}`);
          setStep(5);
        });
      }
    });
  };

  const inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 5) {
        elmnt.target?.form?.elements[next]?.focus();
      }
    }
  };

  return (
    //#region
    // <div className='d-flex align-items-center gap-4  py-5 py-md-0'>
    //     <div className='d-none d-md-block' style={{ backgroundImage: `url("/assets/img/signin/bannerwithbgLogo.png")`, backgroundSize: "100% 100%" }}>
    //         <img src='/assets/img/bannerWithBgLogo.png' alt='' />
    //     </div>
    //     <div className='px-5 col-md-4 col-12'>
    //         <h6 className='fs-3 py-2'>Sign up</h6>
    //         <div className='py-3'>
    //             <p className='fs-6 py-1'>Already have an account</p>
    //             <p className='fs-6 py-1'><Link to="/login" style={{
    //                 textDecoration: "none",
    //                 color: "#0d6efd",
    //                 backgroundClip: "text",
    //             }}>Login here!</Link></p>
    //         </div>

    //         {(step == 1 || step == 2) &&
    //             <>

    //                 <div className="py-3">
    //                     <div className="mb-3 py-1 mx-auto">
    //                         <label className="form-label" htmlFor="email">Email</label>
    //                         <input disabled={step == 2} placeholder="Enter your email address" type="email" id="email" className="form-control" name="email" onChange={(e) => setData({ ...data, Email: e.target.value })} />
    //                         <span className='error-message' style={{color:"#dc3545"}}>{validationMessage.filter(o=>o.key=="Email")[0]?.message}</span>
    //                     </div>
    //                     {
    //                         step == 2 &&
    //                         <>
    //                             <div className="mb-3">
    //                                 <label className="form-label" htmlFor="OTP">OTP</label>
    //                                 <input disabled={loading} placeholder="Enter Enter OTP" type="text" id="OTP" className="form-control" name="otp"
    //                                 onChange={(e)=> {
    //                                     setData({...data, OTP:e.target.value})
    //                                 }}
    //                                 onKeyDown={(event) => {
    //                                     if(event.key!="Backspace" && data?.OTP?.length==4)event.preventDefault();
    //                                     if (event.key!="Backspace" && !/[0-9]/.test(event.key)) {
    //                                         event.preventDefault();
    //                                     }
    //                                 }}
    //                                 />
    //                                 <span className='error-message' style={{color:"#dc3545"}}>{validationMessage.filter(o=>o.key=="OTP")[0]?.message}</span>
    //                             </div>
    //                             <div className='d-flex py-3 gap-3 align-items-baseline'>
    //                                 <Form.Check
    //                                     type='checkbox'
    //                                     id='IAgree'
    //                                     onClick={() => setData({ ...data, iAgree: !data?.iAgree })}
    //                                 />
    //                                 <Form.Label className='fs-6 py-1' >I agree with all <a href="/term-and-condition" target='_blank' style={{
    //                                     textDecoration: "none",
    //                                     color: "#0d6efd",
    //                                     backgroundClip: "text",
    //                                 }}>Terms and Conditions</a> and <a href="/privacy-policy" target='_blank' style={{
    //                                     textDecoration: "none",
    //                                     color: "#0d6efd",
    //                                     backgroundClip: "text",
    //                                 }}>Privacy Policy</a> of United We Care</Form.Label>
    //                             </div>
    //                         </>
    //                     }
    //                 </div>
    //                 <Button disabled={loading || (step == 2 && !data?.iAgree) || (step == 1 && !data?.Email) || (step == 2 && !data?.OTP)} className='w-100 my-3 py-2' style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", borderRadius: "40px", border: "none" }} onClick={onLogin}>{loading ? "Loading..." : step == 1 ? "Continue Sign up" : "Sign up"}</Button>
    //             </>
    //             ||
    //             step == 3 &&
    //             <>
    //                 <div className="py-3">
    //                     <p className='fs-6 py-1'>What's Your Name?</p>
    //                     <div className="mb-3">
    //                         <label className="form-label" htmlFor="firstName">First Name</label>
    //                         <input placeholder="First Name" type="text" id="firstName" className="form-control" name="firstName" onChange={(e) => setData({ ...data, firstName: e.target.value })} />
    //                     </div>
    //                     <div className="mb-3">
    //                         <label className="form-label" htmlFor="lastName">Last Name</label>
    //                         <input placeholder="Last Name" type="text" id="lastName" className="form-control" name="lastName" onChange={(e) => setData({ ...data, lastName: e.target.value })} />
    //                     </div>
    //                 </div>
    //                 <Button disabled={loading || !data?.firstName || !data?.lastName} className='w-100 my-3 py-2' style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", borderRadius: "40px", border: "none" }} onClick={onLogin}>{loading ? "Loading..." : step == 1 ? "Continue Sign up" : "Sign up"}</Button>
    //             </>
    //             ||
    //             step == 4 &&
    //             <>
    //                 <div className="py-3">
    //                     <p className='fs-6 py-1'>Please let us know your Country of Residence. This will help us to provide you services as per your Country</p>
    //                     <div className="mb-3">
    //                         <label className="form-label" htmlFor="countryId">Country</label>
    //                         <select id="countryId" className='form-control' onChange={(e) => onCountryChange(e.target.value)}>
    //                             <option>Select Country</option>
    //                             {countryList.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
    //                         </select>
    //                     </div>
    //                     <div className="mb-3">
    //                         <label className="form-label" htmlFor="countryId">Country</label>
    //                         <select id="countryId" className='form-control' onChange={(e) => onTimezoneChange(e.target.value)}>
    //                             <option>Select Timezone</option>
    //                             {timezoneList.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
    //                         </select>
    //                     </div>
    //                 </div>
    //                 <Button disabled={loading || !data?.countryId || !data?.timezoneId} className='w-100 my-3 py-2' style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", borderRadius: "40px", border: "none" }} onClick={onLogin}>{loading ? "Loading..." : step == 1 ? "Continue Sign up" : "Sign up"}</Button>
    //             </>
    //             ||
    //             step == 5 &&
    //             <>
    //                 <div className="py-3">
    //                     <p className='fs-6 py-1'>Do you have a Referral code?</p>
    //                     <div className="mb-3">
    //                         <label className="form-label fs-6 py-1" htmlFor="referralCode">If yes, Please put your referral code above. If no, You can click <Link style={{
    //                             textDecoration: "none",
    //                             color: "#0d6efd",
    //                             backgroundClip: "text",
    //                         }} onClick={onLogin}>SKIP</Link></label>
    //                         <input placeholder="Referral Code" type="text" id="referralCode" className="form-control" name="referralCode" onChange={(e) => setData({ ...data, referralCode: e.target.value })} />
    //                     </div>

    //                 </div>
    //                 <Button disabled={loading} className='w-100 my-3 py-2' style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", borderRadius: "40px", border: "none" }} onClick={onLogin}>{loading ? "Loading..." : step == 1 ? "Continue Sign up" : "Sign up"}</Button>
    //             </>
    //         }

    //         {/* <p className='fs-6 text-center'>or continue with</p>
    //         <div className='d-flex justify-content-center gap-3 py-3'>
    //             <img src='/assets/img/signin/facebook.png' alt='' />
    //             <img src='/assets/img/signin/apple.png' alt='' />
    //             <img src='/assets/img/signin/google.png' alt='' />
    //         </div> */}
    //     </div>
    // </div >
    //#endregion
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ background: "#E7F6FF", minHeight: "100vh" }}
    >
      {step == 1 && (
        // <div className='col-12 col-md-3 bg-white rounded-4 py-5 d-flex flex-column align-items-center justify-content-center signUpMobile'>
        <div className="col-12 col-md-4 bg-white rounded-4 py-5 d-flex flex-column align-items-center justify-content-center">
          <img src="/assets/img/SignUpImage.png" alt="" />
          <div className="py-3 px-md-3">
            <h6 className="font-inter font-w600 fs-3 text-center lh-lg">
              Let’s Start
            </h6>
            <p className="font-inter fs-6 text-center">
              Create an account to access personalized mental health support and
              resources.
            </p>
          </div>
          <div className="gap-3 d-flex flex-column py-3 w-100 px-3 px-md-5">
            <div className="mb-3">
              {/* <input disabled={step == 2} placeholder="Your Name" type="email" id="floatingInput" className="form-control rounded-3" name="Name" onChange={(e) => setData({ ...data, firstName: e.target.value })} style={{ height: "50px" }} /> */}
              <TextField
                disabled={step == 2}
                label="Your Name"
                type="email"
                className="w-100 rounded-3"
                name="Name"
                onChange={(e) =>
                  setData({ ...data, firstName: e.target.value })
                }
              // style={{ height: "50px" }}
              />
              {/* <label className='fs-6 font-inter lh-1' htmlFor="floatingInput">Your Name</label> */}
            </div>
            <div className="mb-3">
              {/* <input disabled={step == 2} type="email" className="form-control rounded-3" id="floatingInput" placeholder="name@example.com" name="email" onChange={(e) => setData({ ...data, Email: e.target.value })} style={{ height: "50px" }} /> */}
              <TextField
                disabled={step == 2}
                type="email"
                className="w-100 rounded-3"
                label="name@example.com"
                name="email"
                onChange={(e) => setData({ ...data, Email: e.target.value })}
                variant="outlined"
              // style={{ height: "50px" }}
              />
              {/* <label className='fs-6 font-inter lh-1' htmlFor="floatingInput">Your Email</label> */}
              <span className="error-message" style={{ color: "#dc3545" }}>
                {validationMessage.filter((o) => o.key == "Email")[0]?.message}
              </span>
            </div>
            <Button
              disabled={loading || (step == 1 && !data?.Email && !data?.Name)}
              className="py-2 border-0 rounded-5 font-inter text-black font-w500 fs-6"
              style={{ background: "#C4E5F9", height: "50px" }}
              onClick={onLogin}
            >
              {loading ? "Loading..." : "Request OTP"}
            </Button>
          </div>
        </div>
      )}
      {step == 2 && (
        // <div className='col-12 col-md-3 bg-white rounded-4 py-5 d-flex flex-column align-items-center justify-content-center signUpMobile'>
        <div className="col-12 col-md-4 bg-white rounded-4 py-5 d-flex flex-column align-items-center justify-content-center">
          <img src="/assets/img/SignUpImage.png" alt="" className="" />
          <div className="py-3 px-md-3">
            <h6 className="font-inter font-w600 fs-3 text-center lh-lg">
              {data?.Email}
            </h6>
            <p className="font-inter fs-6 text-center px-3">
              Enter 4 digit code we sent to your email
            </p>
          </div>
          <div className="gap-3 d-flex flex-column py-3 mx-4">
            <form className="d-flex align-items-center justify-content-evenly gap-4 col-12">
              <input
                disabled={loading}
                type="text"
                id="OTP"
                className="form-control text-center"
                style={{ height: "50px", width: "50px" }}
                name="otp"
                maxLength={1}
                onChange={(e) => {
                  setData({ ...data, OTP1: e.target.value });
                }}
                onKeyDown={(event) => {
                  // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                  if (event.key != "Backspace" && !/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                autoComplete="off"
                tabIndex="1"
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                disabled={loading}
                type="text"
                id="OTP"
                className="form-control text-center"
                style={{ height: "50px", width: "50px" }}
                name="otp"
                maxLength={1}
                onChange={(e) => {
                  setData({ ...data, OTP2: e.target.value });
                }}
                onKeyDown={(event) => {
                  // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                  if (event.key != "Backspace" && !/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                autoComplete="off"
                tabIndex="2"
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                disabled={loading}
                type="text"
                id="OTP"
                className="form-control text-center"
                style={{ height: "50px", width: "50px" }}
                name="otp"
                maxLength={1}
                onChange={(e) => {
                  setData({ ...data, OTP3: e.target.value });
                }}
                onKeyDown={(event) => {
                  // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                  if (event.key != "Backspace" && !/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                autoComplete="off"
                tabIndex="3"
                onKeyUp={(e) => inputfocus(e)}
              />
              <input
                disabled={loading}
                type="text"
                id="OTP"
                className="form-control text-center"
                style={{ height: "50px", width: "50px" }}
                name="otp"
                maxLength={1}
                onChange={(e) => {
                  setData({ ...data, OTP4: e.target.value });
                }}
                onKeyDown={(event) => {
                  // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                  if (event.key != "Backspace" && !/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                autoComplete="off"
                tabIndex="4"
                onKeyUp={(e) => inputfocus(e)}
              />
            </form>
            <span className="error-message" style={{ color: "#dc3545" }}>
              {validationMessage.filter((o) => o.key == "OTP")[0]?.message}
            </span>
            <Button
              disabled={
                loading ||
                (step == 2 &&
                  !data?.OTP1 &&
                  !data?.OTP2 &&
                  !data?.OTP3 &&
                  !data?.OTP4)
              }
              className="py-2 border-0 rounded-5 font-inter text-black font-w500"
              style={{ background: "#C4E5F9", height: "50px" }}
              onClick={onLogin}
            >
              {loading ? "Loading..." : "Continue"}
            </Button>
            <p className="font-inter fs-6 pt-5 text-center">
              Didn’t recieve code?
            </p>

            {(counter === 0 && otpSent && (
              <div onClick={resendOTP} style={{ cursor: "pointer" }}>
                <p className="font-inter fs-6 text-black text-center">
                  Resend Code
                </p>
              </div>
            )) || (
                <div className="font-inter fs-6 text-black text-center border-0 bg-white">
                  Resend OTP {counter}
                </div>
              )}
          </div>
        </div>
      )}
      {step == 3 && (
        <div className="col-12 col-md-4 bg-white rounded-4 signUpMobile">
          <div className="d-none d-md-block">
            <ProgressBar variant="success" now={35} />
          </div>
          <div className="pb-5 d-flex flex-column h-100 gap-5">
            <div
              className="py-3 px-md-4 h-25 mx-3 my-3"
              style={{ marginBottom: 20 }}
            >
              <h6 className="font-inter font-w600 fs-3 text-center py-3">
                Connect with the right experts
              </h6>
              <div className="d-block d-md-none my-3">
                <ProgressBar variant="success" now={70} />
              </div>
              <div className="font-inter fs-6 text-center py-3">
                Choose your country of residence & time zone so that we can
                suggest experts suited to your time & country
              </div>
            </div>
            <div className="gap-3 d-flex flex-column py-3 px-4 w-100 h-30 my-5 my-md-0">
              <Form.Select
                className="rounded-3"
                onChange={(e) => onCountryChange(e.target.value)}
                style={{ height: "50px" }}
              >
                <option className="fs-6 font-inter lh-1">
                  Choose your Country
                </option>
                {countryList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Select
                className="rounded-3 fs-6 font-inter lh-1"
                onChange={(e) => onTimezoneChange(e.target.value)}
                style={{ height: "50px" }}
              >
                {/* {timezoneList.length > 0 || <option>Time Zone</option> } */}
                <option>Time Zone</option>
                {timezoneList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </div>
            <button
              disabled={loading || !data?.countryId || !data?.timezoneId}
              className="py-2 border-0 rounded-5 font-inter text-black font-w500 mx-4"
              style={{ background: "#C4E5F9", height: "50px" }}
              onClick={onLogin}
            >
              {loading ? "Loading..." : "Next"}
            </button>
          </div>
        </div>
      )}
      {step == 4 && (
        <div className="col-12 col-md-4 bg-white rounded-4 signUpMobile">
          <div className="d-none d-md-block">
            <ProgressBar variant="success" now={70} />
          </div>
          <div className="h-100 mx-4 mx-md-3">
            <div className="py-5 px-md-4 h-25">
              <h6 className="font-inter font-w600 fs-3 text-md-center lh-lg">
                Have a Referral Code?
              </h6>
              <div className="d-block d-md-none my-2">
                <ProgressBar variant="success" now={70} />
              </div>
              <p className="font-inter fs-6 text-md-center">
                Have a referral code? Enter it to unlock exclusive benefits
              </p>
            </div>
            <div className="form-floating my-5 h-15">
              <TextField
                disabled={step == 2}
                label="Referral Code"
                value={validReferralCode}
                type="code"
                // id="floatingInput"
                className="rounded-3 w-100"
                name="Name"
                onChange={(e) => HandelReferalCodeChange(e)}
              // style={{ height: "50px" }}
              />
              {/* <label for="floatingInput">Referral Code</label> */}
              <p
                className="font-inter font-w500 p-1"
                style={{
                  color:
                    referralCodeMessage === "Referral code Applied"
                      ? "green"
                      : "red",
                }}
              >
                {referralCodeMessage}
              </p>
            </div>
            <div className="pt-5 d-block my-auto">
              <Link className="text-decoration-none">
                <p
                  className="text-center font-inter fs-6 lh-lg"
                  onClick={handleSkip}
                >
                  Skip this step
                </p>
              </Link>
              <button
                className="py-2 border-0 rounded-5 font-inter text-black font-w500 w-100 my-3"
                style={{ background: "#C4E5F9", height: "50px" }}
                onClick={onRedeemCode}
              >
                {loading ? "Loading..." : "Redeem Code"}
              </button>
            </div>
          </div>
        </div>
      )}
      {step == 5 && (
        <div className="col-12 col-md-4 bg-white rounded-md-4 my-md-5 py-5 py-md-0">
          <div className="d-none d-md-block">
            <ProgressBar variant="success" now={100} />
          </div>
          <div className="mx-3">
            <div className="py-3">
              <h6 className="font-inter font-w600 fs-3 text-center">
                Share your consent with us
              </h6>
            </div>
            <div className="d-block d-md-none">
              <ProgressBar variant="success" now={100} />
            </div>
            <div className="">
              <h6 className="fs-5 pt-5 font-inter font-w500">Disclaimer</h6>
              <p
                className="py-3"
                style={{ wordBreak: "break-word" }}
                dangerouslySetInnerHTML={{ __html: disclaimer }}
              ></p>
              <Form.Group
                className="d-flex align-items-center my-3"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  onClick={() => setIAgree(!iAgree)}
                  type="checkbox"
                  label=""
                />
                <label>
                  Accept{" "}
                  <Link
                    to="/term-and-condition"
                    target="_blank"
                    style={{ color: "#1B8BCF" }}
                  >
                    Terms and Conditions
                  </Link>
                </label>
              </Form.Group>
              <Button
                disabled={!iAgree}
                onClick={() => onSubmit()}
                className="py-2 border-0 rounded-5 font-inter text-black font-w500 my-5 col-12"
                style={{ background: "#C4E5F9", height: "50px" }}
              >
                Finish
              </Button>
            </div>
          </div>
        </div>
      )}

      {/* </>
                ||
                step == 3 &&
                <>
                    <div className="py-3">
                        <p className='fs-6 py-1'>What's Your Name?</p>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="firstName">First Name</label>
                            <input placeholder="First Name" type="text" id="firstName" className="form-control" name="firstName" onChange={(e) => setData({ ...data, firstName: e.target.value })} />
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="lastName">Last Name</label>
                            <input placeholder="Last Name" type="text" id="lastName" className="form-control" name="lastName" onChange={(e) => setData({ ...data, lastName: e.target.value })} />
                        </div>
                    </div>
                    <Button disabled={loading || !data?.firstName || !data?.lastName} className='w-100 my-3 py-2' style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", borderRadius: "40px", border: "none" }} onClick={onLogin}>{loading ? "Loading..." : step == 1 ? "Continue Sign up" : "Sign up"}</Button>
                </>
                ||
                step == 4 &&
                <>
                    <div className="py-3">
                        <p className='fs-6 py-1'>Please let us know your Country of Residence. This will help us to provide you services as per your Country</p>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="countryId">Country</label>
                            <select id="countryId" className='form-control' onChange={(e) => onCountryChange(e.target.value)}>
                                <option>Select Country</option>
                                {countryList.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
                            </select>
                        </div>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="countryId">Country</label>
                            <select id="countryId" className='form-control' onChange={(e) => onTimezoneChange(e.target.value)}>
                                <option>Select Timezone</option>
                                {timezoneList.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <Button disabled={loading || !data?.countryId || !data?.timezoneId} className='w-100 my-3 py-2' style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", borderRadius: "40px", border: "none" }} onClick={onLogin}>{loading ? "Loading..." : step == 1 ? "Continue Sign up" : "Sign up"}</Button>
                </>
                ||
                step == 5 &&
                <>
                    <div className="py-3">
                        <p className='fs-6 py-1'>Do you have a Referral code?</p>
                        <div className="mb-3">
                            <label className="form-label fs-6 py-1" htmlFor="referralCode">If yes, Please put your referral code above. If no, You can click <Link style={{
                                textDecoration: "none",
                                color: "#0d6efd",
                                backgroundClip: "text",
                            }} onClick={onLogin}>SKIP</Link></label>
                            <input placeholder="Referral Code" type="text" id="referralCode" className="form-control" name="referralCode" onChange={(e) => setData({ ...data, referralCode: e.target.value })} />
                        </div>

                    </div>
                    <Button disabled={loading} className='w-100 my-3 py-2' style={{ background: "linear-gradient(90deg, #FF9C00 0%, #FFD255 100%)", borderRadius: "40px", border: "none" }} onClick={onLogin}>{loading ? "Loading..." : step == 1 ? "Continue Sign up" : "Sign up"}</Button>
                </> */}
    </div>
  );
};

export default SignupComponent;
