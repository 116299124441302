
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ProfileCard from './ProfileCard'
import React, { Component, useContext, useEffect, useState } from 'react';
import { getExpertListWithFilter_Web_V3Service, GetGeoLocationByBrowser, US_FilterFieldsService } from './core/_request';
import { ButtonGroup, Col, Collapse, FloatingLabel, Form, InputGroup, Modal, OverlayTrigger, Row, ToggleButton, Tooltip } from 'react-bootstrap';
// import moment from 'moment';
import { getCountryCode } from './core/commonFun';
import { Button, Tab, Tabs } from 'react-bootstrap'
import './ExpertFilterDrawer.css'
import { useAuth } from '../auth';
import LocationModal from './LocationModal';
import { AppContext } from '../context';
import LocationOnIcon from '@mui/icons-material/LocationOn';

export default class SearchView extends Component {

    componentDidMount = () => {

        this.setState({ loading: true });
        var _interval = setInterval(() => {
            const countryCode = getCountryCode();
            if (countryCode) {
                this.setState({ loading: false });
                clearInterval(_interval);
            }
        }, 1000);
    };

    render() {
        return (
            this.state?.loading && <div>...</div>
            ||
            <Search />
        );
    }
}

const Search = () => {
    const [searchBarParams, setSearchBarParams] = useSearchParams();
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [catSubcatData, setCatSubcatData] = useState();
    const [subcatData, setSubcatData] = useState();
    const [filtershow, setFiltershow] = useState(false);
    const [filters, setFilters] = useState();
    // const [multipleFilters, setMultipleFilters] = useState([]);
    const [loading, setLoading] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [isLoadMore, setIsLoadMore] = useState();
    const [isFirstLoad, setIsFirstLoad] = useState();
    const [isFilterSelected, setIsFilterSelected] = useState(false);
    const [isUrlFirst, setIsUrlFirst] = useState(false);
    const [locationData, setLocationData] = useState();
    const [longitude, setLongitude] = useState();
    const [latitude, setLatitude] = useState();

    const search = useLocation().search;
    const Profession_url = new URLSearchParams(search).get("Profession");
    const Category_url = new URLSearchParams(search).get("Category");
    const Experience_url = new URLSearchParams(search).get("Experience");
    const Minprice_url = new URLSearchParams(search).get("Minprice");
    const Maxprice_url = new URLSearchParams(search).get("Maxprice");

    const q_url = new URLSearchParams(search).get("q");

    const countryCode = getCountryCode();
    const [final_selected, setFinal_selected] = useState([]);
    const [area_of_conseron_selected, setArea_of_conseron_selected] = useState([]);
    const [experience_selected, setExperience_selected] = useState();
    const [price_selected, setPrice_selected] = useState();
    const [location, setLocation] = useState(false);
    const [locationModalShow, setLocationModalShow] = React.useState(false);
    const { currentUser } = useAuth();
    const locationState = sessionStorage.getItem("UserState");
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);

    const handleChange_Area_of_concern = event => {
        const { checked, value } = event.currentTarget;
        var Json_val = JSON.parse(value)
        setArea_of_conseron_selected(
            prev => checked
                ? [...prev, Json_val]
                : prev.filter(val => val?.Id !== Json_val?.Id)
        );
        setIsFilterSelected(true)
    };

    const handleChange_Experience = event => {
        const { checked, value } = event.currentTarget;
        // console.log("handleChange_Experience", value)
        setExperience_selected(value);
        setIsFilterSelected(true)
    };

    const handleChange_Price = event => {
        const { checked, value } = event.currentTarget;
        var Json_val = JSON.parse(value)
        setPrice_selected(Json_val);
        setIsFilterSelected(true)
    };

    const handel_Apply_Filter = () => {
        setFinal_selected({ "area_of_conseron": area_of_conseron_selected, "experience": experience_selected, "price": price_selected });
        setFiltershow(false);
        setData([]);
        setPageNumber(0);
        binddata();

        if (area_of_conseron_selected)
            searchBarParams.set("Category", area_of_conseron_selected?.map((item, index) => item).toString());
        if (experience_selected)
            searchBarParams.set("Experience", experience_selected?.toString());
        if (price_selected && price_selected.Min)
            searchBarParams.set("Minprice", price_selected?.Min);
        if (price_selected && price_selected.Max)
            searchBarParams.set("Maxprice", price_selected?.Max);

        setSearchBarParams(searchBarParams);
    }

    const handel_Clear_Filter = () => {
        setFinal_selected([]);
        setArea_of_conseron_selected([]);
        setExperience_selected();
        setFiltershow(false);
        setPrice_selected();
        //if (searchBarParams.has("Profession")) searchBarParams.delete("Profession");
        if (searchBarParams.has("Category")) searchBarParams.delete("Category");
        if (searchBarParams.has("Experience")) searchBarParams.delete("Experience");
        if (searchBarParams.has("Minprice")) searchBarParams.delete("Minprice");
        if (searchBarParams.has("Maxprice")) searchBarParams.delete("Maxprice");
        setSearchBarParams(searchBarParams);
        setIsFilterSelected(false)
    }

    const handel_remove_area_of_conseron = (item) => {
        if (searchBarParams.has("Category")) searchBarParams.delete("Category");
        setSearchBarParams(searchBarParams);
        setIsFilterSelected(false)

        setArea_of_conseron_selected((prevState) =>
            prevState?.filter((prevItem) => prevItem !== item)
        );

        setFinal_selected((prevState) => { return { ...prevState, "area_of_conseron": prevState?.area_of_conseron?.filter((prevItem) => prevItem !== item) } }
        );
        setData([]);
        setPageNumber(0);
        binddata();
    }

    const handel_remove_experience = (item) => {
        if (searchBarParams.has("Experience")) searchBarParams.delete("Experience");
        setSearchBarParams(searchBarParams);
        setIsFilterSelected(false)

        setExperience_selected();

        setFinal_selected((prevState) => { return { ...prevState, "experience": null } }
        );
        setData([]);
        setPageNumber(0);
        binddata();
    }

    const handel_remove_price = (item) => {
        if (searchBarParams.has("Minprice")) searchBarParams.delete("Minprice");
        if (searchBarParams.has("Maxprice")) searchBarParams.delete("Maxprice");
        setSearchBarParams(searchBarParams);
        setIsFilterSelected(false)

        setPrice_selected();

        setFinal_selected((prevState) => { return { ...prevState, "price": null } }
        );
        setData([]);
        setPageNumber(0);
        binddata();
    }

    useEffect(() => {
        if (!Profession_url && !isFirstLoad) {
            navigate("/?Profession=Mental Health and Wellness Coach");
        }
        setPageNumber(0);
        binddata();

        US_FilterFieldsService().then(resp => {
            var newData = resp?.data;
            console.log("resp?.data", resp?.data)
            setCatSubcatData(newData);
        });
    }, [filters]);

    useEffect(() => {
        binddata();
    }, [pageNumber, locationState]);

    useEffect(() => {
        binddata();
    }, [locationState]);

    useEffect(() => {
        if (!isFirstLoad && catSubcatData) {
            setIsFirstLoad(true);
            if ((Profession_url == null || Profession_url == "Mental Health and Wellness Coach") && !Category_url && !Experience_url && !Minprice_url && !Maxprice_url && !q_url) {
                onCatChange(111, false);
            }
            // if (!isFirstLoad && catSubcatData) {
            //     setIsFirstLoad(true); 
            // } 
            // if(!Category_url && !Experience_url && !Minprice_url && !Maxprice_url){
            //     onCatChange(70, false);
            // }
            // else if(!Profession_url){
            //     onCatChange(70, false);
            // }
            // else{
            //     var sf=catSubcatData.filter(o=>o.Name==Profession_url);
            //     onCatChange(sf.Id, false);
            // }

        }
    }, [catSubcatData])

    useEffect(() => {
        binddata();
    }, [searchBarParams]);

    const onFilterChange = (key, value) => {
        var newFilters = JSON.parse(JSON.stringify(filters ?? {}));
        newFilters[key] = value;
        setPageNumber(0);
        setFilters(newFilters);
        searchBarParams.set(key, value);
        setSearchBarParams(searchBarParams);
    };

    const onSearchClick = () => {
        if (searchBarParams.has("Profession")) searchBarParams.delete("Profession");
        if (searchBarParams.has("Category")) searchBarParams.delete("Category");
        if (searchBarParams.has("Experience")) searchBarParams.delete("Experience");
        if (searchBarParams.has("Minprice")) searchBarParams.delete("Minprice");
        if (searchBarParams.has("Maxprice")) searchBarParams.delete("Maxprice");
        setSearchBarParams(searchBarParams);
    };

    const binddata = () => {
        setLoading(true);
        var req = {
            "Country": "United States",
            "q": filters?.q ?? q_url,
            "Profession": Profession_url, //filters?.Profession ?? 
            "Experience": experience_selected ?? Experience_url, //filters?.Experience
            "SortBy": filters?.SortBy,
            "MinPrice": price_selected?.Min ?? Minprice_url ?? 0,
            "MaxPrice": price_selected?.Max ?? Maxprice_url ?? 20000,
            "PageNumber": pageNumber,
            "RowsOfPage": 3,
            "licensed_practice_in_state": Profession_url === "Therapist" && currentUser?.Location || null,
        }
        if (area_of_conseron_selected?.length > 0) {
            req["Category"] = area_of_conseron_selected?.map((item, index) => item).toString();
        }
        else {
            req["Category"] = Category_url
        }


        getExpertListWithFilter_Web_V3Service(req).then(resp => {
            var tdata = resp?.data;
            tdata?.map(o => {
                if (countryCode == "IN")
                    o["countryAmount"] = o.Hourly_Consultaion_Fee_amount;
                else
                    o["countryAmount"] = o.USD_Amount;

                return o;
            });
            if (pageNumber == 0) {
                setData(tdata);
            }
            else {
                var nData = [...data, ...tdata];
                setData(nData);
            }
            setLoading(false);
            setIsLoadMore(resp?.data?.length == 3);
        });
    }

    const nextPage = () => {
        setPageNumber(pageNumber + 1);
        //binddata();
    }

    const onCatChange = (Id, isPressed, e) => {
        if (e?.target?.innerHTML === "Therapist") {
            if (currentUser && !currentUser?.Location) {
                setLocationModalShow(true);
            } else {
                localStorage.setItem('retrunurl', `/`);
                setShowLloginPopup(true);
                // if (!ShowLloginPopup) {
                //     setLocationModalShow(true);
                // }
            }
        }

        if (isPressed) {
            if (searchBarParams.has("Category")) searchBarParams.delete("Category");
            if (searchBarParams.has("Experience")) searchBarParams.delete("Experience");
            if (searchBarParams.has("Minprice")) searchBarParams.delete("Minprice");
            if (searchBarParams.has("Maxprice")) searchBarParams.delete("Maxprice");
        }

        handel_Clear_Filter(); // Clear all filters 
        filter_tab_data(Id);
    }

    useEffect(() => {
        if (catSubcatData && catSubcatData?.length > 0 && !isUrlFirst) {
            setIsUrlFirst(true);
            var current_url_id = catSubcatData?.find(p => p?.Name === Profession_url)?.Id;
            filter_tab_data(current_url_id);
        }
    }, [catSubcatData]);

    const filter_tab_data = (Id) => {
        var item = catSubcatData.find(o => o.Id == Id);
        setFilters({ ...filters, Category: null, Profession: item?.Name });
        setSubcatData(item?.Data);
        searchBarParams.set("Profession", item?.Name);
        setSearchBarParams(searchBarParams);
    }

    // const onLetsChat = () => {
    //     navigate('/stella')
    // }

    useEffect(() => {
        if (currentUser && !currentUser?.Location) {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(success, error);
            } else {
                console.log("Geolocation not supported");
            }

            function success(position) {
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
                console.log("position", position)
            }

            function error() {
                console.log("Unable to retrieve your location");
            }
        }
    }, []);

    var locationBody = { lat: latitude, lon: longitude }
    useEffect(() => {
        GetGeoLocationByBrowser(locationBody).then(resp => {
            if (resp?.code === 200) {
                setLocationData(resp?.data)
                console.log("location Data", locationData)
            }
        })
    }, [latitude, longitude]);

    return (
        <>
            {currentUser?.Location &&
                <div className='d-none d-md-block py-2' style={{ background: "#DAF1FF" }}>
                    <div className='ms-auto py-2 me-md-5 px-3' style={{ borderRadius: "48px", background: "#E7F6FF", width: 'fit-content' }}>
                        <OverlayTrigger placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">
                                <h6 style={{ color: "#fff", whiteSpace: "nowrap" }}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M7.2 12H8.8V7.2H7.2V12ZM8 5.6C8.22667 5.6 8.4168 5.5232 8.5704 5.3696C8.724 5.216 8.80053 5.02613 8.8 4.8C8.8 4.57333 8.7232 4.3832 8.5696 4.2296C8.416 4.076 8.22613 3.99947 8 4C7.77333 4 7.5832 4.0768 7.4296 4.2304C7.276 4.384 7.19947 4.57387 7.2 4.8C7.2 5.02667 7.2768 5.2168 7.4304 5.3704C7.584 5.524 7.77387 5.60053 8 5.6ZM8 16C6.89333 16 5.85333 15.7899 4.88 15.3696C3.90667 14.9493 3.06 14.3795 2.34 13.66C1.62 12.94 1.05013 12.0933 0.6304 11.12C0.210667 10.1467 0.000533333 9.10667 0 8C0 6.89333 0.210133 5.85333 0.6304 4.88C1.05067 3.90667 1.62053 3.06 2.34 2.34C3.06 1.62 3.90667 1.05013 4.88 0.6304C5.85333 0.210667 6.89333 0.000533333 8 0C9.10667 0 10.1467 0.210133 11.12 0.6304C12.0933 1.05067 12.94 1.62053 13.66 2.34C14.38 3.06 14.9501 3.90667 15.3704 4.88C15.7907 5.85333 16.0005 6.89333 16 8C16 9.10667 15.7899 10.1467 15.3696 11.12C14.9493 12.0933 14.3795 12.94 13.66 13.66C12.94 14.38 12.0933 14.9501 11.12 15.3704C10.1467 15.7907 9.10667 16.0005 8 16ZM8 14.4C9.78667 14.4 11.3 13.78 12.54 12.54C13.78 11.3 14.4 9.78667 14.4 8C14.4 6.21333 13.78 4.7 12.54 3.46C11.3 2.22 9.78667 1.6 8 1.6C6.21333 1.6 4.7 2.22 3.46 3.46C2.22 4.7 1.6 6.21333 1.6 8C1.6 9.78667 2.22 11.3 3.46 12.54C4.7 13.78 6.21333 14.4 8 14.4Z" fill="#8E8E8E" />
                                    </svg> Location Confirmation</h6>
                                <p>You are viewing experts licensed to practice in {currentUser?.Location}.</p>
                            </Tooltip>}>
                            <p className='font-inter text-center' style={{ color: "#000", fontSize: "16px" }}><LocationOnIcon /> {currentUser?.Location}</p>
                        </OverlayTrigger>
                    </div>
                </div >
            }
            <section className='pt-5' style={{ backgroundImage: `url("/assets/img/bg-3.png")`, backgroundRepeat: "no-repeat", backgroundSize: "100% 176px" }}>
                {/* <img src='/assets/img/shadow.png' alt='' style={{backgroundRepeat: "no-repeat", backgroundSize:"100% 100%"}}/> */}
                <div className='container d-flex flex-column gap-3'>
                    <h1 className='font-inter font-w600 fs-2 lh-base text-center'>Find a Professional</h1>
                    <p className='font-inter fs-6 text-center'>Get the support from the right professionals - fast, confidential & right from your home</p>
                    {/* <Col xs={12} md={3} className='mx-auto'>
                        <InputGroup className='mb-3 lh-1 my-3 rounded-3'>
                            <Form.Control className="custom-input border-end-0" style={{ height: "50px" }} type="text" value={q_url} placeholder="Search “Professionals” " onChange={(e) => onFilterChange("q", e.target.value)} />
                            <InputGroup.Text><img src='/assets/img/Icon(1).png' alt='' /></InputGroup.Text>
                        </InputGroup>
                    </Col> */}
                    <div className='d-flex justify-content-center my-3'>
                        <ButtonGroup className='d-flex flex-row align-items-center hideScrollbar gap-2' style={{ overflowX: 'scroll' }}>
                            {
                                catSubcatData && catSubcatData?.filter(o => countryCode !== "IN" || o?.Name != 'Psychiatrist')?.map((item, index) =>
                                    item &&
                                    <div>
                                        <ToggleButton
                                            onClick={(e) => onCatChange(item.Id, true, e)}
                                            type="radio"
                                            name="radio"
                                            className="rounded-2 col-2 px-1 py-1 ms-1 border-0 expertFilterTab"
                                            style={{
                                                background: Profession_url === item?.Name ? "#1B8BCF" : "#F3F3F3",
                                                color: Profession_url === item?.Name ? "#FFFFFF" : "#21231E",
                                                border: "1px solid #50B3EF",
                                                minWidth: "140px",
                                                height: "40px",
                                                whiteSpace: "nowrap"
                                            }}>
                                            <p className='font-inter fs-6 text-center lh-lg'>{item?.Name}</p>
                                        </ToggleButton>
                                    </div>
                                )
                            }
                            {/* <ToggleButton
                                onClick={(e) => onCatChange('Not Know', true)}
                                type="radio"
                                name="radio"
                                className="rounded-2 col-2 px-1 py-1 ms-1 border-0"
                                style={{
                                    background: Profession_url === 'Not Know' ? "#1B8BCF" : "#F3F3F3",
                                    color: Profession_url === 'Not Know' ? "#FFFFFF" : "#21231E",
                                    border: "1px solid #50B3EF",
                                    minWidth: "110px",
                                    maxWidth: "150px",
                                    height: "40px"
                                }}>
                                <p className='font-inter fs-6 text-center lh-lg'>I Don't Know</p>
                            </ToggleButton> */}
                        </ButtonGroup>
                    </div>
                    {filters?.Profession == "Not Know" && <h6 className='pb-3 pt-5 text-center fs-4'>It’s okay to not know.<br /><span className='fs-6 font-w500'> Together we can figure out  how we can help you further.</span> <br /><span className='fs-6 font-w500'> These are the experts who can guide you better.</span></h6>}
                    <div className='d-flex align-items-center gap-3 py-3'>
                        {filters?.Profession != "Not Know" &&
                            <span style={{ cursor: 'pointer' }} className='d-flex align-items-center gap-2 text-decoration-none' onClick={() => setFiltershow(true)}>
                                <img src='/assets/img/Mask group(8).png' alt='' />
                                <p className='font-inter fs-6 text-black'>Filters</p>
                            </span>
                        }
                        {filters?.Profession != "Not Know" &&
                            <div className='vr' />
                        }

                        <div className='d-flex hideScrollbar gap-2' style={{ overflowX: 'scroll', marginLeft: 12, height: 35 }}>
                            {filters?.Profession !== "Therapist" &&
                                (!final_selected?.area_of_conseron || final_selected?.area_of_conseron?.length === 0 || final_selected?.experience?.length === 0 || final_selected?.price?.length === 0) &&
                                subcatData && subcatData.slice(0, 3).map((item, index) =>
                                    <h2 className='d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6' style={{ background: "#E7F6FF", border: "1px solid #C4E5F9", whiteSpace: "nowrap", marginLeft: 3 }}>{item}</h2>
                                )
                            }

                            {
                                final_selected && final_selected?.area_of_conseron?.map((item, index) =>
                                    <span onClick={(e) => { handel_remove_area_of_conseron(item) }} className='d-flex text-decoration-none gap-2 rounded-5 px-3 align-items-center fs-6 ' style={{ background: "#333131", color: "#fff", border: "1px solid #333131", cursor: "pointer", whiteSpace: "nowrap", marginRight: 3, display: '-ms-inline-flexbox', padding: 2, lineHeight: 2 }}> <img src='/assets/img/closeIcon.png' alt='Filter' style={{ height: 10, width: 10 }} /> &nbsp; {item}</span>
                                )
                            }
                            {
                                final_selected && final_selected?.experience &&
                                <span onClick={(e) => { handel_remove_experience(final_selected?.experience) }} className='d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6' style={{ background: "#333131", color: "#fff", border: "1px solid #333131", cursor: "pointer", whiteSpace: "nowrap", marginRight: 3, display: '-ms-inline-flexbox', padding: 2, lineHeight: 2 }}> <img src='/assets/img/closeIcon.png' alt='Filter' style={{ height: 10, width: 10 }} /> &nbsp; {final_selected?.experience}</span>
                            }
                            {
                                final_selected && final_selected?.price &&
                                <span onClick={(e) => { handel_remove_price(final_selected?.price) }} className=' d-flex text-decoration-none rounded-5 px-3 align-items-center fs-6' style={{ background: "#333131", color: "#fff", border: "1px solid #333131", cursor: "pointer", whiteSpace: "nowrap", marginRight: 3, display: '-ms-inline-flexbox', padding: 2, lineHeight: 2 }}> <img src='/assets/img/closeIcon.png' alt='Filter' style={{ height: 10, width: 10 }} /> &nbsp; {final_selected?.price?.Min} to {final_selected?.price?.Max}</span>
                            }
                        </div>
                    </div>
                    <Modal
                        show={filtershow}
                        onHide={() => setFiltershow(false)}
                        dialogClassName="left-modal"
                        aria-labelledby="example-custom-modal-styling-title"
                        className='m-0 col-8'
                    // style={{ height: "100vh" }}
                    >
                        {/* <ExpertFilterDrawer /> */}


                        <div>
                            <div className='m-0 ' style={{ height: "100vh" }}>
                                <Modal.Header className='justify-content-end  ' closeButton style={{ paddingTop: 70 }}>
                                    <Modal.Title className='font-inter fs-5 text-end ms-auto text-black' id="example-custom-modal-styling-title">
                                        Filter search results
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Tabs
                                        defaultActiveKey="area-of-concern"
                                        id="justify-tab-example"
                                        className="mb-3"
                                        justify
                                    >
                                        <Tab eventKey="area-of-concern" title="Area of Interest" className='filterTab'>

                                            <div style={{ scrollBehavior: 'smooth', overflowY: 'scroll', height: 480 }}>
                                                {subcatData && subcatData.map((item, index) =>
                                                    <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                        <input
                                                            id={`Area_of_concern${index}`}
                                                            value={JSON.stringify(item)}
                                                            type="checkbox"
                                                            checked={area_of_conseron_selected?.some(val => val === item)}
                                                            onChange={handleChange_Area_of_concern}
                                                            style={{ zoom: 1.3 }}
                                                        />&nbsp;&nbsp;
                                                        <label for={`Area_of_concern${index}`} className='fs-6'>{item}</label>
                                                    </div>
                                                )}
                                            </div>
                                        </Tab>
                                        <Tab eventKey="experience" title="Years of Experience" className='font-inter fs-5 font-w400'>
                                            <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                <input
                                                    id={`Year_1`}
                                                    name='Year'
                                                    value={'0-5 Year'}
                                                    type="checkbox"
                                                    checked={experience_selected === '0-5 Year'}
                                                    onChange={handleChange_Experience}
                                                    style={{ zoom: 1.3 }}
                                                />{' '}&nbsp;&nbsp;
                                                <label for={`Year_1`} className='fs-6'>{'0 - 5 Year'}</label>
                                            </div>
                                            <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                <input
                                                    id={`Year_2`}
                                                    name='Year'
                                                    value={'6-10 Year'}
                                                    type="checkbox"
                                                    checked={experience_selected === '6-10 Year'}
                                                    onChange={handleChange_Experience}
                                                    style={{ zoom: 1.3 }}
                                                />{' '}&nbsp;&nbsp;
                                                <label for={`Year_2`} className='fs-6'>{'6 - 10 Year'}</label>
                                            </div>
                                            <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                <input
                                                    id={`Year_3`}
                                                    name='Year'
                                                    value={'11-15 Year'}
                                                    type="checkbox"
                                                    checked={experience_selected === '11-15 Year'}
                                                    onChange={handleChange_Experience}
                                                    style={{ zoom: 1.3 }}
                                                />{' '}&nbsp;&nbsp;
                                                <label for={`Year_3`} className='fs-6'>{'11 - 15 Year'}</label>
                                            </div>
                                            <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                <input
                                                    id={`Year_4`}
                                                    name='Year'
                                                    value={'> Above 16 Year'}
                                                    type="checkbox"
                                                    checked={experience_selected === '> Above 16 Year'}
                                                    onChange={handleChange_Experience}
                                                    style={{ zoom: 1.3 }}
                                                />{' '}&nbsp;&nbsp;
                                                <label for={`Year_4`} className='fs-6'>{'> Above 16 Year'}</label>
                                            </div>
                                            {/* <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                <input
                                                    id={`Year_5`}
                                                    name='Year'
                                                    value={'> 5 Year'}
                                                    type="checkbox"
                                                    checked={experience_selected === '> 5 Year'}
                                                    onChange={handleChange_Experience}
                                                    style={{ zoom: 1.3 }}
                                                />{' '}&nbsp;&nbsp;
                                                <label for={`Year_5`} className='fs-6'>{'> Above 5 Year'}</label>
                                            </div> */}
                                        </Tab>
                                        {/* <Tab eventKey="price" title="Price" className='font-inter fs-6 font-w400'>
                                            {countryCode === 'IN' &&
                                                <>
                                                    <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                        <input
                                                            id={`Price_1`}
                                                            name='Price'
                                                            value={JSON.stringify({ 'Min': 0, 'Max': 500 })}
                                                            type="checkbox"
                                                            checked={price_selected?.Min === 0 && price_selected?.Max === 500}
                                                            onChange={handleChange_Price}
                                                            style={{ zoom: 1.3 }}
                                                        />{' '}&nbsp;&nbsp;
                                                        <label for={`Price_1`} className='fs-6'>{'0 to 500 INR'}</label>
                                                    </div>
                                                    <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                        <input
                                                            id={`Price_2`}
                                                            name='Price'
                                                            value={JSON.stringify({ 'Min': 501, 'Max': 1000 })}
                                                            type="checkbox"
                                                            checked={price_selected?.Min === 501 && price_selected?.Max === 1000}
                                                            onChange={handleChange_Price}
                                                            style={{ zoom: 1.3 }}
                                                        />{' '}&nbsp;&nbsp;
                                                        <label for={`Price_2`} className='fs-6'>{'501 to 1000 INR'}</label>
                                                    </div>
                                                    <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                        <input
                                                            id={`Price_3`}
                                                            name='Price'
                                                            value={JSON.stringify({ 'Min': 1001, 'Max': 1500 })}
                                                            type="checkbox"
                                                            checked={price_selected?.Min === 1001 && price_selected?.Max === 1500}
                                                            onChange={handleChange_Price}
                                                            style={{ zoom: 1.3 }}
                                                        />{' '}&nbsp;&nbsp;
                                                        <label for={`Price_3`} className='fs-6'>{'1001 to 1500 INR'}</label>
                                                    </div>
                                                    <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                        <input
                                                            id={`Price_4`}
                                                            name='Price'
                                                            value={JSON.stringify({ 'Min': 1501, 'Max': 2000 })}
                                                            type="checkbox"
                                                            checked={price_selected?.Min === 1501 && price_selected?.Max === 2000}
                                                            onChange={handleChange_Price}
                                                            style={{ zoom: 1.3 }}
                                                        />{' '}&nbsp;&nbsp;
                                                        <label for={`Price_4`} className='fs-6'>{'1501 to 2000 INR'}</label>
                                                    </div>
                                                    <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                        <input
                                                            id={`Price_5`}
                                                            name='Price'
                                                            value={JSON.stringify({ 'Min': 2001, 'Max': 5000 })}
                                                            type="checkbox"
                                                            checked={price_selected?.Min === 2001 && price_selected?.Max === 5000}
                                                            onChange={handleChange_Price}
                                                            style={{ zoom: 1.3 }}
                                                        />{' '}&nbsp;&nbsp;
                                                        <label for={`Price_5`} className='fs-6'>{'Above 2000 INR'}</label>
                                                    </div>
                                                </>

                                                ||

                                                <>
                                                    <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                        <input
                                                            id={`Price_1`}
                                                            name='Price'
                                                            value={JSON.stringify({ 'Min': 0, 'Max': 30 })}
                                                            type="checkbox"
                                                            checked={price_selected?.Min === 0 && price_selected?.Max === 30}
                                                            onChange={handleChange_Price}
                                                            style={{ zoom: 1.3 }}
                                                        />{' '}&nbsp;&nbsp;
                                                        <label for={`Price_1`} className='fs-6'>{'Upto 15 USD'}</label>
                                                    </div>
                                                    <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                        <input
                                                            id={`Price_2`}
                                                            name='Price'
                                                            value={JSON.stringify({ 'Min': 31, 'Max': 60 })}
                                                            type="checkbox"
                                                            checked={price_selected?.Min === 31 && price_selected?.Max === 60}
                                                            onChange={handleChange_Price}
                                                            style={{ zoom: 1.3 }}
                                                        />{' '}&nbsp;&nbsp;
                                                        <label for={`Price_2`} className='fs-6'>{'16 to 30 USD'}</label>
                                                    </div>
                                                    <div style={{ padding: 5, alignItems: 'center', marginLeft: 15, display: 'flex' }}>
                                                        <input
                                                            id={`Price_5`}
                                                            name='Price'
                                                            value={JSON.stringify({ 'Min': 61, 'Max': 500 })}
                                                            type="checkbox"
                                                            checked={price_selected?.Min === 61 && price_selected?.Max === 500}
                                                            onChange={handleChange_Price}
                                                            style={{ zoom: 1.3 }}
                                                        />{' '}&nbsp;&nbsp;
                                                        <label for={`Price_5`} className='fs-6'>{'Above 30 USD'}</label>
                                                    </div>
                                                </>

                                            }
                                        </Tab> */}
                                    </Tabs>
                                    <Modal.Footer className='d-flex justify-content-around' style={{ position: "fixed", bottom: "0", width: "85%", backgroundColor: '#fff', paddingBottom: 55 }}>
                                        {final_selected?.length === 0 &&
                                            <Button disabled style={{ cursor: 'pointer !important', background: "none" }} className='border-0 text-black font-inter fs-6'>
                                                Clear All
                                            </Button> ||
                                            <Button onClick={handel_Clear_Filter} style={{ cursor: 'pointer !important', background: "none" }} className='border-0 text-black font-inter fs-6'>
                                                Clear All
                                            </Button>
                                        }
                                        <Button disabled={!isFilterSelected} onClick={handel_Apply_Filter} className='rounded-5 border-0 text-black font-inter fs-6 px-4' style={{ background: '#C4E5F9', cursor: 'pointer !important' }}>
                                            Apply
                                        </Button>
                                    </Modal.Footer>
                                </Modal.Body>

                            </div>
                        </div>
                    </Modal>

                </div>
            </section>

            {/* <div className='position-fixed' style={{ right: 10, bottom: "20%" }}>
                <Link onClick={() => setSmShow(true)}>
                    <img src='/assets/img/Group 1000002404.png' alt='' className='col-8' />
                </Link>
            </div>

            <Modal
                size="sm"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
                className='position-relative'
            >
                <Modal.Header closeButton>
                    <img src='/assets/img/Ellipse 390.png' alt='' className='position-absolute' style={{top:-20, left:"40%"}} />
                </Modal.Header>
                <Modal.Body className='my-3'>
                    <p className='fs-5 text-center lh-lg' style={{ fontFamily: 'Helvetica' }}>👋 Hi, I am Stella.</p>
                    <p className='fs-6 text-center'>Your Mental Health and Wellness Coach</p>
                    <Button
                        onClick={onLetsChat}
                        className='rounded-5 d-block mx-auto mt-3'
                        style={{ background: "#fff", border: "1px solid #1B8BCF", color: "#1B8BCF" }}>
                        Let's Chat
                    </Button>
                </Modal.Body>
            </Modal> */}

            <div className="container my-3">
                <section className="mt-40 sec-pb-120">
                    <div className="">
                        <div className="row row-30-wrap gx-3 gx-lg-12 brand-row">
                            {
                                data?.map((item, index) =>
                                    <div key={index} className='col-lg-4 col-md-6 col-sm-6'>
                                        <ProfileCard key={index} item={item} filters={filters?.Profession == "Not Know"} />
                                    </div>)}
                            {
                                loading && !(filters?.Profession == "Not Know") && <center><img src="/assets/img/loading.gif" alt="Unitedwecare" style={{ height: 26, width: 26, margin: 17, cursor: "pointer" }} /></center>
                                ||
                                isLoadMore && !(filters?.Profession == "Not Know") && <center><button onClick={nextPage} className="ms-auto ms-xl-0 rounded-5 px-3 border-2 py-1" style={{ border: "2px solid #3DACF0CC" }}>Load More</button></center>
                            }
                        </div>
                        {

                            !loading && data?.length == 0 &&
                            <div>
                                <p className='font-inter fs-5 text-center'>No Expert Found</p>
                                <p className='font-inter fs-5 text-center'>Please Modify Your Search</p>
                            </div>
                        }
                    </div>
                </section>
            </div>

            <LocationModal
                show={locationModalShow}
                onHide={() => setLocationModalShow(false)}
            />
        </>
    );

}

