import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { SendRequestOTPService, LoginByOTPService, OODOGetCountryListV4Service, OODOGetTimezoneV4Service, RegistrationByOTPV4Service, OODOCreateUserV4Service } from './core/_request';
import { useAuth } from '../auth';

const SignUpDrawer = (props) => {
    const { login, setCurrentUser } = useAuth();
    const [step, setStep] = useState(props?.step ?? 1);
    const [validationMessage, setValidationMessage] = useState([])
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(props?.data);
    const navigate = useNavigate();
    const [countryList, setCountryList] = useState([]);
    const [timezoneList, setTimezoneList] = useState([]);

    //const{retrunurl}=useParams();
    const retrunurl = null;
    const params = new URLSearchParams(
        window ? window.location.search : {}
    );
    useEffect(() => {
        OODOGetCountryListV4Service().then(resp => setCountryList(resp.data.countries));

    }, []);

    const onCountryChange = (value) => {
        var item = countryList.find(o => o.id == value);
        if (item) {
            setData({ ...data, countryId: value, country: item.name })
            setLoading(true);
            var body = {
                "code": item.code
            }
            OODOGetTimezoneV4Service(body).then(resp => {
                //console.log("OODOGetTimezoneV4Service resp ", JSON.stringify(resp)); 
                setTimezoneList(resp.data.timezones); setLoading(false);
            });
            setLoading(false);
        }
        else {
            setTimezoneList([])
            setData({ ...data, timezoneId: null, timezoneName: null });
        }
    }

    const onTimezoneChange = (value) => {
        const item = timezoneList.find(o => o.id == value);
        if (item) {
            setData({ ...data, timezoneId: value, timezoneName: item.name });
        }
        else {
            setData({ ...data, timezoneId: null, timezoneName: null });
        }
    }

    const onLogin = () => {
        setValidationMessage([]);
        setLoading(true);
        switch (step) {
            case 1: {

                var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i
                if (!pattern.test(data.Email)) {
                    let newValidationMessage = Object.assign([], [...validationMessage.filter(o => o.key != "Email"), { key: "Email", message: 'not a valid e-mail address' }]);
                    setValidationMessage(newValidationMessage);
                    setLoading(false);
                    break;
                }

                var req = { "Email": data.Email }
                SendRequestOTPService(req).then(resp => {
                    setLoading(false);
                    //console.log("SendRequestOTPService resp", resp);
                    if (resp != null && resp.ResponseCode == 200) {
                        setStep(2);
                    }
                    else if (resp != null && resp.ResponseCode == 401) {
                        //console.log("ResponseCode 401");
                    }
                })

                break;
            }
            case 2: {
                var req = { "Email": data.Email, OTP: data?.OTP };
                LoginByOTPService(req).then(resp => {
                    //console.log("LoginByOTPService resp", resp);
                    if (resp != null && resp.ResponseCode == 200) {
                        if (resp.data.IsLogin) {
                            let UserType = resp.data.UserType;
                            let profile = resp.data.userdetails;
                            profile.UserType = UserType;
                            login(resp.data);
                            setCurrentUser(profile);
                            //localStorage.setItem("Authorization", resp.data.token_type + " " + resp.data.access_token);
                            //localStorage.setItem("profile", JSON.stringify(profile));
                            const retrunurl = params.get("retrunurl");
                            //console.log("retrunurl", retrunurl)
                            if (retrunurl) {
                                //window.location.href=retrunurl;
                                navigate(retrunurl)
                            }
                            else {
                                navigate("/")
                            }
                        }
                        else {
                            setLoading(false);
                            setStep(3);
                        }
                    }
                    else {
                        let newValidationMessage = Object.assign([], [...validationMessage.filter(o => o.key != "OTP"), { key: "OTP", message: resp.ResponseMessage }]);
                        setValidationMessage(newValidationMessage);
                        setLoading(false);
                    }
                })

                break;
            }
            case 3: {
                setLoading(false);
                setStep(4);
                break;
            }
            case 4: {
                setLoading(false);
                setStep(5);
                break;
            }
            case 5: {
                createRegistration();
                break;
            }
        }

    }

    const createRegistration = () => {


        setLoading(true);
        //const{SocialID, SocialType}=this.props.route.params??{};
        const SocialID = null;
        const SocialType = null;

        var MobileNo = "";
        if (data.country == "India") {
            MobileNo = "+91 0000000000";
        }
        else if (data.country == "Canada") {
            MobileNo = "+1 0000000000";
        }
        else {
            MobileNo = "+00 0000000000";
        }
        var name = ""
        if (data.firstName != null && data.firstName != "") {
            name = data.firstName + " " + data.lastName;
        }
        else {
            name = "user";
            setData({ data: { ...data, firstName: "user", lastName: " " } })
        }

        //SocialID:appleAuthRequestResponse.user, SocialType:"apple"
        var req = { "Emailid": data.Email, "Title": '`', "Name": name, MobileNo: MobileNo, ReferralCode: null, Referral_Code: data.referralCode, Country: data.country, UserType: 4, TimeZone: data.timezoneName, SocialID, SocialType }

        //var req={"Email":"demo555@tmail.com","OTP":"7777","firstName":"test","lastName":"testest","countryId":"37","country":"Belize","timezoneId":"America/Belize","timezoneName":"America/Belize","email":"demo555@tmail.com","userId":30493,"device_type":"web"};

        //console.log("RegistrationByOTPV4Service req", req);
        RegistrationByOTPV4Service(req).then((resp) => {

            if (resp != null && resp.ResponseCode == 200) {
                //console.log("RegistrationByOTPV4Service resp", resp);

                let UserType = resp.data.UserType;
                let profile = resp.data.userdetails;
                profile.UserType = UserType;

                if (resp.data.userdetails.Country == null && resp.data.userdetails.MobileNo.includes("+91")) {
                    profile.Country = "India";
                }
                else if (resp.data.userdetails.Country == null && resp.data.userdetails.MobileNo.includes("+1")) {
                    profile.Country = "Canada";
                }
                //this.setState({profile, data:{...data, email:profile.Emailid, userId:profile.UserId}});

                login(resp.data);
                setCurrentUser(profile);

                var oodoCreateUserData = { ...data, email: profile.Emailid, userId: profile.UserId, device_type: "web", timezone: data.timezoneName };



                //console.log("OODOCreateUserV4Service req", oodoCreateUserData);

                OODOCreateUserV4Service(oodoCreateUserData).then(resp2 => {
                    //console.log("OODOCreateUserV4Service resp", JSON.stringify(resp2));
                    //this.setState(prevState=>({createUserResp:resp2.data, profile:{...prevState.profile, showB2BWelcomeScreen:resp2.data.showB2BWelcomeScreen}}), ()=> {callback()}); 
                    //Auth.setAddAProfList(resp2.data.AddAProfList);
                    localStorage.setItem("disclaimer", resp2?.data?.disclaimer);

                    setLoading(false);
                    const retrunurl = params.get("retrunurl");

                    navigate(`/disclaimer${retrunurl ? `?retrunurl=${retrunurl}` : ""}`);
                });
            }
        });
    }

    // const onClickLogin = () => {
    //     setStep(1)
    // }

    return (
        <div className='d-flex align-items-center justify-content-center w-100'>
            {/* <div className='d-none d-md-block' style={{ backgroundImage: `url("/assets/img/signin/bannerwithbgLogo.png")`, backgroundSize: "100% 100%" }}>
                <img src='/assets/img/bannerWithBgLogo.png' alt='' /> 
            </div> */}
            <div className='d-flex flex-column align-items-center justify-content-center w-75'>
                <p className='fs-3 py-2 font-inter text-black font-w600'>Sign up</p>
                <div className='py-3'>
                    <p className='fs-6 py-1'>Already have an account</p>
                    <p className='fs-6 py-1'>
                        <Link
                            // to="/login" 
                            onClick={() => props.onSwithView("signIn")}
                            style={{
                                textDecoration: "none",
                                color: "#0d6efd",
                                backgroundClip: "text",
                            }}>
                            Login here!
                        </Link>
                    </p>
                </div>

                {(step == 1 || step == 2) &&
                    <>
                        <div className="py-3 w-100">
                            <div className="mb-3 py-1 mx-auto">
                                <label className="form-label" htmlFor="email">Email</label>
                                <input disabled={step == 2} placeholder="Enter your email address" type="email" id="email" className="form-control" name="email" onChange={(e) => setData({ ...data, Email: e.target.value })} />
                                <span className='error-message' style={{ color: "#dc3545" }}>{validationMessage.filter(o => o.key == "Email")[0]?.message}</span>
                            </div>
                            {
                                step == 2 &&
                                <>
                                    <div className="mb-3">
                                        <label className="form-label" htmlFor="OTP">OTP</label>
                                        <input disabled={loading} placeholder="Enter Enter OTP" type="text" id="OTP" className="form-control" name="otp"
                                            onChange={(e) => {
                                                setData({ ...data, OTP: e.target.value })
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                                                if (event.key != "Backspace" && !/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        <span className='error-message' style={{ color: "#dc3545" }}>{validationMessage.filter(o => o.key == "OTP")[0]?.message}</span>
                                    </div>
                                    <div className='d-flex py-3 gap-3 align-items-baseline'>
                                        <Form.Check
                                            type='checkbox'
                                            id='IAgree'
                                            onClick={() => setData({ ...data, iAgree: !data?.iAgree })}
                                        />
                                        <Form.Label className='fs-6 py-1' >I agree with all <a href="/term-and-condition" target='_blank' style={{
                                            textDecoration: "none",
                                            color: "#0d6efd",
                                            backgroundClip: "text",
                                        }}>Terms and Conditions</a> and <a href="/privacy-policy" target='_blank' style={{
                                            textDecoration: "none",
                                            color: "#0d6efd",
                                            backgroundClip: "text",
                                        }}>Privacy Policy</a> of United We Care</Form.Label>
                                    </div>
                                </>
                            }
                        </div>
                        <Button disabled={loading || (step == 2 && !data?.iAgree) || (step == 1 && !data?.Email) || (step == 2 && !data?.OTP)}
                            className='w-100 py-2 text-black font-inter px-3 border-0'
                            style={{ background: "#C4E5F9", height: "50px" }}
                            onClick={onLogin}>{loading ? "Loading..." : step == 1 ? "Continue Sign up" : "Sign up"}</Button>
                    </>
                    ||

                    step == 3 &&
                    <>
                        <div className="py-3 w-100">
                            <p className='fs-6 py-1'>What's Your Name?</p>
                            <div className="mb-3">
                                <input placeholder="First Name" type="text" id="firstName" className="form-control" name="firstName" onChange={(e) => setData({ ...data, firstName: e.target.value })} />
                            </div>
                            <div className="mb-3">
                                <input placeholder="Last Name" type="text" id="lastName" className="form-control" name="lastName" onChange={(e) => setData({ ...data, lastName: e.target.value })} />
                            </div>
                        </div>
                        <Button disabled={loading || !data?.firstName || !data?.lastName}
                            className='w-100 my-3 py-2 text-black font-inter px-3 border-0'
                            style={{ background: "#C4E5F9", height: "50px" }}
                            onClick={onLogin}>
                            {loading ? "Loading..." : "Sign up"}
                        </Button>
                    </>
                    ||
                    step == 4 &&
                    <>
                        <div className="py-3">
                            <p className='fs-6 py-1'>Please let us know your Country of Residence. This will help us to provide you services as per your Country</p>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="countryId">Country</label>
                                <select id="countryId" className='form-control' onChange={(e) => onCountryChange(e.target.value)}>
                                    <option>Select Country</option>
                                    {countryList.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="countryId">Country</label>
                                <select id="countryId" className='form-control' onChange={(e) => onTimezoneChange(e.target.value)}>
                                    <option>Select Timezone</option>
                                    {timezoneList.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <Button disabled={loading || !data?.countryId || !data?.timezoneId}
                            className='w-100 my-3 py-2 text-black font-inter px-3 border-0'
                            style={{ background: "#C4E5F9", height: "50px" }}
                            onClick={onLogin}>
                            {loading ? "Loading..." : "Continue Sign up"}
                        </Button>
                    </>
                    ||
                    step == 5 &&
                    <>
                        <div className="py-3">
                            <p className='fs-6 py-1'>Do you have a Referral code?</p>
                            <div className="mb-3">
                                <label className="form-label fs-6 py-1" htmlFor="referralCode">If yes, Please put your referral code above. If no, You can click <Link style={{
                                    textDecoration: "none",
                                    color: "#0d6efd",
                                    backgroundClip: "text",
                                }} onClick={onLogin}>SKIP</Link></label>
                                <input placeholder="Referral Code" type="text" id="referralCode" className="form-control" name="referralCode" onChange={(e) => setData({ ...data, referralCode: e.target.value })} />
                            </div>

                        </div>
                        <Button disabled={loading}
                            className='w-100 my-3 py-2 text-black font-inter px-3 border-0'
                            style={{ background: "#C4E5F9", height: "50px" }}
                            onClick={onLogin}>
                            {loading ? "Loading..." : step == 1 ? "Continue Sign up" : "Sign up"}
                        </Button>
                    </>
                }

                {/* <p className='fs-6 text-center'>or continue with</p>
                <div className='d-flex justify-content-center gap-3 py-3'>
                    <img src='/assets/img/signin/facebook.png' alt='' />
                    <img src='/assets/img/signin/apple.png' alt='' />
                    <img src='/assets/img/signin/google.png' alt='' />
                </div> */}
            </div>
        </div >
    )
}

export default SignUpDrawer