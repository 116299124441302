import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { downloadWorksheetData_WebService, getWorksheetData_WebService, uploadWorksheetData_WebService } from '../core/_request';
import { useAuth } from '../../auth';
import {storage} from "../../firebaseConfig"
import {ref, uploadBytesResumable, getDownloadURL} from 'firebase/storage';
import { getAuth, signInAnonymously  } from "firebase/auth";
import moment from 'moment';

const CourseWorksheet = (props) => {
    const { worksheetData } = props;
    const [data, setData] = useState();
    const[percent, setPercent]=useState();
    const [downloadData, setDownloadData] = useState();
    const [loading, setLoading] = useState();
    const { currentUser } = useAuth();
    

    useEffect(() => {
        const auth = getAuth();
        signInAnonymously(auth)
        .then(() => {
            // Signed in..
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            // ...
        });

        const body = {
            "userId": currentUser.UserId,
            "worksheetId": worksheetData.worksheet.id
        }
        getWorksheetData_WebService(body).then(resp => {
            // console.log("data", resp.data)
            setData(resp.data);
        });


        downloadWorksheetData_WebService(body).then(resp => {
            // console.log("data", resp.data)
            setDownloadData(resp.data);
        });
    }, [])

    const handleFileUpload = async (event) => {
        setLoading(true);

        var file=event.target.files[0];

        var dirName=moment().format("MMMM_YYYY");
        const storageRef = ref(storage, dirName + "/" + file.name)
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
            const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            // update progress
                setPercent(percent);
            },
            (err) => alert(err),
            () => {
            // download url
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
               

                var req = {"userId": currentUser.UserId,
                "worksheetId": worksheetData.worksheet.id,
                attachments:[
                    url
                ],
                "notes": "This is a sample note"
                }
                uploadWorksheetData_WebService(req);
                alert("Thanks");

                setLoading(false);
            });
        });
    }

    // useEffect(() => {
    //     const body = {
    //         "userId": currentUser.UserID,
    //         "worksheetId": worksheetData.Id
    //     }
    //     uploadWorksheetData_WebService(body).then(resp => {
    //         console.log("data", resp.data)
    //         setUploadData(resp.data);
    //     });
    // }, [])

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center py-3'>
                <div className=' d-flex align-items-center gap-5'>
                    <div>
                        <h6>Steps to fill the Worksheet:</h6>
                    </div>
                    <div>
                        <p>1. Download and send.</p>
                        <p>2. Print and fill.</p>
                        <p>3. Upload the worksheet.</p>
                    </div>
                </div>
                <div className='d-flex align-items-center gap-3'>
                    <Button variant="outline-warning" >Share</Button>
                    <Button variant="outline-warning"><a href={downloadData?.link} target='_blank' rel="noreferrer" className='text-decoration-none'>Download</a></Button>

                    <div className='d-flex align-items-center'>
                        {
                            loading && <span>Loading....</span> ||
                        <input type='file' onChange={handleFileUpload}  />
                        }
                    </div>
                </div>
            </div>
            <hr />
            {data && data.images && data.images.map((item) => (
                <div className='col-md-4'>
                    <img src={item} alt='' />
                    {/* <p>1. Find the path of the line.</p>
                    <img src='/assets/img/Group 5266.png' alt='' />
                    <p>Time taken to complete the task:<br />
                        The number of errors made:
                    </p>
                    <hr />
                    <p>2. Find the number in the circle & encircle the same number on this sheet
                        .Time given to complete the task- 45 seconds to 1 min
                    </p>
                    <img src='/assets/img/Group 5337.png' alt='' />
                    <hr />
                    <p>3. Find the number written above the line & encircle the same number on the sheet
                        . Time given to complete the task- 45 seconds to 1 min
                    </p>
                    <img src='/assets/img/Frame 6042.png' alt='' /> */}
                </div>
            ))
            }
        </div>
    )
}

export default CourseWorksheet