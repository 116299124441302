import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

//ICONS
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

const Checked = () => {
  return (
    <div className="CheckedIcon">
      <CheckRoundedIcon />
    </div>
  );
};

const ClientPHQTable = ({ options, responses, totalScore, score }) => {
  //console.log("responses", responses)
  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}>
        <TableRow>
          <TableCell sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}>
            Questionnaire
          </TableCell>

          {
            options?.map(item => (
              <TableCell
                key={item?.sequence}
                align="center"
                sx={{ borderRight: "1px solid rgba(224, 224, 224, 1)" }}
              >
                {item?.name}
              </TableCell>
            ))
          }


          {/* <TableCell align="center">Nearly every day</TableCell> */}

        </TableRow>
      </TableHead>
      <TableBody>

        {
          responses?.map((res, i) => (
            <TableRow key={i}>
              <TableCell component="th" scope="row">
                {i + 1}. {res?.question}
              </TableCell>
              {options?.map((op) => (
                <TableCell align="center" key={op.sequence}>
                  {+res?.response === +op?.sequence && <Checked />}
                </TableCell>
              ))}
            </TableRow>
          ))
        }

        <TableRow
          sx={{
            "&:last-child td, &:last-child th": { border: "0 !important" },
            marginTop: "10px",
          }}
        >
          <TableCell component="th" scope="row" align="right" sx={{ background: "#FFF" }}>
            <b>Total Score</b>
          </TableCell>
          {options?.map((op, index) => (
            <TableCell align="center" sx={{ background: "#F3F4F5" }} key={op.sequence}>
              {index === Math.floor(options.length / 2) ? `${score}/${totalScore}` : null}
            </TableCell>
          ))}

        </TableRow>
      </TableBody>
    </Table>
  );
};

export default ClientPHQTable;
