import React, {useEffect} from 'react'
import ComingSoon from './component/ComingSoon'

//Helpers
import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from 'react-router-dom';

const ExpertCommunitiesDB = () => {

  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if user is logged in
  useEffect(() => {
   
    const isLoggedIn = isUserLoggedIn();

    if (!isLoggedIn) {
      // Redirect to "/login"
      //navigate('/login');
      window.location.href = "https://www.unitedwecare.com/";
    }
  }, [location, navigate]);
  
  return (
    <ComingSoon />
  )
}

export default ExpertCommunitiesDB