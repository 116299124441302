import React from 'react'
import "./PlanDetailsPage.css"
import PlanDetailsLanding from '../components/planDetails/PlanDetailsLanding'
import PlanDetailsStats from '../components/planDetails/PlanDetailsStats'
import PlanDetailsReviewSlide from '../components/planDetails/PlanDetailsReviewSlide'
import ThreeImgBackground from '../components/planDetails/ThreeImgBackground'
import { WhyTrustUs } from '../components/planDetails/WhyTrustUs'
import WhyPlan from '../components/planDetails/WhyPlan'
import { Helmet } from 'react-helmet'

const PlanDetailsPage = ({ type }) => {
    //console.log("TYPE", type)
    return (
        <div>
            <Helmet>
                <script src="//code.tidio.co/y84keavfozv8pkn1ar3c4h0cofpeq7le.js" async></script>
            </Helmet>
            {/* Header */}
            <PlanDetailsLanding type={type} />

            {/* Stats */}
            <PlanDetailsStats />

            {/* Carousel Section */}
            <WhyPlan type={type} />

            {/* Trust */}
            <WhyTrustUs type={type} />

            {/* Affordable Healing */}
            <ThreeImgBackground type={type} />

            {/* Reviews */}
            <PlanDetailsReviewSlide />
        </div>
    )
}

export default PlanDetailsPage