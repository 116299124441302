import React, { useState } from "react";
import {
    Button,
    ButtonGroup,
    CloseButton,
    Col,
    Form,
    InputGroup,
    Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./RegisterAsProfessional.css";
import { useEffect } from "react";
import {
    OODOGetCountryListV4Service,
    OODOGetGender_V4Service,
    OODOGetId_Proofs_V4Service,
    OODOGetState_ListV4Service,
    OODOGetcall_code_ListV4Service,
    OODOGetTimezoneV4Service,
    usOnBoardingExpertProfileDetail,
    usOnBoardingSendRequestOTPService,
    usOnBoardingVerifyRequestOTPService,
    usOnBoarding_GetInfoRegisteredEmail,
    usOnBoarding_registrationStatus,
    usOnBoarding_GetState,
    usOnBoarding_GetSTDCode,
    usOnBoarding_GetTimeZone,
    usOnBoarding_GetCountry,
} from "../core/_request";
import { Box, Divider, Grid, MenuItem, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import { AccountCircle, Email } from "@mui/icons-material";
import { storage } from "../../firebaseConfig";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getAuth, signInAnonymously } from "firebase/auth";
import moment from "moment";
import { green } from "@mui/material/colors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';

const RegisterAsProfessionalProfileDetailTab = (props) => {
    const [modalShow, setModalShow] = React.useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState();
    const [percent, setPercent] = useState();
    const [downloadData, setDownloadData] = useState();
    const [idProofPercent, setIdProofPercent] = useState();
    const [idProofLoading, setIdProofLoading] = useState();
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [call_codeList, setCall_CodeList] = useState([]);
    const [genderList, setGenderList] = useState([]);
    const [id_ProofsList, setId_ProofsList] = useState([]);
    const [timezoneList, setTimezoneList] = useState([]);
    const [validationMessage, setValidationMessage] = useState([]);
    const [email_verified, setEmail_verified] = useState(false);
    const [validated, setValidated] = useState(false);
    const [isNext, setIsNext] = useState(false);
    const [registeredData, setRegisteredData] = useState(false);
    const [statusData, setStatusData] = useState({});
    const handleClose = () => setModalShow(false);
    const [UserId, setUserId] = useState(0);
    const [value, setValue] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [counter, setCounter] = useState(0);
    const count_time = process.env.REACT_APP_OTP_TIME;
    const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z ]+$/;
    const ALPHANUMERIC_DASH_REGEX = /^[0-9a-zA-Z. ]*$/;
    const ALPHANUMERICSomeSpecial_DASH_REGEX = /^[0-9a-zA-Z. /\&*-,]*$/;
    const navigate = useNavigate();

    const state = {
        button: "saveNext",
    };

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);

    useEffect(() => {
        usOnBoarding_GetCountry().then((resp) =>
            setCountryList(resp.data.countries)
        );
        OODOGetGender_V4Service().then((resp) => setGenderList(resp.data.genders));
        OODOGetId_Proofs_V4Service().then((resp) =>
            setId_ProofsList(resp.data.id_proofs)
        );
    }, []);

    const onEmailChange = (value) => {
        setData({ ...data, Email: value });
        setValidationMessage([]);
        var pattern = /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.['com', 'in', co.in]$/;
        if (!pattern.test(data.Email)) {
            let newValidationMessage = Object.assign(
                [],
                [
                    ...validationMessage.filter((o) => o.key != "Email"),
                    { key: "Email", message: "not a valid e-mail address" },
                ]
            );
            setValidationMessage(newValidationMessage);
        }
    };

    const onCountryChange = (value) => {
        var item = countryList.find((o) => o.id == value);
        if (item) {
            setData({ ...data, countryId: value, country: item.name });

            usOnBoarding_GetState(item?.code).then((resp) => {
                setStateList(resp?.data?.states);
            });

            usOnBoarding_GetSTDCode(item?.code).then((resp) => {
                setCall_CodeList(resp?.data);
            });

            var body = {
                code: item?.code,
            };
            usOnBoarding_GetTimeZone(body).then((resp) => {
                //console.log("OODOGetTimezoneV4Service resp ", JSON.stringify(resp));
                setTimezoneList(resp.data.timezones);
            });
        } else {
            setTimezoneList([]);
            setData({ ...data, timezoneId: null, timezoneName: null });
        }
    };

    const onStateChange = (value) => {
        var item = stateList.find((o) => o.id == value);
        if (item) {
            setData({ ...data, stateId: value, state: item.name });
        } else {
            setData({ ...data, timezoneId: null, timezoneName: null });
        }
    };

    const onTimezoneChange = (value) => {
        const item = timezoneList.find((o) => o.id == value);
        if (item) {
            setData({ ...data, timezoneId: value, timezoneName: item.name });
        } else {
            setData({ ...data, timezoneId: null, timezoneName: null });
        }
    };

    const openOtpPopup = () => {
        if (data && data.Email) {
            var pattern = /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i;
            if (!pattern.test(data.Email)) {
                let newValidationMessage = Object.assign(
                    [],
                    [
                        ...validationMessage?.filter((o) => o.key != "Email"),
                        { key: "Email", message: "not a valid e-mail address" },
                    ]
                );
                setValidationMessage(newValidationMessage);
                return;
            }
            setCounter(count_time);
            setOtpSent(true);
            var req = { email: data.Email };
            usOnBoardingSendRequestOTPService(req).then((resp) => {
                //console.log("SendRequestOTPService resp", resp);
                if (resp != null && resp.ResponseCode == 200) {
                    setOtpSent(true);
                } else if (resp != null && resp.ResponseCode == 401) {
                    //console.log("ResponseCode 401");
                }
            });

            setModalShow(true);
        } else {
            let newValidationMessage = Object.assign(
                [],
                [
                    ...validationMessage?.filter((o) => o.key != "Email"),
                    { key: "Email", message: "email id required" },
                ]
            );
            setValidationMessage(newValidationMessage);
            //alert("email id required")
        }
    };

    const onClickSubmitOTP = () => {
        if (data?.OTP1 === undefined || data?.OTP1 === null || data?.OTP1 === "") {
            toast("enter valid otp", {
                toastId: 2
            });
            return false;
        } else if (
            data?.OTP2 === undefined ||
            data?.OTP2 === null ||
            data?.OTP2 === ""
        ) {
            toast("enter valid otp", {
                toastId: 3
            });
            return false;
        } else if (
            data?.OTP3 === undefined ||
            data?.OTP3 === null ||
            data?.OTP3 === ""
        ) {
            toast("enter valid otp", {
                toastId: 4
            });
            return false;
        } else if (
            data?.OTP4 === undefined ||
            data?.OTP4 === null ||
            data?.OTP4 === ""
        ) {
            toast("enter valid otp", {
                toastId: 5
            });
            return false;
        } else {
            var req = {
                email: data.Email,
                otp: data?.OTP1 + data?.OTP2 + data?.OTP3 + data?.OTP4,
            };

            usOnBoardingVerifyRequestOTPService(req).then((resp) => {
                if (resp != null && resp.code == 200) {
                    setEmail_verified(true);
                    setData({ ...data, email_verified: true });
                    setUserId(resp?.data?.id);
                    localStorage.setItem("Email_Verified_id", resp?.data?.id);

                    var body = { user_id: UserId, email: data.Email, screen: null };
                    usOnBoarding_GetInfoRegisteredEmail(body).then((resp) => {
                        if (resp.code == 200 && resp.data.profile.email_verified) {
                            setRegisteredData(true);
                            setUserId(resp?.data?.id);
                            localStorage.setItem("User_Registered_Info", JSON.stringify(resp?.data));
                            setData({
                                ...data,
                                user_id: resp?.data?.id,
                                first_name: resp?.data?.profile?.first_name,
                                middle_name: resp?.data?.profile?.middle_name,
                                last_name: resp?.data?.profile?.last_name,
                                gender: resp?.data?.profile?.gender_id,
                                image_link: resp?.data?.profile?.image_link,
                                Email: resp?.data?.profile?.email,
                                email_verified: resp?.data?.profile?.email_verified,
                                id_proof_type_id: resp?.data?.profile?.id_proof_type_id,
                                id_proof_link: resp?.data?.profile?.id_proof_link,
                                //dob: moment(resp?.data?.profile?.dob, "YYYY-MM-DD"),
                                dob: resp?.data?.profile?.dob,
                                address_details_address: resp?.data?.profile?.address,
                                address_details_landmark: resp?.data?.profile?.landmark,
                                countryId: resp?.data?.profile?.country_id,
                                stateId: resp?.data?.profile?.state_id,
                                address_details_City: resp?.data?.profile?.city,
                                address_details_time_zone: resp?.data?.profile?.timezone,
                                address_details_zip_code: resp?.data?.profile?.zip,
                                country_phone_code: resp?.data?.profile?.country_phone_code,
                                address_details_mobile_number: resp?.data?.profile?.mobile,
                            });
                            toast("Email id already exist", {
                                toastId: 1
                            });

                            var body4 = { "email": data.Email }
                            usOnBoarding_registrationStatus(body4).then((resp) => {
                                setStatusData(resp.data)
                                if (resp.data.profile === true && resp.data.professional === true && resp.data.scheduling === true && resp.data.payment === true && resp.data.payment === true) {
                                    navigate('/expert/dashboard')
                                }
                                else if (resp.data.profile === true && resp.data.professional === true && resp.data.scheduling === true && resp.data.payment === true) {
                                    props.onClick('thankYou');
                                }
                                else if (resp.data.profile === true && resp.data.professional === true && resp.data.scheduling === true) {
                                    props.onClick('payment');
                                }
                                else if (resp.data.profile === true && resp.data.professional === true) {
                                    props.onClick('scheduling');
                                }
                                else if (resp.data.profile === true) {
                                    props.onClick('professionalDetail');
                                }
                                else {
                                    props.onClick('profile');
                                }
                            })


                        }
                    })



                } else {
                    let newValidationMessage = Object.assign(
                        [],
                        [
                            ...validationMessage.filter((o) => o.key != "OTP"),
                            { key: "OTP", message: resp.ResponseMessage },
                        ]
                    );
                    setValidationMessage(newValidationMessage);
                }
                setModalShow(false);
            });
        }
    };

    const onClickNext = () => {
        ValidateForm("saveNext");
    };

    const onClickSave = () => {
        ValidateForm("save");
    };

    function ValidateForm(type) {
        if (
            data.first_name === undefined ||
            data.first_name === null ||
            data.first_name === ""
        ) {
            toast("Required first name", {
                toastId: 6
            });
            return false;
        } else if (
            data.last_name === undefined ||
            data.last_name === null ||
            data.last_name === ""
        ) {
            toast("Required last name", {
                toastId: 7
            });
            return false;
        } else if (
            data.gender === undefined ||
            data.gender === null ||
            data.gender === ""
        ) {
            toast("Required gender", {
                toastId: 8
            });
            return false;
        } else if (
            data.Email === undefined ||
            data.Email === null ||
            data.Email === ""
        ) {
            toast("Required email id", {
                toastId: 9
            });
            return false;
        } else if (
            data.email_verified === undefined ||
            data.email_verified === null ||
            data.email_verified === false
        ) {
            toast("Required email verification", {
                toastId: 10
            });
            return false;
        } else if (
            data.id_proof_type_id === undefined ||
            data.id_proof_type_id === null ||
            data.id_proof_type_id === ""
        ) {
            toast("Required id proof", {
                toastId: 11
            });
            return false;
        } else if (
            data.id_proof_type_id === undefined ||
            data.id_proof_type_id === null ||
            data.id_proof_type_id === ""
        ) {
            toast("Required id proof", {
                toastId: 12
            });
            return false;
        } else if (
            data.id_proof_link === undefined ||
            data.id_proof_link === null ||
            data.id_proof_link === ""
        ) {
            toast("Required id proof document", {
                toastId: 13
            });
            return false;
        } else if (data.dob === undefined || data.dob === null || data.dob === "") {
            toast("Required date of birth", {
                toastId: 14
            });
            return false;
        } else if (
            data.address_details_address === undefined ||
            data.address_details_address === null ||
            data.address_details_address === ""
        ) {
            toast("Required address", {
                toastId: 15
            });
            return false;
        } else if (
            data.countryId === undefined ||
            data.countryId === null ||
            data.countryId === ""
        ) {
            toast("Required country", {
                toastId: 16
            });
            return false;
        } else if (
            data.stateId === undefined ||
            data.stateId === null ||
            data.stateId === ""
        ) {
            toast("Required state", {
                toastId: 17
            });
            return false;
        } else if (
            data.address_details_City === undefined ||
            data.address_details_City === null ||
            data.address_details_City === ""
        ) {
            toast("Required city", {
                toastId: 18
            });
            return false;
        } else if (
            data.address_details_time_zone === undefined ||
            data.address_details_time_zone === null ||
            data.address_details_time_zone === ""
        ) {
            toast("Required time zone", {
                toastId: 19
            });
            return false;
        } else if (
            data.address_details_zip_code === undefined ||
            data.address_details_zip_code === null ||
            data.address_details_zip_code === ""
        ) {
            toast("Required zip code", {
                toastId: 20
            });
            return false;
        } else if (
            data.address_details_zip_code.length < 5 ||
            data.address_details_zip_code.length > 9
        ) {
            toast("Enter valid zip code", {
                toastId: 25
            });
            return false;
        } else if (
            data.address_details_mobile_number === undefined ||
            data.address_details_mobile_number === null ||
            data.address_details_mobile_number === ""
        ) {
            toast("Required mobile number", {
                toastId: 21
            });
            return false;
        } else if (
            data.address_details_mobile_number.length !== 10
        ) {
            toast("Enter valid mobile number", {
                toastId: 22
            });
            return false;
        } else {
            Save_Profile(type);
        }
    }

    const Save_Profile = (type) => {
        var body = {
            user_id: UserId,
            first_name: data?.first_name,
            middle_name: data?.middle_name,
            last_name: data?.last_name,
            gender_id: data?.gender,
            // "image_link": "https://wuc.firebase.com/abc",
            email: data?.Email,
            email_verified: email_verified,
            id_proof_type_id: data?.id_proof_type_id,
            id_proof_link: data?.id_proof_link,
            dob: data?.dob,
            address: data?.address_details_address,
            landmark: data?.address_details_landmark,
            country_id: data?.countryId,
            state_id: data?.stateId,
            city: data?.address_details_City,
            timezone: data?.address_details_time_zone,
            zip: data?.address_details_zip_code,
            country_phone_code: call_codeList?.phone_code ?? "+1",
            mobile: data?.address_details_mobile_number,
        };
        usOnBoardingExpertProfileDetail(body).then((resp) => {
            if (resp?.code === 200) {
                toast("Professional Details Saved Successfully", {
                    toastId: 22
                });
                localStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
                if (type === "saveNext") {
                    props.onNext();
                }
            } else {
                toast(resp?.data?.error ?? resp?.message, {
                    toastId: 23
                });
            }
        });
    };

    useEffect(() => {
        const auth = getAuth();
        signInAnonymously(auth)
            .then(() => {
                // Signed in..
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ...
            });
    });

    const handleFileUpload = async (event) => {
        setLoading(true);

        if (event.target.files[0].size > 5e6) {
            toast("File Size Exceeds", {
                toastId: 24
            });
            setLoading(false);
            return false;
        } else {
            var file = event.target.files[0];
            if (file?.type === "image/png" || file?.type === "image/jpg" || file?.type === "image/jpeg" || file?.type === "application/pdf") {
                var dirName = moment().format("MMMM_YYYY");
                const storageRef = ref(storage, dirName + "/" + file.name);
                const uploadTask = uploadBytesResumable(storageRef, file);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const percent = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                        // update progress
                        setIdProofPercent(percent);
                    },
                    (err) => toast(err, {
                        toastId: 26
                    }),
                    () => {
                        // download url
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            // console.log("file path url ", url);
                            toast("File uploaded Successfully.", {
                                toastId: 27
                            });
                            setData({ ...data, id_proof_link: url });
                            setIdProofLoading(false);
                        });
                    }
                );
            }
            else {
                toast("Incorrect  file format.", {
                    toastId: 28
                })
            }
        }
    };

    const inputfocus = (elmnt) => {
        if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
            const next = elmnt.target.tabIndex - 2;
            if (next > -1) {
                elmnt.target.form.elements[next].focus();
            }
        } else {
            const next = elmnt.target.tabIndex;
            if (next < 5) {
                elmnt.target?.form?.elements[next]?.focus();
            }
        }
    };

    return (
        <div>
            <div
                className="d-flex align-items-center gap-3 py-3 rounded-top px-3"
                style={{ background: "#C4E5F9" }}
            >
                <img src="/assets/img/Vector(21).png" alt="" />
                <p
                    className="font-inter font-w500 text-black"
                    style={{ color: "#21231E", fontSize: "24px" }}
                >
                    Profile Details
                </p>
            </div>
            <div>
                {/* <Form
                    validated={validated}
                    onSubmit={onClickProfileData}> */}
                <div className="mx-2 my-2">
                    <p
                        className="font-inter font-w600 fs-6 px-3"
                        style={{ color: "#21231E" }}
                    >
                        Personal Details
                    </p>
                    <div className="d-flex gap-2 mx-2 my-2 ">
                        <TextField
                            required
                            type="text"
                            inputProps={{ maxLength: 20 }}
                            id="outlined-required"
                            label="First Name"
                            tabIndex="1"
                            InputLabelProps={registeredData && { shrink: true }}
                            value={data?.first_name}
                            onChange={(e) => setData({ ...data, first_name: e.target.value })}
                            className="w-75"
                            onKeyDown={(event) => {
                                if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />

                        <TextField
                            id="outlined"
                            label="Middle Name (Optional)"
                            tabIndex="2"
                            InputLabelProps={registeredData && { shrink: true }}
                            value={data?.middle_name}
                            onChange={(e) =>
                                setData({ ...data, middle_name: e.target.value })
                            }
                            className="w-75"
                            inputProps={{ maxLength: 20 }}
                            onKeyDown={(event) => {
                                if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />

                        <TextField
                            required
                            id="outlined"
                            label="Last Name"
                            tabIndex="3"
                            InputLabelProps={registeredData && { shrink: true }}
                            value={data?.last_name}
                            onChange={(e) => setData({ ...data, last_name: e.target.value })}
                            className="w-75"
                            inputProps={{ maxLength: 20 }}
                            onKeyDown={(event) => {
                                if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </div>
                </div>
                {/* <div className="col-4 rounded-2 mx-3 my-3" style={{ display: 'flex', alignItems: 'center', justifyContent:"space-between", border: "1px solid rgb(196, 196, 196)", height:"56px" }}>
                    <div className="col-2 h-100 d-flex align-items-center justify-content-center" style={{ background: "#1C1B1F1F" }}>
                        <UploadFileRoundedIcon className="" sx={{ color: "#21231E" }} />
                    </div>
                    <div className="col-8 px-4 d-flex align-items-center justify-content-center">
                            Upload
                    </div>
                    <button className="col-2 border-0" style={{background:"none"}}>
                        <AddIcon />
                    </button>
                </div> */}
                {/* <TextField
                        id="outlined"
                        label="Upload"
                        onChange={(e) => setData({ ...data, middle_name: e.target.value })}
                        className='w-75'
                        InputProps={{
                            // startAdornment: <UploadFileRoundedIcon />,
                            endAdornment: <AddIcon />
                        }}
                    // InputLabelProps={{shrink:false}}
                    /> */}
                <div className="d-flex gap-2 mx-3 my-3">
                    {/* <TextField
            required
            id="outlined-select-currency"
            select
            label="Gender"
            tabIndex="4"
            InputLabelProps={registeredData && { shrink: true }}
            value={data?.gender}
            className="col-4"
            onChange={(e) => setData({ ...data, gender: e.target.value })}
            placeholder="Gender"
            // SelectProps={}
          >
            {genderList?.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField> */}

                    <select
                        required
                        id="outlined-select-Gender"
                        label="Gender"
                        InputLabelProps={registeredData && { shrink: true }}
                        value={data?.gender}
                        className="col-4 select-option px-1"
                        onChange={(e) => setData({ ...data, gender: e.target.value })}
                    >
                        <option>Select Gender *</option>
                        {genderList?.map((item, index) => (
                            <option
                                key={index}
                                selected={item.id === data?.gender}
                                value={item.id}
                            >
                                {item.name}
                            </option>
                        ))}
                    </select>
                    {/* <TextField
                        required
                        id="outlined-select-Gender"
                        select
                        label="Gender"
                        className='col-4'
                        onChange={(e) => setData({ ...data, gender: e.target.value })}
                        // value={data?.gender}
                        defaultValue={data?.gender}
                    >
                        {genderList.map((item, index) => <MenuItem key={index} value={item.id}>{item.name}</MenuItem>)}
                    </TextField> */}
                    <TextField
                        required
                        id="outlined"
                        label="Email"
                        type="email"
                        tabIndex="5"
                        InputLabelProps={registeredData && { shrink: true }}
                        value={data?.Email}
                        onChange={(e) => onEmailChange(e.target.value)}
                        className="col-4"
                        helperText={
                            <Link
                                onClick={() => openOtpPopup()}
                                className="font-inter font-w500"
                                style={{
                                    color: "#6A6C6A",
                                    fontSize: "14px",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                Send One Time Password to verify your Email ID
                            </Link>
                        }
                    />
                    <span className="error-message" style={{ color: "#dc3545" }}>
                        {validationMessage.filter((o) => o.key == "Email")[0]?.message}
                    </span>
                    {email_verified && (
                        <span className="d-flex align-items-center justify-content-center gap-3">
                            <img src="/assets/img/Vector(29).png" alt="" />
                            <p className="font-inter" style={{ color: "#0E0F0C" }}>
                                Verified
                            </p>
                        </span>
                    )}
                    <Modal
                        show={modalShow}
                        size="md"
                        aria-labelledby=""
                        centered
                        onHide={handleClose}
                        className="professional-otp-popUp"
                    >
                        <Modal.Body className="">
                            <div className="mb-3 d-flex flex-column align-items-center justify-content-around gap-3 py-3">
                                <label
                                    className="font-inter text-center lh-sm font-w600"
                                    style={{ color: "#21231E", fontSize: "16px" }}
                                >
                                    <p className="font-inter text-black fs-4 font-w600 lh-lg">
                                        OTP Verification
                                    </p>
                                    OTP (One Time Password) has been sent to email id{" "}
                                    {data?.Email}
                                </label>
                                <label
                                    className="font-inter fs-6 px-4 text-center lh-base my-3"
                                    style={{ color: "#21231E" }}
                                >
                                    Please verify your email id with the one-time passcode shared
                                    on your email id {data?.Email}
                                </label>
                                <form className="d-flex align-items-center justify-content-center gap-4 col-12">
                                    <input
                                        type="text"
                                        id="OTP"
                                        className="form-control text-center"
                                        style={{ height: "50px", width: "50px" }}
                                        name="otp"
                                        maxLength={1}
                                        onKeyDown={(event) => {
                                            // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                                            if (
                                                event.key != "Backspace" &&
                                                !/[0-9]/.test(event.key)
                                            ) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => setData({ ...data, OTP1: e.target.value })}
                                        autoComplete="off"
                                        tabIndex="1"
                                        onKeyUp={(e) => inputfocus(e)}
                                    />
                                    <input
                                        type="text"
                                        id="OTP"
                                        className="form-control text-center"
                                        style={{ height: "50px", width: "50px" }}
                                        name="otp"
                                        maxLength={1}
                                        onKeyDown={(event) => {
                                            // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                                            if (
                                                event.key != "Backspace" &&
                                                !/[0-9]/.test(event.key)
                                            ) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => setData({ ...data, OTP2: e.target.value })}
                                        autoComplete="off"
                                        tabIndex="2"
                                        onKeyUp={(e) => inputfocus(e)}
                                    />
                                    <input
                                        type="text"
                                        id="OTP"
                                        className="form-control text-center"
                                        style={{ height: "50px", width: "50px" }}
                                        name="otp"
                                        maxLength={1}
                                        onKeyDown={(event) => {
                                            // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                                            if (
                                                event.key != "Backspace" &&
                                                !/[0-9]/.test(event.key)
                                            ) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => setData({ ...data, OTP3: e.target.value })}
                                        autoComplete="off"
                                        tabIndex="3"
                                        onKeyUp={(e) => inputfocus(e)}
                                    />
                                    <input
                                        type="text"
                                        id="OTP"
                                        className="form-control text-center"
                                        style={{ height: "50px", width: "50px" }}
                                        name="otp"
                                        maxLength={1}
                                        onKeyDown={(event) => {
                                            // if (event.key != "Backspace" && data?.OTP?.length == 4) event.preventDefault();
                                            if (
                                                event.key != "Backspace" &&
                                                !/[0-9]/.test(event.key)
                                            ) {
                                                event.preventDefault();
                                            }
                                        }}
                                        onChange={(e) => setData({ ...data, OTP4: e.target.value })}
                                        autoComplete="off"
                                        tabIndex="4"
                                        onKeyUp={(e) => inputfocus(e)}
                                    />
                                </form>
                                <span className="error-message" style={{ color: "#dc3545" }}>
                                    {" "}
                                </span>
                                <div className="d-flex align-items-center justify-content-between col-10 mx-auto">
                                    <p
                                        className="font-inter font-w600"
                                        style={{
                                            whiteSpace: "nowrap",
                                            color: "#153300",
                                            fontSize: "16px",
                                        }}
                                    >
                                        Didn’t recieve code? <br />
                                        {(counter === 0 && otpSent && (
                                            <Link
                                                onClick={() => openOtpPopup()}
                                                className="text-decoration-none"
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    color: "blue",
                                                    fontSize: "14px",
                                                }}
                                            >
                                                Resend Code
                                            </Link>
                                        )) || (
                                                <span
                                                    className=""
                                                    style={{
                                                        whiteSpace: "nowrap",
                                                        color: "#6A6C6A",
                                                        fontSize: "14px",
                                                    }}
                                                >
                                                    Next OTP in {counter} seconds
                                                </span>
                                            )}
                                    </p>
                                    {/* {(counter === 0 && otpSent && (
                                            <div onClick={resendOTP} style={{ cursor: 'pointer' }}>
                                                <p className='font-inter fs-6 text-black text-center' >Resend Code</p>
                                            </div>
                                        ))
                                            ||
                                            <div className='font-inter fs-6 text-black text-center border-0 bg-white' style={{ color: 'red !important' }}>Resend OTP {counter}</div>
                                        } */}
                                    <Button
                                        id="btn_Final_Submit"
                                        className="text-black font-inter px-4 border-0 rounded-3"
                                        style={{ background: "#9FE770", height: "50px" }}
                                        onClick={() => onClickSubmitOTP()}
                                    >
                                        Confirm
                                    </Button>
                                </div>
                                {/* <Link><p className='font-inter fs-6 text-black'>Resend Code</p></Link> */}
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>

                <div className="px-2 my-2">
                    <p
                        className="font-inter font-w600 fs-6 px-3 my-2"
                        style={{ color: "#21231E" }}
                    >
                        ID Proof
                    </p>
                    <div className="d-flex mx-2 gap-1">
                        {/* <TextField
                                required
                                id="outlined-select-currency"
                                select
                                label="ID Proof"
                                InputLabelProps={registeredData && { shrink: true }}
                                value={data?.id_proof_type_id}
                                className='col-4'
                                onChange={(e) => setData({ ...data, id_proof_type_id: e.target.value })}                               
                            >
                                {id_ProofsList?.map((item, index) => <MenuItem key={index} selected="2" value={item.id}>{item.name}</MenuItem>)}
                            </TextField> */}

                        <select
                            required
                            id="outlined-select-currency"
                            label="ID Proof"
                            InputLabelProps={registeredData && { shrink: true }}
                            value={data?.id_proof_type_id}
                            className="col-4 select-option px-1"
                            onChange={(e) =>
                                setData({ ...data, id_proof_type_id: e.target.value })
                            }
                        >
                            <option>Select ID Proof *</option>
                            {id_ProofsList?.map((item, index) => (
                                <option
                                    key={index}
                                    selected={item.id === data?.id_proof_type_id}
                                    value={item.id}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </select>

                        <Form.Group as={Col} className="position-relative mb-3 col-4">
                            {(idProofLoading && (
                                <>
                                    {" "}<span style={{ color: "green" }}> {percent} </span>{" "}
                                    <span style={{ background: "#e9ecef", color: "#000", padding: 6, borderRadius: 5, }}>
                                        Uploading....
                                    </span>
                                </>
                            )) || (
                                    <Form.Control
                                        accept="image/*,.pdf"
                                        type="file"
                                        name="file"
                                        placeholder="Upload ID Proof"
                                        // style={{ height: "48px" }}
                                        onChange={handleFileUpload}
                                        value={data?.file}
                                    >
                                    </Form.Control>
                                )}
                            {/* {data?.id_proof_link &&
                                    <a href={data?.id_proof_link} target='_blank' style={{ color: 'red' }}>{data?.id_proof_link?.slice(0, 30) + "..."} </a>
                                } */}
                            <Form.Label
                                className="font-inter font-w500"
                                style={{
                                    color: "#6A6C6A",
                                    fontSize: "14px",
                                    //   whiteSpace: "nowrap",
                                }}
                            >
                                Only .jpg, .jpeg, .png, .pdf allowed up to 5mb
                            </Form.Label>
                            {data?.file &&
                                <div>
                                    {data?.file} <CloseButton />
                                </div> 
                            }
                        </Form.Group>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                disableFuture
                                label="Date of birth*"
                                className="col-4"
                                onChange={(newValue) =>
                                    setData({
                                        ...data,
                                        dob: moment(newValue.toDate()).format("YYYY-MM-DD"),
                                    })
                                }
                                defaultValue={dayjs(data?.dob)}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                <div className="mx-2 my-2">
                    <p
                        className="font-inter font-w600 fs-6 px-3 my-2"
                        style={{ color: "#21231E" }}
                    >
                        Personal Details
                    </p>
                    <div className="d-flex gap-2 mx-2">
                        <TextField
                            multiline
                            maxRows={4}
                            required
                            id="outlined"
                            label="Address"
                            // onKeyDown={(event) => {
                            //     if (!ALPHANUMERIC_DASH_REGEX.test(event.key)) {
                            //         event.preventDefault();
                            //     }
                            // }}
                            InputLabelProps={registeredData && { shrink: true }}
                            value={data?.address_details_address}
                            onChange={(e) =>
                                setData({ ...data, address_details_address: e.target.value })
                            }
                            className="w-75"
                        />

                        <TextField
                            id="outlined"
                            label="Landmark (Optional)"
                            onKeyDown={(event) => {
                                if (!ALPHANUMERIC_DASH_REGEX.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            InputLabelProps={registeredData && { shrink: true }}
                            value={data?.address_details_landmark}
                            onChange={(e) =>
                                setData({ ...data, address_details_landmark: e.target.value })
                            }
                            className="w-75"
                        />

                        {/* <TextField
              required
              id="outlined-select-currency"
              select
              label="Country"
              InputLabelProps={registeredData && { shrink: true }}
              value={data?.countryId}
              onChange={(e) => onCountryChange(e.target.value)}
              className="col-4"
            >
              <MenuItem value="0">Country</MenuItem>
              {countryList?.map((item, index) => (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField> */}
                        <select
                            required
                            id="outlined-select-Country"
                            label="Country"
                            InputLabelProps={registeredData && { shrink: true }}
                            value={data?.countryId}
                            className="col-4 select-option px-1"
                            onChange={(e) => onCountryChange(e.target.value)}
                        >
                            <option>Select Country *</option>
                            {countryList?.map((item, index) => (
                                <option
                                    key={index}
                                    selected={item.id === data?.countryId}
                                    value={item.id}
                                >
                                    {item.name}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="d-flex gap-2 mx-3 my-3">
                    {/* <TextField
            required
            id="outlined-select-currency"
            select
            label="State"
            InputLabelProps={registeredData && { shrink: true }}
            value={data?.stateId}
            onChange={(e) => onStateChange(e.target.value)}
            className="col-4"
          >
            <MenuItem value="0">State</MenuItem>
            {stateList?.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField> */}

                    <select
                        required
                        id="outlined-select-State"
                        label="State"
                        InputLabelProps={registeredData && { shrink: true }}
                        value={data?.stateId}
                        className="col-4 select-option px-1"
                        onChange={(e) => onStateChange(e.target.value)}
                    >
                        <option>Select State *</option>
                        {stateList?.map((item, index) => (
                            <option
                                key={index}
                                selected={item.id === data?.stateId}
                                value={item.id}
                            >
                                {item.name}
                            </option>
                        ))}
                    </select>

                    <TextField
                        required
                        id="outlined"
                        label="City"
                        // onKeyDown={(event) => {
                        //     if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                        //         event.preventDefault();
                        //     }
                        // }}
                        InputLabelProps={registeredData && { shrink: true }}
                        value={data?.address_details_City}
                        onChange={(e) =>
                            setData({ ...data, address_details_City: e.target.value })
                        }
                        className="w-75"
                    />

                    {/* <TextField
            required
            id="outlined-select-currency"
            select
            label="Timezone"
            onChange={(e) =>
              setData({ ...data, address_details_time_zone: e.target.value })
            }
            className="col-4"
          >
            <MenuItem value="0">Timezone</MenuItem>
            {timezoneList?.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField> */}

                    <select
                        required
                        id="outlined-select-Timezone"
                        label="Timezone"
                        InputLabelProps={registeredData && { shrink: true }}
                        value={data?.address_details_time_zone}
                        className="col-4 select-option px-1"
                        onChange={(e) =>
                            setData({ ...data, address_details_time_zone: e.target.value })
                        }
                    >
                        <option>Select Timezone *</option>
                        {timezoneList?.map((item, index) => (
                            <option
                                key={index}
                                selected={item.id === data?.address_details_time_zone}
                                value={item.id}
                            >
                                {item.name}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="d-flex gap-2 mx-3 my-3">
                    <TextField
                        required
                        id="outlined"
                        label="Zipcode"
                        InputLabelProps={registeredData && { shrink: true }}
                        value={data?.address_details_zip_code}
                        onChange={(e) =>
                            setData({ ...data, address_details_zip_code: e.target.value })
                        }
                        className="col-4"
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        inputProps={{ minLength: 6, maxLength: 9 }}
                    />

                    <Col xs="auto" className="col-4">
                        <InputGroup className="mb-2" style={{ height: "56px" }}>
                            <InputGroup.Text>
                                {data?.country_phone_code ?? call_codeList?.phone_code ?? "+1"}
                            </InputGroup.Text>
                            <Form.Control
                                required
                                maxLength={10}
                                minLength={10}
                                inputProps={{ minLength: 10, maxLength: 10 }}
                                type="tel"
                                onChange={(e) =>
                                    setData({
                                        ...data,
                                        address_details_mobile_number: e.target.value,
                                    })
                                }
                                placeholder="Mobile*"
                                InputLabelProps={registeredData && { shrink: true }}
                                value={data?.address_details_mobile_number}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                            />
                        </InputGroup>
                    </Col>
                </div>

                <ButtonGroup className="d-flex flex-row align-items-center justify-content-end gap-4 me-3 my-3">
                    {/* <Button
                        className="rounded-2 border-0 text-black saveButton"
                        style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}
                        // onSubmit={setIsNext(true)}
                        //type='submit'
                        onClick={onClickSave}
                    >
                        Save
                    </Button> */}
                    <Button
                        onClick={onClickNext}
                        //onClick={() => (state.button = 'saveNext')}
                        className="rounded-2 border-0 text-black saveButton"
                        style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}
                    //type='submit'
                    >
                        Save & Next
                    </Button>
                </ButtonGroup>
                {/* </Form> */}
                {/* <Row xs="auto" className="mx-4 my-3 align-items-center">
                    <Form.Check
                        type="checkbox"
                        id="autoSizingCheck2"
                    />
                    <label>I, understand and accept <Link className='font-inter fontw-500' style={{ color: '#0065FF' }}>T&C</Link></label>
                </Row> */}
            </div>
            <div className="d-flex flex-row align-items-center justify-content-end">
                {/* <ButtonGroup className='d-flex flex-row align-items-center justify-content-end gap-4 me-3 my-3'>
                    <Button
                        className='rounded-2 border-0 text-black'
                        style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}
                        onClick={onClickProfileData}
                        type='submit'
                    >Save</Button>
                    <Button
                        onClick={onClickNext}
                        className='rounded-2 border-0 text-black'
                        style={{ backgroundColor: "#9FE770", whiteSpace: "nowrap" }}
                    >
                        Save & Next
                    </Button>
                </ButtonGroup> */}
                <ToastContainer />
            </div>
        </div>
    );
};

export default RegisterAsProfessionalProfileDetailTab;
