import React from 'react'

const TermAndCondition = () => {
    return (
        <div>
            <div className='' style={{ background: "linear-gradient(180deg, #3DACF0 55.1%, rgba(99, 132, 227, 0) 100%)", padding: 0, margin: 0 }}>
                <div className='container py-5'>
                    {/* <div className="py-5">
                        <h6 className="text-white font-w600 fs-4">Terms and Condition</h6>
                    </div> */}
                    {/* <div className='bg-white px-5' style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)", borderRadius: "12px" }}>
                        <h6 className='fs-5 pt-5'>TERMS OF USE</h6>
                        <p className='py-3'>This document is an electronic record in terms of the Information Technology Act, 2000 (as may be amended, modified, re-enacted, consolidated or replaced from time to time) and rules thereunder pertaining to electronic records in various statutes as applicable and amended from time to time, and is published in accordance with the provisions of Rule 3 of the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021 which mandates the publishing of rules and regulations, privacy policy and the terms and conditions for access or usage of the UNITEDWECARE platforms, namely, http://www.unitedwecare.com (“Website”), and UNITEDWECARE mobile application (“Application”) for Android and iOS devices (hereinafter collectively referred to as the “Platforms”) and mobile based and/or web browser based AI chatbot service functioning in the name of ‘Stella’ (“Stella AI Chatbot”). By visiting or accessing the Platforms, you accept the conditions of use. Please read them carefully.

                            For the purpose of these Terms of Use, wherever the context so requires, “You”, “Your” and “User (s)” shall mean any natural or legal person who has agreed to become a service recipient on the Platform by visiting or accessing the Platform(s) and Stella AI Chatbot on the Platform. The terms “UNITEDWECARE”, “We", "Us", "Our" shall have the meaning as United For Her Private Limited, a non-government, private limited company duly incorporated under the Companies Act 2013 and having its registered office at Tower 2-9A, Sector 50, Gurugram, Haryana 122018 India., and its affiliates. (hereinabove & hereinafter referred as “UNITEDWECARE”)

                            By mere use of and/or access to the Platforms, Users understand that they are contracting with UNITEDWECARE. Users also understand that UNITEDWECARE shall remain the sole owner of the Platforms.

                            The Users by using/ accessing the Platforms and by providing any information through the Platforms, impliedly or expressly, hereby accept these Terms of Use and enter into a binding contract with UNITEDWECARE. It is thereby acknowledged that the Users have read, understood these Terms of Use and are bound by them, regardless of how the Users or anyone on the User’s behalf has accessed or used on the Platforms. If the User does not want to be bound by the Terms of Use, they must not retain, access and/or use the Platform in any manner whatsoever. Anything done, caused to be done, whether expressly or impliedly, in contravention of these Terms of Use, may render the User liable for legal action.

                            Downloading Our mobile application from Google Play Store or Apple App Store, the User will also be subject to Google Play Terms of Service or Apple App Store Terms of Service, respectively. If there is any conflict between Google Play Store Terms of Service or Apple App Store Terms of Service and these Terms of Use with respect to a User’s use of the android and/or iOS mobile platform application, then, these Terms of Use shall prevail.

                            DEFINITIONS

                            In these Terms and Conditions, references to:

                            “Platform” means the website(s), apps, tools, platforms and/or other devices of UNITEDWECARE.com and its affiliated companies and business partners on or through which the Service is (made) available.

                            “Services” shall include but not be limited to services made available for Users, for license, for a fee, including any online services and other web-based services identified on the services list. Services also include consulting, counseling, job search/and application, undertaking various psychometric test(s) pertaining to depression, anxiety, stress etc., and other services or advice provided through the Platforms including voice assistance provided through Stella AI Chatbot on the Platform, and any service that the Service Professional provides to UNITEDWECARE on the line reservation system of the Website or Application through which Service Professionals can make their services available for reservation, and through which Users can make reservations for such services, the facilitated payment, customer service and the communication service.

                            “Service Professional(s)” shall mean the professionals listed on Platforms partnered and catered with UNITEDWECARE for providing and/or delivering Services to User (s) from time to time.

                            “Stella AI Chatbot” shall mean an artificial intelligence based chatbot which through comprehension of natural language holds a preliminary conversation with the User according to each User’s relevant specifications. It also facilitates the conducting of various tests, assessments, and screeners for the Users of the Platform.

                            “User(s)” shall mean an individual who are resident in India.

                            “User Account” shall have the meaning ascribed to it under section 3.1 hereinbelow.

                            ELIGIBILITY

                            Legal Capacity and Age of Consent: Apart from the feature/functionality of booking sessions on the Platform, Our Services are accessible by all Users of any age. The feature/functionality of booking sessions is only accessible if You are at least 18 years of age. Accessibility is limited only to those who are not prohibited from entering into a contract of service of this kind by laws applicable in their competent jurisdiction. With this understanding, You specifically agree that at the time of booking sessions on the Platform, you are at least 18 years of age and you are competent under law to enter into a legally binding and enforceable contract.

                            USE OF THE PLATFORM THROUGH STELLA

                            To enable You to provide better Services, We have also included on Our Platform Stella AI Chatbot which will be used to automatically hold a conversation with the User. In this regard, Stella AI Chatbot using artificial intelligence and comprehension of natural language will give answers to phrases (questions) according to the User’s relevant specifications. The conversation with Stella AI Chatbot is deemed to have been commenced from the moment User writes on the window chat or when by opening the window chat the Stella AI Chatbot executes an action, such as bring information through an integration or open an Ad (proactive tool). Please note that User is deemed to have acknowledged and accepted all conditions, warranties, risks, liabilities, disclaimers, etc. with respect to the Stella AI Chatbot at the time of downloading/accessing the Platform.

                            The User can have unlimited interaction with Stella AI Chatbot, however, the interaction will be deemed to be ended in the following cases: (i) inactivity of User for a term of 5 (five) minutes; (ii) rerouting the conversation to another channel such as telephone operator etc.; (iii) active option to end the conversation in the chat window. Further, each time that a User closes the browser/window of the browser wherein an active conversation is ongoing with Stella AI Chatbot, the conversation with Stella AI Chatbot will be deemed to have ended. Each time that a User starts a conversation with Stella AI Chatbot after it has been deemed to have ended for any of the aforementioned reasons, the User shall be deemed to have started a fresh conversation with Stella AI Chatbot. Whenever a User starts a fresh conversation with Stella AI Chatbot, a User will not have access to any previous conversations that the User might have had with Stella AI Chatbot.

                            Your interaction with Stella AI Chatbot is with an artificial intelligence chatbot and not a human. Stella AI Chatbot is restricted in the manner of response and its intended use is for the purpose of providing the firsthand information available pertaining to the concern raised by the User. Stella AI Chatbot is not in any way intended to provide diagnosis, treatment and cure of any issue, disease, disorder and illness. The tests conducted by the Stella AI Chatbot have been clinically approved and tested. Stella AI Chatbot is only a facilitator for the Users to access and undertake the tests, assessments and screeners. Stella AI Chatbot does not guarantee any specific outcome of the tests, assessment and screeners, and is not responsible for any outcome thereof. The results and the analysis of the Stella AI Chatbot are only indicative, and not conclusive in nature. Stella AI Chatbot uses some worksheets and descriptions for conducting the tests, assessment and screeners on the Platform. Stella AI Chatbot shall not be responsible for any claims arising out of the content of these worksheets and descriptions. Stella AI Chatbot cannot and will not offer advise or information on the concerns or issued raised by You that is not recognized by Stella AI Chatbot.

                            YOUR ACCOUNT AND REGISTRATION OBLIGATIONS

                            To enable Your access and use of the Services listed on the Platforms, You are obligated to register on the Platform by providing certain information which shall be verified with a one-time password to Your mobile number. Upon successful registration on the Platforms, the User(s) will be entitled to a virtual space (“User Account”) which shall be utilized solely to browse and book the Services that may be offered by the Service Professionals/Partners to the Users of the Platforms, and is not to be utilized for any other non-permitted purposes. If You use the Platform(s), You shall be responsible for maintaining the confidentiality of Your display name and password and You shall be responsible for all activities that occur under Your display name and password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete, or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with these Terms of Use, We have the right to indefinitely suspend or terminate or block access of Your Account on the Platform and cease Your access to the Platform(s).
                            If You have joined the Platform through a referral program of Your employer, You shall be bound by the applicable terms and conditions of arrangement of the referral program entered into by Us and Your employer. We do not share Your personal data and the results of the tests, assessment and screeners of the Users which have joined through a referral program with Their employers. We only share the anonymized analytical data of the Users with Their employers, which has arrived upon after conducting some tests, assessments and screeners of the Users. We are not responsible for any outcome/consequences of the tests, assessments and screeners which may have a positive or a negative outcome. You hereby agree that any claims with respect to your usage of the Platform (which has been through a referral program) shall lie exclusively with Your employer, and We shall not be liable for any damages and claims arising out of usage of the Platform by You.
                            You agree that You shall not (i) select or use as a username, a name of another person with the intent to impersonate that person; (ii) use as a username, a name that is otherwise offensive, vulgar or obscene. The UNITEDWECARE reserves the right to refuse registration of or cancel a username in its sole discretion. You will be solely responsible for any and all activity that takes place in or through Your account.
                            Your contact number, as made available to Us, is treated as Your primary identification on the Platform. It is Your responsibility to ensure that the contact number provided on the Platform is valid and remains updated at all times. You agree to promptly notify the mobile phone number and/or e-mail address in case of any change therein, by updating the same on the User Account through a onetime password verification.
                            You agree that upon confirmation of a booking or an appointment, the Service Professionals shall be entitled to contact You on the contact number/ email address provided in Your Account, pursuant to your request to avail the Services. You shall not hold UNITEDWECARE liable for non-rendering of requested Services owing to any incorrect or invalid information that is reflected/uploaded on Your Account.
                            You fully acknowledge and understand that You shall be solely liable and responsible for all the activities undertaken under Your Account and any consequences therefrom.

                            REGISTRATION CHARGES

                            UNITEDWECARE does not charge any fee for browsing/ accessing the Platform and is thus free for its User(s), however, UNITEDWECARE reserves the right to change its fee policy, from time to time.

                            In addition to the service fees payable to a Service Professional(s) to enable a booking, Users will also be charged a convenience fee upon booking of an appointment with such Service Professional(s) for the Services booked and obtained through the Platform(s).

                            UNITEDWECARE reserves the right, at its sole discretion, to introduce new Services and/or modify some or all of the existing Services offered on the Platform(s). In such an event, UNITEDWECARE reserves the right to introduce a fee for the new/ existing Service(s) offered or Service(s) modified, as the case may be. Changes to the fee policy shall be posted on the Platform and such changes shall become effective immediately.

                            Unless otherwise stated, all fees shall be quoted and paid in Indian Rupees and all payments shall be subject to deductions/taxes/levies as maybe applicable under law. You shall be solely responsible for compliance of all applicable laws including for making payments to Service Professional (s).

                            ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION

                            The information on the Platforms is not guaranteed to be correct, complete or up to date. The material and information provided on the Platforms are provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on the Platforms are at Your own risk. The Platforms may contain certain historical information. Historical information, necessarily, is not current and is provided for Your reference only. We reserve the right (not being an obligation) to modify the contents of the Platforms at any time and it shall be Your responsibility to monitor changes to the Platform.

                            COMMUNICATIONS

                            When You use the Platform or send emails or other data, information or communication to Us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from Us periodically or as and when required. We may communicate with You by email or by such other mode of communication, electronic or otherwise.

                            You also acknowledge and agree that the telephonic conversations through the Platforms for the purposes of booking an appointment with any Service Professional, may be recorded solely for quality assurance and training purposes.

                            RENDERING OF SERVICES

                            We partner with third party Service Professionals in order to deliver the Services. Service details of Service Professionals such as approximate time of rendering the Service(s) will be communicated or extended vide text message, notification on the User Account, etc, upon receipt of confirmation from the User Account.
                            You acknowledge and agree that UNITEDWECARE solely acts as enablers or facilitators between the User(s) and the Service Professional(s). The opinions, statements, answers (collectively “consultation”) provided by the Service Professionals via the Platforms are solely individual and independent opinions and statements of such individuals/Service Professionals alone, and in no way reflect the opinions of UNITEDWECARE, its affiliates or any other organizations or institutions of UNITEDWECARE to which such Service Professional is affiliated and/or provides services. We do not recommend or endorse any specific tests, service professionals, products, procedures, opinions, or other information that may be mentioned on the Platforms or by a licensee of UNITEDWECARE.
                            As part of the Services, We also provide psychometric tests pertaining to various mental status including (but not limited to) depression, anxiety, stress, marital advice, etc. that can be used as part of the assessment process as listed in detail on the Platform. These tests have been designed to assess the intelligence, logic, reasoning ability, numerical ability, verbal ability, etc. of the Users. These tests can be used by the User as per their needs and requirements. However, We do not take any responsibility for the results and outcomes generated through these psychometric tests and therefore, UNITEDWECARE does not undertake any sort of liability for the outcomes generated through these psychometric tests.
                            Prior to availing the requisite service(s) listed on the Platforms, the User will be directed to provide relevant information including but not limited to name, mobile number, e-mail address and details of the issue being faced. User assures and affirms that the aforesaid information provided is correct, complete and accurate. Any failure in receiving the necessary service(s) arising out of the User’s failure to provide correct, complete and accurate information shall not be attributable to UNITEDWECARE and consequently, UNITEDWECARE shall not be held liable at any point in time.
                            We at our sole discretion, reserve the right to cancel any and/or all kinds of Service (s). In the event of such cancellation, We shall refund the amount (after any applicable deductions, if any) paid by the User for the Service(s) cancelled or not availed due to such cancellation.
                            While We will make reasonable efforts in ensuring the delivery of Service(s) to the User in a timely manner, the User understands that delivering the same may sometimes be delayed on account of:
                            Reasons and issues beyond Our control;
                            Unsuitable/intermittent connectivity conditions;
                            Disruptions in data or telecom networks by default of third party, etc.;
                            Acts of God such as floods, earthquakes, etc.; and
                            Any other unforeseen circumstances beyond Our control.
                            In such events of delay as laid out in clause 8.6 above, We shall make all reasonable efforts to intimate the User in writing, on her mobile number and/or registered email. We disclaim all liabilities that may arise on account of failure to intimate User of anticipated delays in delivering the Service (s) availed through the Platform. Further, We shall be under no obligation to compensate User for any mental agony or any tortuous claim that may otherwise arise on account of a delay in providing the Service (s).

                            USE OF THIS PLATFORM

                            This Platform is for User’s personal use. User shall not copy, replicate, modify, distribute, display, perform, create derivative works from, transfer or sell any information (including the content hosted on the Platform such as articles, audio clips, videos, etc.), software, products or services obtained from this Platform whether printed, visual, electronic, e-mail forwarding, digital transmission, scanned, website re-cycled or any other form of material except where expressly noted that the material is available for such purpose. As a condition of User’s use of this Platform, User hereby warrant that User will not use this Platform for any purpose that is unlawful or prohibited by these Terms and Conditions of Use or otherwise.

                            User further agree and acknowledge that any unauthorized or unlawful use of this Platform would result in irreparable injury to the UNITEDWECARE for which monetary damages may be inadequate, and in such event UNITEDWECARE, shall have the right, in addition to other remedies available at law and in equity, to obtain immediate injunctive relief against User. Nothing contained in these Terms and Conditions of Use shall be construed to limit remedies available pursuant to statutory or other claims that UNITEDWECARE may have under separate legal authority.

                            GENERAL CONDITIONS

                            UNITEDWECARE reserves the right to refuse Service to anyone for any reason at any time.

                            Users (s) understand that the content (not including credit card information of any User, may be transferred unencrypted and involve (a) transmissions over various networks; (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.

                            UNITEDWECARE reserves the right to add, amend or discontinue, temporarily or permanently, any of its Services (or any part thereof) in future offered through the Platform or the design and functionality of the Platform, with or without notice and/or reason(s). User agrees that UNITEDWECARE shall not be liable either to User or to any third party for any modification, suspension or discontinuance of any of the Services including without limitation any failure of performance, error, omission, interruption, deletion, loss of information, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of records, whether for breach of contract, tortuous actions, negligence, or under any other cause or action.

                            MODE OF PAYMENT

                            You acknowledge that payment made by net banking / debit card / credit card / mobile wallets (if any) would be in accordance with the prevailing guidelines issued by the Reserve Bank of India or the Central Government as the case may be, including guidelines relating to know-your-customer norms / anti-money laundering and combatting of financing of terrorism. Further, We will not be liable for any charges occurring due to dishonour of payment instructions. It is however, clarified that any amount paid by You towards booking of an appointment with a Service Professional and such appointment being cancelled or the Service Professional failing to attend such appointment, shall be refunded to You.
                            UNITEDWECARE does not implicitly or explicitly support or endorse the sale or purchase of any Services on the Platform. UNITEDWECARE accepts no liability for any errors or omissions, whether on behalf of itself or third parties.
                            UNITEDWECARE is not responsible for any non-performance or breach of any contract entered into between User (s) and Service Professional (s). Your correspondence or dealings with Service Professional(s) found on or through the Platform, including payment and delivery of services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such Service Professional(s). UNITEDWECARE cannot and does not guarantee that the concerned User (s) and/or Service Professional (s) will perform any transaction concluded on the Platform. UNITEDWECARE shall not and is not required to mediate or resolve any dispute or disagreement relating to the Services between User (s) or and Service Professional (s).
                            UNITEDWECARE does not at any point of time during any transaction between User (s) and Service Professional (s) on the Platform (s) come into or take possession of any of the Services offered by Service Professional (s)  nor does it at any point gain title to or have any rights or claims over the Services offered by Service Professional (s) and User (s).
                            At no time shall UNITEDWECARE hold any right, title or interest or any obligations or liabilities in respect of such contract entered into between User (s) and Service Professional (s). UNITEDWECARE is not responsible for unsatisfactory or delayed performance of Services.
                            UNITEDWECARE solely provides a platform that can be utilized by User (s) to reach a larger base to avail Services and undertake communication. It is agreed that the contract for sale of any of the Services shall be a strictly bipartite contract between the User (s) and Service Professional (s).
                            You shall independently agree upon the manner and terms and conditions of availing the Service, and the payment towards the Service with the Service Professional (s) You transact with.

                            PROHIBITED ACTIONS FOR USERS

                            We outrightly prohibit You from the following actions:

                            use the Platforms for any purpose other than as expressly permitted by Us.

                            not to hold Us responsible for any links to other websites or application which are beyond Our control.

                            not to hold Us responsible for the third-party advertisements that may be placed/made use of on the Platform. You acknowledge that such advertisements are placed by third parties and We are not directly or indirectly responsible for such advertisement or for any links contained therein.

                            not to unauthorize Us from using the user generated content on the Platform for any of the business purposes, even following the termination of Your registration.

                            post on the Platform any content, whether or not containing links, for any of Our competitor.

                            the Platform or any portion of this Platform may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without the express written consent of UNITEDWECARE. You may not systematically extract and/or re-utilise parts of the contents of the Platform without the express written consent of UNITEDWECARE. In particular, You may not utilize any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilization of any substantial parts of the Platform without the express written consent of UNITEDWECARE.

                            collect, post or submit on this Platform any incomplete, fictitious, false or inaccurate information.

                            aggregate, copy or duplicate in any manner any content or information available from this Platforms.

                            upload, share or otherwise distribute any text, graphics, images, videos or data which victimize, harass, degrade, or intimidate an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age or disability.

                            INTELLECTUAL PROPERTY RIGHTS (IPR) RESTRICTION

                            This site is controlled and operated by UNITEDWECARE and Services rendered by respective Service Professionals. All material on this site and/or the Platforms, including (but not limited to) assistance/service provided by Stella AI Chatbot, articles, posts, blogs, images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks, trade secrets, and other intellectual property rights thereon, all of which vest exclusively with UNITEDWECARE. Material on Platform is solely for Your personal, non-commercial use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by email or other electronic means and whether directly or indirectly and You must not assist any other person to do so. Without the prior written consent of the owner, modification of the materials, use of the materials on any other Platform or networked computer environment or use of the materials for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks and other intellectual property rights, and is strictly prohibited. Any use for which You receive any remuneration, whether monetarily or otherwise, is construed as a commercial use for the purposes of this clause and UNITEDWECARE shall hold you accountable and liable for infringement of trademark, copyright, trade secrets and other intellectual property rights under the relevant prevailing laws in India.

                            The User confirms and undertakes not to display or use the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights of any third party on the Platform. The User further agrees to indemnify and hold Us, Our directors, employees, affiliates and assigns harmless against all costs, damages, loss and harm including towards litigation costs and counsel fees, in respect of any third party claims that may be initiated including for infringement of Intellectual Property Rights arising out of such display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on the Platform, by User or through the User’s commissions or omissions.

                            REFUND POLICY

                            We may, in certain exceptional circumstances and at our sole and absolute discretion, refund the amount to the User after deducting applicable charges and taxes, if any. Such exceptional circumstances are provided hereinbelow:

                            In the event of any cancellation of appointments/meetings by a Service Professional or rescheduling of meetings by a Service Professional to a time not suitable for the User, then any amount paid for the booking of such appointment/meeting shall be refunded to the User. It is clarified that UNITEDWECARE shall not be liable to refund any amounts in the event of any unsatisfactory services from a Service Professional.
                            In the event of any excess payment towards the Services has been made by the User due to any technical glitch on the Platform or payment gateway.

                            Subject to clause 14.1 above, all monies payable to UNITEDWECARE by the User(s) on consummated transactions, i.e., exchange of Services for consideration, are final and non-refundable. UNITEDWECARE will not be liable to process the refund of any paid amount in the event of any unsatisfactory services obtained from Service Professional(s).

                            In the event that the User fails to attend any appointment/meeting with the Service Professional at the time specified for the appointment/meeting, the User will not be entitled to any refund in respect of the session booked by the User. Subject to clause 14.1 above, UNITEDWECARE will not be liable to refund any amount to the User for its failure to attend the appointment/meeting with the Service Professional at the time specified for the appointment/meeting.

                            RELEASE AND LIMITATIONS OF LIABILITY

                            Users shall access the Platform voluntarily and at their own risk. We shall under no circumstances be held responsible or liable on account of any loss or damage sustained (including but not limited to any accident, injury, death, loss of property) by Users or any other person or entity during the course of access to and/or use of the Platform, including claims based on malpractice.
                            You agree that any claim arising out of Your relationship with Service Professional shall be brought solely against such Service Professional, and neither UNITEDWECARE nor any of its licensors or affiliates shall be included within any such claim.
                            By accessing the Platform, Users hereby release from and agree to indemnify Us, and/or any of Our directors, employees, partners, associates and licensors, against all liability, cost, loss or expense arising out of their access to the Platform including but not limited to personal injury and damage to property, whether direct, indirect, consequential, or foreseeable, caused due to any negligent act or omission on their part, or otherwise.
                            Users shall be solely responsible for any consequence which may arise due to their access and use of the Platform, including conducting an illegal/fraudulent act or non-conformity with these Terms of Use and other rules and regulations in relation to the Platform, including provision of incorrect address or other personal details. Users also undertake to indemnify Us and Our respective officers, directors, employees and agents on the happening of such an event (including without limitation, cost of attorney, etc.) on full indemnity basis for any loss/damage suffered by Us on account of such act on the part of the Users.

                            GOVERNING LAW AND DISPUTE RESOLUTION

                            These terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws, principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of courts, tribunals, fora, applicable authorities at Gurugram.

                            TAXES

                            You shall be responsible for payment of all fees/costs/charges associated with availing of Services from Us and You agree to bear any and all applicable taxes including but not limited to GST, duties, cess etc.

                            WAIVER

                            Any failure or delay by a party to enforce or exercise any provision of these Terms of Use, or any related right, shall not constitute a waiver by such party of that provision or right. To clarify, if a User breaches any of these conditions and We do not initiate any action against the same, We will still be entitled to use our rights and remedies in any other situation where You breach these conditions. Any waiver by a party shall only be made in writing and executed by a duly authorized officer of such party.

                            MISCELLANEOUS

                            We may be required under applicable law, to notify Users of certain events. User hereby acknowledges and consents that such notices will be effective upon Our posting them on the Platform or delivering them to the User through the email address provided by the User at the time of registration. User (s) may update their email address by logging into their account on the Platform. If they do not provide Us with accurate information, We shall not be held liable for failure to notify the User.
                            We shall not be liable for any delay or failure to perform resulting from causes outside Our reasonable control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond Our control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.
                            Users agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Platform or these Terms must be filed within 15 (fifteen) days of such claim or cause of action arising or be forever barred from making the claim.
                            If any part of these Terms of Use is determined to be indefinite, invalid, or otherwise unenforceable, the rest of these Terms of Use shall continue in full force.
                            We reserve the right to moderate, restrict or ban the use of the Platform, specifically to any User, or generally, in accordance with Our policy/policies amended from time to time, at Our sole and absolute discretion and without any notice.
                            We may, at Our sole and absolute discretion, permanently close or temporarily suspend any services provided on the Platform.

                            DISCLAIMERS

                            Unless otherwise explicitly specified, We are not affiliated in any way to and claim no association, in any capacity whatsoever, with any organization, association, institution, business, trust, authority, governing bodies and leagues, whether local or national or international.

                            WE ARE NO WAY, DIRECTLY OR INDIRECTLY RESPONSIBLE FOR THE ADVICE AND ASSISTANCE PROVIDED BY THE SERVICE PROFESSIONALS. WE ARE AN INDEPENDENT THIRD PARTY ENABLER WHO HAVE BUILT AND PROVIDED YOU WITH A PLATFORM WHERE USERS CAN COMMUNICATE WITH, AND IF NEED BE, MEET THE SERVICE PROFESSIONALS. WE DO NOT HAVE ANY CONTROL OVER THE SERVICES PROVIDED BY THE SERVICE PROFESSIONALS, AND THUS, CANNOT BE HELD RESPONSIBLE INCASE OF ANY ISSUES/DISCREPANCIES WITH THE SERVICES PROVIDED BY THE SERVICE PROFESSIONALS.

                            The Platform has multiple Service Professionals for the same practice area and there might be instances wherein a same/similar query has been answered in different ways by the Service Professional(s). We take no liability towards these different opinions and answers of the Service Professional(s) and encourage You to take a separate independent opinion/ alternately decide which opinion to go ahead with based on merits and logical conclusions.

                            In no event, UNITEDWECARE shall be liable to any User on account of such User’s use or reliance on Stella AI Chatbot, for any damages whatsoever, including direct, special, punitive, indirect, consequential or incidental damages or damages for tort (including negligence), even if UNITEDWECARE is aware of or has been advised of the possibility of such damage, arising out of or connected with the use or performance of the Stella AI Charbot. UNITEDWECARE does not assume any legal liability or responsibility for the accuracy, completeness or usefulness of any information disclosed or provided by Stella AI Chatbot.

                            All such information is provided “as is” without warranty of any kind. UNITEDWECARE hereby disclaim any warranties and conditions with regard to the information provided by Stella AI Chatbot and in no event shall UNITEDWECARE be liable for any direct, indirect, punitive, incidental, special or consequential damages arising out of or in any way connected with the access or use of Stella AI Chatbot on the Platform.

                            The User assumes all responsibility and risk associated, if any, for the use of the Stella AI Chatbot on the Platform and will independently judge whether the information or advice being provided by Stella AI Chatbot is accurate and will work best for the User or not.

                            To the extent permitted under law, We shall not be responsible for any harm resulting from downloading or accessing any information or material vide the Platform, the quality of servers. We disclaim any responsibility for any technical glitches on the servers or the technology that underlies Our Platforms, failures by service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of Our facilities, acts of nature, war, civil disturbance, or any other cause beyond Our reasonable control, which affect the functioning of the Platform(s). In addition, We do not provide any warranty as to the content on the Platform.

                            Any material accessed, downloaded or otherwise obtained through Us and/or the Platform shall be User's sole discretion, competence, acceptance and risk. The User will be solely responsible for any potential damage to User's computer system/mobile or loss of data that results from a User's download of any such material.

                            We shall make best endeavors to ensure that the Platform and experience through it is error-free and secure, however, neither We nor any of Our partners, Service Professional (s) or associates make any warranty that:

                            the Platforms will meet Users' requirements;
                            The Platforms will be uninterrupted, timely, secure, or error free;
                            the listings on the Platform will be accurate or reliable; and
                            the quality of Services, information, or other material that Users avail or obtain through the Platform will meet Users' expectations.

                            To the extent permitted under law, neither We nor our partners, Service Professional (s) or associates shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use Our website/mobile application, even if We have been advised of the possibility of such damages.

                            No representation is made that the quality of the professional services to be performed is greater than the quality of professional services performed by other Service Professionals. We urge all Users to make their own independent investigation and evaluation of any Service Professional being considered.

                            CONTACT AND COMPLAINT

                            In the event the User has any questions, feedback, suggestions, experience, comments and requests (“Feedback”) regarding these Terms of Use such respective User may address the same to the designated grievance officer in respect of the Platform. Any complaints or concerns with regards to any content on the Platform or any breach of the Privacy Policy or Terms of Use or any other policy can be directed to the designated officer in writing and the details are as follows:

                            United For Her Private Limited, Tower 2-9A,

                            Sector 50, Gurugram, Haryana 122018,

                            A complaint or concern may also be raised through an email to contactus.india@unitedwecare.com.

                            The Feedback, compliant or concern shall be deemed to be non-confidential and non-compensatory in nature. UNITEDWECARE reserves the right, at its sole discretion to use such information in any manner as may be deemed appropriate by UNITEDWECARE and such use shall be entirely unrestricted.

                            User further represent and warrant that while posting any Feedback for the Platforms, User shall not use any offensive, libelous, derogatory, hateful or racially or ethnically objectionable language. Further, User shall not post any content on any part on the Platform that is obscene, pornographic and that all such Feedback will be in accordance with the applicable law.
                        </p>
                    </div> */}

                    {/* <div class="entry-content clear" itemprop="text">
                        <p><b>TERMS OF USE&nbsp;</b></p>
                        <p><span style={{fontWeight: 400}}>This document is an electronic record in terms of the Information Technology Act, 2000 (as may be amended, modified, re-enacted, consolidated or replaced from time to time) and rules thereunder pertaining to electronic records in various statutes as applicable and amended from time to time, and is published in accordance with the provisions of Rule 3 of the Information Technology (Intermediaries Guidelines and Digital Media Ethics Code) Rules, 2021 which mandates the publishing of rules and regulations, privacy policy and the terms and conditions for access or usage of the UNITEDWECARE platforms, namely, </span><a href="https://www.unitedwecare.com"><span style="font-weight: 400;">https://www.unitedwecare.com</span></a> <span style="font-weight: 400;">(“</span><b>Website</b><span style="font-weight: 400;">”)</span><span style="font-weight: 400;">, and UNITEDWECARE mobile application </span><span style="font-weight: 400;">(“</span><b>Application</b><span style="font-weight: 400;">”)</span><span style="font-weight: 400;"> for Android and iOS devices (hereinafter collectively referred to as the “</span><b>Platforms</b><span style="font-weight: 400;">”) and mobile based and/or web browser based AI chatbot service functioning in the name of ‘Stella’ (“</span><b>Stella AI Chatbot</b><span style="font-weight: 400;">”). By visiting or accessing the Platforms, you accept the conditions of use. Please read them carefully.</span></p>
                        <p><span style="font-weight: 400;">For the purpose of these Terms of Use, wherever the context so requires, “</span><b>You</b><span style="font-weight: 400;">”, “</span><b>Your</b><span style="font-weight: 400;">” and “</span><b>User (s)</b><span style="font-weight: 400;">” shall mean any natural or legal person who has agreed to become a service recipient on the Platform by visiting or accessing the Platform(s) and Stella AI Chatbot on the Platform. The terms “</span><b>UNITEDWECARE</b><span style="font-weight: 400;">”, “</span><b>We</b><span style="font-weight: 400;">“, “</span><b>Us</b><span style="font-weight: 400;">“, “</span><b>Our</b><span style="font-weight: 400;">” shall have the meaning as </span><span style="font-weight: 400;">United For Her Private Limited, a non-government, private limited company duly incorporated under the Companies Act 2013 and having its registered office at Tower 2-9A, Sector 50, Gurugram, Haryana 122018 India.</span><span style="font-weight: 400;">, and its affiliates. (hereinabove &amp; hereinafter referred as </span><b>“UNITEDWECARE”</b><span style="font-weight: 400;">)</span></p>
                        <p><span style="font-weight: 400;">By mere use of and/or access to the Platforms, Users understand that they are contracting with UNITEDWECARE. Users also understand that UNITEDWECARE shall remain the sole owner of the Platforms.</span></p>
                        <p><span style="font-weight: 400;">The Users by using/ accessing the Platforms and by providing any information through the Platforms, impliedly or expressly, hereby accept these Terms of Use and enter into a binding contract with UNITEDWECARE. It is thereby acknowledged that the Users have read, understood these Terms of Use and are bound by them, regardless of how the Users or anyone on the User’s behalf has accessed or used on the Platforms. If the User does not want to be bound by the Terms of Use, they must not retain, access and/or use the Platform in any manner whatsoever. Anything done, caused to be done, whether expressly or impliedly, in contravention of these Terms of Use, may render the User liable for legal action.</span></p>
                        <p><span style="font-weight: 400;">Downloading Our mobile application from Google Play Store or Apple App Store, the User will also be subject to Google Play Terms of Service or Apple App Store Terms of Service, respectively. If there is any conflict between Google Play Store Terms of Service or Apple App Store Terms of Service and these Terms of Use with respect to a User’s use of the android and/or iOS mobile platform application, then, these Terms of Use shall prevail.</span></p>
                        <ol>
                            <li style="font-weight: 400;" aria-level="1"><b>DEFINITIONS</b></li>
                        </ol>
                        <p><span style="font-weight: 400;">In these Terms and Conditions, references to:</span></p>
                        <ol>
                            <li style="font-weight: 400;" aria-level="2"><b><i>“</i></b><b>Platform</b><b><i>”</i></b><span style="font-weight: 400;"> means the website(s), apps, tools, platforms and/or other devices of UNITEDWECARE.com and its affiliated companies and business partners on or through which the Service is (made) available. </span><span style="font-weight: 400;">&nbsp;</span></li>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">“</span><b>Services</b><span style="font-weight: 400;">” shall include but not be limited to:</span></li>
                        </ol>
                        <ol>
                            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">services made available for Users, for license, for a fee, including any online services and other web-based services identified on the services list;</span></li>
                            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">services also include consulting, counseling, job search/and application, undertaking various psychometric test(s) pertaining to depression, anxiety, stress etc., and other services or advice provided through the Platforms including voice assistance provided through Stella AI Chatbot on the Platform; and&nbsp;</span></li>
                        </ol>
                        <ol>
                            <li style="font-weight: 400;" aria-level="2"><b>“Stella AI Chatbot”</b> <span style="font-weight: 400;">shall mean an artificial intelligence based chatbot which through comprehension of natural language holds a preliminary conversation with the User according to each User’s relevant specifications. It also facilitates the conducting of various tests, assessments, and screeners for the Users of the Platform.</span></li>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">“</span><b>User(s)</b><span style="font-weight: 400;">” </span><span style="font-weight: 400;">mean persons availing of the UNITEDWECARE Services (as defined above), directly or indirectly.</span></li>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">“</span><b>User Account</b><span style="font-weight: 400;">” shall have the meaning ascribed to it under section 3.1 hereinbelow.</span></li>
                        </ol>
                        <ul>
                            <li aria-level="1"><b>ELIGIBILITY</b></li>
                        </ul>
                        <ol>
                            <li style="list-style-type: none;">
                                <ol>
                                    <li style="list-style-type: none;">
                                        <ol>
                                            <li style="font-weight: 400;" aria-level="2"><b>Legal Capacity and Age of Consent</b><span style="font-weight: 400;">: Apart from the feature/functionality of booking sessions on the Platform, Our Services are accessible by all Users of any age. The feature/functionality of booking sessions is only accessible if You are at least 18 years of age and resident of India or Canada. Accessibility is limited only to those who are not prohibited from entering into a contract of service of this kind by laws applicable in their competent jurisdiction. With this understanding, You specifically agree that at the time of booking sessions on the Platform, you are at least 18 years of age and you are competent under law to enter into a legally binding and enforceable contract.</span></li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <ul>
                            <li aria-level="1"><b>USE OF THE PLATFORM THROUGH STELLA&nbsp;</b></li>
                        </ul>
                        <ul>
                            <li aria-level="2"><span style="font-weight: 400;">To enable You to provide better Services, We have also included on Our Platform Stella AI Chatbot which will be used to automatically hold a conversation with the User. In this regard, Stella AI Chatbot using artificial intelligence and comprehension of natural language will give answers to phrases (questions) according to the User’s relevant specifications. The conversation with Stella AI Chatbot is deemed to have been commenced from the moment User writes on the window chat or when by opening the window chat the Stella AI Chatbot executes an action, such as bring information through an integration or open an Ad (proactive tool). Please note that User is deemed to have acknowledged and accepted all conditions, warranties, risks, liabilities, disclaimers, etc. with respect to the Stella AI Chatbot at the time of downloading/accessing the Platform.&nbsp;&nbsp;&nbsp;</span></li>
                        </ul>
                        <ul>
                            <li aria-level="2"><span style="font-weight: 400;">The User can have unlimited interaction with Stella AI Chatbot, however, the interaction will be deemed to be ended in the following cases: (i) inactivity of User for a term of 5 (five) minutes; (ii) rerouting the conversation to another channel such as telephone operator etc.; (iii) active option to end the conversation in the chat window. Further, each time that a User closes the browser/window of the browser wherein an active conversation is ongoing with Stella AI Chatbot, the conversation with Stella AI Chatbot will be deemed to have ended. Each time that a User starts a conversation with Stella AI Chatbot after it has been deemed to have ended for any of the aforementioned reasons, the User shall be deemed to have started a fresh conversation with Stella AI Chatbot. Whenever a User starts a fresh conversation with Stella AI Chatbot, a User will not have access to any previous conversations that the User might have had with Stella AI Chatbot.</span></li>
                        </ul>
                        <ol>
                            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">Your interaction with Stella AI Chatbot is with an artificial intelligence chatbot and not a human. Stella AI Chatbot is restricted in the manner of response and its intended use is for the purpose of providing the firsthand information available pertaining to the concern raised by the User. Stella AI Chatbot is not in any way intended to provide diagnosis, treatment and cure of any issue, disease, disorder and illness. The tests conducted by the Stella AI Chatbot have been clinically approved and tested. Stella AI Chatbot is only a facilitator for the Users to access and undertake the tests, assessments and screeners. Stella AI Chatbot does not guarantee any specific outcome of the tests, assessment and screeners, and is not responsible for any outcome thereof. The results and the analysis of the Stella AI Chatbot are only indicative, and not conclusive in nature. Stella AI Chatbot uses some worksheets and descriptions for conducting the tests, assessment and screeners on the Platform. Stella AI Chatbot shall not be responsible for any claims arising out of the content of these worksheets and descriptions. Stella AI Chatbot cannot and will not offer advise or information on the concerns or issued raised by You that is not recognized by Stella AI Chatbot.&nbsp;</span></li>
                            <li style="font-weight: 400;" aria-level="1"><b>YOUR ACCOUNT AND REGISTRATION OBLIGATIONS</b></li>
                        </ol>
                        <ol>
                            <li style="list-style-type: none;">
                                <ol>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">To enable Your access and use of the Services listed on the Platforms, You are obligated to register on the Platform</span><span style="font-weight: 400;"> by providing certain information which shall be verified with a one-time password to Your mobile number</span><span style="font-weight: 400;">. Upon successful registration on the Platforms, the User(s) will be entitled to a virtual space (“</span><b>User Account</b><span style="font-weight: 400;">”) which shall be utilized solely to browse the Platforms, and is not to be utilized for any other non-permitted purposes. </span><span style="font-weight: 400;">If You use the Platform(s), You shall be responsible for maintaining the confidentiality of Your display name and password and You shall be responsible for all activities that occur under Your display name and password. You agree that if You provide any information that is untrue, inaccurate, not current or incomplete, or We have reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, or not in accordance with these Terms of Use, We have the right to indefinitely suspend or terminate or block access of Your Account on the Platform and cease Your access to the Platform(s).</span></li>
                                </ol>
                            </li>
                        </ol>
                        <ul>
                            <li aria-level="2"><span style="font-weight: 400;">If You have joined the Platform through a referral program of Your employer, You shall be bound by the applicable terms and conditions of arrangement of the referral program entered into by Us and Your employer. We do not share Your personal data and the results of the tests, assessment and screeners of the Users which have joined through a referral program with Their employers. We only share the anonymized analytical data of the Users with Their employers, which has arrived upon after conducting some tests, assessments and screeners of the Users. We are not responsible for any outcome/consequences of the tests, assessments and screeners which may have a positive or a negative outcome. You hereby agree that any claims with respect to your usage of the Platform (which has been through a referral program) shall lie exclusively with Your employer, and We shall not be liable for any damages and claims arising out of usage of the Platform by You.</span></li>
                        </ul>
                        <ol>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">You agree that </span><span style="font-weight: 400;">You shall not (i) select or use as a username, a name of another person with the intent to impersonate that person; (ii) use as a username, a name that is otherwise offensive, vulgar or obscene. The UNITEDWECARE reserves the right to refuse registration of or cancel a username in its sole discretion. You will be solely responsible for any and all activity that takes place in or through Your account.</span></li>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Your contact number, as made available to Us, is treated as Your primary identification on the Platform. It is Your responsibility to ensure that the contact number provided on the Platform is valid and remains updated at all times. You agree to promptly notify the mobile phone number and/or e-mail address in case of any change therein, by updating the same on the User Account through a onetime password verification.</span></li>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">You fully acknowledge and understand that You shall be solely liable and responsible for all the</span><span style="font-weight: 400;"> activities undertaken under Your Account and any consequences therefrom.</span></li>
                        </ol>
                        <ul>
                            <li aria-level="1"><b>REGISTRATION CHARGES</b></li>
                        </ul>
                        <ul>
                            <li aria-level="2"><span style="font-weight: 400;">UNITEDWECARE</span><span style="font-weight: 400;"> does not charge any fee for browsing/ accessing the Platform and is thus free for its User(s), however, </span><span style="font-weight: 400;">UNITEDWECARE</span><span style="font-weight: 400;"> reserves the right to change its fee policy, from time to time.&nbsp;</span></li>
                        </ul>
                        <ul>
                            <li aria-level="2"><span style="font-weight: 400;">UNITEDWECARE</span><span style="font-weight: 400;"> reserves the right, at its sole discretion, to introduce new Services and/or modify some or all the existing Services offered on the Platform(s). In such an event, </span><span style="font-weight: 400;">UNITEDWECARE</span><span style="font-weight: 400;"> reserves the right to introduce a fee for the new/ existing Service(s) offered or Service(s) modified, as the case may be. Changes to the fee policy shall be posted on the Platform and such changes shall become effective immediately.&nbsp;</span></li>
                        </ul>
                        <ul>
                            <li aria-level="2"><span style="font-weight: 400;">Unless otherwise stated, all fees shall be quoted and paid in Indian Rupees and all payments shall be subject to deductions/taxes/levies as maybe applicable under law.</span></li>
                        </ul>
                        <ul>
                            <li aria-level="1"><b>ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION</b></li>
                        </ul>
                        <p><span style="font-weight: 400;">The information on the Platforms is not guaranteed to be correct, complete or up to date. </span><span style="font-weight: 400;">The material and information provided on the Platforms are provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on the Platforms are at Your own risk. The Platforms may contain certain historical information. Historical information, necessarily, is not current and is provided for Your reference only. We reserve the right (not being an obligation) to modify the contents of the Platforms at any time and it shall be Your responsibility to monitor changes to the Platform.</span></p>
                        <ul>
                            <li aria-level="1"><b>COMMUNICATIONS</b></li>
                        </ul>
                        <p><span style="font-weight: 400;">When You use the Platform or send emails or other data, information or communication to Us, You agree and understand that You are communicating with Us through electronic records and You consent to receive communications via electronic records from Us periodically or as and when required. We may communicate with You by email or by such other mode of communication, electronic or otherwise.</span></p>
                        <p><span style="font-weight: 400;">You also acknowledge and agree that the telephonic conversations through the Platforms may be recorded solely for quality assurance and training purposes.</span></p>
                        <ol>
                            <li style="font-weight: 400;" aria-level="1"><b>RENDERING OF SERVICES</b><span style="font-weight: 400;">&nbsp;</span></li>
                        </ol>
                        <ol>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">As part of the Services, We also provide psychometric tests pertaining to various mental status including (but not limited to) depression, anxiety, stress, marital advice, etc. that can be used as part of the assessment process as listed in detail on the Platform. These tests have been designed to assess the intelligence, logic, reasoning ability, numerical ability, verbal ability, etc. of the Users. These tests can be used by the User as per their needs and requirements. However, We do not take any responsibility for the results and outcomes generated through these psychometric tests and therefore, UNITEDWECARE does not undertake any sort of liability for the outcomes generated through these psychometric tests.</span></li>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Prior to availing the requisite service(s) listed on the Platforms, the User will be directed to provide relevant information including but not limited to name, mobile number, e-mail address and details of the issue being faced. User assures and affirms that the aforesaid information provided is correct, complete and accurate. Any failure in receiving the necessary service(s) arising out of the User’s failure to provide correct, complete and accurate information shall not be attributable to UNITEDWECARE and consequently, UNITEDWECARE shall not be held liable at any point in time.</span></li>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">We at our sole discretion, reserve the right to cancel any and/or all kinds of Service (s). In the event of such cancellation, We shall refund the amount (after any applicable deductions, if any) paid by the User for the Service(s) cancelled or not availed due to such cancellation.&nbsp;</span></li>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">While We will make reasonable efforts in ensuring the delivery of Service(s) to the User in a timely manner, the User understands that delivering the same may sometimes be delayed on account of:</span>
                                <ol>
                                    <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">Reasons and issues beyond Our control;</span></li>
                                    <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">Unsuitable/intermittent connectivity conditions;</span></li>
                                    <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">Disruptions in data or telecom networks by default of third party, etc.;</span></li>
                                    <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">Acts of God such as floods, earthquakes, etc.; and</span></li>
                                    <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">Any other unforeseen circumstances beyond Our control.</span></li>
                                </ol>
                            </li>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">In such events of delay as laid out in clause 9.4 above, We shall make all reasonable efforts to intimate the User in writing, on her mobile number and/or registered email. We disclaim all liabilities that may arise on account of failure to intimate User of anticipated delays in delivering the Service(s) availed through the Platform. Further, We shall be under no obligation to compensate User for any mental agony or any tortuous claim that may otherwise arise on account of a delay in providing the Service (s).</span></li>
                        </ol>
                        <ul>
                            <li aria-level="1"><b>USE OF THIS PLATFORM</b></li>
                        </ul>
                        <ol>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">This Platform is for User’s personal use. User shall not copy, replicate, modify, distribute, display, perform, create derivative works from, transfer or sell any information (including the content hosted on the Platform such as articles, audio clips, videos, etc.), software, products or services obtained from this Platform whether printed, visual, electronic, e-mail forwarding, digital transmission, scanned, website re-cycled or any other form of material except where expressly noted that the material is available for such purpose. As a condition of User’s use of this Platform, User hereby warrant that User will not use this Platform for any purpose that is unlawful or prohibited by these Terms and Conditions of Use or otherwise.</span></li>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">User further agrees and acknowledges that any unauthorized or unlawful use of this Platform would result in irreparable injury to UNITEDWECARE for which monetary damages may be inadequate, and in such event UNITEDWECARE, shall have the right, in addition to other remedies available at law and in equity, to obtain immediate injunctive relief against User. Nothing contained in these Terms and Conditions of Use shall be construed to limit remedies available pursuant to statutory or other claims that UNITEDWECARE may have under separate legal authority.</span></li>
                        </ol>
                        <ul>
                            <li aria-level="1"><b>GENERAL CONDITIONS</b></li>
                        </ul>
                        <ol>
                            <li style="list-style-type: none;">
                                <ol>
                                    <li style="list-style-type: none;">
                                        <ol>
                                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">UNITEDWECARE reserves the right to refuse Service to anyone for any reason at any time.</span></li>
                                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Users (s) understand that the content (not including credit card information of any User), may be transferred unencrypted and involve (a) transmissions over various networks; (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks.&nbsp;</span></li>
                                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">UNITEDWECARE reserves the right to add, amend or discontinue, temporarily or permanently, any of its Services (or any part thereof) in future offered through the Platform or the design and functionality of the Platform, with or without notice and/or reason(s). User agrees that UNITEDWECARE shall not be liable either to User or to any third party for any modification, suspension or discontinuance of any of the Services including without limitation any failure of performance, error, omission, interruption, deletion, loss of information, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of records, whether for breach of contract, tortuous actions, negligence, or under any other cause or action.</span></li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <ul>
                            <li aria-level="1"><b>MODE OF PAYMENT</b></li>
                        </ul>
                        <ol>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">You acknowledge that payment made by net banking / debit card / credit card / mobile wallets (if any) would be in accordance with the prevailing guidelines issued by the Reserve Bank of India or the Central Government as the case may be, including guidelines relating to know-your-customer norms / anti-money laundering and combatting of financing of terrorism. Further, We will not be liable for any charges occurring due to dishonour of payment instructions.</span></li>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">UNITEDWECARE does not implicitly or explicitly support or endorse the sale or purchase of any Services on the Platform. UNITEDWECARE accepts no liability for any errors or omissions, whether on behalf of itself or third parties.</span></li>
                        </ol>
                        <ul>
                            <li aria-level="1"><b>PROHIBITED ACTIONS FOR USERS</b></li>
                        </ul>
                        <ol>
                            <li style="list-style-type: none;">
                                <ol>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">We outrightly prohibit You from the following actions:</span></li>
                                </ol>
                            </li>
                        </ol>
                        <ol>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">use the Platforms for any purpose other than as expressly permitted by Us.&nbsp;</span></li>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">not to hold Us responsible for any links to other websites or application which are beyond Our control.&nbsp;</span></li>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">not to hold Us responsible for the third-party advertisements that may be placed/made use of on the Platform. You acknowledge that such advertisements are placed by third parties and We are not directly or indirectly responsible for such advertisement or for any links contained therein.</span></li>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">not to unauthorize Us from using the user generated content on the Platform for any of the business purposes, even following the termination of Your registration.</span></li>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">post on the Platform any content, whether or not containing links, for any of Our competitor.&nbsp;</span></li>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">the Platform or any portion of this Platform may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any commercial purpose without the express written consent of UNITEDWECARE. You may not systematically extract and/or re-utilise parts of the contents of the Platform without the express written consent of UNITEDWECARE. In particular, You may not utilize any data mining, robots, or similar data gathering and extraction tools to extract (whether once or many times) for re-utilization of any substantial parts of the Platform without the express written consent of UNITEDWECARE.</span></li>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">collect, post or submit on this Platform any incomplete, fictitious, false or inaccurate information.&nbsp;</span></li>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">aggregate, copy or duplicate in any manner any content or information available from this Platforms.&nbsp;</span></li>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">upload, share or otherwise distribute any text, graphics, images, videos or data which victimize, harass, degrade, or intimidate an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age or disability.</span></li>
                        </ol>
                        <p>&nbsp;</p>
                        <ul>
                            <li aria-level="1"><b>INTELLECTUAL PROPERTY RIGHTS (IPR) RESTRICTION</b></li>
                        </ul>
                        <ol>
                            <li style="list-style-type: none;">
                                <ol>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">This site is controlled and operated by UNITEDWECARE. All material on this site and/or the Platforms, including (but not limited to) assistance/service provided by Stella AI Chatbot, articles, posts, blogs, images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks, trade secrets, and other intellectual property rights thereon, all of which vest exclusively with UNITEDWECARE. Material on Platform is solely for Your personal, non-commercial use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by email or other electronic means and whether directly or indirectly and You must not assist any other person to do so. Without the prior written consent of the owner, modification of the materials, use of the materials on any other Platform or networked computer environment or use of the materials for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks and other intellectual property rights, and is strictly prohibited. Any use for which You receive any remuneration, whether monetarily or otherwise, is construed as a commercial use for the purposes of this clause and UNITEDWECARE shall hold you accountable and liable for infringement of trademark, copyright, trade secrets and other intellectual property rights under the relevant prevailing laws in India.&nbsp;</span></li>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The User confirms and undertakes not to display or use the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights of any third party on the Platform. The User further agrees to indemnify and hold Us, Our directors, employees, affiliates and assigns harmless against all costs, damages, loss and harm including towards litigation costs and counsel fees, in respect of any third party claims that may be initiated including for infringement of Intellectual Property Rights arising out of such display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on the Platform, by User or through the User’s commissions or omissions.</span></li>
                                </ol>
                            </li>
                            <li style="font-weight: 400;" aria-level="1"><b>REFUND POLICY</b><span style="font-weight: 400;">&nbsp;</span>
                                <ol>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">We may, in certain exceptional circumstances and at our sole and absolute discretion, refund the amount to the User after deducting applicable charges and taxes, if any. Such exceptional circumstances are provided hereinbelow:</span></li>
                                </ol>
                            </li>
                        </ol>
                        <ol>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">In the event of any excess </span><span style="font-weight: 400;">payment towards the Services has been made by the User due to </span><span style="font-weight: 400;">any technical glitch on the Platform or payment gateway.</span></li>
                        </ol>
                        <ol>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Subject to clause 15.1 above, all monies payable to UNITEDWECARE by the User(s) on consummated transactions, i.e., exchange of Services for consideration, are final and non-refundable. UNITEDWECARE will not be liable to process the refund of any paid amount in the event of any unsatisfactory Services.</span></li>
                        </ol>
                        <ul>
                            <li aria-level="1"><b>RELEASE AND LIMITATIONS OF LIABILITY</b></li>
                        </ul>
                        <ol>
                            <li style="list-style-type: none;">
                                <ol>
                                    <li style="list-style-type: none;">
                                        <ol>
                                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Users shall access the Platform voluntarily and at their own risk. We shall under no circumstances be held responsible or liable on account of any loss or damage sustained (including but not limited to any accident, injury, death, loss of property) by Users or any other person or entity during the course of access to and/or use of the Platform, including claims based on malpractice.</span></li>
                                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">By accessing the Platform, Users hereby release from and agree to indemnify Us, and/or any of Our directors, employees, partners, associates and licensors, against all liability, cost, loss or expense arising out of their access to the Platform including but not limited to personal injury and damage to property, whether direct, indirect, consequential, or foreseeable, caused due to any negligent act or omission on their part, or otherwise.</span></li>
                                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Users shall be solely responsible for any consequence which may arise due to their access and use of the Platform, including conducting an illegal/fraudulent act or non-conformity with these Terms of Use and other rules and regulations in relation to the Platform, including provision of incorrect address or other personal details. Users also undertake to indemnify Us and Our respective officers, directors, employees and agents on the happening of such an event (including without limitation, cost of attorney, etc.) on full indemnity basis for any loss/damage suffered by Us on account of such act on the part of the Users.</span></li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <ul>
                            <li aria-level="1"><b>GOVERNING LAW AND DISPUTE RESOLUTION</b></li>
                        </ul>
                        <p><span style="font-weight: 400;">These terms shall be governed by and constructed in accordance with the laws of India without reference to conflict of laws, principles and disputes arising in relation hereto shall be subject to the exclusive jurisdiction of courts, tribunals, fora, applicable authorities at Gurugram.&nbsp;</span></p>
                        <ul>
                            <li aria-level="1"><b>TAXES</b></li>
                        </ul>
                        <p><span style="font-weight: 400;">You shall be responsible for payment of all fees/costs/charges associated with availing of Services from Us and You agree to bear any and all applicable taxes including but not limited to GST, duties, cess etc.</span></p>
                        <ul>
                            <li aria-level="1"><b>WAIVER</b></li>
                        </ul>
                        <p><span style="font-weight: 400;">Any failure or delay by a party to enforce or exercise any provision of these Terms of Use, or any related right, shall not constitute a waiver by such party of that provision or right. To clarify, if a User breaches any of these conditions and We do not initiate any action against the same, We will still be entitled to use our rights and remedies in any other situation where You breach these conditions. Any waiver by a party shall only be made in writing and executed by a duly authorized officer of such party.</span></p>
                        <ol>
                            <li style="list-style-type: none;">
                                <ol>
                                    <li style="font-weight: 400;" aria-level="1"><b>MISCELLANEOUS</b>
                                        <ol>
                                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">We may be required under applicable law, to notify Users of certain events. User hereby acknowledges and consents that such notices will be effective upon Our posting them on the Platform or delivering them to the User through the email address provided by the User at the time of registration. User (s) may update their email address by logging into their account on the Platform. If they do not provide Us with accurate information, We shall not be held liable for failure to notify the User.</span></li>
                                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">We shall not be liable for any delay or failure to perform resulting from causes outside Our reasonable control, including but not limited to any failure to perform due to unforeseen circumstances or cause beyond Our control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, network infrastructure failures, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.&nbsp;</span></li>
                                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Users agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of the Platform or these Terms must be filed within 15 (fifteen) days of such claim or cause of action arising or be forever barred from making the claim.</span></li>
                                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">If any part of these Terms of Use is determined to be indefinite, invalid, or otherwise unenforceable, the rest of these Terms of Use shall continue in full force.</span></li>
                                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">We reserve the right to moderate, restrict or ban the use of the Platform, specifically to any User, or generally, in accordance with Our policy/policies amended from time to time, at Our sole and absolute discretion and without any notice.</span></li>
                                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">We may, at Our sole and absolute discretion, permanently close or temporarily suspend any services provided on the Platform.</span></li>
                                        </ol>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                        <ul>
                            <li aria-level="1"><b>DISCLAIMERS</b></li>
                        </ul>
                        <ol>
                            <li style="list-style-type: none;">
                                <ol>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Unless otherwise explicitly specified, We are not affiliated in any way to and claim no association, in any capacity whatsoever, with any organization, association, institution, business, trust, authority, governing bodies and leagues, whether local or national or international.</span></li>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">In no event, UNITEDWECARE shall be liable to any User on account of such User’s use or reliance on Stella AI Chatbot, for any damages whatsoever, including direct, special, punitive, indirect, consequential or incidental damages or damages for tort (including negligence), even if UNITEDWECARE is aware of or has been advised of the possibility of such damage, arising out of or connected with the use or performance of the Stella AI Charbot. UNITEDWECARE does not assume any legal liability or responsibility for the accuracy, completeness or usefulness of any information disclosed or provided by Stella AI Chatbot.</span></li>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">All such information is provided “as is” without warranty of any kind. UNITEDWECARE hereby disclaim any warranties and conditions with regard to the information provided by Stella AI Chatbot and in no event shall UNITEDWECARE be liable for any direct, indirect, punitive, incidental, special or consequential damages arising out of or in any way connected with the access or use of Stella AI Chatbot on the Platform.&nbsp;</span></li>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">The User assumes all responsibility and risk associated, if any, for the use of the Stella AI Chatbot on the Platform and will independently judge whether the information or advice being provided by Stella AI Chatbot is accurate and will work best for the User or not.</span></li>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">To the extent permitted under law, We shall not be responsible for any harm resulting from downloading or accessing any information or material vide the Platform, the quality of servers. We disclaim any responsibility for any technical glitches on the servers or the technology that underlies Our Platforms, failures by service providers (including telecommunications, hosting, and power providers), computer viruses, natural disasters or other destruction or damage of Our facilities, acts of nature, war, civil disturbance, or any other cause beyond Our reasonable control, which affect the functioning of the Platform(s). In addition, We do not provide any warranty as to the content on the Platform.&nbsp;</span></li>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">Any material accessed, downloaded or otherwise obtained through Us and/or the Platform shall be User’s sole discretion, competence, acceptance and risk. The User will be solely responsible for any potential damage to User’s computer system/mobile or loss of data that results from a User’s download of any such material.</span></li>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">We shall make best endeavors to ensure that the Platform and experience through it is error-free and secure, however, neither We nor any of Our partners or associates make any warranty that:</span></li>
                                </ol>
                            </li>
                        </ol>
                        <ol>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">the Platforms will meet Users’ requirements;</span></li>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">The Platforms will be uninterrupted, timely, secure, or error free;</span></li>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">the listings on the Platform will be accurate or reliable; and</span></li>
                            <li style="font-weight: 400;" aria-level="3"><span style="font-weight: 400;">the quality of Services, information, or other material that Users avail or obtain through the Platform will meet Users’ expectations.</span></li>
                        </ol>
                        <ol>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">To the extent permitted under law, neither We nor our partners or associates shall be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use of or inability to use Our website/mobile application, even if We have been advised of the possibility of such damages.</span></li>
                        </ol>
                        <ol>
                            <li style="font-weight: 400;" aria-level="1"><b>CONTACT</b><b> AND COMPLAINT</b>
                                <ol>
                                    <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">In the event the User has any questions, feedback, suggestions, experience, comments and requests (“</span><b>Feedback</b><span style="font-weight: 400;">”) regarding these Terms of Use such respective User may address the same to the designated grievance officer in respect of the Platform. Any complaints or concerns with regards to any content on the Platform or any breach of the Privacy Policy or Terms of Use or any other policy can be directed to the designated officer in writing and the details are as follows:</span></li>
                                </ol>
                            </li>
                        </ol>
                        <p><span style="font-weight: 400;">United For Her Private Limited, Tower 2-9A,&nbsp;</span></p>
                        <p><span style="font-weight: 400;">Sector 50, Gurugram, Haryana 122018,</span></p>
                        <p>&nbsp;</p>
                        <ol>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">A complaint or concern may also be raised through an email to </span><span style="font-weight: 400;">[ contactus ( at ) unitedwecare (dot) com</span><span style="font-weight: 400;">]</span><span style="font-weight: 400;">.&nbsp;</span></li>
                        </ol>
                        <ol>
                            <li style="font-weight: 400;" aria-level="1"><span style="font-weight: 400;">The Feedback, compliant or concern shall be deemed to be non-confidential and non-compensatory in nature. UNITEDWECARE reserves the right, at its sole discretion to use such information in any manner as may be deemed appropriate by UNITEDWECARE and such use shall be entirely unrestricted.</span></li>
                        </ol>
                        <p>&nbsp;</p>
                        <ol>
                            <li style="font-weight: 400;" aria-level="2"><span style="font-weight: 400;">User further represent and warrant that while posting any Feedback for the Platforms, User shall not use any offensive, libelous, derogatory, hateful or racially or ethnically objectionable language. Further, User shall not post any content on any part on the Platform that is obscene, pornographic and that all such Feedback will be in accordance with the applicable law.</span></li>
                        </ol>
                        <p><span style="font-weight: 400;">☐</span><i><span style="font-weight: 400;"> By reading so far, you indicate that you have read and agree to our Terms of Use.</span></i></p>
                    </div> */}
                    <div className='bg-white px-5 term_of_use' style={{ boxShadow: "3px 6px 20px rgba(93, 134, 194, 0.12)", borderRadius: "12px" }}>
                        <p className="font-inter fs-4 lh-lg font-w600 text-black"><span class="c1">TERMS OF USE</span></p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        {/* <p class="c3">
                            <span class="c1">Last Updated: </span><span class="c1">April 11</span
                            ><span class="c2">, 2022</span>
                        </p> */}
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 start font-w500" start="1">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">Agreement to Terms of Use</span>
                            </li>
                        </ol>
                        <p class="c3" id="h.gjdgxs">
                            <span
                            >The following Terms of Use (&ldquo;Terms&rdquo; or
                                &ldquo;Agreement&rdquo;) govern your access to and use of </span
                            ><span class="c20">UNITED WE CARE INC</span
                            ><span
                            >&nbsp;(&ldquo;Company&rdquo;) website, mobile application for Android
                                and iOS devices (collectively &ldquo;the Platform&rdquo;),</span
                            ><span class="c18 c31">&nbsp;</span
                            ><span
                            >and mobile-based and/or web browser based AI chatbot service
                                functioning known as &lsquo;Stella&rsquo; (which is </span
                            ><span class="c15">an artificial </span
                            ><span class="c25">intelligence-based</span
                            ><span class="c15"
                            >&nbsp;chatbot which through comprehension of natural language holds a
                                preliminary conversation with a user according to each user&rsquo;s
                                relevant specifications. It also facilitates the conducting of various
                                tests, assessments, and screeners for </span
                            ><span class="c25">user</span><span class="c15">s</span
                            ><span class="c15">&nbsp;of the Platform</span
                            ><span
                            >&nbsp;hereinafter the &ldquo;Stella AI Chatbot&rdquo;), and all other
                                services we provide. &nbsp;The Services shall include but not be limited
                                to services made available for you, for a license, for a fee, including
                                any online services and other web-based services identified on the
                                services list. Services also include consulting, counseling, job
                                search/and application, undertaking various psychometric test(s)
                                pertaining to depression, anxiety, stress, yoga sessions, workout
                                sessions, meditation sessions, etc., and other services or advice
                                provided through the Platforms including voice assistance provided
                                through Stella AI Chatbot on the Platform, and any service that a
                                service professional provides on the line reservation system of the
                                Website or Application through which service professionals can make
                                their services available for reservation, and through which users can
                                make reservations for such services, the facilitated payment, customer
                                service and the communication service (&ldquo;Services&rdquo;). Please
                                read the Terms carefully. Company grants you </span
                            ><span class="c17"
                            >a limited, non-exclusive, non-sublicensable, revocable,
                                non-transferable license to access the Platform and use the Services in
                                accordance with these Terms.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span class="c2"
                            >Your use of the Services constitutes your agreement to be bound by all
                                terms. If you disagree with one or more of these terms or find them
                                unacceptable in any way, please do not use the Services.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span
                            >The Company makes certain telehealth-related and other health-related
                                information available to you and/or facilitates access to telemedicine
                                and medical services.</span
                            ><span class="c17">&nbsp;</span
                            ><span class="c2"
                            >This Agreement is divided into two parts. Part One explains all of the
                                terms that govern your use of the Services. Part Two contains additional
                                legal terms, including provisions that limit our liability to you and
                                require individual arbitration for any potential legal dispute. To use
                                the Services, you must accept all of the terms of this Agreement.
                                &nbsp;</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span
                            >Downloading Our mobile application from Google Play Store or Apple App
                                Store, you will also be subject to Google Play Terms of Service or Apple
                                App Store Terms of Service, respectively. If there is any conflict
                                between Google Play Store Terms of Service or Apple App Store Terms of
                                Service and these Terms of Use with respect to a User&rsquo;s use of the
                                Android and/or iOS mobile platform application, then, these Terms of Use
                                shall prevail.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="2">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">Changes to Terms of Use</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >We have the right to change or add to the terms of this Agreement at
                                any time by posting the amended Terms on the Platform. Any use of the
                                Services after our publication of any such changes shall constitute your
                                acceptance of the Terms as modified. However, any dispute that arose
                                before the modification shall be governed by the Terms (including the
                                binding individual arbitration clause) that were published and in place
                                at the time when the dispute arose.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span class="c1"
                            >Part One: Terms Governing Use of the Platform and Services and Stella
                                AI Chatbot</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="3">
                            <li class="c3 c6 li-bullet-0"><span class="c1">Emergencies</span></li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >THIS PLATFORM IS NOT AN EMERGENCY-RESPONSE OR EMERGENCY-MONITORING
                                SERVICE AND ANY PERSON WHO IS AWARE OF AN EMERGENCY SITUATION OR
                                BELIEVES THAT A PERSON MAY BE AT RISK OF INJURY OR DEATH OR WHO MAY HARM
                                THEMSELVES OR ANOTHER PERSON SHOULD DIAL &ldquo;911&rdquo; OR AN
                                APPROPRIATE EMERGENCY RESPONDER. THE COMPANY IS UNDER NO OBLIGATION TO
                                MONITOR OR RESPOND TO COMMUNICATIONS MADE TO THIS PLATFORM.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="4">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">Patient&#39;s Responsibility to be under the care of Independent
                                    Healthcare Professionals</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span>The Company&rsquo;s</span
                            ><span class="c2"
                            >&nbsp;role is limited to making certain telehealth-related information
                                and services available to you from physicians and health professionals
                                affiliated with the Company. The Company provides this information and
                                consulting services, offering insights and findings for your information
                                and also for you to share, if desired, with your doctor, who can
                                independently evaluate the information and make recommendations with
                                them. The Company&rsquo;s medical services are limited to its specific
                                areas of focus and are not intended to take the place of primary care or
                                specialist physicians who you may see for your ongoing healthcare
                                needs.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="5">
                            <li class="c3 c6 li-bullet-0"><span class="c1">Age Limitations</span></li>
                        </ol>
                        <p class="c3 c14">
                            <span class="c2"
                            >The Platform is not intended for anyone under the age of 18, without
                                the consent of a parent or guardian. If you are under 18 years of age,
                                please do not use or access the Platform, without the consent of a
                                parent or guardian. By using the Platform, you affirm that you are over
                                the age of 18 or that you have the consent of a parent or guardian. The
                                Company does not seek through the Platform to gather personal
                                information from or about persons under the age of 18 without the
                                consent of a parent or guardian.</span
                            >
                        </p>
                        <p class="c3 c14 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="6">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">Stella AI Chatbot</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c21 c25"
                            >Included on the Platform is the Stella AI Chatbot which will be used to
                                automatically hold a conversation with you. In this regard, Stella AI
                                Chatbot uses artificial intelligence and comprehension of natural
                                language to give answers to phrases (questions) according to your
                                relevant specifications. The conversation with Stella AI Chatbot is
                                deemed to have commenced from the moment you write in the window chat or
                                when by opening the window chat the Stella AI Chatbot executes an
                                action, such as bringing information through an integration or opening
                                an Ad (proactive tool). Please note that you are deemed to have
                                acknowledged and accepted all conditions, warranties, risks,
                                liabilities, disclaimers, etc. with respect to the Stella AI Chatbot at
                                the time of downloading/accessing the Platform.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c17 c21"></span></p>
                        <p class="c3">
                            <span class="c15"
                            >You can have unlimited interaction with Stella AI Chatbot, however, the
                                interaction will be deemed to be ended in the following cases: (i)
                                inactivity by you for a term of 5 (five) minutes; (ii) rerouting the
                                conversation to another channel such as telephone operator</span
                            ><span class="c25">,</span
                            ><span class="c10"
                            >&nbsp;etc.; (iii) active option to end the conversation in the chat
                                window. Further, each time that you close the browser/window of the
                                browser wherein an active conversation is ongoing with Stella AI
                                Chatbot, the conversation with Stella AI Chatbot will be deemed to have
                                ended. Each time you start a conversation with Stella AI Chatbot after
                                it has been deemed to have ended for any of the aforementioned reasons,
                                you shall be deemed to have started a fresh conversation with Stella AI
                                Chatbot. Whenever you start a fresh conversation with Stella AI Chatbot,
                                you will not have access to any previous conversations that you might
                                have had with Stella AI Chatbot.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c24 c9"></span></p>
                        <p class="c3">
                            <span class="c9"
                            >YOUR INTERACTION WITH STELLA AI CHATBOT IS WITH AN ARTIFICIAL
                                INTELLIGENCE CHATBOT AND NOT A HUMAN. STELLA AI CHATBOT IS RESTRICTED IN
                                THE MANNER OF RESPONSE AND ITS INTENDED USE IS FOR THE PURPOSE OF
                                PROVIDING THE FIRSTHAND INFORMATION AVAILABLE PERTAINING TO THE CONCERN
                                RAISED BY YOU. STELLA AI CHATBOT IS NOT IN ANY WAY INTENDED TO PROVIDE
                                DIAGNOSIS, TREATMENT</span
                            ><span class="c16">,</span><span class="c9">&nbsp;AND CURE </span
                            ><span class="c16">FOR</span
                            ><span class="c9">&nbsp;ANY ISSUE, DISEASE, DISORDER</span
                            ><span class="c16">,</span><span class="c9">&nbsp;AND ILLNESS.</span
                            ><span class="c15">&nbsp;</span
                            ><span class="c9"
                            >STELLA AI CHATBOT IS ONLY A FACILITATOR FOR YOU TO ACCESS AND UNDERTAKE
                                TESTS, ASSESSMENTS</span
                            ><span class="c16">,</span
                            ><span class="c9"
                            >&nbsp;AND SCREENERS. STELLA AI CHATBOT DOES NOT GUARANTEE ANY SPECIFIC
                                OUTCOME OF THE TESTS, ASSESSMENT</span
                            ><span class="c16">,</span
                            ><span class="c9"
                            >&nbsp;AND SCREENERS, AND IS NOT RESPONSIBLE FOR ANY OUTCOME THEREOF.
                                THE RESULTS AND THE ANALYSIS OF THE STELLA AI CHATBOT ARE ONLY
                                INDICATIVE, AND NOT CONCLUSIVE IN NATURE. STELLA AI CHATBOT USES SOME
                                WORKSHEETS AND DESCRIPTIONS FOR CONDUCTING THE TESTS, </span
                            ><span class="c16">ASSESSMENTS,</span
                            ><span class="c9 c24">&nbsp;AND SCREENERS ON THE PLATFORM. </span>
                        </p>
                        <p class="c3 c5"><span class="c24 c9"></span></p>
                        <p class="c3">
                            <span class="c9"
                            >STELLA AI CHATBOT SHALL NOT BE RESPONSIBLE FOR ANY CLAIMS ARISING OUT
                                OF THE CONTENT OF THESE WORKSHEETS AND DESCRIPTIONS. STELLA AI CHATBOT
                                CANNOT AND WILL NOT OFFER ADV</span
                            ><span class="c16">IC</span
                            ><span class="c9">E OR INFORMATION ON THE CONCERNS OR </span
                            ><span class="c16">ISSUES</span
                            ><span class="c9">&nbsp;RAISED BY YOU THAT </span
                            ><span class="c16">ARE</span
                            ><span class="c9">&nbsp;NOT RECOGNIZED BY STELLA AI CHATBOT.</span>
                        </p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="7">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1"
                                >Information on this Platform is Not Professional Advice</span
                                >
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >All data, information, text, graphics, links, and other material on
                                this Platform are provided as a convenience to our Platform visitors.
                                Except as to the information provided or communicated by the
                                Company&rsquo;s affiliated health professionals in the course of a
                                telehealth encounter directly between you and a health professional, the
                                information provided on this Platform is for general informational and
                                educational purposes only. The information provided on our Platform is
                                not intended to serve as medical or other professional advice and is not
                                to be used for diagnosis or treatment of any condition or symptom.
                                Information provided on this Platform does not constitute the provision
                                or practice of medical or professional health care advice or
                                services.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span class="c2"
                            >You should consult your physician or other qualified healthcare
                                provider regarding any specific questions you may have. You should never
                                disregard professional advice or delay in seeking treatment based on the
                                information contained on this Platform or other websites linked to or
                                from it.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="8">
                            <li class="c3 c6 li-bullet-0"><span class="c1">Restricted Use</span></li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >You may use this Platform only to the extent that you obey all laws,
                                rules, and regulations applicable to your use of this Platform. In using
                                the Platform, you agree not to:
                            </span>
                        </p>
                        <ul class="c13 lst-kix_list_2-0 start">
                            <li class="c3 c7 li-bullet-0">
                                <span class="c2"
                                >Send or otherwise transmit to or through the Platform any unlawful,
                                    infringing, harmful, harassing, defamatory, threatening, hateful</span
                                ><span>,</span
                                ><span class="c2"
                                >&nbsp;or otherwise objectionable material of any kind, any material
                                    that can cause harm or delay to the Platform or computers of any kind,
                                    and any unsolicited advertising, solicitation, or promotional
                                    materials;</span
                                >
                            </li>
                            <li class="c3 c7 li-bullet-0">
                                <span class="c2"
                                >Misrepresent your identity or affiliation in any way;</span
                                >
                            </li>
                            <li class="c3 c7 li-bullet-0">
                                <span class="c2"
                                >Restrict or inhibit any person from using the Platform, </span
                                ><span>disclosing</span
                                ><span class="c2"
                                >&nbsp;personal information obtained from the Platform</span
                                ><span>,</span><span class="c2">&nbsp;or </span><span>collecting</span
                                ><span class="c2">&nbsp;information about users of the Platform;</span>
                            </li>
                            <li class="c3 c7 li-bullet-0">
                                <span class="c2"
                                >Reverse engineer, disassemble or decompile any section or technology
                                    on the Platform, or attempt to do any of the foregoing;</span
                                >
                            </li>
                        </ul>
                        <ul class="c13 lst-kix_list_2-1 start">
                            <li class="c3 c4 li-bullet-0">
                                <span class="c2"
                                >Gain unauthorized access to the Platform, to other users&rsquo;
                                    accounts, names, personally identifiable information, or other
                                    information, or to other computers or websites connected or linked to
                                    the Platform;</span
                                >
                            </li>
                            <li class="c3 c4 li-bullet-0">
                                <span class="c2"
                                >Launch or use any automated system, including without limitation
                                    &ldquo;robots,&rdquo; &ldquo;spiders,&rdquo; or &ldquo;offline
                                    readers,&rdquo; that access the Platform in a manner that sends more
                                    request messages to our servers in a given period of time than a human
                                    can reasonably produce in the same period by using a conventional web
                                    browser;</span
                                >
                            </li>
                            <li class="c3 c4 li-bullet-0">
                                <span class="c2"
                                >Send or otherwise transmit to or through the Platform chain letters,
                                    unsolicited messages, so-called &ldquo;spamming&rdquo; or
                                    &ldquo;phishing&rdquo; messages, or messages marketing or advertising
                                    goods and services;</span
                                >
                            </li>
                            <li class="c3 c4 li-bullet-0">
                                <span class="c2"
                                >Post, transmit or otherwise make available any virus, worm,
                                    spyware</span
                                ><span>,</span
                                ><span class="c2">&nbsp;or any other computer code, file</span
                                ><span>,</span
                                ><span class="c2"
                                >&nbsp;or program that may or is intended to damage or hijack the
                                    operation of any hardware, software, or telecommunications
                                    equipment;</span
                                >
                            </li>
                            <li class="c3 c4 li-bullet-0">
                                <span class="c2"
                                >Violate any applicable laws or regulations in any way;</span
                                >
                            </li>
                            <li class="c3 c4 li-bullet-0">
                                <span class="c2"
                                >Alter or modify any part of the content or services offered on or
                                    through the Platform;</span
                                >
                            </li>
                            <li class="c3 c4 li-bullet-0">
                                <span class="c2"
                                >Allow any other person to use the Platforms with your registration or
                                    login information;</span
                                >
                            </li>
                            <li class="c3 c4 li-bullet-0">
                                <span class="c2"
                                >Breach or otherwise circumvent Company&rsquo;s security or
                                    authentication measures; and</span
                                >
                            </li>
                            <li class="c3 c4 li-bullet-0">
                                <span class="c2"
                                >Assist or permit any persons in engaging in any of the activities
                                    described above.</span
                                >
                            </li>
                        </ul>
                        <p class="c3 c14 c5 c27"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="9">
                            <li class="c3 c6 li-bullet-0"><span class="c1">Your Privacy</span></li>
                        </ol>
                        <p class="c3">
                            <span
                            >Upon acceptance of these Terms, you confirm that you have read,
                                understood, and accepted Company&rsquo;s </span
                            ><span class="c18">Online Privacy Policy</span
                            ><span
                            >. You acknowledge that any questions about the use of your personal
                                information (including y</span
                            ><span class="c17"
                            >our name, email address, mailing address, gender, date of birth, any
                                personal or protected health information, or, any data about you that
                                you elect to provide electronically through the Platform and any other
                                information that identifies who you are) may be used by Company solely
                                in accordance with these Terms and Company&#39;s </span
                            ><span class="c18 c17">Online</span><span class="c17">&nbsp;</span
                            ><span class="c17 c18">Privacy Policy</span
                            ><span class="c2"
                            >&nbsp;(or as otherwise agreed upon between you and Company in
                                writing).</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="10">
                            <li class="c3 c6 li-bullet-0"><span class="c1">Registration</span></li>
                        </ol>
                        <p class="c3">
                            <span class="c17"
                            >To enable your access and use of the Services listed on the Platforms,
                                you are obligated to register on the Platform</span
                            ><span class="c15"
                            >&nbsp;by providing certain information which shall be verified with a
                                one-time password to your mobile number</span
                            ><span class="c17"
                            >. Upon successful registration on the Platforms, you will be entitled
                                to a virtual space (&ldquo;User Account&rdquo;) which shall be utilized
                                solely to browse and book the Services that may be offered by the
                                service professionals/partners to you and is not to be utilized for any
                                other non-permitted purposes. </span
                            ><span class="c15"
                            >If you use the Platform(s), You shall be responsible for maintaining
                                the confidentiality of your display name and password </span
                            ><span>(&ldquo;Credentials&rdquo;), </span
                            ><span class="c15"
                            >and you shall be responsible for all activities that occur under your
                                display name and password. </span
                            ><span
                            >You should keep your Credentials private and not share your Credentials
                                with anyone else. You are responsible for maintaining the
                                confidentiality of your Credentials.</span
                            ><span class="c15"
                            >&nbsp;You agree that if you provide any information that is untrue,
                                inaccurate, not current or incomplete, or we have reasonable grounds to
                                suspect that such information is untrue, inaccurate, not current or
                                incomplete, or not in accordance with these Terms of Use, we have the
                                right to indefinitely suspend or terminate or block access of your
                                Account on the Platform and cease your access to the Platform(s</span
                            ><span
                            >. If you believe someone has accessed the Platform using your
                                Credentials without your authorization, e-mail us immediately at </span
                            ><span class="c12"
                            ><a class="c0" href="mailto:helpUS@unitedwecare.com"
                            >helpUS@unitedwecare.com</a
                                ></span
                            ><span class="c2">.</span>
                        </p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="11">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">Registration Charges</span>
                            </li>
                        </ol>
                        <p class="c19">
                            <span class="c17">The Company </span
                            ><span class="c15"
                            >does not charge any fee for browsing/ accessing the Platform and is
                                thus free for its user(s), however, </span
                            ><span class="c17">the Company</span
                            ><span class="c15"
                            >&nbsp;reserves the right to change its fee policy, from time to time.
                            </span>
                        </p>
                        <p class="c19">
                            <span class="c15"
                            >In addition to the service fees payable to a service professional(s) to
                                enable a booking, users will also be charged a convenience fee upon
                                booking an appointment with such service professional(s) for the
                                Services booked and obtained through the Platform(s).</span
                            >
                        </p>
                        <p class="c19">
                            <span class="c17">The Company</span
                            ><span class="c15"
                            >&nbsp;reserves the right, at its sole discretion, to introduce new
                                Services and/or modify some or all of the existing Services offered on
                                the Platform(s). In such an event, </span
                            ><span class="c17">the Company</span
                            ><span class="c15"
                            >&nbsp;reserves the right to introduce a fee for the new/ existing
                                Service(s) offered or Service(s) modified, as the case may be. Changes
                                to the fee policy shall be posted on the Platform and such changes shall
                                become effective immediately.
                            </span>
                        </p>
                        <p class="c36 c39">
                            <span class="c15"
                            >Unless otherwise stated, all fees shall be quoted and paid in US
                                Dollars and all payments shall be subject to deductions/taxes/levies as </span
                            ><span class="c25">may be</span
                            ><span class="c15"
                            >&nbsp;applicable under law. You shall be solely responsible for
                                compliance </span
                            ><span class="c25">with</span
                            ><span class="c15"
                            >&nbsp;all applicable laws including for making payments to a service
                                professional(s).</span
                            >
                        </p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="12">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">Use of the Platform</span>
                            </li>
                        </ol>
                        <p class="c19">
                            <span class="c2"
                            >This Platform is for your personal use. You shall not copy, replicate,
                                modify, distribute, display, perform, create derivative works from,
                                transfer, or sell any information (including the content hosted on the
                                Platform such as articles, audio clips, videos, etc.), software,
                                products or services obtained from this Platform whether printed,
                                visual, electronic, e-mail forwarding, digital transmission, scanned,
                                website re-cycled or any other form of material except where expressly
                                noted that the material is available for such purpose. As a condition of
                                your use of this Platform, you hereby warrant that you will not use this
                                Platform for any purpose that is unlawful or prohibited by these Terms
                                of Use or otherwise.</span
                            >
                        </p>
                        <p class="c3">
                            <span class="c2"
                            >You further agree and acknowledge that any unauthorized or unlawful use
                                of this Platform would result in irreparable injury to the Company for
                                which monetary damages may be inadequate, and in such event Company,
                                shall have the right, in addition to other remedies available at law and
                                in equity, to obtain immediate injunctive relief against you. Nothing
                                contained in these Terms of Use shall be construed to limit remedies
                                available pursuant to statutory or other claims that the Company may
                                have in law or equity.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="13">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1"
                                >Services under the &ldquo;Our Community&rdquo; Section</span
                                >
                            </li>
                        </ol>
                        <p class="c19">
                            <span class="c2"
                            >As part of the Services available on the Platform, you can also access
                                content and avail yourself</span
                            ><span>&nbsp;</span
                            ><span class="c2"
                            >of Services on a variety of subject matters (including but not limited
                                to yoga sessions, meditation sessions, workout sessions, etc.) by
                                services professional(s).</span
                            >
                        </p>
                        <p class="c3 c36">
                            <span class="c2"
                            >The content available as part of Our Community Section is available in
                                the form of video, audio, text as well as a combination thereof. All
                                rights, </span
                            ><span>titles,</span><span class="c2">&nbsp;and </span
                            ><span>interests</span
                            ><span class="c2"
                            >&nbsp;including all intellectual property rights in the content hosted
                                on the Platform as part of the Community Section including images,
                                videos, literary works, musical works, modifications, enhancements, etc.
                                shall belong to and vest entirely with the Company or the service
                                professional(s). &nbsp;</span
                            >
                        </p>
                        <p class="c3 c5 c36"><span class="c2"></span></p>
                        <p class="c3 c36">
                            <span class="c2"
                            >You will be able to view some content (which could either be in the
                                form of text, audio</span
                            ><span>,</span
                            ><span class="c2"
                            >&nbsp;or video) of the Service Professional(s) engaged as part of Our
                                Community Section. You will have the option to book/reserve sessions
                                with the service professional(s) for accessing/availing live/recorded
                                sessions of such service professional(s) at the specified fee. You</span
                            ><span>&nbsp;</span
                            ><span class="c2"
                            >agree that the live/recorded sessions of such service professional(s)
                                which have been bought by them can only be accessed/availed through the
                                Platform. You agree that the engagement with the service professional(s)
                                is only for the purposes of availing the Services bought by you.</span
                            >
                        </p>
                        <p class="c3 c36 c5 c40"><span class="c2"></span></p>
                        <p class="c3 c36">
                            <span class="c2"
                            >YOU HEREBY AGREE THAT THE COMPANY WILL HAVE NO LIABILITY IN ENSURING
                                THAT THE CONTENT (EITHER LIVE/RECORDED) BEING MADE AVAILABLE BY THE
                                SERVICE PROFESSIONAL(S) AS PART OF THE PAID SESSIONS IS IN COMPLIANCE
                                WITH THE PREVAILING LAWS. YOU HEREBY AGREE TO WAIVE ANY LEGAL CLAIM
                                AGAINST THE COMPANY AS REGARDS THE CONTENT OF THE SERVICE(S) BEING MADE
                                AVAILABLE BY THE SERVICE PROFESSIONAL(S) AS PART OF THE PAID
                                SESSIONS.</span
                            >
                        </p>
                        <p class="c3 c36 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span
                            >THE COMPANY DISCLAIMS ANY GUARANTEE INCLUDING (BUT NOT LIMITED TO) AS
                                REGARDS THE ACCURACY OF THE TYPE, DURATION, AND SATISFACTION OF THE
                                SERVICES RENDERED FACILITATED OR PROVIDED TO YOU AS PART OF OUR
                                COMMUNITY SECTION. FURTHER, THE COMPANY DOES NOT MAKE ANY REPRESENTATION
                                THAT SERVICES PROVIDED BY THE SERVICE PROFESSIONAL(S) AS PART OF OUR
                                COMMUNITY SECTION ARE APPROPRIATE OR SAFE FOR USE. YOU HEREBY AGREE THAT
                                THE SERVICES OFFERED AS PART OF OUR COMMUNITY SECTION, BY THEIR VERY
                                NATURE, INCLUDE CERTAIN INHERENT RISKS THAT CANNOT BE ELIMINATED
                                REGARDLESS OF THE CARE TAKEN TO AVOID INJURIES AND/OR ANY KIND OF
                                LOSSES. YOU HEREBY UNEQUIVOCALLY ASSERT THAT YOUR PARTICIPATION IS
                                ENTIRELY VOLUNTARY AND THAT YOU KNOWINGLY ASSUME ALL SUCH RISKS AND
                                HENCE HEREBY RELEASE THE SERVICE PROFESSIONAL(S) AND THE PLATFORM OF ALL
                                LIABILITY ARISING OUT OF SUCH AFOREMENTIONED RISKS.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="14">
                            <li class="c3 c6 li-bullet-0"><span class="c1">Transactions</span></li>
                        </ol>
                        <p class="c3">
                            <span
                            >If you wish to purchase products or services described or linked to on
                                the Platform (each such purchase, a &ldquo;Transaction&rdquo;), the
                                Company or the third party provider of the product or service will
                                request certain information from you that is applicable to your
                                Transaction, including, without limitation, credit card and other
                                payment and shipping information. You understand that, if any such
                                information is provided to Company, then Company shall treat any such
                                information in the manner described in our </span
                            ><span class="c11">Online Privacy Policy</span
                            ><sup><a href="#cmnt1" id="cmnt_ref1">[a]</a></sup
                            ><span class="c2"
                            >. By supplying such information, you grant Company the right to provide
                                such information to third parties for purposes of facilitating the
                                completion of Transactions initiated by you or on your behalf. You agree
                                to pay all charges incurred by you or any users of your membership
                                account or credit card (or other applicable payment mechanism) at the
                                price(s) in effect when such charges are incurred, including, without
                                limitation, all shipping and handling charges. You shall also be
                                responsible for paying any applicable taxes relating to your purchases.
                                YOU REPRESENT AND WARRANT THAT YOU HAVE THE LEGAL RIGHT TO USE ANY
                                CREDIT CARD(S) OR OTHER PAYMENT MECHANISM USED IN CONNECTION WITH ANY
                                TRANSACTION.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span
                            >Descriptions or images of, or references to, products or services on
                                the Platform do not imply the Company&rsquo;s endorsement of such
                                products or services. We reserve the right, without prior notification,
                                to change such descriptions or references, to limit the order quantity
                                on any product or service, and/or to refuse service to you. Verification
                                of information applicable to a purchase may be required prior to the
                                Company&rsquo;s acceptance of any order. The price and availability of
                                any product or service are subject to change without notice. The Company
                                is not responsible for errors in the prices or descriptions of any
                                product or service. Refunds and exchanges shall be subject to
                                Company&rsquo;s and/or any applicable third party&rsquo;s refund and
                                exchange policies in effect at the time of the applicable Transaction.
                                Current rates for any product or service available through the Platform
                                may be obtained by sending an email to </span
                            ><span class="c12"
                            ><a class="c0" href="mailto:helpUS@unitedwecare.com"
                            >helpUS@unitedwecare.com</a
                                ></span
                            ><span>.</span>
                        </p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="15">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c18">&nbsp;Refund Policy</span>
                            </li>
                        </ol>
                        <p class="c19">
                            <span class="c2"
                            >We may, in certain exceptional circumstances and at our sole and
                                absolute discretion, refund the amount to you after deducting applicable
                                charges and taxes, if any. Such exceptional circumstances are provided
                                herein below:</span
                            >
                        </p>
                        <ol class="c13 lst-kix_list_1-1 start" start="1">
                            <li class="c3 c32 li-bullet-0">
                                <span class="c10"
                                >In the event of any cancellation of appointments/meetings by a
                                    service professional or rescheduling of meetings by a service
                                    professional to a time not suitable for you, then any amount paid for
                                    the booking of such appointment/meeting shall be refunded to you. It
                                    is clarified that UNITEDWECARE shall not be liable to refund any
                                    amounts in the event of any unsatisfactory services from a service
                                    professional</span
                                ><span class="c2">.</span><span class="c10">&nbsp; </span>
                            </li>
                            <li class="c3 c32 li-bullet-0">
                                <span class="c10 c14">In the event of any excess </span
                                ><span class="c10"
                                >payment towards the Services has been made by you due to </span
                                ><span class="c10 c14"
                                >any technical glitch on the Platform or payment gateway.</span
                                >
                            </li>
                            <li class="c3 c32 li-bullet-0">
                                <span class="c10"
                                >Subject to the above, all monies payable to the Company by you on
                                    consummated transactions, i.e., exchange of Services for
                                    consideration, are final and non-refundable. The Company will not be
                                    liable to process the refund of any paid amount in the event of any
                                    unsatisfactory services obtained from </span
                                ><span class="c25">the </span
                                ><span class="c10">service professional(s).</span>
                            </li>
                            <li class="c19 c35 li-bullet-0">
                                <span class="c10"
                                >In the event that you fail to attend any appointment/meeting with the
                                    service professional at the time specified for the
                                    appointment/meeting, you will not be entitled to any refund in respect
                                    of the session booked by you. Subject to the above, the Company will
                                    not be liable to refund any amount to you for your failure to attend
                                    the appointment/meeting with the service professional at the time
                                    specified for the appointment/meeting.</span
                                >
                            </li>
                        </ol>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="16">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">Social Media and Online Communities</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >The Company may provide you opportunities to share information on
                                third-party social media sites or platforms such as Facebook, Instagram,
                                LinkedIn, Twitter, Google+, or other similar sites (collectively,
                                &ldquo;Social Media Sites&rdquo;). The Company may also provide you
                                opportunities to participate in online communities on such Social Media
                                Sites and may host discussion boards, chats, and other forums on this
                                Platform. For example, you may use your Instagram handle and tag your
                                photos and postings (&ldquo;Tagged Content&rdquo;) with hashtags that we
                                may provide from time to time in order to submit your Tagged Content for
                                potential use on our Platform. If you post Tagged Content with hashtags
                                as we may provide from time to time, your activity and participation is
                                governed by these Terms. Additionally, you remain responsible for your
                                compliance with other applicable Terms such as those of Instagram or
                                other Social Media Sites. Without limitation of any other obligations,
                                you agree that you will be respectful of others and their privacy and
                                will not submit photos of others without their express permission. The
                                Company may terminate or restrict your access to the Platform at any
                                time.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="17">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Selection and Removal of Tagged Content </span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span
                            >The Company will review Tagged Content and select certain Tagged
                                Content for posting on our Platform; we make no guarantee that your
                                Tagged Content will be posted. Notwithstanding the foregoing, you remain
                                fully responsible and liable for your Tagged Content and its compliance
                                with these Terms, other applicable terms of use, and applicable laws. If
                                your Tagged Content is posted to our Platform, you may request removal
                                of your Tagged Content by marking it as &ldquo;private&rdquo; in your
                                Social Media Platform account or by emailing us at </span
                            ><span class="c12"
                            ><a class="c0" href="mailto:helpUS@unitedwecare.com"
                            >helpUS@unitedwecare.com</a
                                ></span
                            ><span class="c2">.</span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="18">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Monitoring</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >We have no obligation to monitor any related websites, chats,
                                discussion boards, or any other materials that you or third parties
                                transmit or post on or to the Platform or related websites, if any.
                                However, you acknowledge and agree that we have the right (but not the
                                obligation) to monitor the Platform, discussion boards, chats, and the
                                materials you transmit or post; to alter or remove any such materials
                                (including, without limitation, any posting to a chat or discussion
                                board); and to disclose such materials and the circumstances surrounding
                                their transmission to any third party in order to operate the Platform
                                properly, to protect ourselves, our sponsors, and our other clients and
                                visitors, and to comply with legal obligations or governmental
                                requests.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="19">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Copyright Notice</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span
                            >This Platform is owned and operated solely by Company. The entire
                                contents and design of the Platform are protected by U.S. and
                                international copyright law</span
                            ><span class="c17">. The Company owns all trademarks, trade secrets</span
                            ><span>,</span
                            ><span class="c17"
                            >&nbsp;and other intellectual property rights, in and to the Platform
                                and any related materials and documentation. No title or ownership of
                                the Platform or any portion thereof is transferred to you hereunder.
                                Company reserves all rights not expressly granted hereunder. You agree
                                not to change or delete any copyright or proprietary notice related to
                                materials downloaded from the Platform. </span
                            ><span class="c2"
                            >All rights regarding the Platform and materials contained on the
                                Platform are either owned by Company, are licensed to it, or are used
                                with permission. The Company and its licensors retain and reserve all
                                proprietary rights to the contents of this Platform.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span
                            >You may not copy, republish, upload, post, display, transmit, or frame
                                any of these materials without prior written consent from Company. You
                                may link to, view, download, use, display, and print a single copy of
                                the materials found on this Platform only for personal, noncommercial,
                                and informational purposes as long as: (1) you do not alter or modify
                                the materials in any way; (2) you include all applicable copyright,
                                trademark and other notices and disclaimers; and (3) you do not use the
                                materials in a way that suggests an association with Company or an
                                affiliated entity. All such copies must include, at a minimum, the
                                following copyright notice: &ldquo;</span
                            ><span class="c18"
                            >Copyright &copy; 2022 UNITED WE CARE INC All rights reserved</span
                            ><span
                            >.&rdquo; Any other use of the Platform or the information contained
                                here is strictly prohibited. The Company may terminate the above license
                                at any time for any reason. If you breach any of these terms your
                                license terminates immediately and automatically and without notice.
                                Upon the termination of this license, you must stop using this Platform,
                                including all content, and return or destroy all copies, including
                                electronic copies, of the content in your possession or control.
                            </span>
                        </p>
                        <p class="c3 c14">
                            <span class="c2"
                            >You may provide input, comments, or suggestions regarding the Platform
                                (&ldquo;Feedback&rdquo;). You acknowledge and agree that Company may use
                                any Feedback without any obligation to you and you hereby grant Company
                                a worldwide, perpetual, irrevocable, royalty-free, transferable license
                                to reproduce, display, perform, distribute, publish, modify, edit, or
                                otherwise use such Feedback as Company may deem appropriate, without
                                restriction, for any and all commercial and/or non-commercial purposes,
                                in its sole discretion.</span
                            >
                        </p>
                        <p class="c3 c14 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="20">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Copyright Infringement - DMCA Notice</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span
                            >The Digital Millennium Copyright Act (the &ldquo;DMCA&rdquo;) provides
                                remedies for copyright holders who believe in good faith that material
                                appearing on the Internet infringes their rights under copyright law. If
                                you believe in good faith that content or material on this Platform
                                infringes a valid copyright owned by you, you (or your agent) may send
                                Company a notice requesting that the material be removed, or access to
                                it blocked. This request should be sent to </span
                            ><span class="c12"
                            ><a class="c0" href="mailto:helpUS@unitedwecare.com"
                            >helpUS@unitedwecare.com</a
                                ></span
                            ><span class="c2">.</span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span class="c2"
                            >The notice must include the following information: (a) a physical or
                                electronic signature of a person authorized to act on behalf of the
                                owner of an exclusive right that is allegedly infringed; (b)
                                identification of the copyrighted work claimed to have been infringed;
                                (c) identification of the material that is claimed to be infringing or
                                the subject of infringing activity; (d) the name, address, telephone
                                number, and email address of the complaining party; (e) a statement that
                                the complaining party has a good faith belief that use of the material
                                in the manner complained of is not authorized by the copyright owner,
                                its agent or the law; and (f) a statement that the information in the
                                notification is accurate and, under penalty of perjury, that the
                                complaining party is authorized to act on behalf of the owner of an
                                exclusive right that is allegedly infringed. If you believe in good
                                faith that a notice of copyright infringement has been wrongly filed
                                against you, the DMCA permits you to send us a counter-notice. Notices
                                and counter-notices must meet the then-current statutory requirements
                                imposed by the DMCA. Notices and counter-notices with respect to the
                                Platform should be sent to the email address above.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="21">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Trademark Notice</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >Company names and logos and all related product and service names,
                                design marks, and slogans are the trademarks or service marks of the
                                Company. All rights are reserved. You are not authorized to use any
                                Company name or mark in any advertisement, publicity, or in any other
                                commercial manner without prior written consent of the Company. All
                                other trademarks appearing on the Platform are the property of their
                                respective owners.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="22">
                            <li class="c3 c6 li-bullet-0"><span class="c1">&nbsp;Security</span></li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >We have implemented technical and organizational measures designed to
                                secure your personal information from accidental loss and from
                                unauthorized access, use, alteration, or disclosure. However, we cannot
                                guarantee that unauthorized third parties will never be able to defeat
                                those measures or use your personal information for improper purposes.
                                You acknowledge that you provide your personal information at your own
                                risk.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3"><span class="c1">Part Two: Additional Legal Terms</span></p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="23">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Representation and Warranties</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span
                            >You represent and warrant to us that: (a) you are at least eighteen
                                (18) years of age; and (b) your use of this Platform will be in
                                compliance with these Terms; </span
                            ><span class="c2"
                            >(iii) the information you have provided to Company in your registration
                                is accurate and complete, (iv) you will comply with any and all laws
                                applicable to your use of the Platform, (v) you will not interfere with
                                a third party&#39;s use and enjoyment of the Platform, (vi) you will not
                                interfere with or disrupt Company&rsquo;s or its vendors&rsquo; security
                                measures, (vii) if any information you provide to Company becomes
                                inaccurate, incomplete or otherwise false or misleading, you will
                                immediately notify Company, (viii) you acknowledge that any fees paid by
                                you or by any other persons on your behalf are not provided in exchange
                                for any undertaking by Company or its representatives that such
                                healthcare services will be made available to you, and (ix) you are
                                accessing the Platform for yourself or a child under the age of eighteen
                                (18) for whom you are the legal guardian.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="24">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;No Warranties</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span
                            >USE OF THIS PLATFORM AND STELLA AI CHATBOT IS AT YOUR OWN RISK AND
                                CONTENT ON THE PLATFORM IS PROVIDED ON AN &ldquo;AS IS&rdquo; OR
                                &ldquo;AS AVAILABLE&rdquo; BASIS TO THE MAXIMUM EXTENT PERMITTED BY
                                APPLICABLE LAW, THE PLATFORM, STELLA AI CHATBOT, AND SERVICES ARE
                                PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                                INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
                                FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR
                                INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE COMPANY
                                OR THROUGH THIS PLATFORM OR STELLA AI CHATBOT WILL CREATE ANY WARRANTY
                                NOT EXPRESSLY STATED HEREIN. WITHOUT LIMITING THE FOREGOING, COMPANY,
                                ANY AFFILIATED COMPANY ENTITY, THEIR PROCESSORS, PROVIDERS, LICENSORS
                                (AND THEIR RESPECTIVE SUBSIDIARIES, AFFILIATES, AGENTS, DIRECTORS,
                                OFFICERS, EMPLOYEES, CONTRACTORS, AND REPRESENTATIVES) (COLLECTIVELY
                                &ldquo;COMPANY PARTIES&rdquo;) DO NOT WARRANT THAT THE CONTENT IS
                                ACCURATE, ERROR-FREE, RELIABLE, COMPLETE OR CORRECT; THAT THIS PLATFORM
                                OR STELLA AI CHATBOT WILL MEET YOUR REQUIREMENTS; THAT THIS PLATFORM OR
                                STELLA AI CHATBOT WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
                                UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED;
                                OR THAT THIS PLATFORM IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
                                ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THIS
                                PLATFORM IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY
                                RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY OR LOSS OF DATA THAT RESULTS
                                FROM SUCH DOWNLOAD.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span class="c2"
                            >COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
                                FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY
                                THROUGH THIS PLATFORM OR ANY HYPERLINKED WEBSITE OR SERVICE, OR FEATURED
                                IN ANY BANNER OR OTHER ADVERTISING, AND THE COMPANY WILL NOT BE A PARTY
                                TO OR IN ANY WAY MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY
                                PROVIDERS OF PRODUCTS OR SERVICES.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span class="c9"
                            >YOUR INTERACTION WITH STELLA AI CHATBOT IS WITH AN ARTIFICIAL
                                INTELLIGENCE CHATBOT AND NOT A HUMAN. STELLA AI CHATBOT IS RESTRICTED IN
                                THE MANNER OF RESPONSE AND ITS INTENDED USE IS FOR THE PURPOSE OF
                                PROVIDING THE FIRSTHAND INFORMATION AVAILABLE PERTAINING TO THE CONCERN
                                RAISED BY YOU. STELLA AI CHATBOT IS NOT IN ANY WAY INTENDED TO PROVIDE
                                DIAGNOSIS, TREATMENT</span
                            ><span class="c16">,</span
                            ><span class="c9">&nbsp;AND CURE OF ANY ISSUE, DISEASE, DISORDER</span
                            ><span class="c16">,</span><span class="c9">&nbsp;AND ILLNESS.</span
                            ><span class="c15">&nbsp;</span
                            ><span class="c9"
                            >STELLA AI CHATBOT IS ONLY A FACILITATOR FOR YOU TO ACCESS AND UNDERTAKE
                                TESTS, ASSESSMENTS</span
                            ><span class="c16">,</span
                            ><span class="c9"
                            >&nbsp;AND SCREENERS. STELLA AI CHATBOT DOES NOT GUARANTEE ANY SPECIFIC
                                OUTCOME OF THE TESTS, ASSESSMENT</span
                            ><span class="c16">,</span
                            ><span class="c9"
                            >&nbsp;AND SCREENERS, AND IS NOT RESPONSIBLE FOR ANY OUTCOME THEREOF.
                                THE RESULTS AND THE ANALYSIS OF THE STELLA AI CHATBOT ARE ONLY
                                INDICATIVE, AND NOT CONCLUSIVE IN NATURE. STELLA AI CHATBOT USES SOME
                                WORKSHEETS AND DESCRIPTIONS FOR CONDUCTING THE TESTS, </span
                            ><span class="c16">ASSESSMENTS,</span
                            ><span class="c24 c9">&nbsp;AND SCREENERS ON THE PLATFORM. </span>
                        </p>
                        <p class="c3 c5"><span class="c24 c9"></span></p>
                        <p class="c3">
                            <span class="c9"
                            >STELLA AI CHATBOT SHALL NOT BE RESPONSIBLE FOR ANY CLAIMS ARISING OUT
                                OF THE CONTENT OF THESE WORKSHEETS AND DESCRIPTIONS. STELLA AI CHATBOT
                                CANNOT AND WILL NOT OFFER ADVICE OR INFORMATION ON THE CONCERNS OR </span
                            ><span class="c16">ISSUES</span
                            ><span class="c9">&nbsp;RAISED BY YOU THAT </span
                            ><span class="c16">ARE</span
                            ><span class="c9">&nbsp;NOT RECOGNIZED BY STELLA AI CHATBOT.</span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="25">
                            <li class="c3 c6 li-bullet-0"><span class="c1">&nbsp;Indemnity</span></li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >BY VISITING THIS PLATFORM AND/OR USING THE STELLA AI CHATBOT, YOU AGREE
                                TO INDEMNIFY, DEFEND, AND HOLD HARMLESS COMPANY, AND COMPANY PARTIES
                                FROM AND AGAINST ALL CLAIMS, ACTIONS, DEMANDS, LIABILITIES, JUDGMENTS,
                                SETTLEMENTS, COSTS, LOSSES, DAMAGES, TAX ASSESSMENTS, PENALTIES,
                                INTEREST AND EXPENSES (INCLUDING, WITHOUT LIMITATION, REASONABLE
                                ATTORNEYS&rsquo; FEES) ARISING OUT OF ANY DIRECT, INDIRECT, INCIDENTAL,
                                CONSEQUENTIAL, SPECIAL, EXEMPLARY, PUNITIVE OR ANY OTHER CLAIM YOU MAY
                                INCUR IN CONNECTION WITH YOUR USE OF THIS PLATFORM, INCLUDING, WITHOUT
                                LIMITATION, ANY CLAIMS FOR DEFAMATION, VIOLATION OF PUBLICITY OR
                                PRIVACY, COPYRIGHT OR TRADEMARK INFRINGEMENT RESULTING FROM YOUR
                                SUBMISSIONS OF ANY CONTENT, TAGGED CONTENT OR OTHER MATERIALS, ANY
                                ECONOMIC HARM, LOST PROFITS, DAMAGES TO BUSINESS, DATA OR COMPUTER
                                SYSTEMS, OR ANY DAMAGES RESULTING FROM RELIANCE ON ANY CONTENT OR
                                RESULTING FROM ANY INTERRUPTIONS, WORK STOPPAGES, COMPUTER FAILURES,
                                DELETION OF FILES, ERRORS, OMISSIONS, INACCURACIES, DEFECTS, VIRUSES,
                                DELAYS OR MISTAKES OF ANY KIND, EVEN IF YOU HAVE PREVIOUSLY ADVISED
                                COMPANY OF THE POSSIBILITY OF SUCH CLAIM.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="26">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Limitation of Liability and Damages</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >YOUR SOLE REMEDY FOR DISSATISFACTION WITH THE PLATFORM AND/OR STELLA AI
                                CHATBOT AND ANY OF ITS SERVICES IS TO STOP USING THE PLATFORM, STELLA AI
                                CHATBOT, OR SERVICE. YOU AGREE THAT UNDER NO CIRCUMSTANCE SHALL ANY OF
                                THE COMPANY PARTIES BE LIABLE FOR ANY DAMAGE RESULTING FROM YOUR USE OR
                                INABILITY TO USE THIS PLATFORM, STELLA AI CHATBOT, OR THE MATERIALS ON
                                THIS PLATFORM. THIS PROTECTION COVERS CLAIMS BASED ON WARRANTY,
                                CONTRACT, TORT, STRICT LIABILITY, AND ANY OTHER LEGAL THEORY. THIS
                                PROTECTION COVERS ALL LOSSES AND CLAIMS OF ANY TYPE INCLUDING, WITHOUT
                                LIMITATION, DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, RELIANCE,
                                CONSEQUENTIAL, EXEMPLARY, AND PUNITIVE DAMAGES, PERSONAL INJURY/WRONGFUL
                                DEATH, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS
                                INTERRUPTION.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="27">
                            <li class="c3 c6 li-bullet-0"><span class="c1">&nbsp;Disputes</span></li>
                        </ol>
                        <p class="c3">
                            <span
                            >If a dispute of any kind arises, we want to understand and address your
                                concerns quickly and to your satisfaction. Please contact us at </span
                            ><span class="c12"
                            ><a class="c0" href="mailto:helpUS@unitedwecare.com"
                            >helpUS@unitedwecare.com</a
                                ></span
                            ><span>&nbsp;</span
                            ><span class="c2"
                            >with any dispute. If we cannot resolve your concerns, we agree to an
                                informal and inexpensive dispute resolution process requiring individual
                                arbitration. &ldquo;Disputes&rdquo; between you and Company, including
                                Company Parties, are defined for the purposes of these Terms to include
                                any claim, controversy, or dispute (whether involving contract, tort,
                                equitable, statutory, or any other legal theory) between you and Company
                                including, but not limited to, any claims relating in any way to these
                                Terms (including its breach, termination, or interpretation), any other
                                aspect of our relationship, Company advertising, and any use of Company
                                services.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="28">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Binding Individual Arbitration</span>
                            </li>
                        </ol>
                        <p class="c36 c37">
                            <span
                            >You and Company agree to arbitrate all Disputes. Arbitration is more
                                informal than a lawsuit in court and seeks to resolve disputes more
                                quickly. Instead of a judge or a jury, the case will be decided by a
                                neutral arbitrator who has the power to award the same damages and
                                relief that a court can. ANY ARBITRATION UNDER THIS AGREEMENT WILL BE
                                ONLY BE ON AN INDIVIDUAL BASIS; CLASS ARBITRATIONS, CLASS ACTIONS,
                                PRIVATE ATTORNEY GENERAL ACTIONS, AND CONSOLIDATION WITH OTHER
                                ARBITRATIONS ARE NOT PERMITTED, AND YOU ARE WAIVING YOUR RIGHTS TO HAVE
                                YOUR CASE DECIDED BY A JURY AND TO PARTICIPATE IN A CLASS ACTION AGAINST
                                COMPANY. If any provision of this arbitration agreement is found
                                unenforceable, the unenforceable provision shall be severed, and the
                                remaining arbitration terms shall be enforced (but in no case shall
                                there be a class arbitration). All Disputes shall be resolved finally
                                and exclusively by binding individual arbitration with a single
                                arbitrator administered by a neutral service agreed to by the parties
                                according to this provision and the applicable arbitration rules for
                                that forum. The Uniform Arbitration Act (10 Del. C. &sect; 5701 et </span
                            ><span class="c11"
                            ><a
                                class="c0"
                                href="https://www.google.com/url?q=https://www.lawinsider.com/clause/seq&amp;sa=D&amp;source=editors&amp;ust=1687520515139983&amp;usg=AOvVaw2A1TspLItjZ58LK8ph1s0H"
                            >seq</a
                                ></span
                            ><span class="c2"
                            >.), fully applies. Any arbitration hearing will occur in Delaware,
                                another mutually agreeable location, or a location ordered by the
                                arbitrator. The arbitrator&rsquo;s award shall be binding on the parties
                                and may be entered as a judgment in any court of competent jurisdiction.
                                If you prevail on any claim for which you are legally entitled to
                                attorney&rsquo;s fees, you may recover those fees from the arbitrator.
                                For any claim where you are seeking relief, Company will not seek to
                                have you pay its attorney&rsquo;s fees, even if fees might otherwise be
                                awarded unless the arbitrator determines that your claim was frivolous.
                                For purposes of this arbitration provision, references to you and
                                Company also include respective subsidiaries, affiliates, agents,
                                employees, predecessors, successors, and assigns.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="29">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Jurisdiction</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >Information provided on Company&rsquo;s Platform is not targeted to
                                users in any particular locality nor is it intended to constitute the
                                doing of business in any jurisdiction.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span class="c2"
                            >Use of this Platform is prohibited in any jurisdiction having laws that
                                would void this Agreement in whole or essential part or which make
                                accessing the Platform illegal. Users in such jurisdictions visit and
                                use this Platform entirely at their own risk. Note: the essential parts
                                of this Agreement include, without limitation, the exclusive remedy
                                provisions, and the warranty disclaimers.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <p class="c3">
                            <span class="c2"
                            >This Agreement is entered into and performed in the State of Delaware,
                                United States of America. It is governed by and shall be construed under
                                the laws of Delaware, exclusive of any choice of law or conflict of law
                                provisions.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="30">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Requests for Information </span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span
                            >If you contact Company and request information about our programs or
                                services, we will use your email or postal address to provide the
                                information you requested. You agree that we may use and share
                                information you provide us as described in our </span
                            ><span class="c11">Online Privacy Policy</span><span class="c1">.</span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="31">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Limitation on Time to Initiate a Dispute</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >Unless otherwise required by law, an action or proceeding by you
                                relating to any Dispute must commence within one year after the cause of
                                action accrues.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="32">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">Links to Other Websites</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >Company Platform may contain links to third-party websites as a
                                convenience to you. The inclusion of any website link does imply an
                                approval, endorsement, or recommendation by Company. You agree that you
                                access any such website at your own risk and that the Platform is not
                                governed by the Terms contained in these Terms. The Company expressly
                                disclaims any liability for these websites. Please remember that when
                                you use a link to go from our Platform to another website,
                                Company&rsquo;s Online Privacy Policy is no longer in effect. Your
                                browsing and interaction on any other website, including those that have
                                a link on our Platform, is subject to that website&rsquo;s own rules and
                                policies.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="33">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">Downloadable Files and Email </span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >The Company cannot and does not guarantee or warrant that email or
                                files available for downloading from its Platform will be free of
                                viruses or other code that may contaminate or destroy data on your
                                computer. You are responsible for implementing sufficient protective
                                procedures and checks to maintain the accuracy of your data for
                                maintaining a data backup or other means for the reconstruction of any
                                lost data. The Company does not assume any responsibility or risk for
                                damage to your computer or its files related to your use of the Platform
                                or Services.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="34">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Notice for California Users</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span
                            >If you have a question or complaint regarding the Services provided to
                                you by Company, please contact us at </span
                            ><span class="c12"
                            ><a class="c0" href="mailto:helpUS@unitedwecare.com"
                            >helpUS@unitedwecare.com</a
                                ></span
                            ><span
                            >&nbsp;to receive further information regarding the Services or to
                                resolve the complaint. You may also contact the Medical Board of
                                California by telephone at </span
                            ><span class="c8"
                            >(800)633-2322 or in writing at 2005 Evergreen St #1200, Sacramento, CA
                                95815,</span
                            ><span class="c2"
                            >&nbsp;and the California Department of Justice, by telephone at (888)
                                382-1222 or in writing at California Department of Justice; Attn: Public
                                Inquiry Unit; P.O. Box 944255; Sacramento, CA 94244-2550.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="35">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Assignment</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >You may not assign any rights or obligations under this Agreement
                                without Company&rsquo;s prior written consent. Company may assign all or
                                part of this Agreement.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="36">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Termination; Survival</span>
                            </li>
                        </ol>
                        <p class="c3 c14">
                            <span class="c2"
                            >If you violate these Terms, your ability to use the Platform will be
                                terminated. Company may, in its sole discretion, terminate your access
                                to the Platform, or any portion thereof, for any reason whatsoever
                                without prior notice. These actions are in addition to any other right
                                or remedy </span
                            ><span>the </span
                            ><span class="c2"
                            >Company may have available at law. Further, The Company shall not be
                                liable to you or any third party for any such termination or
                                discontinuance. You may terminate these Terms by ceasing to access and
                                use the Platform. Upon any termination of these Terms, you must
                                immediately cease use of the Platform. To the extent permitted by
                                applicable law, the disclaimers, limitations on liability,
                                termination</span
                            ><span>,</span
                            ><span class="c2"
                            >&nbsp;and your warranties and indemnities shall survive any termination
                                of these Terms. All sections of this Agreement which, by their nature
                                are designed to survive expiration or termination of this Agreement,
                                including but not limited to indemnity and limitation of liability
                                clauses, shall survive.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="37">
                            <li class="c3 c6 li-bullet-0"><span class="c1">Waiver</span></li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >No waiver of any of these terms shall be deemed a further or continuing
                                waiver of such term or condition or any other term or condition.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="38">
                            <li class="c3 c6 li-bullet-0"><span class="c1">&nbsp;Notices</span></li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >You agree that we may provide any and all notices to you by e-mail,
                                telephone, fax, as well as by any other method.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c1"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="39">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Severability</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >If any of the provisions of this Agreement are held unenforceable by a
                                court or other tribunal of competent jurisdiction, then those provisions
                                shall be limited or eliminated to the minimum extent necessary to allow
                                the remainder of this Agreement to retain its full force and effect.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="40">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Entire Agreement; Amendment</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >This Agreement constitutes the entire agreement between you and Company
                                applicable to its subject matter. It may not be modified except as
                                described elsewhere in this Agreement.
                            </span>
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="41">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Conflicting Terms </span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span class="c2"
                            >Anything on the Platform inconsistent or in conflict with the terms of
                                this Agreement is superseded by the terms of this Agreement.</span
                            >
                        </p>
                        <p class="c3 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="42">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Electronic Document</span>
                            </li>
                        </ol>
                        <p class="c3 c14">
                            <span class="c2"
                            >This electronic document, and all other electronic documents referred
                                to or incorporated herein, will be: (a) deemed for all purposes to be a
                                &ldquo;writing&rdquo; or &ldquo;in writing&rdquo;, and to comply with
                                all statutory, contractual, and other legal requirements for </span
                            ><span>a </span
                            ><span class="c2"
                            >writing; and (b) legally enforceable as a signed agreement. A printed
                                version of these Terms and any notice given in electronic form shall be
                                admissible in judicial proceedings or administrative proceedings based
                                upon or relating to these Terms to the same extent and subject to the
                                same conditions as other business documents and records originally
                                generated and maintained in printed form.</span
                            >
                        </p>
                        <p class="c3 c14 c5"><span class="c2"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="43">
                            <li class="c3 c6 c14 li-bullet-0">
                                <span class="c1">&nbsp;Language</span>
                            </li>
                        </ol>
                        <p class="c3 c14">
                            <span class="c2"
                            >English shall be the language of these Terms and the parties waive any
                                right to use and rely upon any other language or translations</span
                            ><span class="c22 c17">.</span>
                        </p>
                        <p class="c3 c14 c5"><span class="c17 c22"></span></p>
                        <ol class="c13 lst-kix_list_1-0 font-w600" start="44">
                            <li class="c3 c6 li-bullet-0">
                                <span class="c1">&nbsp;Contact Information</span>
                            </li>
                        </ol>
                        <p class="c3">
                            <span
                            >Please contact us with any questions or concerns regarding this
                                Agreement at </span
                            ><span class="c12"
                            ><a class="c0" href="mailto:helpUS@unitedwecare.com"
                            >helpUS@unitedwecare.com</a
                                ></span
                            ><span class="c2">.</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TermAndCondition