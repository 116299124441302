import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";

import TextField from "@mui/material/TextField";

import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import jwtDecode from "jwt-decode";

const ViewClientWorksheet = ({
  id,
  name,
  img,
  country,
  assignedOn,
  completedOn,
  status,
}) => {
 // console.log("CLIENT", id);

  const [questions, setQuestions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const getWorksheetData = async () => {
      try {
        const response = await fetch(
          `https://odoo.unitedwecare.ca/uwc_worksheet_data/read/${id}`,
          {
            method: "POST",
            headers,
          }
        );
        const postResponse = await response.json();

        //console.log(jwtDecode(postResponse?.data))
        setQuestions(jwtDecode(postResponse?.data)?.questions);
        //console.log("postResponse", postResponse);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    getWorksheetData();
  }, []);

  const isOptionSelected = (questionId, optionId) => {
    return selectedOptions[questionId]?.includes(optionId);
  };

  const handleChange = (questionId, optionId, isMultiSelect) => {
    setSelectedOptions((prevSelectedOptions) => {
      let updatedSelectedOptions = { ...prevSelectedOptions };

      if (isMultiSelect) {
        // For multi-select questions, toggle the selected option
        const currentSelectedOptions = prevSelectedOptions[questionId] || [];
        const isSelected = currentSelectedOptions.includes(optionId);

        if (isSelected) {
          updatedSelectedOptions[questionId] = currentSelectedOptions.filter(
            (selectedOption) => selectedOption !== optionId
          );
        } else {
          updatedSelectedOptions[questionId] = [
            ...currentSelectedOptions,
            optionId,
          ];
        }
      } else {
        // For single-select questions, only select the chosen option and unselect others
        updatedSelectedOptions[questionId] = [optionId];

        // Unselect other options for single-select questions
        const allQuestions = Object.keys(prevSelectedOptions);
        allQuestions.forEach((qId) => {
          if (qId !== questionId) {
            updatedSelectedOptions[qId] = [];
          }
        });
      }

      return updatedSelectedOptions;
    });
  };

 //console.log("questions", questions);

  return (
    <Grid container spacing={2} className="NewWorksheet--Grid">
      <Grid item xs={8} className="NewWorksheet--Left">
        <div className="bg-white rounded-2 p-3">
          {questions?.map((item) => (
            <div
              className="mt-3 bg-white rounded-2 Left--Container px-2"
              key={item?.question_id}
            >
              <span>Question</span>
              <h6>{item?.question_name}</h6>

              {item?.question_type === "shorttext" ||
              item?.question_type === "longtext" ? (
                <div>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Response"
                    variant="outlined"
                    disabled
                    value={item?.response == null ? "" : item?.response}
                  />
                </div>
              ) : null}

              {item?.question_type === "selection" ||
              item?.question_type === "multiselection" ? (
                <FormControl
                  sx={{ margin: "10px 0", width: "100%" }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormGroup>
                    {item?.options?.map((op) => (
                      <FormControlLabel
                        key={op?.option_id}
                        control={
                          <Checkbox
                            checked={isOptionSelected(
                              item?.question_id,
                              op?.option_id
                            )}
                            disabled
                            // onChange={() =>
                            //   handleChange(
                            //     item?.question_id,
                            //     op?.option_id,
                            //     item?.question_type === "multiselection"
                            //   )
                            // }
                          />
                        }
                        label={op?.option_name}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              ) : null}
            </div>
          ))}
        </div>
      </Grid>
      <Grid item xs={4} className="NewWorksheet--UserDetails">
        <div
          className="bg-white rounded-2 p-3"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={img}
            width={110}
            height={110}
            style={{ borderRadius: "50%" }}
          />
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h6
              style={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "130%",
              }}
            >
              {name}
            </h6>
            <span
              style={{
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "130%",
              }}
            >
              {country}
            </span>
          </div>

          <div
            className="d-flex align-items-center gap-2 User--Details"
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <div>
              <span>Assigned</span>
              <p className="mt-1">
                {moment(assignedOn).format("DD MMMM YYYY")}
              </p>
            </div>

            <div>
              <span>Completed</span>
              <p className="mt-1">
                {completedOn == "-"
                  ? "Ongoing"
                  : moment(completedOn).format("DD MMMM YYYY")}
              </p>
            </div>

            <div>
              <span>Status</span>
              <p className="complete mt-1">{status}</p>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default ViewClientWorksheet;
