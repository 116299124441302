import React from 'react'
import ExpertDetailsComp from '../components/ExpertDetails';
import { useParams } from "react-router-dom";

const ExpertDetails = () => { 
    let { id } = useParams();
    return (
        <ExpertDetailsComp id={id}/>
    )
}

export default ExpertDetails