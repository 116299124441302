import React from 'react'
import './Plans.css'
import HeroArea from './Components/HeroArea'
import PersonalDataArea from './Components/PersonalDataArea'
import PickUpPlans from './Components/PickUpPlans'
import ComparePlans from './Components/ComparePlans'
import TrustArea from './Components/TrustArea'
import ReasonArea from './Components/ReasonArea'
import ReviewArea from './Components/ReviewArea'
import FaqArea from './Components/FaqArea'
import PlanDetailsReviewSlide from '../planDetails/PlanDetailsReviewSlide'

const NewPlans2 = () => {
    return (
        <div className="main bg-white new-plans">
            <HeroArea bgType="blueGradient" />
            <PersonalDataArea />
            <PickUpPlans />
            <ComparePlans />
            <TrustArea bgType="pinkGradient" />
            <ReasonArea bgType="yellowGradient" />
            <ReviewArea />
            <FaqArea />
            <div style={{ clear: 'both' }}>
                <PlanDetailsReviewSlide />
            </div>
        </div>
    )
}

export default NewPlans2