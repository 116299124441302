import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UsOnBoardingDashboard from './UsOnBoardingDashboard'
import ExpertAppointmentDB from '../../components/expertDashboard/ExpertAppointmentDB'
import ExpertClientDB from '../../components/expertDashboard/ExpertClientDB'
import DashboardLayout from '../../components/expertDashboard/Dashboard';
import ExpertDashboard from '../../components/expertDashboard/ExpertDashboard'
import ExpertScheduleDB from '../../components/expertDashboard/ExpertScheduleDB'
import ExpertWorksheetsDB from '../../components/expertDashboard/ExpertWorksheetsDB'
import ExpertArticlesDB from '../../components/expertDashboard/ExpertArticlesDB'
import ExpertPostsDB from '../../components/expertDashboard/ExpertPostsDB'
import ExpertNotificationsDB from '../../components/expertDashboard/ExpertNotificationsDB'
import ExpertCommunitiesDB from '../../components/expertDashboard/ExpertCommunitiesDB'
import ExpertHelpCenterDB from '../../components/expertDashboard/ExpertHelpCenterDB'
import ExpertPayoutsDB from '../../components/expertDashboard/ExpertPayoutsDB'
import ExpertSettingsDB from '../../components/expertDashboard/ExpertSettingsDB'
import ExpertChatDB from '../../components/expertDashboard/ExpertChatDB'
import SessionManagement from '../../components/expertDashboard/ExpertSessionManagement'

const index = () => {
    return (
        <Routes>
            <Route element={<DashboardLayout />}>
                <Route path="dashboard" element={<ExpertDashboard />}/>
                <Route path='appointments' element={<ExpertAppointmentDB />} />
                <Route path='sessionmanagement' element={<SessionManagement/>}/>
                <Route path='clients' element={<ExpertClientDB />} />
                <Route path='chat' element={<ExpertChatDB />} />
                <Route path='my-schedule' element={<ExpertScheduleDB />} />
                <Route path='worksheets' element={<ExpertWorksheetsDB /> } />
                <Route path='articles' element={<ExpertArticlesDB /> } />
                <Route path='posts' element={<ExpertPostsDB /> } />
                <Route path='notifications' element={<ExpertNotificationsDB /> } />
                <Route path='communities' element={<ExpertCommunitiesDB /> } />
                <Route path='help-center' element={<ExpertHelpCenterDB /> } />
                <Route path='payouts' element={<ExpertPayoutsDB /> } />
                <Route path='settings' element={<ExpertSettingsDB /> } />
            </Route>
            {/* <Route path='/appointment' element={<Navigate replace to="my-courses" />} /> */}
        </Routes>
    )
}

export default index