const isUserLoggedIn = () => {
    const userID = localStorage.getItem("profile");

    if(userID == undefined || userID == null){
        return false;
    }

    return true;
}

export default isUserLoggedIn