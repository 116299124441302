import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '.././Plans.css'

const accItems = [
    {
        title: 'What benefits or features do I get with the subscription?',
        content: 'At United We Care, we offer a versatile subscription model designed to suit your needs. Our subscription plans include: <br /> <br /> Free Access: Gain entry to essential resources and tools through our free subscription, providing valuable support for mental well-being. <br /><br /> Premium Subscription: Upgrade to our premium subscription with an upfront fee to unlock additional features and exclusive content, enhancing your mental wellness journey. <br /><br /> Premium + Coaching: For an unparalleled experience, our premium subscription with coaching offers unlimited access to therapy sessions. This personalized support empowers you to thrive and flourish on your path to well-being.'
    },
    {
        title: 'How do I access the program content or resources once I subscribe?',
        content: 'Once you subscribe, you will gain access to our program content and resources through your personalized account on our website or mobile app. The platform is designed to be user-friendly, enabling easy navigation and utilization of our offerings.'
    },
    {
        title: 'Can I cancel or modify my subscription at any time, and what is the cancellation policy?',
        content: 'Yes, you can cancel or modify your subscription at any time. We believe in providing flexibility to our users. The details of our cancellation policy can be found in our terms and conditions or subscription agreement.'
    },
    {
        title: 'Is the program subscription suitable for individuals with specific mental health conditions or needs?',
        content: 'Yes, our program subscription is designed to cater to a wide range of mental health conditions and needs. Our platform offers tailored support and resources to meet individual requirements.'
    },
    {
        title: 'Are there any resources or tools available for self-help or self-assessment?',
        content: 'Yes, we offer a range of self-help resources and tools to support your mental well-being. From informative articles and guided exercises to self-assessment quizzes, our platform empowers you with valuable insights and techniques to foster personal growth.'
    },
    {
        title: 'Are there licensed professionals or experts available to support subscribers during the program?',
        content: 'Absolutely! We have a team of licensed professionals and mental health experts who are committed to supporting and guiding you throughout your journey. They are there to ensure you receive the best possible care and assistance.'
    },
    {
        title: 'Can I share the subscription with family members or friends?',
        content: 'The ability to share the subscription may depend on the type of subscription plan you choose. Please refer to our subscription terms for more details on sharing options.'
    },
];

const FaqArea = () => {
    return (
        <div>
            {/* <!------ FAQ-area (Desktop)------> */}
            <div className="faq-area mobile-display-none desktop-display">
                <div className="container">
                    <div className="faq-inner">
                        <h2 className="heading"><span className="diff-clr">Frequently</span> Asked Questions</h2>
                        <p className='font-inter text-center fs-4 font-w500 lh-lg'>All your questions answered</p>
                        <div className="row my-2">
                            <div className="col-lg-6 col-md-6">
                                {accItems.map((item, index) => (
                                    <Accordion className=''>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <h6 className='font-inter fs-6 font-w700 lh-lg text-left mb-0'>
                                                {item.title}
                                            </h6>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div dangerouslySetInnerHTML={{ __html: item?.content }} className='font-inter fs-6 font-w400 lh-base' >
                                                {/* {item.content} */}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="faq-img">
                                    <img src="assets/img/img10.jpg" className="align-middle" alt='' />
                                </div>
                            </div>
                        </div>
                        <div className="plan1-btn">
                            <button type="button" className="btn center-btn"><a href="#Subscribe_Now">Get Started</a></button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <!------ FAQ-area (Mobile)------> */}
            <div className="faq-area mobile-display desktop-display-none">
                <div className="container">
                    <div className="faq-inner">
                        <h2 className="heading"><span className="diff-clr">Frequently</span> Asked Questions</h2>
                        <p className='font-inter text-center fs-4 font-w500 lh-lg'>All your questions answered</p>
                        <div className="plan1-btn mt-3">
                            <button type="button" className="btn center-btn"><a href="#Subscribe_Now">Get Started</a></button>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="faq-img">
                                <img src="assets/img/img10.jpg" className="align-middle" alt='' />
                            </div>
                        </div>
                        <div className="row my-1">
                            <div className="col-lg-6 col-md-6">
                                {accItems.map((item, index) => (
                                    <Accordion className=''>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <h6 className='font-inter fs-6 font-w700 lh-lg text-left'>
                                                {item.title}
                                            </h6>
                                        </AccordionSummary>
                                        <AccordionDetails className="">
                                            <div dangerouslySetInnerHTML={{ __html: item?.content }} className='font-inter fs-6 font-w400 lh-base'>
                                                {/* {item.content} */}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqArea