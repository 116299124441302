import Cookies from 'universal-cookie';

export const getCountryCode = () => {
    const cookies = new Cookies();
    var countryCode = cookies.get('countryCode');
    return countryCode;
}

export const getCurrencyCode =()=> {
    const cookies = new Cookies();
    var countryCode = cookies.get('countryCode');
    if(countryCode == "IN") return "₹";
    else return "$";
}

// export const getPriceOnly = (amount) =>{
//     var Curny = JSON.parse(localStorage.getItem("Curny"));
//     if(Curny?.Currency === 'USD')
//     {
//         var cal_amt = Math.round((amount / Curny.CurrencyValue) + Curny.Margin_IN_Percentage);
//         return cal_amt;
//     }
//     return amount;
// }

// export const getPrice = (amount) =>{  
//     // var Curny = JSON.parse(decode(localStorage.getItem("Curny")));
//     var Curny = JSON.parse(localStorage.getItem("Curny"));
//     if(Curny?.Currency === 'USD')
//     {
//         var cal_amt = Math.round((amount / Curny.CurrencyValue) + Curny.Margin_IN_Percentage);
//         return cal_amt + " USD";
//     }
//     return amount + " INR";
// }

// export const getPriceType = () =>{
//     // var Curny = JSON.parse(decode(localStorage.getItem("Curny")));
//     var Curny = JSON.parse(localStorage.getItem("Curny"));
//     return Curny?.Currency;
// }

 