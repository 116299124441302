import { useState, useEffect } from "react";
import NotificationRowCard from "./cards/NotificationRowCard";
import moment from "moment";

import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../../auth";

//Helper function to sort notifications by date
const sortNotificationsByDate = (notifications) => {
  const sortedNotifications = notifications.slice();

  sortedNotifications.sort((a, b) => {
    const dateA = moment(a.date_time.split(" ")[0]);
    const dateB = moment(b.date_time.split(" ")[0]);

    return dateB - dateA;
  });

  return sortedNotifications;
};


const groupNotificationsByDate = (notifications) => {
  const groupedNotifications = {};

  for (const notification of notifications) {
    const dateTimeParts = notification.date_time.split(" ");
    const date = dateTimeParts[0];
    if (!groupedNotifications[date]) {
      groupedNotifications[date] = [];
      groupedNotifications[date].push(moment(date, ("YYYY-MM-DD")).format("DD/MM/YYYY"));
    }
    groupedNotifications[date].push(notification);

  }
  //console.log("groupedNotifications", groupedNotifications)
  return groupedNotifications;
};

const ExpertNotificationsDB = () => {
  const [notifications, setNotifications] = useState([]);
  const [selectedTab, setSelectedTab] = useState("All");
  const [groupedNotifications, setGroupedNotifications] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  const { currentUser } = useAuth();

  useEffect(() => {
    // Check if user is logged in
    const isLoggedIn = isUserLoggedIn();

    if (!isLoggedIn) {
      // Redirect to "/login"
      //navigate("/login");
      window.location.href = "https://www.unitedwecare.com/";
    }
  }, [location, navigate]);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  useEffect(() => {
    const payload = {
      user_id: currentUser?.UserId,
    };

    const postNotificationsData = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/expert/dashboard/notifications`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const data = await postResponse.data?.notifications;
        const sortedData = await sortNotificationsByDate(data)
        const groupedData = groupNotificationsByDate(sortedData);



        setNotifications(data);
        setGroupedNotifications(groupedData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postNotificationsData();
  }, [selectedTab]);

  const handleTabSelect = (e) => {
    setSelectedTab(e.target.innerText);
  };



  return (
    <div className="my-3 ExpertNotificationsDashboard">
      <div className="ExpertNotificationsDashboard--Header">
        <h2>Notifications</h2>

        <div className="d-flex gap-3 Header--Options">
          <div
            className={`Option--Item ${selectedTab === "All" ? "SelectedItem" : ""
              }`}
            onClick={(e) => handleTabSelect(e)}
          >
            All
          </div>
          <div
            className={`Option--Item ${selectedTab === "Appointments" ? "SelectedItem" : ""
              }`}
            onClick={(e) => handleTabSelect(e)}
          >
            Appointments
          </div>
          <div
            className={`Option--Item ${selectedTab === "Review" ? "SelectedItem" : ""
              }`}
            onClick={(e) => handleTabSelect(e)}
          >
            Review
          </div>
          {/* <div
            className={`Option--Item ${selectedTab === "Clients" ? "SelectedItem" : ""
              }`}
            onClick={(e) => handleTabSelect(e)}
          >
            Clients
          </div> */}
        </div>
      </div>

      <div className="ExpertNotificationsDashboard--Content">
        {
          (notifications === undefined || notifications === null || notifications?.length === 0) ? <p style={{ margin: '0 auto', textAlign: 'center' }}>No New notifications</p> :
            Object.values(groupedNotifications)?.map((item, index) => {
              const filteredItems = item.slice(1).filter((el) => {
                if (selectedTab === "All") {
                  return true;
                } else if (selectedTab === "Review") {
                  return el.type === "review";
                } else if (selectedTab === "Appointments") {
                  return el.type === "appointment";
                } else if (selectedTab === "Clients") {
                  return el.type !== "appointment" && el.type !== "review";
                }
                return false;
              });
              //console.log('filteredItems', filteredItems)

              return (
                <div className="Content--Item" key={index}>
                  {filteredItems.length > 0 && (
                    <h6>
                      {moment(item[0], "DD-MM-YYYY[T]HH:mm:ss z").format(
                        "D MMM YYYY"
                      )}
                    </h6>
                  )}
                  <div>
                    {filteredItems?.map((el) => {
                      //console.log("el", el);
                      return (
                        <NotificationRowCard
                          key={el.id}
                          image={el.image}
                          title={el.title}
                          clientID={el.uwc_user_id}
                          appointmentID={el.appointment_id}
                          type={el.type}
                          description={el.description}
                          day={el.day}
                          time={el.time}
                          clientType={el.client_type}
                          session={el.session}
                          joinNow={el.join_btn}
                        />
                      )

                    })}
                  </div>
                </div>
              );
            })}

        {/* <div className="Content--Item">
          <h6>Today</h6>
          <div>
            {notifications?.map((item) => (
              <NotificationRowCard
                key={item.appointment_id}
                image={item.image}
                title={item.title}
                clientID={item.client_id}
                appointmentID={item.appointment_id}
                type={item.type}
                description={item.description}
                day={item.day}
                time={item.time}
                clientType={item.client_type}
                sessionType={item.session_type}
              />
            ))}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ExpertNotificationsDB;
