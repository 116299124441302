import { useState, useEffect, useContext } from 'react'
import { useAuth } from '../../auth';
import { AppContext } from '../../context';
import { useNavigate } from 'react-router-dom';

const PlanDetailsLanding = ({ type }) => {

    const [pageValue, setPageValue] = useState({});
    const [selectedItem, setSelectedItem] = useState(1);
    const { currentUser } = useAuth();
    const { ShowLloginPopup, setShowLloginPopup } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (type == "Platinum") {
            setPageValue({
                type,
                price: "$134.99 / Month",
                sessionTime: 'UNLIMITED',
                componentCSS: "RTL"
            })
        } else if (type == "Premium") {
            setPageValue({
                type,
                price: "$14.99/ Month",
                // sessionTime: '3 Hour',
                componentCSS: "LTR"
            })
        } else if (type == "Super") {
            setPageValue({
                type,
                price: "$64.99/ Month",
                sessionTime: '2 sessions',
                componentCSS: "RTL"
            })
        }
    }, []);

    const onClickSubscribe = (pageType) => {
        if (!currentUser) {
            //window.location.href=`/login?retrunurl=/detail/${id}`;
            // navigate(`/login?retrunurl=/course/course-order-preview/${id}`);
            localStorage.setItem("retrunurl", `/subscription-order-summary`)
            setShowLloginPopup(true);
        }
        else {
            if (pageType === "Platinum") {
                sessionStorage.setItem("subscriptionID", 2);
                navigate(`/subscription-order-summary`)
            } else if (pageType === "Premium") {
                sessionStorage.setItem("subscriptionID", 11);
                navigate(`/subscription-order-summary`)
            } else if (pageType === "Super") {
                sessionStorage.setItem("subscriptionID", 12);
                navigate(`/subscription-order-summary`)
            }
        }
    }

    return (
        <div className={`d-flex gap-5 PlanDetails--Header ${pageValue.componentCSS} ${pageValue.type}`}>

            <div className='Header--Left'>
                <h2>{type} Care</h2>
                <h6>{pageValue.price}</h6>


                <div className='PackOffers'>


                    <div className={`Offers--Item ${selectedItem == 1 ? "SelectedItem" : ''}`} onMouseEnter={() => setSelectedItem(1)} onClick={() => setSelectedItem(1)}>
                        <div className='Item--Number'>1</div>
                        <div className='Item--Desc'>
                            {
                                type == "Platinum" &&
                                (
                                    <p>Personalized 1-on-1 Sessions - <span>{pageValue.sessionTime}</span></p>
                                )
                                || type == "Super" &&
                                (
                                    <p>Personalized 1-on-1 Sessions - <span>{pageValue.sessionTime}</span></p>
                                )
                                ||
                                (
                                    <p>Chat with Stella - anytime, anywhere</p>
                                )
                            }

                            {
                                type == "Platinum" &&
                                (
                                    <span className={`${selectedItem !== 1 ? 'd-none' : 'flex'}`}>You get unlimited one-on-one sessions with our top, highly qualified professionals.</span>
                                )
                                || type == "Super" &&
                                (
                                    <span className={`${selectedItem !== 1 ? 'd-none' : 'flex'}`}>You get 2 one-on-one sessions with our top, highly qualified professionals.</span>
                                )
                                ||
                                (
                                    <span className={`${selectedItem !== 1 ? 'd-none' : 'flex'}`}>Stella is our ultimate wellness expert, enabled with generative AI that understands 29 languages and 40 human emotions.</span>
                                )
                            }

                        </div>
                    </div>
                    <div className={`Offers--Item ${selectedItem == 2 ? "SelectedItem" : ''}`} onMouseEnter={() => setSelectedItem(2)} onClick={() => setSelectedItem(2)}>
                        <div className='Item--Number'>2</div>
                        <div className='Item--Desc'>
                            {
                                type == "Platinum" &&
                                (
                                    <p>Chat with Stella - anytime, anywhere</p>
                                )
                                || type == "Super" &&
                                (
                                    <p>Chat with Stella - anytime, anywhere</p>
                                )
                                ||
                                (
                                    <p>Premium Courses</p>
                                )
                            }

                            {
                                type == "Platinum" &&
                                (
                                    <span className={`${selectedItem !== 2 ? 'd-none' : 'flex'}`}>Stella is our ultimate wellness expert, enabled with generative AI that understands 29 languages and 40 human emotions.</span>
                                )
                                || type == "Super" &&
                                (
                                    <span className={`${selectedItem !== 2 ? 'd-none' : 'flex'}`}>Stella is our ultimate wellness expert, enabled with generative AI that understands 29 languages and 40 human emotions.</span>
                                )
                                ||
                                (
                                    <span className={`${selectedItem !== 2 ? 'd-none' : 'flex'}`}>Unlock 40+ premium courses and enhance your mental health and wellness.</span>
                                )
                            }
                        </div>
                    </div>

                    <div className={`Offers--Item ${selectedItem == 3 ? "SelectedItem" : ''}`} onMouseEnter={() => setSelectedItem(3)} onClick={() => setSelectedItem(3)}>
                        <div className='Item--Number'>3</div>
                        <div className='Item--Desc'>
                            {
                                type == "Platinum" &&
                                (
                                    <p>Premium Courses</p>
                                )
                                || type == "Super" &&
                                (
                                    <p>Premium Courses</p>
                                )
                                ||
                                (
                                    <p>Enriching Content</p>
                                )
                            }
                            {
                                type == "Platinum" &&
                                (
                                    <span className={`${selectedItem !== 3 ? 'd-none' : 'flex'}`}>Unlock 40+ premium courses and enhance your mental health and wellness.</span>
                                )
                                || type == "Super" &&
                                (
                                    <span className={`${selectedItem !== 3 ? 'd-none' : 'flex'}`}>Unlock 40+ premium courses and enhance your mental health and wellness.</span>
                                )
                                ||
                                (
                                    <span className={`${selectedItem !== 3 ? 'd-none' : 'flex'}`}>You can access the exclusive self-care content of 100+ videos, audio, and blogs</span>
                                )
                            }
                        </div>
                    </div>
                    {
                        type == "Platinum" && (
                            <div className={`Offers--Item ${selectedItem == 4 ? "SelectedItem" : ''}`} onMouseEnter={() => setSelectedItem(4)} onClick={() => setSelectedItem(4)}>
                                <div className='Item--Number'>4</div>
                                <div className='Item--Desc'>
                                    <p>Enriching Content</p>
                                    <span className={`${selectedItem !== 4 ? 'd-none' : 'flex'}`}>You can access the exclusive self-care content of 100+ videos, audio, and blogs</span>

                                </div>
                            </div>
                        )
                    }

                    {
                        type == "Super" && (
                            <div className={`Offers--Item ${selectedItem == 4 ? "SelectedItem" : ''}`} onMouseEnter={() => setSelectedItem(4)} onClick={() => setSelectedItem(4)}>
                                <div className='Item--Number'>4</div>
                                <div className='Item--Desc'>
                                    <p>Enriching Content</p>
                                    <span className={`${selectedItem !== 4 ? 'd-none' : 'flex'}`}>You can access the exclusive self-care content of 100+ videos, audio, and blogs</span>

                                </div>
                            </div>
                        )
                    }
                </div>

                <button onClick={() => onClickSubscribe(pageValue.type)}>Subscribe</button>
            </div>
            {
                pageValue.type == "Platinum" && <img src='/assets/img/planDetails/PlatinumCareLanding.png' /> ||
                pageValue.type == "Premium" && <img src='/assets/img/planDetails/PremiumCareLanding.png' /> ||
                pageValue.type == "Super" && <img src='/assets/img/planDetails/SuperCareLanding_1.png' />
            }


        </div>
    )
}

export default PlanDetailsLanding