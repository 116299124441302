import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import Grid from "@mui/material/Grid";
import WorksheetCard from "./cards/WorksheetCard";
import ArticleCard from "./cards/ArticleCard";
import NewArticle from "./component/NewArticle";
import ComingSoon from "./component/ComingSoon";

//Helpers
import isUserLoggedIn from "./helpers/redirect";
import { useNavigate, useLocation } from 'react-router-dom';

const ExpertArticlesDB = () => {
  const [newArticle, setNewArticle] = useState(false);
  const [articlesList, setArticlesList] = useState([]);

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const navigate = useNavigate();
  const location = useLocation();
  
  // Check if user is logged in
  useEffect(() => {
   
    const isLoggedIn = isUserLoggedIn();

    if (!isLoggedIn) {
      // Redirect to "/login"
      //navigate('/login');
      window.location.href = "https://www.unitedwecare.com/";
    }
  }, [location, navigate]);

  useEffect(() => {
    const payload = {
      user_id: 1,
    };

    const postArticlesData = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/expert/dashboard/articles`,
          {
            method: "POST",
            headers,
            body: JSON.stringify(payload),
          }
        );
        const postResponse = await response.json();

        const data = await postResponse.data?.articles;

        setArticlesList(data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    postArticlesData();
  }, []);

  const handleNewArticle = () => {
    setNewArticle(!newArticle);
  };
  console.log("articlesList", articlesList);

  /*return (
    <div className="mt-3 ExpertArticles--Dashboard">
      <div className="ExpertArticles--Header">
        <h2>{!newArticle ? "Articles" : "New Article"}</h2>
      </div>
      {newArticle ? (
        <NewArticle />
      ) : (
        <>
          <div className="d-flex ExpertArticles--Options align-items-center mt-3">
            <div className="d-flex gap-3 Options">
              <div className="Option--Item SelectedItem">All Articles</div>
              <div className="Option--Item">Published</div>
              <div className="Option--Item">Unpublished</div>
              <div className="Option--Item">Drafts</div>
            </div>

            <Button
              onClick={handleNewArticle}
              variant="contained"
              className="PrimaryCTA"
              disableElevation
            >
              <AddCircleIcon />
              Add New Article
            </Button>
          </div>

          <div className="ExpertAppointmentDashboard--Content mt-3 padding-3">
            <Grid container spacing={2}>
              {articlesList?.map((item) => (
                <Grid item xs={3} key={item.id}>
                  <ArticleCard
                    comments={item.comments}
                    views={item.views}
                    date={item.date}
                    id={item.id}
                    img={item.image}
                    title={item.name}
                    status={item.status}
                  />
                </Grid>
              ))}
            </Grid>
          </div>
        </>
      )}
    </div>
  );*/

  return <ComingSoon />;
};

export default ExpertArticlesDB;
