import React from 'react'
import '.././Plans.css'

const BestArea = () => {
    return (
        <div>
            {/* <!------ best-area ------> */}
            <div className="best-area">
                <div className="container">
                    <div className="best-inner">
                        <h2 className="heading1">The very best in the world have <br />
                            chosen us for <span className="diff-clr">trust</span></h2>
                        <img src="assets/img/ratting.png" alt='' />
                        <ul>
                            <li><img src="assets/img/Check icon.png" alt='' /> 100+ Licensed Experts and Mental Health professionals (Wellness Experts, Life Coaches, Psychologists, and Psychiatrists)</li>
                            <li><img src="assets/img/Check icon.png" alt='' /> 40+ premium courses; progress at your pace</li>
                            <li><img src="assets/img/Check icon.png" alt='' /> 12,000+ people have entrusted us with their well-being</li>
                            <li><img src="assets/img/Check icon.png" alt='' /> STELLA - diagnoses with 80% efficacy</li>
                            <li><img src="assets/img/Check icon.png" alt='' /> 75% of people saw a change in sleeping patterns after using STELLA</li>
                            <li><img src="assets/img/Check icon.png" alt='' /> Tri-star certified - HIPPA, GDPR, ISO 27000</li>
                        </ul>
                        <div className="plan1-btn mt-4">
                            <button type="button" className="btn center-btn py-2"><a href="#Subscribe_Now">Subscribe Now</a></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BestArea